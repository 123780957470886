import countryData from 'country-data';

export default (country) => {
  if (window.locales.locationsList[country])
    return window.locales.locationsList[country.toLowerCase()];

  let name = '';
  try {
    name = countryData.countries[country.toUpperCase()].name;
  } catch (e) {
    name = country;
  }

  return name;
};
