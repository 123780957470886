import Network from '../../common/Network';
import { getActiveProject } from '../Projects';

window.data.authentifications = {};

const getAuthentifications = (clearCache) => {
  return new Promise((resolve) => {
    let project = getActiveProject();
    if (window.data.authentifications[project.publickey] && clearCache)
      delete window.data.authentifications[project.publickey];

    if (window.data.authentifications[project.publickey])
      return resolve(window.data.authentifications[project.publickey]);

    loadAuthentifications()
      .then((response) => {
        try {
          window.data.authentifications[project.publickey] = response.response.all_auth_settings;
          return resolve(window.data.authentifications[project.publickey]);
        } catch (e) {
          return resolve([]);
        }
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

const loadAuthentifications = (publickey) => {
  publickey = publickey ? publickey : getActiveProject().publickey;
  let request = window.config.host + 'portal/project/authentifications_setting';
  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
  };

  return Network.get(request, params);
};

const editAuthentification = (publickey, auth_method, auth_settings, auth_settings_id) => {
  publickey = publickey ? publickey : getActiveProject().publickey;

  let request = window.config.host + 'portal/project/authentifications_setting';
  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
    auth_method: auth_method,
    auth_settings: auth_settings,
    auth_settings_id: auth_settings_id,
  };

  return Network.put(request, params);
};

const addAuthentification = (publickey, auth_method, auth_settings) => {
  publickey = publickey ? publickey : getActiveProject().publickey;

  let request = window.config.host + 'portal/project/authentifications_setting';
  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
    auth_method: auth_method,
    auth_settings: auth_settings,
  };

  return Network.post(request, params);
};

const deleteAuthentifications = (publickey, auth_method, auth_settings_id) => {
  return new Promise((resolve, reject) => {
    publickey = publickey ? publickey : getActiveProject().publickey;

    let request = window.config.host + 'portal/project/authentifications_setting';
    let params = {
      access_token: window.user.firebaseToken,
      publickey: publickey,
      auth_method: auth_method,
      auth_settings_id: auth_settings_id,
    };

    return Network.delete(request, params)
      .then(() => {
        return resolve();
      })
      .catch(() => {
        return reject({ message: 'Internal server error' });
      });
  });
};

export {
  loadAuthentifications,
  addAuthentification,
  getAuthentifications,
  editAuthentification,
  deleteAuthentifications,
};
