import React, { Component } from 'react';

class ExportingFailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 27,
      height: props.height ? props.height : 23,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 27,
      height: props.height ? props.height : 23,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={'icons addUser ' + this.state.theme + (this.state.label ? ' flex' : '')}
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fill="#FF5C5C"
            fillRule="evenodd"
            stroke="#FF5C5C"
            d="M20.403 17a6.967 6.967 0 0 1-6.788 5.271 6.97 6.97 0 0 1-7-7c0-3.889 3.11-7 7-7 3.889 0 7 3.111 7 7 0 .161-.006.32-.016.479h.213c2.125 0 3.313-1.563 3.5-3.063.25-1.562-.5-3.312-2.562-3.874l-.375-.126-.063-.374a3.39 3.39 0 0 0-2.125-2.688c-1.125-.438-2.375-.313-3.375.438l-.687.5-.25-.813c-.813-2.375-2.75-3.313-4.563-3.313-.437 0-.874 0-1.312.126-2.125.5-4.125 2.374-3.688 5.437l.063.625-.625.125c-2.188.375-3 2.125-2.875 3.75s1.25 3.25 3.5 3.25h.875c0 .438.063.875.188 1.25H5.375c-3 0-4.563-2.25-4.75-4.375C.5 10.687 1.5 8.375 4 7.687 3.812 4.25 6.25 2 8.688 1.438c2.437-.562 5.562.25 7 3.25 1.187-.625 2.624-.687 3.937-.187 1.438.563 2.438 1.813 2.813 3.25 2.5.813 3.375 3.188 3.125 5.063-.25 2-1.813 4.187-4.688 4.187h-.472zm-6.788 3.716a5.41 5.41 0 0 0 5.444-5.445 5.41 5.41 0 0 0-5.444-5.444A5.41 5.41 0 0 0 8.17 15.27a5.41 5.41 0 0 0 5.445 5.445zm-2.99-5.966h5.655V16h-5.655v-1.25z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default ExportingFailed;
