import React, { Component } from 'react';

import {
  IconUser,
  IconEvent,
  IconExport,
  IconNetwork,
  IconSettings,
  IconSessions,
  IconDashboard,
} from '../../common/icons';

import { goTo } from '../../common/methods';
import { IconLogoSmall } from '../../common/icons';
import { ThemeContext } from '../../ThemeContext';
import { VersionApplicationContainer, VersionApplication, LogoContainer } from './components';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      permissions: props.permissions,
      activeProject: props.activeProject,
    };
    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;
    this.setState({
      page: props.page,
      permissions: props.permissions,
      activeProject: props.activeProject,
    });
  }

  goTo(page) {
    goTo(page + '/' + this.state.activeProject.publickey);
  }

  render() {
    return (
      <div id="SideMenu">
        <div className={'elementsContainer'}>
          <div
            id={'SIDE_MENU_DASHBOARD'}
            className={'element' + (this.state.page === 'dashboard' ? ' active' : '')}
            onClick={this.goTo.bind(this, 'dashboard')}
          >
            <div className="blueLine" />
            <div className="contentContainer">
              <IconDashboard theme={this.state.page === 'dashboard' ? 'blue' : 'default'} />
              <div className="name noselect">{window.locales.dashboard}</div>
            </div>
          </div>
          <div
            id={'SIDE_MENU_USERS'}
            className={
              'element' + (['users', 'user'].indexOf(this.state.page) !== -1 ? ' active' : '')
            }
            onClick={this.goTo.bind(this, 'users')}
          >
            <div className="blueLine" />
            <div className="contentContainer">
              <IconUser
                theme={['users', 'user'].indexOf(this.state.page) !== -1 ? 'blue' : 'default'}
              />
              <div className="name noselect">{window.locales.users}</div>
            </div>
          </div>
          <div
            id={'SIDE_MENU_SESSIONS'}
            className={'element' + (this.state.page === 'active_sessions' ? ' active' : '')}
            onClick={this.goTo.bind(this, 'active_sessions')}
          >
            <div className="blueLine" />
            <div className="contentContainer">
              <IconSessions theme={this.state.page === 'active_sessions' ? 'blue' : 'default'} />
              <div className="name noselect">{window.locales.activeSessions}</div>
            </div>
          </div>
          {this.state.permissions.indexOf('project_countries_read') !== -1 && (
            <div
              id={'SIDE_MENU_NETWORK'}
              className={
                'element' +
                (this.state.page === 'network' || this.state.page === 'pools' ? ' active' : '')
              }
              onClick={this.goTo.bind(this, 'network')}
            >
              <div className="blueLine" />
              <div className="contentContainer">
                <IconNetwork
                  theme={
                    this.state.page === 'network' || this.state.page === 'pools'
                      ? 'blue'
                      : 'default'
                  }
                />
                <div className="name noselect">{window.locales.network}</div>
              </div>
            </div>
          )}
          <div
            id={'SIDE_MENU_SETTINGS'}
            className={'element' + (this.state.page === 'settings' ? ' active' : '')}
            onClick={this.goTo.bind(this, 'settings')}
          >
            <div className="blueLine" />
            <div className="contentContainer">
              <IconSettings theme={this.state.page === 'settings' ? 'blue' : 'default'} />
              <div className="name noselect">{window.locales.settings}</div>
            </div>
          </div>
          <div
            id={'SIDE_MENU_EXPORT'}
            className={'element' + (this.state.page === 'export' ? ' active' : '')}
            onClick={this.goTo.bind(this, 'export')}
          >
            <div className="blueLine" />
            <div className="contentContainer">
              <IconExport theme={this.state.page === 'export' ? 'blue' : 'default'} />
              <div className="name noselect">{window.locales.exportData}</div>
            </div>
          </div>
          <div
            id={'SIDE_MENU_LOG'}
            className={'element' + (this.state.page === 'event' ? ' active' : '')}
            onClick={this.goTo.bind(this, 'event')}
          >
            <div className="blueLine" />
            <div className="contentContainer">
              <IconEvent theme={this.state.page === 'event' ? 'blue' : 'default'} />
              <div className="name noselect">{window.locales.log}</div>
            </div>
          </div>
        </div>
        <div className={'logo'}>
          <ThemeContext.Consumer>
            {(context) => (
              <LogoContainer>
                <IconLogoSmall color={context.theme === '' ? 'white' : 'black'} width="45" />
              </LogoContainer>
            )}
          </ThemeContext.Consumer>
          <VersionApplicationContainer>
            <VersionApplication>{window.locales.version} 3.2.0</VersionApplication>
            <VersionApplication>{window.locales.build} 1</VersionApplication>
          </VersionApplicationContainer>
        </div>
      </div>
    );
  }
}

export default index;
