import React, {Component} from 'react';

import Access from './Access';
import Payments from './Payments';
import Parameters from './Parameters';
import Authentifications from './Authentifications';
import Fireshield from './Fireshield';
import goTo from '../../common/methods/goTo';
import styled from 'styled-components';
import {ThemeContext} from '../../ThemeContext';
import Bypass from "./Bypass"

const TitlePage = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 36px;
  color: ${(props) => (props?.nameTheme === '' ? 'white' : 'black')};
`;

class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: props.tab,
            permissions: props.permissions,
            activeProject: props.activeProject,
        };

        this.dump = JSON.stringify(props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.componentDidMount(props);

        this.setState({
            tab: props.tab,
            permissions: props.permissions,
            activeProject: props.activeProject,
        });
    }

    componentDidMount(props) {
        let state = this.state;
        if (props) {
            state.tab = props.tab;
            state.permissions = props.permissions;
            state.activeProject = props.activeProject;
        }

        if (state.permissions.indexOf('project_read') === -1) return goTo('404');

        switch (state.tab) {
            case 'authentication':
                if (state.permissions.indexOf('portal_project_auth_read') === -1) return goTo('404');
                break;
            case 'access':
                if (state.permissions.indexOf('portal_project_access_read') === -1) return goTo('404');
                break;
            case 'payments':
                if (state.permissions.indexOf('portal_project_payment_read') === -1) return goTo('404');
                break;
            default:
                break;
        }
    }

    changeTab(tab) {
        if (this.state.tab === tab) return;

        goTo('settings/' + this.state.activeProject.publickey + '/' + tab);
    }

    openVPNPage() {
        goTo('vpn/' + this.state.activeProject.publickey);
    }

    render() {
        let content;
        let permissions = this.state.permissions;

        switch (this.state.tab) {
            case 'authentication':
                content = (
                    <Authentifications activeProject={this.state.activeProject} permissions={permissions}/>
                );
                break;
            case 'access':
                content = <Access activeProject={this.state.activeProject} permissions={permissions}/>;
                break;
            case 'payments':
                content = <Payments activeProject={this.state.activeProject} permissions={permissions}/>;
                break;
            case 'bypass':
                content = <Bypass activeProject={this.state.activeProject} permissions={permissions}/>;
                break;
            case 'fireshield':
                content = <Fireshield activeProject={this.state.activeProject} permissions={permissions}/>;
                break;
            default:
                content = <Parameters activeProject={this.state.activeProject} permissions={permissions}/>;
                break;
        }

        return (
            <div id={'screenSettings'} className={'portalContentContainer'}>
                <ThemeContext.Consumer>
                    {(context) => <TitlePage nameTheme={context.theme}>{window.locales.settings}</TitlePage>}
                </ThemeContext.Consumer>
                <div className={'navigation'}>
                    <div
                        className={this.state.tab === 'parameters' ? 'active' : ''}
                        onClick={this.changeTab.bind(this, 'parameters')}
                    >
                        {window.locales.general}
                        <div className={'line'}/>
                    </div>
                    {permissions.indexOf('portal_project_auth_read') !== -1 &&
                    this.state.activeProject.current_user_role.name === 'OWNER' &&
                    <div
                        className={this.state.tab === 'authentication' ? 'active' : ''}
                        onClick={this.changeTab.bind(this, 'authentication')}
                    >
                        {window.locales.authentifications}
                        <div className={'line'}/>
                    </div>
                    }
                    {permissions.indexOf('portal_project_payment_read') !== -1 &&
                    this.state.activeProject.current_user_role.name === 'OWNER' &&
                    <div
                        className={this.state.tab === 'payments' ? 'active' : ''}
                        onClick={this.changeTab.bind(this, 'payments')}
                    >
                        {window.locales.paymentsMethod}
                        <div className={'line'}/>
                    </div>
                    }
                    <div
                        className={this.state.tab === 'bypass' ? 'active' : ''}
                        onClick={this.changeTab.bind(this, 'bypass')}
                    >
                        {window.locales.vpnBypassList}
                        <div className={'line'}/>
                    </div>
                    {permissions.indexOf('portal_project_access_read') !== -1 && (
                        <div
                            className={this.state.tab === 'access' ? 'active' : ''}
                            onClick={this.changeTab.bind(this, 'access')}
                        >
                            {window.locales.member}
                            <div className={'line'}/>
                        </div>
                    )}
                </div>
                {content}
            </div>
        );
    }
}

/**/

export default index;
