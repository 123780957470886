import React, { Component } from 'react';

import { IconUpload, IconQuestion } from '../../common/icons';

import Modal from '../../common/modal';

import { bulkUsersUpdate } from '../../components/Users';

class UpdateByFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      i: 0,
      activeProject: props.activeProject,
    };

    this.file = {};
    this.form = {};
    this.loadUsers = props.loadUsers;
    this.updateLog = undefined;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.activeProject.publickey === props.activeProject.publickey) return;

    this.file = {};
    this.form = {};
    this.loadUsers = props.loadUsers;
    this.updateLog = undefined;
  }

  formSubmit() {
    if (!this.file || Object.keys(this.file).length === 0) return;
    let state = this.state;

    window.Loading.show();
    bulkUsersUpdate(this.file)
      .then((response) => {
        this.updateLog = response;
        state.message = '';
        this.setState(state, () => {
          this.loadUsers({ checkboxes: { all: 'off' }, page: '0' }).then(() => {
            window.Loading.hide();
          });
        });
      })
      .catch((e) => {
        this.setState({ message: e.message }, () => {
          window.Loading.hide();
        });
      });
  }

  showUpdateModal() {
    this.form.file = '';
    this.file = '';
    this.updateLog = undefined;
    this.setState({ i: this.state.i + 1 }, () => {
      this.modal.show();
    });
  }

  hideModal() {
    this.modal.hide();
  }

  render() {
    let cancelText = window.locales.cancel;
    let submitText = window.locales.updateSelectedUsersBtn;
    let submitFunction = this.formSubmit.bind(this);
    let style;

    let updateLog;
    if (this.updateLog) {
      updateLog = [];
      for (let k in this.updateLog) {
        try {
          let l = this.updateLog[k];
          let email = '';
          if (l && l.social && l.social.email) {
            email = l.social.email;
          } else if (l && l.given_name) {
            email = l.given_name;
          }

          let tc = {};
          l.changes = l.changes ? l.changes : {};
          if (typeof l.changes.condition !== 'undefined') {
            tc[window.locales.condition] =
              l.changes.condition === 0 ? window.locales.active : window.locales.disabled;
          }
          if (typeof l.changes.description !== 'undefined') {
            tc[window.locales.description] = l.changes.description;
          }

          let changesRow = [];
          for (let k in tc) {
            changesRow.push(<div key={k}>{k.toLocaleLowerCase() + ': ' + tc[k]}</div>);
          }

          if (changesRow.length === 0) changesRow = window.locales.noChanges;

          updateLog.push(
            <tr key={Number(k)}>
              <td>{l.id}</td>
              <td>{l.user_name}</td>
              <td>{email}</td>
              <td>{changesRow}</td>
              <td>{window.locales[l.status] ? window.locales[l.status] : l.status}</td>
            </tr>,
          );
        } catch (e) {}
      }

      cancelText = window.locales.close;
      submitText = window.locales.downloadUserUpdateLog;
      submitFunction = this.downloadLog.bind(this);
      style = { width: '1000px', marginLeft: '-500px', height: '680px' };
    }

    return (
      <div>
        <IconUpload
          label={window.locales.massUpdate}
          theme={'blue'}
          style={{ marginLeft: '20px' }}
          onClick={this.showUpdateModal.bind(this)}
        />
        <Modal
          title={window.locales.updateSelectedUsersTitle}
          onRef={(ref) => {
            this.modal = ref;
          }}
          message={this.state.message}
          cancel={{
            label: cancelText,
            click: this.hideModal.bind(this, 'update'),
          }}
          submit={{
            label: submitText,
            click: submitFunction,
          }}
          style={style}
        >
          {!updateLog && (
            <div className={'modalFileUploadContainer'}>
              <div className={'bulkButtonContainer'}>
                <div className="saveButton">{window.locales.bulkUserAddCsv}</div>
                <input
                  onChange={this.handleFileUpload.bind(this)}
                  type="file"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '181px',
                    height: '30px',
                    opacity: '0',
                  }}
                />
                {this.form.file && this.form.file && <div>{this.form.file}</div>}
                {this.state.error && <div className={'fileError'}>{this.state.error}</div>}
              </div>
              <div className={'exampleList'} style={{ marginTop: '10px' }}>
                <span onClick={this.downloadExample.bind(this)}>
                  {window.locales.downloadExample}
                </span>
                <IconQuestion onClick={this.openExampleDescription.bind(this)} />
              </div>
            </div>
          )}
          {updateLog && (
            <div className={'logPlaceholder'}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <td>{window.locales.id}</td>
                    <td>{window.locales.unm}</td>
                    <td>{window.locales.description}</td>
                    <td>{window.locales.eventChanges}</td>
                    <td>{window.locales.status}</td>
                  </tr>
                </thead>
                <tbody>{updateLog}</tbody>
              </table>
            </div>
          )}
        </Modal>
      </div>
    );
  }

  handleFileUpload(event) {
    let file;
    try {
      file = event.target.files[0];
    } catch (e) {}

    this.form.file = '';

    if (
      !file ||
      !file.name ||
      !file.name.match(/\.[^.]+$/) ||
      ['txt', 'csv'].indexOf(file.name.match(/\.([^.]+)$/)[1].toLowerCase()) === -1
    ) {
      return this.setState({
        error: window.locales.bulkUserError,
      });
    }

    this.form.file = file.name;

    let reader = new FileReader();
    reader.onload = (theFile) => {
      try {
        let result = theFile.target.result;
        let t = result;
        if (t.indexOf('\n') !== -1) t = t.split('\n');
        else if (t.indexOf('\r') !== -1) t = t.split('\r');

        result = {};
        if (typeof t === 'string') t = [t];

        for (let k in t) {
          let temp = t[k].split(',');
          if (!temp || temp.length < 2 || temp[0] === '' || temp[0] === 'User name') continue;
          let name = temp[0].trim();
          let description = temp[1] ? temp[1].trim() : undefined;
          let condition = temp[2] ? temp[2].trim().toLowerCase() : undefined;
          condition = condition === 'active' ? 0 : condition === 'disabled' ? 1 : undefined;

          if (typeof description === 'undefined' && typeof condition === 'undefined') continue;

          result[name] = {};
          if (typeof condition !== 'undefined') result[name].condition = condition;
          if (typeof description !== 'undefined') result[name].description = description;
        }

        this.file = result;
        this.setState({ i: this.state.i + 1, error: '' });
      } catch (e) {
        return this.setState({
          error: window.locales.bulkUserMalformed,
        });
      }
    };
    reader.readAsText(file);
  }

  downloadExample() {
    let element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(`anonymous_3420590384875035017,test description,Active
anonymous_dG91Y2h2cG5fY2FjY2NkYzAzYzY5Yzg3Mw,,Disabled
anonymous_dG91Y2h2cG5fZTgzZTIwMmFiNWYwYmQ5MA,test description2`),
    );
    element.setAttribute('download', 'example.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  openExampleDescription() {
    window.open('https://pango.gitbook.io/pango-platform/console-details/users#mass-update');
  }

  downloadLog() {
    let date = new Date();
    let element = document.createElement('a');
    let log = [
      [
        window.locales.id +
          ',' +
          window.locales.unm +
          ',' +
          window.locales.description +
          ',' +
          window.locales.status,
      ],
    ];

    for (let k in this.updateLog) {
      try {
        let l = this.updateLog[k];
        let email = '';
        if (l && l.social && l.social.email) {
          email = l.social.email;
        } else if (l && l.given_name) {
          email = l.given_name;
        }

        log.push(
          l.id +
            ',' +
            l.user_name +
            ',' +
            email +
            ',' +
            (window.locales[l.status] ? window.locales[l.status] : l.status),
        );
      } catch (e) {}
    }
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(log.join('\n')),
    );
    element.setAttribute(
      'download',
      'USERS-UPDATE_' +
        date.getFullYear() +
        (date.getMonth() + 1) +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        '.csv',
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    this.modal.hide();
  }
}

export default UpdateByFile;
