import React, {Component} from 'react';

import {
  IconArrow
} from '../../../common/icons';

import {
  goTo,
  changeParams
} from "../../../common/methods";

import {
  loadPayments
} from '../../../components/Payments';

import Data from './Data';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      activeProject: props.activeProject,
      path: props.path,
      traffic: props.traffic,
      activeTab: props.params.tab ? props.params.tab : 'parameters',
      payments: false
    };

    this.reload = props.reload;
    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.componentDidMount(props);
  }

  componentDidMount(props) {
    let state = this.state;
    if (props) {
      state.path = props.path;
      state.user = props.user;
      state.traffic = props.traffic;
      state.activeTab = props.params.tab ? props.params.tab : 'parameters';
        state.activeProject = props.activeProject;

      this.reload = props.reload;
    }

    loadPayments().then((payments) => {
      state.payments = payments.length > 0 ? true : false
      this.setState(state);
    });
  }

  back() {
    let params = '';
    if (window.tempStorage.event) {
      delete window.tempStorage.event;
      goTo('#event/' + this.state.activeProject.publickey);
      return;
    } else if (window.tempStorage.search) {
      params = [];
      for (let k in window.tempStorage.search) {
        params.push(k + "=" + window.tempStorage.search[k]);
      }
      params = '?' + params.join("&");
      delete window.tempStorage.search;
    }
    goTo('#users/' + this.state.activeProject.publickey + params);
  }


  changeTab(tab) {
    if (this.state.activeTab === tab) return;

    changeParams('tab', tab);
  }

  render() {
    let splitUsername = this.state.user.name.match(/^([^_]+)([_@])(.*)/);
    if(!splitUsername || splitUsername.length < 4) splitUsername = <h2>{this.state.user.name}</h2>;
    else splitUsername = <h2>{splitUsername[1]}<span style={{textTransform: "inherit"}}>{splitUsername[2]}{splitUsername[3]}</span></h2>;

    return (
      <div className='header'>
        <div className='navi'>
          <span onClick={this.back.bind(this)}>{window.locales.users}</span> {'>'} <span>ID {this.state.path[2]}</span>
        </div>
        {splitUsername}
        <IconArrow onClick={this.back.bind(this)}/>
        <div className='menu'>
          {this.state.activeProject.role !== 'SUPPORT' && <Data user={this.state.user} reload={this.reload}/>}
        </div>
        <div className={'tabNavigation'}>
          <div className={'noselect' + ((this.state.activeTab === 'parameters') ? ' active' : '')}
               onClick={this.changeTab.bind(this, 'parameters')}>{window.locales.parameters}</div>
          <div className={'noselect' + ((this.state.activeTab === 'sessions') ? ' active' : '')}
               onClick={this.changeTab.bind(this, 'sessions')}>{window.locales.sessions}</div>
          <div className={'noselect' + ((this.state.activeTab === 'devices') ? ' active' : '')}
               onClick={this.changeTab.bind(this, 'devices')}>{window.locales.devices}</div>
          {this.state.payments &&
          <div className={'noselect' + ((this.state.activeTab === 'purchases') ? ' active' : '')}
               onClick={this.changeTab.bind(this, 'purchases')}>{window.locales.purchases}</div>}
        </div>
      </div>
    );
  }
}

export default index;
