import '../src/common/storage';
import './locales';
import './common/config';
import {Init} from './common/firebase';
import React from 'react';
import ReactDOM from 'react-dom';
import Controller from './Controller';
import {ThemeContextProvider} from "./ThemeContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import GlobalStyle from "./globalStyle"

window.tempStorage = {};

function Start () {
    window.formId = 0;

    Init().then(() => {
        window.Loading.hide();
        window.panel = this;
        window.controller = ReactDOM.render(
            <Router>
                <GlobalStyle/>
                <ThemeContextProvider>
                    <Switch>
                        <Route exact path="/">
                            <Controller/>
                        </Route>
                    </Switch>
                </ThemeContextProvider>
            </Router>, document.getElementById('page'));
    });
}

Start();
