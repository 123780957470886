import React, { Component } from 'react';

import { IconFilter } from '../../common/icons';

import { Select2 } from '../../common/form';

import Calendar from './Calendar';

import { setParams } from '../../common/methods';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.privateSearchId = 'privateSearch' + ++window.formId;
    this.state = {
      i: 0,
      show: false,
      period: '1',
    };

    this.form = {
      time: props.params.time ? props.params.time : '1',
      start_time: props.params.start_time ? props.params.start_time : undefined,
      end_time: props.params.end_time ? props.params.end_time : undefined,
    };

    this._escFunction = this.escFunction.bind(this);
    this._click = this.click.bind(this);

    document.addEventListener('keydown', this._escFunction);
    document.addEventListener('click', this._click);
  }

  click(e) {
    if (!this.state.show) return;
    let is = (el1, el2) => {
      if (!el1) return false;
      if (el1 === el2) return true;
      if (el1.id === 'loadingContainer') return true;
      return is(el1.parentNode, el2);
    };

    if (e.target && e.target.id && e.target.id.indexOf('react-select-') !== -1) return;

    let el;
    el = document.getElementById(this.privateSearchId);
    if (!is(e.target, el)) {
      return this.setState({ show: false });
    }
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.show) {
      this.setState({ show: false });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.form = {
      time: props.params.time ? props.params.time : '1',
      start_time: props.params.start_time ? props.params.start_time : undefined,
      end_time: props.params.end_time ? props.params.end_time : undefined,
    };
  }

  render() {
    const time = this.createTime();

    return (
      <div className={'EventFilterContainer'} id={this.privateSearchId}>
        <div
          className={'eventFilterContainer ' + (this.state.show ? 'show' : '')}
          onClick={this.changeVisibility.bind(this)}
        >
          <IconFilter label={window.locales.filters} theme={'blue'} />
        </div>
        {this.state.show && (
          <div className={'FilterContainer'}>
            <div className={'row'}>
              <div className={'name'}>{window.locales.period}</div>
              <Select2
                options={time}
                isMulti={false}
                label={'Pick'}
                theme={'dark'}
                value={this.state.period}
                onChange={this.onChange.bind(this, 'time')}
              />
            </div>
            {this.form.time === 'custom' && (
              <div className={'row'}>
                <div className={'name'}>{window.locales.dashboardInterval}</div>
                {window.locales.from}:
                <div className={'rootCalendarContainer'}>
                  <Calendar
                    time={this.form.start_time}
                    onChange={this.onChange.bind(this, 'start_time')}
                  />
                </div>
                {window.locales.till}:
                <div className={'rootCalendarContainer'}>
                  <Calendar
                    time={this.form.end_time}
                    onChange={this.onChange.bind(this, 'end_time')}
                  />
                </div>
              </div>
            )}
            <div className={'row'}>
              <div className={'findBtn'} onClick={this.search.bind(this)}>
                {window.locales.find}
              </div>
              <div className={'resetBtn'} onClick={this.resetSearch.bind(this)}>
                {window.locales.reset}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  onChange(key, value) {
    if (value.value) this.form[key] = value.value;
    else this.form[key] = value;

    if (key === 'time' && value === 'custom') {
      this.form.start_time = Date.now() - 24 * 60 * 60 * 1000;
      this.form.end_time = Date.now();
    }

    this.setState({ i: this.state.i + 1, period: this.form.time });
  }

  createTime() {
    return [
      ['1', window.locales.oneDay],
      ['7', window.locales.sevenDays],
      ['14', window.locales.fourteenDays],
      ['30', window.locales.thirtyDays],
      ['custom', window.locales.custom],
    ];
  }

  changeVisibility() {
    this.setState({ show: !this.state.show });
  }

  search() {
    let startDate;
    let endDate;
    if (this.form.time !== 'custom') {
      delete this.form.end_time;
      delete this.form.start_time;

      const date = new Date();
      startDate = date.setDate(date.getDate() - Number(this.form.time));
      endDate = new Date();
    } else {
      startDate = this.form.start_time;
      endDate = this.form.end_time;
    }

    this.props.applyPeriod(startDate, endDate);
    this.setState({ show: !this.state.show });
  }

  resetSearch() {
    this.form = {
      end_time: Date.now(),
      start_time: Date.now() - 24 * 60 * 60 * 1000,
    };
    setParams(this.form);
  }
}

export default Filter;
