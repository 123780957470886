import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';

window.data.devices = {};

const getDevices = (uid, load) => {
  if (load && window.data.devices[uid]) delete window.data.devices[uid];

  return new Promise((resolve) => {
    if (window.data.devices[uid]) return resolve(window.data.devices[uid]);

    loadDevices(uid)
      .then((devices) => {
        window.data.devices[uid] = devices;
        return resolve(window.data.devices[uid]);
      })
      .catch((e) => {
        return resolve([]);
      });
  });
};

const loadDevices = (uid) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/subscribers/' + uid + '/devices';
    let params = {
      access_token: getActiveToken(),
    };

    Network.get(request, params)
      .then((response) => {
        return resolve(response.response.devices);
      })
      .catch(() => {
        return reject();
      });
  });
};

const deleteDevice = (uid, did) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/subscribers/' + uid + '/devices/' + did;
    let params = {
      access_token: getActiveToken(),
    };

    Network.delete(request, params)
      .then((response) => {
        if (response.response.result === 'NOT_FOUND') {
          return reject({ message: 'Device not found' });
        }
        return resolve(true);
      })
      .catch((e) => {
        return reject({ message: 'Internal server error' });
      });
  });
};

export { getDevices, loadDevices, deleteDevice };
