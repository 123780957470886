import React, {Component} from 'react';

import Table from '../../../common/table';

import {
    byteConvert,
    getDateString,
    jsonBeautifier
} from '../../../common/methods';

import {
    IconPen,
    IconStatus,
} from '../../../common/icons';

import {
    Empty,
    Input,
    Checkbox
} from "../../../common/form";

import {
    editUser,
    changeCondition
} from '../../../components/Users';

import {
    getUsers
} from '../../../components/Users';

import countryData from 'country-data';

import Limit from './Limit';

class Parameters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            traffic: props.traffic,
            activeProject: props.activeProject,
            editCountry: false,
            editExtref: false,
            editLicence: false,
            editDescription: false,
            disabled: false,
            form: {
                country: false
            }
        };

        this.form = {
            country: '',
            extref: '',
            given_name: '',
            licence: 0
        };

        this.reload = props.reload;
        this.dump = JSON.stringify(props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.setState({
            user: props.user,
            traffic: props.traffic,
            activeProject: props.activeProject,
            editCountry: false,
            editExtref: false,
            editLicence: false,
            editDescription: false,
            form: {
                country: false
            }
        });
    }

    fieldChanged(key, value) {
        this.form[key] = value;
    }

    conditionChanged() {
        window.Loading.show();
        changeCondition(this.state.user.id, (!this.state.user.condition ? 1 : 0)).then(() => {
            window.Loading.hide();
            this.reload();
            getUsers();
        }).catch((e) => {
            window.Loading.hide();
            this.reload();
        });
    }

    showEditLicence() {
        this.form.licence = this.state.user.bundle.id;

        this.setState({editLicence: true});
    }

    hideEditLicence() {
        this.setState({editLicence: false});
    }

    saveLicence() {
        window.Loading.show();
        this.setState({
            disabled: true
        }, () => {
            editUser(this.state.user.id, {
                name: this.state.user.name,
                license_id: this.form.licence,
                extref: this.state.user.extref,
                given_name: this.state.user.given_name,
                country: this.state.user.extra.country
            }).then(() => {
                let state = this.state;
                for (let k in this.state.activeProject.licenses) {
                    let l = this.state.activeProject.licenses[k];
                    if (Number(l.id) !== Number(this.form.licence)) continue;

                    state.user.bundle = l;
                }

                state.disabled = false;
                state.editLicence = false;
                this.setState(state, window.Loading.hide);
            }).catch((e) => {
                this.setState({
                    disabled: false,
                }, window.Loading.hide)
            });
        });
    }


    showEditExtref() {
        this.form.extref = this.state.user.extref;

        this.setState({editExtref: true});
    }

    hideEditExtref() {
        this.setState({editExtref: false});
    }

    saveExtref() {
        window.Loading.show();
        this.setState({
            disabled: true
        }, () => {
            editUser(this.state.user.id, {
                name: this.state.user.name,
                license_id: this.state.user.bundle.id,
                extref: this.form.extref,
                given_name: this.state.user.given_name,
                country: this.state.user.extra.country
            }).then(() => {
                let state = this.state;
                state.user.extref = this.form.extref;
                state.disabled = false;
                state.editExtref = false;
                this.setState(state, window.Loading.hide);
            }).catch((e) => {
                this.setState({
                    disabled: false,
                }, window.Loading.hide)
            });
        });
    }

    showEditDescription() {
        this.form.given_name = this.state.user.given_name;

        this.setState({editDescription: true});
    }

    hideEditDescription() {
        this.setState({editDescription: false});
    }

    saveDescription() {
        window.Loading.show();
        this.setState({
            disabled: true
        }, () => {
            editUser(this.state.user.id, {
                name: this.state.user.name,
                license_id: this.state.user.bundle.id,
                extref: this.state.user.extref,
                given_name: this.form.given_name,
                country: this.state.user.extra.country
            }).then(() => {
                let state = this.state;
                state.user.given_name = this.form.given_name;
                state.disabled = false;
                state.editDescription = false;

                for (let k in window.data.users[window.data.activeUser]) {
                    if (window.data.users[window.data.activeUser][k].id === this.state.user.id) {
                        window.data.users[window.data.activeUser][k].given_name = this.form.given_name;
                        break;
                    }
                }

                this.setState(state, window.Loading.hide);
            }).catch((e) => {
                this.setState({
                    disabled: false,
                }, window.Loading.hide)
            });
        });
    }

    showEditCountry() {
        this.form.country = this.state.user.extra.country;

        this.setState({editCountry: true});
    }

    hideEditCountry() {
        this.setState({editCountry: false});
    }

    saveCountry() {
        if (this.form.country.length !== 0 && this.form.country.length !== 2) {
            return this.setState({
                form: {
                    country: true
                }
            });
        }

        if (this.form.country.length === 2) {
            this.form.country = this.form.country.toUpperCase();

            let found = false;
            for (let k in countryData.countries) {
                if (countryData.countries[k].alpha2 === this.form.country) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                return this.setState({
                    form: {
                        country: true
                    }
                });
            }
        }

        window.Loading.show();
        this.setState({
            disabled: true,
            form: {
                country: false
            }
        }, () => {
            editUser(this.state.user.id, {
                name: this.state.user.name,
                license_id: this.state.user.bundle.id,
                extref: this.state.user.extref,
                given_name: this.state.user.given_name,
                country: this.form.country,
            }).then(() => {
                let state = this.state;
                state.user.extra.country = this.form.country;
                state.disabled = false;
                state.editCountry = false;

                for (let k in window.data.users[window.data.activeUser]) {
                    if (window.data.users[window.data.activeUser][k].id === this.state.user.id) {
                        window.data.users[window.data.activeUser][k].extra.country = this.form.country;
                        break;
                    }
                }

                this.setState(state, window.Loading.hide);
            }).catch((e) => {
                this.setState({
                    disabled: false,
                }, window.Loading.hide)
            });
        });
    }

    render() {
        let regTime = getDateString(this.state.user.registration_time),
            body = [],
            licence;

        if (this.state.activeProject.traffic_limit_type && this.state.activeProject.traffic_limit_type === "by_device" && !this.state.traffic.traffic_unlimited) {
            body.push(
                [
                    [window.locales.traffic],
                    [
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {window.locales.by_device}
                            <Limit user={this.state.user} reload={this.reload} traffic={this.state.traffic}/>
                        </div>
                    ],
                ]
            );
        } else if (this.state.traffic.traffic_unlimited) {
            body.push(
                [
                    [window.locales.traffic],
                    [
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {window.locales.unlimited}
                            <Limit user={this.state.user} reload={this.reload} traffic={this.state.traffic}/>
                        </div>
                    ],
                ]
            );
        } else {
            let traffic = this.state.traffic;
            let date = getDateString(traffic.traffic_start);
            let used = byteConvert(traffic.traffic_used ? traffic.traffic_used : 0);
            //let remaining = byteConvert(traffic.traffic_remaining ? traffic.traffic_remaining : 0);
            let limit = byteConvert(traffic.traffic_limit ? traffic.traffic_limit : 0);

            body.push(
                [
                    [window.locales.traffic],
                    [
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{marginRight: '15px'}}>
                                {window.locales.trafficLimit}: {(limit.size + " " + limit.type)},
                            </div>
                            <div style={{marginRight: '15px'}}>
                                {window.locales.trafficUsed}: {(used.size + " " + used.type)},
                            </div>
                            {date &&
                            <div>
                                {window.locales.trafficStart}: {(date ? (date.date + ', ' + date.time) : '')}
                            </div>
                            }
                            <Limit user={this.state.user} reload={this.reload} traffic={this.state.traffic}/>
                        </div>
                    ],
                ]
            );
        }

        try {
            licence = this.state.user.bundle.name;
        } catch (e) {
        }

        body.push([
            [window.locales.licence, {width: '150px'}],
            [<div className={'containForm'}><span>{licence}</span> <IconPen theme={'blue'}
                                                                            onClick={this.showEditLicence.bind(this)}/>
            </div>],
        ]);

        body.push(
            [
                [window.locales.activatedDevices, {width: '150px'}],
                [this.state.user.activated_devices],
            ],
            [
                [window.locales.condition],
                [
                    <Checkbox
                        theme={'blue'}
                        status={(this.state.user.condition === 0 ? 'on' : 'off')}
                        changed={this.conditionChanged.bind(this)}
                        label={(this.state.user.condition === 0 ? ((this.state.user.activated_devices === 0 && this.state.user.auth_method === 'carrier_admin') ? window.locales.invited : window.locales.active) : window.locales.disabled)}
                    />
                ]
            ],
            [
                [window.locales.registrationTime],
                [regTime ? (regTime.date + ', ' + regTime.time) : ''],
            ]
        );

        if (this.state.user.purchases.length > 0) {
            body.push(
                [
                    [window.locales.purchases],
                    [<IconStatus status={(this.state.user.condition === 0 ? 'on' : 'off')}
                                 label={(this.state.user.condition === 0 ? ((this.state.user.activated_devices === 0 && this.state.user.auth_method === 'carrier_admin') ? window.locales.invited : window.locales.active) : window.locales.disabled)}/>]
                ]
            );
        }

        let social = {};

        for (let name in this.state.user.social) {
            let value = this.state.user.social[name];
            if (value === '') continue;
            if (typeof (value) === 'object') value = JSON.stringify(value);

            social[name] = value;
        }

        let description;
        if (!this.state.editDescription) {
            description =
                <div className={'containForm'}>{(this.state.user.given_name && this.state.given_name !== '') &&
                <span>{this.state.user.given_name}</span>} <IconPen theme={'blue'}
                                                                    onClick={this.showEditDescription.bind(this)}/>
                </div>;
        } else {
            description =
                <div className={'containForm'}>
                    <Input
                        value={this.form.given_name}
                        label={window.locales.description}
                        theme={'tableForm'}
                        onChange={this.fieldChanged.bind(this, 'given_name')}
                        onEnter={this.saveDescription.bind(this)}
                        disabled={this.state.disabled}
                    />
                    <div className={'saveButton'} onClick={this.saveDescription.bind(this)}>Save</div>
                    <div className={'cancelButton'} onClick={this.hideEditDescription.bind(this)}>Cancel</div>
                </div>;
        }

        body.push([
            [window.locales.description],
            [description],
        ]);

        let country;
        if (!this.state.editCountry) {
            country = <div className={'containForm'}>{this.state.user.extra.country &&
            <span>{this.state.user.extra.country}</span>} <IconPen theme={'blue'}
                                                                   onClick={this.showEditCountry.bind(this)}/>
            </div>;
        } else {
            country =
                <div className={'containForm'}>
                    <Input
                        value={this.form.country}
                        label={"User country"}
                        theme={'tableForm'}
                        onChange={this.fieldChanged.bind(this, 'country')}
                        onEnter={this.saveCountry.bind(this)}
                        disabled={this.state.disabled}
                        notValid={this.state.form.country}
                    />
                    <div className={'saveButton'} onClick={this.saveCountry.bind(this)}>Save</div>
                    <div className={'cancelButton'} onClick={this.hideEditCountry.bind(this)}>Cancel</div>
                </div>;
        }

        body.push([
            ["User country"],
            [country],
        ]);

        let p = jsonBeautifier(social[this.state.user.auth_method]);
        if (p) {
            p = (
                <div className={'absoluteDiv'}>
                    <div className={'row'}>{p}</div>
                </div>
            );
        }

        if (body.length === 0) {
            return <Empty row1={window.locales.noSocialRow}/>;
        }

        return (
            <div className={'screenUsersContentContainer'}>
                <Table body={body}/>
            </div>
        );
    }
}

export default Parameters;
