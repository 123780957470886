import React, { Component } from 'react';

import { IconSearch, IconClose } from '../../common/icons';

import { Input } from '../../common/form';

class Search extends Component {
  constructor(props) {
    super(props);

    window.data.projectSearch = window.data.projectSearch ? window.data.projectSearch : '';

    this.state = {
      show: !!(window.data.projectSearch !== ''),
    };

    this.callback = props.callback;

    if (window.data.projectSearch !== '') {
      this.callback(window.data.projectSearch);
    }

    this._escFunction = this.escFunction.bind(this);

    document.addEventListener('keydown', this._escFunction);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._escFunction);
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.show) {
      this.setState(
        {
          show: false,
        },
        () => {
          window.data.projectSearch = '';
          this.callback('');
        },
      );
    }
  }

  changeSearchState() {
    this.setState({ show: !this.state.show }, () => {
      if (this.state.show) {
        this.inputRef.setFocus();
      }
    });
  }

  onChange(value) {
    window.data.projectSearch = value;
    this.callback(value);
  }

  closeSearch() {
    this.setState({ show: false }, () => {
      window.data.projectSearch = '';
      this.callback('');
    });
  }

  render() {
    return (
      <div className={'projectsSearch' + (this.state.show ? ' show' : '')}>
        <IconSearch
          id={'ICON_PROJECT_SEARCH'}
          theme="blue"
          label={window.locales.searchProject}
          onClick={this.changeSearchState.bind(this)}
        />
        <div className={'searchContainer'}>
          <IconSearch theme="default flex" />
          <Input
            label={window.locales.searchProject}
            value={window.data.projectSearch}
            onChange={this.onChange.bind(this)}
            onRef={(ref) => (this.inputRef = ref)}
          />
          <IconClose onClick={this.closeSearch.bind(this)} />
        </div>
      </div>
    );
  }
}

export default Search;
