import React, {useContext} from "react";
import {ThemeContext} from "../ThemeContext";
import {ErrorMessage} from "formik";
import styled from "styled-components";
import {IColor, IInput, ISelect, ITab} from "../interfaces";
import {ButtonNoBackgroundContainer, TextButton} from "./Plans/components";

export const Colors = {
    blue: '#00A2FF',
    yellow: '#FFA823',
    red: '#FF0001',
}

export const ColorEnvironment = ({isProduction}: { isProduction?: boolean }) => isProduction ? Colors.blue : Colors.yellow


export const Button = styled.div<{width: string}>`	
  padding-top: 12px;	
  padding-bottom: 12px;	
  display: flex;	
  align-items: center;	
  justify-content: center;	
  border-radius: 4px;	
  border: none;	
  font-family: inherit;	
  color: #ffffff;	
  font-size: 16px;	
  :focus {	
    outline: 0;	
  }	
  width: ${(props) => props.width};	
  :hover {	
  background: #3EB8FF;	
  }	
  cursor: pointer;	
`;

export const ButtonLight = styled(Button)<{isProduction: boolean}>`	
  background: ${ColorEnvironment};	
  :hover {	
    background: ${ColorEnvironment};	
  }	
`;

export const NameInput = styled.div`
  position: absolute;
  font-size: 12px;
  top: -17px;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const HelperTextContainer = styled.div<IColor>`
  display: none;
  color: ${ColorEnvironment};
  font-size: 12px;
  line-height: 140%;
  position: absolute;
  left: calc(100% + 24px);
  width: 334px;
  height: 100%;
  align-items: center;
  top: 0;
`;

export const FieldInput = styled.input<IInput>`
  border: ${(props) =>
          props.isInvalid
                  ? '1px solid #FF0001'
                  : props.nameTheme === 'blue'
                  ? 'unset'
                  : '1px solid #B2B9C4'};
  font-family: Helvetica, serif;
  font-size: 16px;
  outline: unset;
  background: ${(props) => (props.nameTheme === 'blue' ? '#FFFFFF' : 'transparent')};
  border-radius: 4px;
  height: 48px;
  width: 100%;
  padding: 0 35px 0 16px;
  box-sizing: border-box;
  color: ${(props) => (props.nameTheme ? '#000000' : '#ffffff')};

  :-webkit-autofill {
    -webkit-text-fill-color: ${(props) => (props.nameTheme ? '#000000' : '#ffffff')};
  }

  ::placeholder {
    color: #b2b9c4;
  }

  :disabled {
    opacity: 0.5;
  }


  ~ ${NameInput} {
    color: ${(props) => props.isInvalid ? '#FF0001' :
            props.nameTheme === 'blue'
                    ? '#878C95'
                    : '#b2b9c4'
    };
  }

  :focus {
    border: ${(props) => props.isProduction ? '1px solid #00a2ff' : '1px solid #ffa823'};
    color: ${(props) => (props.nameTheme ? '#000000' : '#ffffff')};

    ~ ${NameInput} {
      color: ${(props) => props.isProduction ? '#00a2ff' : '#ffa823'};
    }

    ~ ${HelperTextContainer} {
      display: inline-flex;
    }
  }
`;

export const PopupContextContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 16px;
  justify-items: center;
`;


const FieldSelect = styled.select<ISelect>`
  border: ${(props) => props.isInvalid
          ? '1px solid #FF0001'
          : props.nameTheme === 'blue'
                  ? 'unset'
                  : '1px solid #B2B9C4'
  };
  width: 100%;
  background: ${(props) => (props.nameTheme === 'blue' ? '#FFFFFF' : 'transparent')};
  border-radius: 4px;
  font-size: 16px;
  height: 48px;
  outline: unset;
  color: ${(props) => (props.selected ? (props.nameTheme ? '#000000' : '#ffffff') : '#B2B9C4')};
  padding: 0 35px 0 16px;

  ~ ${NameInput} {
    color: ${(props) => props.isInvalid ? '#FF0001' :
            props.nameTheme === 'blue'
                    ? '#878C95'
                    : '#b2b9c4'
    };
  }

  :focus {
    border: ${(props) => props.isProduction ? '1px solid #00a2ff' : '1px solid #ffa823'};
    color: ${(props) => (props.nameTheme ? '#000000' : '#ffffff')};

    + ${NameInput} {
      color: ${ColorEnvironment};
    }
  }
`;

export const ErrorMessageContainer = styled.div`
  color: #ff0001;
  font-size: 12px;
  position: absolute;
`;

export const CustomErrorMessage = (msg: any) =>
    <ErrorMessageContainer>{msg.children}</ErrorMessageContainer>;

export const CustomInput = (data: any) => {
    const context = useContext(ThemeContext);
    const field = data.field;
    const form = data.form;

    return (
        <InputContainer>
            <FieldInput
                nameTheme={context.theme}
                isInvalid={form.touched[field.name] && form.errors[field.name]}
                type={data.type}
                isProduction={data.isProduction}
                disabled={data.isDisabled}
                {...field}
                onBlur={data.onBlur}
                placeholder={!form.errors[field.name] ? data.placeholder : ''}
            />
            {(form.touched[field.name] || field.value) && <NameInput>{data.placeholder}</NameInput>}
            <ErrorMessage component={CustomErrorMessage} name={field.name}/>
        </InputContainer>
    );
};

export const PopupContainer = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
`;

export const BackButton = styled.div<IColor>`
  color: #878C95;
  margin-right: 40px;
  cursor: pointer;

  :hover {
    color: ${ColorEnvironment};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CommonText = styled.div<IColor>`
  color: ${props => props.nameTheme ? '#000000' : '#FFFFFF'};
`;

export const TabContainer = styled.div<ITab>`
  font-size: 16px;
  color: ${props => props.isSelected ? props.nameTheme ? '#000000' : '#FFFFFF' :
          props.nameTheme ? 'rgba(62, 70, 81, 0.5)' : 'rgba(255, 255, 255, 0.5)'};
  border-bottom: ${props => props.isSelected ? props.isProduction ? '3px solid #2ea6f8' : '3px solid #ffa823' : 'unset'};
  padding-bottom: 8px;
  cursor: pointer;
  margin-right: 25px;
  height: 28px;
  box-sizing: border-box;

  :hover {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    color: inherit;
    border-bottom-color: ${ColorEnvironment};
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  border-bottom: 1px solid;
`;

export const TabsHeaderContainer = styled(CommonText)`
  ${TabsContainer} {
    border-color: ${props => props.nameTheme ? 'rgba(0,0,0,.1)' : 'hsla(0,0%,100%,.3)'}
  }
`;

export const PageContainer = styled.div`
  min-height: calc(100vh - 180px);
  padding: 25px 40px 40px 40px;
  position: relative;
  margin-left: 90px;
`;

export const TitlePageContainer = styled.div`
  display: flex;
  font-size: 36px;
  font-weight: bold;
  align-items: center;
`;

export const ButtonSubmit = styled.button<IColor>`
  cursor: pointer;
  color: #ffffff;
  outline: unset;
  background: ${ColorEnvironment};
  font-size: 16px;
  user-select: none;
  border-radius: 4px;
  border: unset;
  padding: 14px 64px;

  :hover {
    background: ${ColorEnvironment};
  }

  :disabled {
    background: ${props => props.nameTheme ? '#B2B9C466' : '#878C9566'};
    color: ${props => props.nameTheme ? '#FFFFFF' : '#B2B9C4'};
  }
`;

export const Plus = styled.span`
  font-size: 28px;
  line-height: 1%;
  display: flex;
  align-items: center;

  ::after {
    line-height: 1%;
    content: '+';
  }
`;

export const Minus = styled.span`
  height: 15px;
  line-height: 1%;
  font-size: 28px;

  ::after {
    line-height: 1%;
    content: '-';
  }
`;

export const Popup = styled.div<IColor>`
  background: ${(props) => {
    if (props.nameTheme === '') return '#3E4651';
    if (props.nameTheme === 'blue') return '#f5f6f9';
    return '#fff';
  }};
  padding: 40px 48px;
  box-sizing: border-box;
  border-radius: 6px;
  color: ${({nameTheme}) => nameTheme ? '#000000' : '#FFFFFF'};
`;

export const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const NamePopup = styled.div`
  display: grid;
  align-items: center;
  column-gap: 12px;
  grid-auto-flow: column;
`;

export const PopupContentContainer = styled.div`
  padding: 40px 0 50px 0;
  display: grid;
  row-gap: 40px;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

export const NameContainer = styled(CommonText)`
  font-size: 24px;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 312px);
  row-gap: 40px;
  column-gap: 23px;
`;

export const CustomSelect = (data: any) => {
    const context = useContext(ThemeContext);
    const {field, form, options} = data;
    const selected = data.selected?.length === 0 ? data.placeholder : data.selected;
    const isProduction = window.config.project === 'production';
    const elementsOptions = options.map((option: string[], index: number) => <option key={index}
                                                                                     value={option[0]}>{option[1]}</option>)
    return <InputContainer>
        <FieldSelect
            isProduction={isProduction}
            selected={data.selected}
            {...field}
            value={selected}
            isInvalid={form.touched[field.name] && form.errors[field.name]}
            nameTheme={context.theme}>
            <option disabled={true} value={data.placeholder}>{data.placeholder}</option>
            {elementsOptions}
        </FieldSelect>
        {(form.touched[field.name] || field.value) && <NameInput>{data.placeholder}</NameInput>}
        <ErrorMessage component={CustomErrorMessage} name={field.name}/>
    </InputContainer>
}

export const Frame = styled.iframe`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 25%;
  right: 0;
  margin: 0 auto;
  width: 500px;
  height: 600px;
  border: unset;
`;

export const NameFieldCard = styled(NameInput)`
  display: block;
`;

export const FieldContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const DescriptionContainer = styled.div`
  margin-top: 16px;
  width: 504px;
`;

export const Description = styled.div<IColor>`
  color: ${props => props.nameTheme ? '#555F6F' : '#B2B9C4'};
  font-size: 16px;
  line-height: 148%;
`;

export const CurrentPlanButton = () => <ButtonNoBackgroundContainer>
    <TextButton>{window.locales.currentPlan}</TextButton>
</ButtonNoBackgroundContainer>


export const ButtonUpgrade = styled(ButtonSubmit)`
  width: 100%;
`;

export const ButtonPlanContainer = styled.div`
  justify-content: center;
  display: flex;
`;

export const InfoPopup = styled(Popup)`
  padding: 64px 115px;
  display: grid;
  grid-auto-flow: row;
  row-gap: 32px;
  justify-items: center;
`;
