import React, { Component } from 'react';

import ComponentNetwork from './ComponentNetwork';
import Pools from './Pools';

import { loadPools, loadPoolDescription } from '../../components/Network';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let pools = {};
    window.Loading.show();
    loadPools()
      .then((tempPools) => {
        this.loadPoolsDescription(pools, tempPools, 0).then((pools) => {
          this.setState(
            {
              pools: pools,
            },
            window.Loading.hide,
          );
        });
      })
      .catch((e) => {
        this.setState(
          {
            pools: {},
          },
          window.Loading.hide,
        );
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.activeProject.publickey !== nextProps.activeProject.publickey) {
      this.componentDidMount();
    }
  }

  loadPoolsDescription(pools, tempPools, k) {
    return new Promise((resolve) => {
      if (k >= tempPools.length) return resolve(pools);
      loadPoolDescription(tempPools[k]).then((pool) => {
        if (pool) {
          pools[pool.name] = pool;
        }

        this.loadPoolsDescription(pools, tempPools, ++k).then((pools) => {
          return resolve(pools);
        });
      });
    });
  }

  render() {
    switch (this.props.path[0]) {
      case 'pools':
        return (
          <Pools
            pool={this.props.path[2]}
            pools={this.state.pools}
            activeProject={this.props.activeProject}
            updateActiveProject={this.props.updateActiveProject}
            parrentComponentMount={this.componentDidMount.bind(this)}
          />
        );
      default:
        return (
          <ComponentNetwork
            path={this.props.path}
            activeProject={this.props.activeProject}
            pools={this.state.pools}
            permissions={this.props.permissions}
            updateActiveProject={this.props.updateActiveProject}
            parrentComponentMount={this.componentDidMount.bind(this)}
          />
        );
    }
  }
}

export default index;
