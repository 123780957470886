import React, {Component} from 'react';

import {IconSearch, IconClose} from '../../../common/icons';

import {Input} from '../../../common/form';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      publickey: props.publickey
    };

    this.form = {
      search: ''
    };

    this.searchChanged = props.searchChanged;

    this._escFunction = this.escFunction.bind(this);
    document.addEventListener("keydown", this._escFunction);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.publickey === this.state.publickey) return;

    this.searchChanged = props.searchChanged;
    this.setState({
      publickey: props.publickey
    }, () => {
      this.searchChanged(this.form.search);
    })
  }

  changeSearchState() {
    if (this.state.show) {
      this.setState({
        show: false
      }, () => {
        this.form.search = '';
        this.searchChanged(this.form.search);
      });
    } else {
      this.setState({show: true});
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._escFunction);
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.show) {
      this.setState({
        show: false
      }, () => {
        this.form.search = '';
        this.searchChanged(this.form.search);
      })
    }
  }

  onChange(value) {
    this.form.search = value;
    this.searchChanged(value);
  }

  render() {
    return (
      <div className={'bypassSearch' + (this.state.show ? ' show' : '')}>
        <IconSearch theme='blue' label={window.locales.searchDomains} onClick={this.changeSearchState.bind(this)}/>
        <div className={'searchContainer'}>
          <IconSearch theme="default flex"/>
          <Input
            label={window.locales.searchDomains}
            value={this.form.search}
            onChange={this.onChange.bind(this)}
            onRef={ref => (this.inputRef = ref)}
          />
          <IconClose onClick={this.changeSearchState.bind(this)}/>
        </div>
      </div>
    );
  }
}

export default Search;
