import React, { useContext, useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Colors } from '../Colors';
import { ResponsiveContainer } from 'recharts';
import { ThemeContext } from '../../../ThemeContext';
import { IconQuestionGraph, IconIncrease, IconSqueeze } from '../../../common/icons';
import IconNoFound from '../../../common/icons/NoFound';
import { TooltipContainer,  ItemTooltip, Name, Circle, Value, LegendContainer,
    LegendEntity, ItemMetric, NameItemMetric, GraphContainer, Titles, IconContainer, TitleContainer,
    ZoomContainer, TitleLegend, ContainerNoFound
} from './components'

interface GraphData {
    data: any[];
    entity: any;
    id: string;
    info: string;
}

interface Metric {
    key: string;
    index: number;
}

interface MetricItem {
    color: string;
    dataKey: string;
    value: string;
}

export const StackedAreaChart = (props: GraphData) => {
    const context = useContext<{theme: string}>(ThemeContext);
    const [data, setData] = useState(props.data);
    const [currentMetric, setCurrentMetric] = useState<Metric | null>(null);
    const [currentData, setCurrentData] = useState<any>({});
    const [opened, setOpened] = useState(false);
    const entity = props.entity;

    useEffect(() => {
        if (!props.data) return;
        setData(props.data);
        setCurrentData(props.data[props.data.length - 1]);
    }, [props.data]);

    const CustomTooltip = (props: any) => (
        <TooltipContainer contextTheme={context.theme}>
            <p>{props.label}</p>
            {props.payload &&
            props.payload.map((line: MetricItem, index: number) => (
                <ItemTooltip key={index}>
                    <Name>
                        <Circle color={line.color} />
                        {line.dataKey} :
                    </Name>
                    <Value>{line.value}</Value>
                </ItemTooltip>
            ))}
        </TooltipContainer>
    );

    const kFormatter = (num: string) => {
        return Math.abs(Number(num)) >= 1.0e12
            ? (Math.abs(Number(num)) / 1.0e12).toFixed(1) + 'T'
            : Math.abs(Number(num)) >= 1.0e9
                ? (Math.abs(Number(num)) / 1.0e9).toFixed(1) + 'B'
                : Math.abs(Number(num)) >= 1.0e6
                    ? (Math.abs(Number(num)) / 1.0e6).toFixed(1) + 'M'
                    : Math.abs(Number(num)) >= 1.0e3
                        ? (Math.abs(Number(num)) / 1.0e3).toFixed(1) + 'K'
                        : Math.abs(Number(num));
    };

    const toggleMetric = (key: string) => {
        if (currentMetric && currentMetric.key === key) {
            setData(props.data);
            setCurrentMetric(null);
        } else {
            const index = Object.keys(props.data[0]).indexOf(key);
            const newData = props.data.map((graphData) => {
                return {
                    name: graphData.name,
                    [key]: graphData[key],
                };
            });
            setData(newData);
            setCurrentMetric({ key, index });
        }
    };

    const CustomLegend = (props: any) => (
        <LegendContainer>
            {entity && <LegendEntity>{window.locales[entity]}</LegendEntity>}
            {props.payload &&
            props.payload.map((property: MetricItem, index: number) => (
                <ItemMetric
                    contextTheme={context.theme}
                    key={index}
                    selected={currentMetric ? currentMetric.key === property.dataKey : true}
                    onClick={() => toggleMetric(property.dataKey)}
                >
                    <NameItemMetric>
                        <Circle color={property.color} />
                        {property.dataKey}
                    </NameItemMetric>
                    {kFormatter(currentData[property.dataKey])}
                </ItemMetric>
            ))}
        </LegendContainer>
    );

    return (
        <GraphContainer contextTheme={context.theme} opened={opened}>
            <Titles width={opened ? '95%' : '90%'}>
                <TitleContainer>
                    {window.locales[props.id]}
                    <IconContainer>
                        <a href={props.info}>
                            <IconQuestionGraph width={20} />
                        </a>
                        <ZoomContainer onClick={() => setOpened(!opened)}>
                            {opened ? <IconSqueeze width={20} /> : <IconIncrease width={20} />}
                        </ZoomContainer>
                    </IconContainer>
                </TitleContainer>
                <TitleLegend>
                    {data && data.length !== 0 ? window.locales.currentData : window.locales.noData}
                </TitleLegend>
            </Titles>
            <ResponsiveContainer height={300} width={opened ? '95%' : '90%'}>
                {data && data.length !== 0 ? (
                    <AreaChart data={data}>
                        <CartesianGrid vertical={false} stroke="#636C7A" />
                        <XAxis
                            tickLine={false}
                            dataKey="name"
                            stroke="#B2B9C4"
                            axisLine={{ stroke: '#636C7A' }}
                            interval={opened ? 15 : 50}
                        />
                        <YAxis
                            tickLine={false}
                            tickFormatter={kFormatter}
                            tickCount={7}
                            stroke="#B2B9C4"
                            axisLine={false}
                            height={10}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend
                            layout={'vertical'}
                            verticalAlign={'top'}
                            align={'right'}
                            content={<CustomLegend />}
                        />
                        {Object.keys(data[0]).map(
                            (key, index) =>
                                key !== 'name' && (
                                    <Area
                                        key={index}
                                        type="monotone"
                                        dataKey={key}
                                        stackId="1"
                                        stroke={currentMetric ? Colors[currentMetric.index] : Colors[index]}
                                        fillOpacity={0.3}
                                        fill={currentMetric ? Colors[currentMetric.index] : Colors[index]}
                                    />
                                ),
                        )}
                    </AreaChart>
                ) : (
                    <ContainerNoFound>
                        <IconNoFound />
                        <h3>{window.locales.sorryNoData}</h3>
                    </ContainerNoFound>
                )}
            </ResponsiveContainer>
        </GraphContainer>
    );
};
