import React, {useState} from "react";
import Header from './Header'
import styled from "styled-components";
import Bypass from "../Bypass/index";
import ClientNetwork from "./ClientNetworks/index";
import General from "./General";
import {PageContainer} from "../../components";
import {IPage} from "../../../interfaces";

const SettingsContainer = styled.div`
  position: relative;
`;

export default ({activeProject, permissions}: IPage) => {
    const [chosenTab, setChosenType] = useState('General');

    return <PageContainer>
        <Header chosenTab={chosenTab} setChosenType={setChosenType} activeProjectId={activeProject.publickey}/>
        <SettingsContainer>
            {
                chosenTab === 'Bypass' && <Bypass permissions={permissions} activeProject={activeProject}/>
            }
            {
                chosenTab === 'Networks' && <ClientNetwork permissions={permissions} activeProject={activeProject}/>
            }
            {
                chosenTab === 'General' && <General activeProject={activeProject}/>
            }
        </SettingsContainer>
    </PageContainer>
}