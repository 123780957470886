import axios from 'axios';
import {StripeResponse} from '../../enums';

const api = axios.create();

const GetUrlForCheckout = (action: string) =>
    `${window.location.href}?response=${action}&session_id={CHECKOUT_SESSION_ID}`

export const CreateCheckoutSessionRequest = async () => {
    const response = await api.post(`${window.config.host}portal/billing/checkout/create_session`,
        {
            success_url: GetUrlForCheckout(StripeResponse.Success),
            cancel_url: GetUrlForCheckout(StripeResponse.Cancel)
        },
        {
            headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
        });

    return response.data;
}

export const CustomerRequest = async (data: any) => {
    const response = await api.post(`${window.config.host}portal/billing/customer`, data, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
};

export const CreateSubscriptionRequest = async (data: any) => {
    const response = await api.post(`${window.config.host}portal/billing/subscriptions`, data, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
};

export const GetInvoicesRequest = async (id: string) => {
    const response = await api.get(`${window.config.host}portal/billing/subscriptions/${id}/invoices`, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`}
    });
    return response.data;
};

export const CreatePaymentIntentRequest = async (data: any) => {
    const response = await api.post(`${window.config.host}portal/billing/payment_intents`, data, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
};

export const CreateRefundRequest = async (id: string) => {
    const response = await api.post(`${window.config.host}portal/billing/payment_intents/${id}/refund`, {}, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`}
    });
    return response.data;
};

export const AttachPaymentMethodRequest = async (data: any) => {
    const response = await api.post(
        `${window.config.host}portal/billing/payment_methods/attach`,
        data,
        {headers: {Authorization: `Bearer ${window.user.firebaseToken}`}},
    );
    return response.data;
};

export const GetPublicKeyRequest = async () => {
    const response = await api.get(`${window.config.host}portal/billing/params`, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
};

export const GetSessionByIdRequest = async (sessionId: string) => {
    const response = await api.get(`${window.config.host}portal/billing/checkout/session/${sessionId}`, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
}

export const GetPaymentMethodIdBySessionIdRequest = async (intentId: string) => {
    const response = await api.get(`${window.config.host}portal/billing/setup_intents/${intentId}`, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
}

export const GetSubscriptionsRequest = async (publicKey: string) => {
    const response = await api.get(`${window.config.host}portal/billing/project/${publicKey}/subscriptions`, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
}

export const GetPromotionCodeRequest = async (promotionCode: string) => {
    const response = await api.get(`${window.config.host}portal/billing/promotion_codes/${promotionCode}`, {
        headers: {Authorization: `Bearer ${window.user.firebaseToken}`},
    });
    return response.data;
}

export const CreateSetupIntentRequest = async (data: any) => {
    const response = await api.post('setup-intent', data);
    return response.data;
};

export const SetNewCardCustomerRequest = async (data: any) => {
    const response = await api.post('creat-card', data);
    return response.data;
};
