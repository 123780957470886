import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';

const loadEvents = (data, cursor) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/event/filter';
    let params = {
      access_token: getActiveToken(),
    };

    let body = {};

    if (cursor && cursor !== '0') {
      params.cursor = cursor;
    }

    if (data.start_time) params.start_time = data.start_time;
    else params.start_time = Date.now() - 24 * 60 * 60 * 1000;

    if (data.end_time) params.end_time = data.end_time;
    else params.end_time = Date.now();

    try {
      let types = JSON.parse(data.types);
      let temp = [];
      for (let k in types) {
        temp.push(types[k]);
      }
      body.event_types = temp;
    } catch (e) {}

    try {
      let authors = JSON.parse(data.authors);
      let temp = [];
      for (let k in authors) {
        temp.push(authors[k]);
      }
      body.event_author = { firebase_emails: temp };
    } catch (e) {}

    Network.post(request, params, body)
      .then((response) => {
        return resolve(response.response);
      })
      .catch(() => {
        return reject();
      });
  });
};

export { loadEvents };
