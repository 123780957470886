export default (data) => {
  return window.fb.default
    .auth()
    .createUserWithEmailAndPassword(data.email, data.password1)
    .then((response) => {
      window.fb.default.auth().useDeviceLanguage();
      return new Promise((resolve) => {
        try {
          window.fb.default
            .database()
            .ref('/users/' + window.fb.default.auth().currentUser.uid)
            .set({
              first_name: data.firstName,
              last_name: data.lastName,
              company: data.companyName,
              email: data.email,
              tos: Math.floor(new Date().getTime() / 1000),
              first: true,
            })
            .then(() => {
              window.fb.default
                .auth()
                .currentUser.sendEmailVerification()
                .then(() => {
                  return resolve({ success: true });
                });
            })
            .catch((e) => {
              window.fb.default.auth().currentUser.delete();
              return resolve({
                success: false,
                message: 'Internal server error, please try again',
              });
            });
        } catch (e) {
          window.fb.default.auth().currentUser.delete();
          return resolve({ success: false, message: 'Internal server error, please try again' });
        }
      });
    })
    .catch((e) => {
      return { success: false, message: e.message };
    });
};
