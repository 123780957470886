import React, { Fragment } from 'react';
import ProgressBar from '../ProgressBar';
import { getName, overwrite } from 'country-list';
import { ItemContainer, NameItem } from './components'

overwrite([
    {
        code: 'gb',
        name: 'United Kingdom',
    },
]);

export default (props: any) => (
    <Fragment>
        {props.data.map((item: string[], index: number) => (
            <ItemContainer key={index}>
                <NameItem>{getName(item[0])}</NameItem>
                <ProgressBar percent={item[1]} />
            </ItemContainer>
        ))}
    </Fragment>
);
