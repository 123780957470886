import React, { Component } from 'react';

import { getUser } from '../../components/Users';

import { loadTraffic } from '../../components/Traffic';

import { goTo } from '../../common/methods';

import Header from './Header';
import Content from './Content';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined,
      path: props.path,
      params: props.params,
      activeProject: props.activeProject,
    };
  }

  componentDidMount() {
    window.Loading.show();
    getUser(this.state.path[2])
      .then((user) => {
        loadTraffic(user.id)
          .then((traffic) => {
            this.setState(
              {
                user: user,
                traffic: traffic,
              },
              window.Loading.hide,
            );
          })
          .catch((e) => {
            goTo('404');
          });
      })
      .catch((e) => {
        goTo('404');
      });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {
        path: props.path,
        params: props.params,
        activeProject: props.activeProject,
      },
      this.componentDidMount.bind(this),
    );
  }

  render() {
    if (!this.state.user) return <div />;

    return (
      <div
        id="screenUser"
        className="portalContentContainer"
        style={{ minHeight: 'calc(100vh - 180px)' }}
      >
        <Header
          user={this.state.user}
          traffic={this.state.traffic}
          path={this.state.path}
          params={this.state.params}
          activeProject={this.state.activeProject}
          reload={this.componentDidMount.bind(this)}
        />
        <Content
          params={this.state.params}
          user={this.state.user}
          traffic={this.state.traffic}
          activeProject={this.state.activeProject}
          reload={this.componentDidMount.bind(this)}
        />
      </div>
    );
  }
}

export default index;
