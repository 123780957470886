import React, { Component } from 'react';

import DayPicker from 'react-day-picker';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.calendarId = 'calendarRandomId' + ++window.formId;
    let time = props.time ? Number(props.time) : undefined;
    if (time) this.last = new Date(time);
    else this.last = new Date();

    this.last.setDate(1);
    this.last.setMonth(this.last.getMonth());

    this.state = {
      show: false,
      currentMonth: this.last,
      time: time,
    };

    this._escFunction = this.escFunction.bind(this);
    this._click = this.click.bind(this);

    document.addEventListener('keydown', this._escFunction);
    document.addEventListener('click', this._click);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._escFunction);
    document.removeEventListener('click', this._click);
  }

  click(e) {
    if (!this.state.show) return;
    let is = (el1, el2) => {
      if (!el1) return false;
      if (el1 === el2) return true;
      if (el1.id === 'loadingContainer') return true;
      return is(el1.parentNode, el2);
    };

    let el;

    el = document.getElementById(this.calendarId);
    if (!is(e.target, el)) {
      return this.setState({ show: false });
    }
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.show) {
      this.setState({ show: false });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let time = props.time ? Number(props.time) : undefined;
    this.setState({ time: time });
  }

  handleDayClick(input) {
    let day = new Date(input);
    if (day.getTime() > Date.now()) return;

    this.setState({ time: day.getTime() }, () => {
      if (this.props.onChange) {
        this.props.onChange(day.getTime());
      }
    });
  }

  renderDay(day) {
    day.setHours(0, 0, 0);
    let className = this.checkIfSelected(day);

    return <div className={'custom-day' + className}>{day.getDate()}</div>;
  }

  checkIfSelected(day) {
    let ts = day.getTime();
    let date = day.getMonth() + 1 + '/' + day.getDate() + '/' + day.getFullYear();
    if (ts >= Date.now()) return ' disabled';
    if (!this.state.time) return '';

    let selected = new Date(this.state.time);
    let selectedDate =
      selected.getMonth() + 1 + '/' + selected.getDate() + '/' + selected.getFullYear();

    if (date === selectedDate) {
      return ' selected outer first';
    }
    return '';
  }

  render() {
    if (!this.state.show && !this.state.time)
      return (
        <div
          onClick={() => {
            this.setState({ show: true });
          }}
        >
          Any
        </div>
      );

    let date = 'Any';
    if (this.state.time) {
      let day = new Date(this.state.time);
      date = day.getMonth() + 1 + '/' + day.getDate() + '/' + day.getFullYear();
      if (!this.state.show)
        return (
          <div
            onClick={() => {
              this.setState({ show: true });
            }}
          >
            {date}
          </div>
        );
    }

    return (
      <div id={this.calendarId}>
        <div
          onClick={() => {
            this.setState({ show: false });
          }}
        >
          {date}
        </div>
        <div className="calendar_dropdown">
          <DayPicker
            onDayClick={this.handleDayClick.bind(this)}
            numberOfMonths={1}
            renderDay={this.renderDay.bind(this)}
            month={this.state.currentMonth}
            months={window.locales.fullMonth}
            weekdaysShort={window.locales.fullWeek}
            fixedWeeks
          />
        </div>
      </div>
    );
  }
}

export default Calendar;
