window.confirmationResult = undefined

export const sendSMS = () => {
    const {phone} = window.user.infoFromFirebase
    const appVerifier = window.recaptchaVerifier;

    window.fb.default.auth().signInWithPhoneNumber(phone, appVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            // ...
        }).catch(() => {
        // Error; SMS not sent
        // ...
    });
}

export const confirmCode = (code) => {
    window.confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
        // ...
    }).catch(() => {
        // User couldn't sign in (bad verification code?)
        // ...
    });
}