import React, {Component} from 'react';

import {
    Input,
} from '../../../common/form';

import {
    signIn
} from '../../../common/firebase';

import {
    IconLock,
    IconPerson
} from '../../../common/icons';

import {
    goTo
} from '../../../common/methods';

class SignIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: true,
            password: true,
            message: '',
            disabled: false,
            params: props.params ? props.params : {},
        };

        this.form = {
            email: '',
            password: '',
            button: 'Sign In'
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            params: props.params ? props.params : {},
        });
    }

    componentDidMount() {
        window.Loading.hide();
        let autoSignIn = window.storage.get('autoSignIn');
        if (autoSignIn && autoSignIn.email && autoSignIn.password) {
            this.form.email = autoSignIn.email;
            this.form.password = autoSignIn.password;
            window.storage.remove('autoSignIn');

            this.submitForm();
        }
    }

    onChange(name, value) {
        this.form[name] = value;
    }

    submitForm() {
        if (this.state.disabled) return;
        let valid = true;
        let state = this.state;

        if (this.form.email.trim() === '') {
            valid = false;
            state.email = false;
        }

        if (this.form.password.trim() === '') {
            valid = false;
            state.password = false;
        }

        if (!valid) {
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        this.form.button = 'Please wait';

        this.setState({
            email: true,
            password: true,
            message: '',
            disabled: true
        });

        window.Loading.show();

        signIn(this.form.email.trim(), this.form.password.trim()).then((response) => {
            window.Loading.hide();
            goTo('projects');
        }).catch(e => {
            this.setState({
                disabled: false,
                message: e.message
            }, window.Loading.hide);
        });
    }

    render() {
        return (
            <div className="signUpForm signIn">
                <div className={'logo business'} onClick={() => {
                    goTo('')
                }}/>
                <div className="errorMessage">
                    {
                        this.state.params.message
                            ? this.state.params.message
                            : this.state.message
                    }
                </div>
                <div className="pageSwitcher">
                    <h3>{window.locales.signIn}</h3>
                </div>
                <form id={'loginForm'}>
                    <div className="row">
                        <Input
                            id={"SIGN_IN_EMAIL"}
                            type="text"
                            name={"username"}
                            label={window.locales.email}
                            theme={'auth'}
                            value={this.form.email}
                            onChange={this.onChange.bind(this, 'email')}
                            onEnter={this.submitForm.bind(this)}
                            notValid={!this.state.email}
                            disabled={this.state.disabled}
                            icon={<IconPerson theme={'auth'}/>}
                            autoComplete={true}
                        />
                    </div>
                    <div className="row">
                        <Input
                            id={"SIGN_IN_PASSWORD"}
                            type="password"
                            name={"password"}
                            label={window.locales.password}
                            theme={'auth'}
                            value={this.form.password}
                            onChange={this.onChange.bind(this, 'password')}
                            onEnter={this.submitForm.bind(this)}
                            notValid={!this.state.password}
                            disabled={this.state.disabled}
                            icon={<IconLock theme={'auth'}/>}
                            autoComplete={true}
                        />
                    </div>
                </form>
                <div className="pageSwitcher" style={{height: '30px'}}>
                    <a href="#/auth/reset">{window.locales.resetPassword}</a>
                </div>
                <div className={'saveButton' + (this.state.disabled ? ' disabled' : '')} id={"SIGN_IN_SUBMIT"}
                     onClick={this.submitForm.bind(this)}>{window.locales.signIn}</div>
            </div>
        );
    }
}

export default SignIn;