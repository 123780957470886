import React, { Component } from 'react';

class Exporting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={'icons addUser ' + this.state.theme + (this.state.label ? ' flex' : '')}
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fill="#2EA6F8"
            fillRule="nonzero"
            d="M2 10a1 1 0 1 1-2 0C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10a1 1 0 1 1 0-2 8 8 0 1 0-8-8z"
          />
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="360"
            to="0"
            dur="1s"
            additive="sum"
            repeatCount="indefinite"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Exporting;
