import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';

const grafanaSignIn = (name, password) => {
  return new Promise((resolve, reject) => {
    if (!name || !password) {
      window.data.grafana = false;
      return resolve(false);
    }
    if (window.data.grafana) return resolve(true);

    let iframe = document.createElement('iframe');
    iframe.src =
      '/pages/g.html?user=' +
      encodeURIComponent(name) +
      '&password=' +
      encodeURIComponent(password) +
      '&project=' +
      window.config.project;
    iframe.id = 'grafanaIframe';
    document.body.appendChild(iframe);

    setTimeout(() => {
      window.data.grafana = true;
      return resolve(true);
    }, 1000);
  });
};

const exportData = (from, till, type) => {
  return new Promise((resolve, reject) => {
    let tz = 'America/Los_Angeles';
    try {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {}

    let request = window.config.host + 'partner/report/' + type;
    let params = {
      access_token: getActiveToken(),
      tz: tz,
    };

    if (from) params.start_time = from;
    if (till) params.end_time = till;

    Network.post(request, params)
      .then((response) => {
        return resolve();
      })
      .catch(() => {
        return reject();
      });
  });
};

const getTaskList = () => {
  return new Promise((resolve) => {
    let request = window.config.host + 'partner/report/list';
    let params = {
      access_token: getActiveToken(),
    };

    Network.get(request, params)
      .then((response) => {
        try {
          return resolve(response.response.reports);
        } catch (e) {
          return resolve([]);
        }
      })
      .catch((e) => {
        return resolve([]);
      });
  });
};

export { exportData, grafanaSignIn, getTaskList };
