import React, { Component } from 'react';

class Applications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 21,
      height: props.height ? props.height : 14,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 21,
      height: props.height ? props.height : 14,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 Applications ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M16.975 5.25A6.585 6.585 0 0 0 10.5 0C7.963 0 5.775 1.4 4.725 3.5 2.013 3.85 0 6.037 0 8.75 0 11.637 2.362 14 5.25 14h11.375A4.333 4.333 0 0 0 21 9.625c0-2.275-1.837-4.2-4.025-4.375zm-2.1 2.625L10.5 12.25 6.125 7.875H8.75v-3.5h3.5v3.5h2.625z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Applications;
