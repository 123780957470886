import React, { Component } from 'react';

import { IconSearch, IconCheckMark, IconClose } from '../../common/icons';

import { Input } from '../../common/form';

import { changeParams } from '../../common/methods';

let FILTERS = {
  uid: window.locales.uid,
  unm: window.locales.unm,
  utk: window.locales.utk,
  did: window.locales.did,
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: !!props.params.search,
      params: props.params,
      filter: 'uid',
      showFilters: false,
      filtersOpened: false,
    };

    let search = props.params.search ? props.params.search : '';

    let f = search.match(/^([a-z]+)_(.*)/);
    if (f && f[1] && FILTERS[f[1]] && f[2]) {
      this.state.filter = f[1];
      search = f[2];
    }

    this.form = {
      search: search,
    };

    this._escFunction = this.escFunction.bind(this);

    document.addEventListener('keydown', this._escFunction);
  }

  UNSAFE_componentWillReceiveProps(props) {
    FILTERS = {
      uid: window.locales.uid,
      unm: window.locales.unm,
      utk: window.locales.utk,
      did: window.locales.did,
    };

    let state = this.state;
    state.show = !!props.params.search;
    state.params = this.state.params;
    state.showFilters = false;

    if (props.params.search) {
      let f = props.params.search.match(/^([a-z]+)_(.*)/);
      if (f && f[1] && FILTERS[f[1]] && f[2]) {
        this.setState({ ...this.state, filter: f[1] });
        this.form.search = f[2];
      }
    } else {
      this.form.search = '';
    }

    this.setState(state);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._escFunction);
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.show) {
      this.setState({
        show: false,
        showFilters: false,
      });
    }
  }

  changeSearchState() {
    this.setState({ show: !this.state.show }, () => {
      if (this.state.show) {
        this.inputRef.setFocus();
      }
    });
  }

  onChange(value) {
    this.form.search = value;
  }

  search() {
    let search = this.form.search.trim();
    if (search === '') return;

    changeParams('search', this.state.filter + '_' + search);
  }

  changeFilter(filter) {
    this.setState(
      {
        showFilters: false,
        filter: filter,
      },
      () => {
        if (this.form.search.trim() !== '') this.search();
      },
    );
  }

  closeSearch() {
    this.setState({ show: false, showFilters: false }, () => {
      this.form.search = '';
      changeParams('search');
    });
  }

  render() {
    let filters = [];

    for (let k in FILTERS) {
      filters.push(
        <div
          className={'filter ' + (this.state.filter === k ? ' active' : '')}
          key={k}
          onClick={this.changeFilter.bind(this, k)}
        >
          {FILTERS[k]}
          <IconCheckMark />
        </div>,
      );
    }

    return (
      <div className={'userSearch' + (this.state.show ? ' show' : '')}>
        <IconSearch
          theme="blue"
          label={window.locales.searchUsers}
          onClick={this.changeSearchState.bind(this)}
        />
        <div className={'searchContainer'}>
          <IconSearch theme="default flex" onClick={this.search.bind(this)} />
          <div
            className={'filterButton'}
            onClick={() => {
              this.setState({ showFilters: !this.state.showFilters });
            }}
          >
            {FILTERS[this.state.filter]}:
          </div>
          <Input
            label={window.locales.searchUsers}
            value={this.form.search}
            onChange={this.onChange.bind(this)}
            onEnter={this.search.bind(this)}
            onRef={(ref) => (this.inputRef = ref)}
          />
          <IconClose onClick={this.closeSearch.bind(this)} />
        </div>
        <div className={'filters' + (this.state.showFilters ? ' show' : '')}>{filters}</div>
      </div>
    );
  }
}

export default Search;
