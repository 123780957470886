import React, { Component } from 'react';

import Table from '../../common/table';

import { getDateString } from '../../common/methods';

import { getTaskList } from '../../components/Grafana';

import Export from './Export';

import {
  IconCompleted,
  IconExporting,
  IconQueryFailed,
  IconExportingFailed,
} from '../../common/icons';

const NAMES_TYPE = {
  BY_CARRIER: window.locales.BY_CARRIER,
  BY_ALL_USERS: window.locales.BY_ALL_USERS,
  BY_USER: window.locales.BY_ALL_USERS,
  BY_USER_ID: window.locales.BY_ALL_USERS,
  BY_DEVICE_ID: window.locales.BY_ALL_DEVICES,
  BY_ALL_DEVICES: window.locales.BY_ALL_DEVICES,
};

const NAMES_STATUS = {
  NEW: <IconExporting label={window.locales.EXPORTING} />,
  EXPORTING: <IconExporting label={window.locales.EXPORTING} />,
  QUERYING: <IconExporting label={window.locales.EXPORTING} />,
  COMPLETION: <IconExporting label={window.locales.EXPORTING} />,
  COMPLETED: <IconCompleted label={window.locales.COMPLETED} />,
  EXPORTING_FAILED: <IconQueryFailed label={window.locales.EXPORTING_FAILED} />,
  QUERYING_FAILED: <IconExportingFailed label={window.locales.QUERYING_FAILED} />,
};

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: undefined,
      activeProject: props.activeProject,
    };

    this.interval = setInterval(() => {
      getTaskList().then((tasks) => {
        this.setState({
          tasks: tasks,
        });
      });
    }, 30000);
    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState(
      {
        tasks: undefined,
        activeProject: props.activeProject,
      },
      this.componentDidMount.bind(this),
    );
  }

  componentDidMount() {
    window.Loading.show();
    getTaskList().then((tasks) => {
      this.setState(
        {
          tasks: tasks,
        },
        window.Loading.hide,
      );
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  exported() {
    this.componentDidMount();
  }

  render() {
    if (!this.state.tasks) {
      return <div />;
    }

    let head = [
      [window.locales.created, { width: '200px' }],
      [window.locales.type, { width: '150px' }],
      [window.locales.exportFrom, { width: '200px' }],
      [window.locales.exportTill, { width: '200px' }],
      [''],
      [window.locales.exportStatus, { width: '300px' }],
      [window.locales.download, { width: '100px' }],
    ];

    let body = [];
    let j = 0;
    let inProgress = {};

    for (let i = this.state.tasks.length - 1; i >= 0; i--) {
      let task = this.state.tasks[i];

      if (
        !inProgress[task.report_type] &&
        ['NEW', 'EXPORTING', 'QUERYING', 'COMPLETION'].indexOf(task.status) !== -1
      )
        inProgress[task.report_type] = true;

      let created;
      try {
        created = getDateString(task.created_time);
        created = (
          <div>
            {created.date}
            <div className={'lightGray'}>{created.time}</div>
          </div>
        );
      } catch (e) {}

      let url;
      try {
        url = JSON.parse(task.report_urls);
      } catch (e) {}

      let download = [];

      if (url) {
        if (url.length > 1) {
          for (let k in url) {
            let coma = ',';
            if (Number(k) + 1 === url.length) coma = '';

            download.push(
              <div style={{ marginRight: '5px', marginBottom: '5px' }} key={++j}>
                <a href={url[k]} target="_blank" rel="noopener noreferrer">
                  {Number(k) + 1}
                </a>
                {coma}
              </div>,
            );
          }
        } else {
          download.push(
            <a href={url[0]} key={++j} target="_blank" rel="noopener noreferrer">
              {window.locales.download}
            </a>,
          );
        }
      }

      let from;
      if (task.start_time) {
        let time =
          typeof task.start_time === 'number'
            ? task.start_time
            : new Date(task.start_time + '+00:00').getTime();
        from = getDateString(time);
        from = (
          <div>
            {from.date}
            <div className={'lightGray'}>{from.time}</div>
          </div>
        );
      }
      let till;
      if (task.end_time) {
        let time =
          typeof task.end_time === 'number'
            ? task.end_time
            : new Date(task.end_time + '+00:00').getTime();
        till = getDateString(time);
        till = (
          <div>
            {till.date}
            <div className={'lightGray'}>{till.time}</div>
          </div>
        );
      }

      body.push([
        [created],
        [NAMES_TYPE[task.report_type] ? NAMES_TYPE[task.report_type] : task.report_type],
        [from],
        [till],
        [''],
        [NAMES_STATUS[task.status] ? NAMES_STATUS[task.status] : task.status],
        [<div style={{ display: 'flex', flexWrap: 'wrap' }}>{download}</div>],
      ]);
    }

    return (
      <div id={'screenUsers'} className="portalContentContainer">
        <div style={{ position: 'relative' }}>
          <h1>{window.locales.exportData}</h1>
          <Export
            activeProject={this.state.activeProject}
            exported={this.exported.bind(this)}
            inProgress={inProgress}
          />
        </div>
        <Table head={head} body={body} />
      </div>
    );
  }
}

export default index;
