import React, { Component } from 'react';

import Modal from '../../common/modal';

import { goTo, jsonBeautifier } from '../../common/methods';

import Table from '../../common/table';

import { Empty, Checkbox } from '../../common/form';

import { IconPen, IconDelete, IconPlus } from '../../common/icons';

import EditGroup from './EditGroup';

import { updatePool } from '../../components/Network';

import { editProject } from '../../components/Project';

class PoolPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let canWrite = false;
    for (let k in this.props.pool.permissions) {
      if (
        this.props.pool.permissions[k].subject === this.props.activeProject.publickey &&
        this.props.pool.permissions[k].can_write
      ) {
        canWrite = true;
        break;
      }
    }
    let config = {};
    try {
      config = JSON.parse(this.props.activeProject.config);
    } catch (e) {}

    let isPrivate = false;
    for (let k in this.props.pool.permissions) {
      if (
        this.props.pool.permissions[k].subject === this.props.activeProject.publickey &&
        this.props.pool.permissions[k].can_list
      ) {
        isPrivate = true;
        break;
      }
    }

    let head = [
      ['#', { width: '50px' }],
      ['Rule name', { width: '100px' }],
      ['Priority', { width: '75px' }],
      ['Request selector', { width: '600px' }],
      ['Server selector', { width: '200px' }],
      ['Description'],
      ['', { width: '25px' }],
      ['', { width: '25px' }],
    ];

    if (canWrite && isPrivate) {
      head = [
        ['', { width: '50px' }],
        ['#', { width: '50px' }],
        ['Rule name', { width: '100px' }],
        ['Priority', { width: '75px' }],
        ['Request selector', { width: '600px' }],
        ['Server selector', { width: '200px' }],
        ['Description'],
        ['', { width: '25px' }],
        ['', { width: '25px' }],
      ];
    }

    let body = [];

    if (this.props.pool && this.props.pool.groups) {
      let groups = this.props.pool.groups;
      groups.sort((a, b) => {
        if (a.priority > b.priority) return -1;
        else if (a.priority < b.priority) return 1;
        else return 0;
      });

      let private_groups = config.private_groups ? config.private_groups : [];

      for (let k in groups) {
        let group = groups[k];

        let c1 = (
          <div className={'absoluteDiv'}>
            <div className={'row'}>{jsonBeautifier(group.request_selector)}</div>
          </div>
        );
        let d1 = window.locales.config;

        try {
          let t = [];
          for (let j in group.request_selector) {
            let t2 = [];
            if (typeof group.request_selector[j] === 'string') {
              t2.push(
                <div className="tag" key={j}>
                  {group.request_selector[j]}
                </div>,
              );
            } else if (
              typeof group.request_selector[j] === 'object' &&
              group.request_selector[j].in
            ) {
              for (let j1 in group.request_selector[j].in) {
                t2.push(
                  <div className="tag" key={j1}>
                    {group.request_selector[j].in[j1]}
                  </div>,
                );
              }
            }
            t.push(
              <div key={j} className="tagField">
                {window.locales[j] ? window.locales[j] : j}: {t2}
              </div>,
            );
          }
          d1 = t;
        } catch (e) {}

        let c2 = (
          <div className={'absoluteDiv'}>
            <div className={'row'}>{jsonBeautifier(group.server_selector)}</div>
          </div>
        );
        let d2 = window.locales.config;

        try {
          let t = [];
          for (let j in group.server_selector) {
            let t2 = [];
            if (typeof group.server_selector[j] === 'string') {
              t2.push(
                <div className="tag" key={j}>
                  {group.server_selector[j]}
                </div>,
              );
            } else if (
              typeof group.server_selector[j] === 'object' &&
              group.server_selector[j].in
            ) {
              for (let j1 in group.server_selector[j].in) {
                t2.push(
                  <div className="tag" key={j1}>
                    {group.server_selector[j].in[j1]}
                  </div>,
                );
              }
            }
            t.push(
              <div key={j} className="tagField">
                {window.locales[j] ? window.locales[j] : j}: {t2}
              </div>,
            );
          }
          d2 = t;
        } catch (e) {}

        if (canWrite && isPrivate) {
          body.push([
            [
              canWrite && isPrivate ? (
                <Checkbox
                  theme={'blue small'}
                  status={
                    private_groups.indexOf(group.request_selector.private_group) !== -1
                      ? 'on'
                      : 'off'
                  }
                  changed={this.changeGroupStatus.bind(this, group.request_selector)}
                />
              ) : (
                ''
              ),
            ],
            [Number(k) + 1],
            [group.name],
            [group.priority],
            [
              <div>
                {d1}
                {c1}
              </div>,
            ],
            [
              <div>
                {d2}
                {c2}
              </div>,
            ],
            [group.description],
            [canWrite ? <IconPen onClick={this.editGroup.bind(this, group)} theme={'blue'} /> : ''],
            [
              canWrite ? (
                <IconDelete theme={'blue'} onClick={this.showDeleteModal.bind(this, group.name)} />
              ) : (
                ''
              ),
            ],
          ]);
        } else {
          body.push([
            [Number(k) + 1],
            [group.name],
            [group.priority],
            [
              <div>
                {d1}
                {c1}
              </div>,
            ],
            [
              <div>
                {d2}
                {c2}
              </div>,
            ],
            [group.description],
            [canWrite ? <IconPen onClick={this.editGroup.bind(this, group)} theme={'blue'} /> : ''],
            [
              canWrite ? (
                <IconDelete theme={'blue'} onClick={this.showDeleteModal.bind(this, group.name)} />
              ) : (
                ''
              ),
            ],
          ]);
        }
      }
    }

    return (
      <div id={'screenNetwork'} className="portalContentContainer">
        <h1>{window.locales.network}</h1>
        <div className={'navigation'}>
          <div onClick={this.changeTab.bind(this, 'network')}>
            {window.locales.locations}
            <div className={'line'} />
          </div>
          <div onClick={this.changeTab.bind(this, 'pools')}>
            Pools
            <div className={'line'} />
          </div>
          <div className={'active'}>
            Groups of pool: {this.props.pool.name}
            <div className={'line'} />
          </div>
        </div>
        {canWrite && (
          <div>
            <IconPlus
              theme={'blue'}
              label={window.locales.add}
              onClick={this.addGroup.bind(this)}
            />
          </div>
        )}
        {body.length > 0 && <Table head={head} body={body} />}
        {body.length === 0 && <Empty row1="No Groups" />}
        <EditGroup
          pool={this.props.pool}
          parrentComponentMount={this.props.parrentComponentMount.bind(this)}
          activeProject={this.props.activeProject}
          onMount={(modal) => {
            this.editModal = modal;
          }}
        />
        <Modal
          title={'Delete pool group'}
          onRef={(ref) => {
            this.deleteModal = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: () => {
              this.deleteModal.hide();
            },
          }}
          submit={{
            label: 'Delete pool group',
            click: () => {
              this.deleteGroupSubmit();
            },
          }}
        >
          <div className={'bodyText'} style={{ marginBottom: '10px' }}>
            {'Do you want to delete pool group?'}
          </div>
        </Modal>
      </div>
    );
  }

  showDeleteModal(name) {
    this.deleteGroupName = name;
    this.deleteModal.show();
  }

  deleteGroupSubmit() {
    this.deleteModal.hide();
    this.deleteGroup(this.deleteGroupName);
  }

  changeGroupStatus(requestSelector) {
    let config = {};
    try {
      config = JSON.parse(this.props.activeProject.config);
    } catch (e) {}

    if (!config.private_groups) config.private_groups = [];
    const existGroup = config.private_groups.indexOf(requestSelector.private_group) !== -1;
    if (!existGroup) {
      config.private_groups.push(requestSelector.private_group);
    } else {
      config.private_groups.splice(config.private_groups.indexOf(requestSelector.private_group), 1);
    }

    window.Loading.show();
    editProject(this.props.activeProject.publickey, { config: JSON.stringify(config) })
      .then(() => {
        window.Loading.hide();
        let p = this.props.activeProject;
        p.config = JSON.stringify(config);
        this.props.updateActiveProject(p);
      })
      .catch((e) => {
        window.Loading.hide();
        this.props.updateActiveProject(this.props.activeProject);
      });
  }

  deleteGroup(name) {
    window.Loading.show();
    updatePool(this.props.pool.name, { del_groups: [name] })
      .then(() => {
        window.Loading.hide();
        this.props.parrentComponentMount();
      })
      .catch(() => {
        window.Loading.hide();
      });
  }

  editGroup(group) {
    this.editModal(group);
  }

  addGroup() {
    this.editModal({});
  }

  changeTab(page) {
    goTo(page + '/' + this.props.activeProject.publickey);
  }
}

export default PoolPage;
