import styled from "styled-components";
import {FieldInputContainer} from "../components";
import React, {FC} from "react";
import {ICheckbox} from "../../../interfaces";

export const Checkmark = styled.span<{ nameTheme: string}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.nameTheme ? '#b3b9c3' : 'hsla(0,0%,89.8%,.3)'};
  border-radius: 2px;
  :after{
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none;
  }
`;

export const FieldCheckbox = styled.input<{ isProduction: boolean }>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :checked{
    ~ ${Checkmark}{
      background-color: ${(props) => props.isProduction ? '#2ea6f8' : '#ffa823'};
      opacity: 1;
      :after{
         display: block;
      }
    }
  }
`;

export const CheckboxContainer = styled.label`
  height: 10px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 47%;
`;

export const TwoFieldContainer = styled(FieldInputContainer)`
 display: flex;
 align-items: baseline;
 justify-content: space-between;
 margin-bottom: 0;
 input {
  margin-bottom: 30px;
 }
`;

export const InputName = styled.div`
  margin-right: 20px;
`;

export const WrapInputContainer = styled(InputContainer)`
  flex-wrap: wrap;
`;

export const ElementsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

export const SelectContainer = styled.div`
  width: 47%;
  margin-bottom: 30px;
`;


export const CustomCheckbox: FC<ICheckbox> = ({isProduction, nameTheme, onChange, checked}) => <CheckboxContainer>
    <FieldCheckbox isProduction={isProduction!} type={'checkbox'} onChange={onChange} checked={checked}/>
    <Checkmark nameTheme={nameTheme!}/>
</CheckboxContainer>
