import React, { Component } from 'react';

import { IconIos, IconAndroid, IconSmallArrow, IconApplications } from '../../common/icons';

import Network from '../../common/Network';

class Applications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDown: false,
    };

    this._escFunction = this.escFunction.bind(this);
    this._click = this.click.bind(this);

    document.addEventListener('keydown', this._escFunction);
    document.addEventListener('click', this._click);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._escFunction);
    document.removeEventListener('click', this._click);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      if (this.state.dropDown) {
        this.setState({
          dropDown: false,
        });
      }
    }
  }

  click(e) {
    if (!this.state.dropDown) return;

    let is = (el1, el2) => {
      if (!el1) return false;
      if (el1 === el2) return true;
      if (el1.id === 'loadingContainer') return true;
      return is(el1.parentNode, el2);
    };

    let el = document.getElementById('applicationContainer');
    if (!is(e.target, el)) {
      return this.setState({ dropDown: false });
    }
  }

  toggleDropDown() {
    this.setState({ dropDown: !this.state.dropDown });
  }

  openLink(type) {
    let links = {
      ios: 'https://itunes.apple.com/us/app/wi-fi-security-for-business/id1441048544',
      android: 'https://play.google.com/store/apps/details?id=com.anchorfree.wifisecuritybusiness',
      windows: 'https://oauth-default-4d635.firebaseio.com/win_update_config.json',
    };

    if (!links[type]) return;

    if (type === 'windows') {
      Network.get(links[type])
        .then((r) => {
          try {
            if (r.response.url) {
              window.open(r.response.url);
              this.setState({ dropDown: false });
            }
          } catch (e) {}
        })
        .catch((e) => {});
    } else {
      window.open(links[type]);
      this.setState({ dropDown: false });
    }
  }

  render() {
    return (
      <div className={'applicationContainer'} id={'applicationContainer'}>
        <IconApplications
          theme={'white'}
          label={window.locales.applications}
          onClick={this.toggleDropDown.bind(this)}
        />
        <IconSmallArrow theme="white flex" onClick={this.toggleDropDown.bind(this)} />
        {this.state.dropDown && (
          <div className="userMenu applicationsMenu">
            <div className={'paletteContainer'} onClick={this.openLink.bind(this, 'android')}>
              for Android
              <IconAndroid theme={'white'} />
            </div>
            <div className={'paletteContainer'} onClick={this.openLink.bind(this, 'ios')}>
              for iOS
              <IconIos theme={'white'} />
            </div>
            <div
              className={'paletteContainer'}
              style={{ marginBottom: '0' }}
              onClick={this.openLink.bind(this, 'windows')}
            >
              for Windows
              <img alt="" style={{ height: '20px' }} src={'/static/assets/icons/windows.png'} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Applications;
