import { clearData } from '../../common/methods';

const SAVED_TOKENS = 'Storage.SavedTokens';
export default () => {
  return new Promise((resolve, reject) => {
    const fb = window.fb;
    const storage = window.storage;
    const user = window.user;
    storage.set(SAVED_TOKENS, {});
    fb.default
      .auth()
      .signOut()
      .then(() => {
        user.name = '';
        user.verified = false;
        user.authorized = false;
        user.accessToken = '';
        user.firebaseToken = '';
        clearData();

        return resolve();
      });
  });
};
