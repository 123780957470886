import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../ThemeContext';
import { Chart } from 'react-google-charts';
import StatsList from '../StatsList';
import { GetMetricsRequest } from '../../../components/Graphs';
import { IconQuestionGraph } from '../../../common/icons';
import { LocationContainer, MapContainer, StatsContainer, MapTitleContainer, TitleContainer,
    SortButton, StatsListContainer, LegendContainer, LegendItemContainer, ColorItem, TextItem, Map } from './components';
import { IGeo } from '../../../interfaces';

const colorsCountries = ['#CAECFF', '#8FD0F5', '#00A2FF', '#AB3962'];
const conditions = ['< 30', '30–60', '60–90', '90–100+'];

export default ({activeProject,  graphsMetadata, interval}: IGeo) => {
    const context = useContext<any>(ThemeContext);
    const [geoData, setGeoData] = useState([['Country', 'Load']]);
    const [listCountry, setListCountry] = useState<Array<Array<any>>>([]);
    const [sortedByPercent, setSortedByPercent] = useState(false);
    const [sortedByAlphabet, setSortedByAlphabet] = useState(false);
    const sortByAlphabet = () => {
        sortedByAlphabet ? listCountry.reverse() : listCountry.sort((a: Array<string>, b: Array<string>) => a[0].localeCompare(b[0]));
        setListCountry([...listCountry]);
        setSortedByAlphabet(!sortedByAlphabet);
        setSortedByPercent(false);
    };

    const sortByPercent = () => {
        sortedByPercent
            ? listCountry.sort((a: number[], b: number[]) => b[1] - a[1])
            : listCountry.sort((a: number[], b: number[]) => a[1] - b[1]);
        setListCountry([...listCountry]);
        setSortedByAlphabet(false);
        setSortedByPercent(!sortedByPercent);
    };

    useEffect(() => {
        window.Loading.show();
        const defaultValue = [['Country', 'Load']];
        let isSubscribed = true;
        setGeoData(defaultValue);

        function getDataGraphs() {
            const serverGroup = JSON.parse(activeProject.config).server_group ?? 'touchvpn';
            const date = new Date();
            const currentDate = date.setMinutes(date.getMinutes() - 5);

            const requests = graphsMetadata.map((graphMetadata) =>
                graphMetadata.items.map((graphId) => GetMetricsRequest(serverGroup, graphId,
                    {startDate: currentDate, endDate: currentDate})),
            );

            requests.forEach((request) =>
                Promise.all(request).then((responses) => {
                    responses.forEach((countryItems: any) => {
                        const data =
                            countryItems &&
                            countryItems.map((countryItem: any) => {
                                return [
                                    countryItem.metric.country.toUpperCase(),
                                    +Number(countryItem.values[0][1]).toFixed(2),
                                ];
                            });

                        if (isSubscribed && data) {
                            setGeoData([...defaultValue, ...data]);
                            const sortedList = data.sort((a: Array<number>, b: Array<number>) => b[1] - a[1]);
                            setListCountry([...sortedList]);
                        }
                    });

                    window.Loading.hide();
                }),
            );
        }

        getDataGraphs();
        if (interval !== '0') {
            const intervalId = setInterval(() => {
                getDataGraphs();
            }, Number(interval) * 1000);
            return () => {
                isSubscribed = false;
                clearInterval(intervalId);
            };
        }
    }, [graphsMetadata, interval, activeProject.config]);

    return (
        <LocationContainer isDefaultTheme={context.theme}>
            <MapContainer>
                <MapTitleContainer>
                    <TitleContainer isDefaultTheme={context.theme}>
                        {window.locales.location_loading_map}
                    </TitleContainer>
                    <a href={graphsMetadata[0].info}>
                        <IconQuestionGraph width={20} />
                    </a>
                </MapTitleContainer>
                <LegendContainer>
                    {colorsCountries.map((color, index) => (
                        <LegendItemContainer key={index}>
                            <ColorItem color={color} />
                            <TextItem isDefaultTheme={context.theme}>{conditions[index]}</TextItem>
                        </LegendItemContainer>
                    ))}
                </LegendContainer>
                <Map>
                    <Chart
                        options={{
                            backgroundColor: 'transparent',
                            colorAxis: {
                                colors: colorsCountries,
                                values: [0, 30, 60, 90],
                            },
                            datalessRegionColor: !context.theme ? '#878C95' : '#D1D8E3',
                            legend: 'none',
                        }}
                        chartType="GeoChart"
                        width="100%"
                        height="670px"
                        data={geoData}
                    />
                </Map>
            </MapContainer>
            <StatsContainer isDefaultTheme={context.theme}>
                <TitleContainer isDefaultTheme={context.theme}>
                    <SortButton onClick={sortByAlphabet} selected={sortedByAlphabet}>
                        {window.locales.location_loading_table}
                    </SortButton>
                    <SortButton onClick={sortByPercent} selected={sortedByPercent}>
                        %
                    </SortButton>
                </TitleContainer>
                <StatsListContainer>
                    <StatsList data={listCountry} />
                </StatsListContainer>
            </StatsContainer>
        </LocationContainer>
    );
};
