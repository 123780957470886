import axios from 'axios';

export const GetMetricsRequest = async (publicKey, graphId, period) => {
  const url = window.config.host + 'portal/metric/query_range';
  const startDate = new Date(period.startDate);
  const endDate = new Date(period.endDate);
  const oneDay = 24 * 60 * 60 * 1000;
  const days = Math.round(Math.abs((startDate - endDate) / oneDay));

  try {
    const response = await axios.get(url, {
      params: {
        access_token: window.user.firebaseToken,
        publickey: publicKey,
        query: graphId,
        start: startDate / 1000,
        end: endDate / 1000,
        step: days ? days * 300 : 1,
      },
    });

    return response.data.data.result;
  } catch (e) {
    window.Loading.hide();
  }
};
