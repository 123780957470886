import React, { Component } from 'react';

class Palette extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 23,
      height: props.height ? props.height : 24,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 23,
      height: props.height ? props.height : 24,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 palette ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path d="M16.556 15.112c.191 0 .287-.095.287-.287l.86-1.817-2.486-1.243-.765 1.817a.29.29 0 0 0 0 .382c.095.096.19.192.287.287.478.192.86.383 1.243.765.096.096.191.096.287.096.191.096.191.096.287 0z" />
          <path d="M18.756 13.964c-.192-.095-.479.096-.574.287l-1.052 2.296c0 .095-.096.191 0 .287.287 1.435-.479 2.965-1.244 3.826-.765.765-2.104 1.243-3.539 1.243-1.339 0-2.487-.478-3.252-1.243-.095-.096-.382-.383-.478-.765-.096.095-.191.286-.382.286H3.452c-.287 0-.478-.19-.478-.478v-6.695c0-.287.191-.478.478-.478h4.783c.287 0 .478.191.478.478v6.121c.191-.382.478-.67.86-.765.096 0 .096 0 .192-.095.096-.096.287-.192.478-.383.096-.096.096-.191.096-.287 0-.096 0-.096.095-.191.096-.192.096-.383.192-.67v-.096-3.538c0-.287.191-.479.478-.479h2.965l.383-.956H3.357c-.287 0-.479-.191-.479-.478V7.365c0-.287.192-.478.479-.478h12.816c.096 0 .192 0 .192.095l.956-2.2c.096-.095 0-.287 0-.478-.096-.096-.191-.191-.383-.191H2.4A2.368 2.368 0 0 0 .01 6.503v14.348A2.368 2.368 0 0 0 2.4 23.242h14.347a2.368 2.368 0 0 0 2.391-2.391V14.73c0-.479-.191-.67-.382-.766z" />
          <path d="M12.06 15.877a3.012 3.012 0 0 0-.86 1.626c-.096.383-.192.67-.479.957-.574.574-.86.67-.86.67-.192.095-.287.19-.287.382 0 .191 0 .287.095.478.67.67 1.53.957 2.583.957 1.147 0 2.295-.383 2.87-.957.956-.956 1.53-2.965.477-4.017-.956-1.052-2.486-1.052-3.539-.096zM21.338 0c-.67 0-1.243.383-1.53.956l-4.209 9.948 2.487 1.243 4.687-9.851c.095-.192.191-.479.191-.766C22.964.67 22.2 0 21.338 0z" />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Palette;
