import React, {Component} from 'react';

import Applications from './Applications';
import ProjectsSelector from './ProjectsSelector';

import {
    IconSignOut,
    IconPalette,
    IconProfile,
    IconQuestion,
    IconLanguage,
    IconSmallArrow,
} from '../../common/icons';

import {goTo, clearData} from '../../common/methods';

import {signOut, resetPassword} from '../../common/firebase';
import {ThemeContext} from '../../ThemeContext';
import styled from 'styled-components';
import Modal from '../../common/modal';

import {Input} from '../../common/form';

const NameTheme = styled.div`
  color: ${(props) =>
          props.isSelected
                  ? props.isProduction
                  ? '#00A2FF !important'
                  : '#ffa823 !important'
                  : 'inherit'};
`;

class index extends Component {
    constructor(props) {
        super(props);

        this.dump = JSON.stringify(props);

        this._escFunction = this.escFunction.bind(this);
        this._click = this.click.bind(this);

        this.form = {
            first_name: window.user.infoFromFirebase.first_name,
            last_name: window.user.infoFromFirebase.last_name,
            company_name: window.user.infoFromFirebase.company,
        };

        this._escFunction = this.escFunction.bind(this);
        this._click = this.click.bind(this);

        this.state = {
            page: props.page,
            projects: props.projects,
            activeProject: props.activeProject,
            userMenu: false,
            palette: false,
            viewProfile: false,
            reseted: false,
            companyName: window.user.infoFromFirebase.company,
        };

        document.addEventListener('keydown', this._escFunction);
        document.addEventListener('click', this._click);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._escFunction);
        document.removeEventListener('click', this._click);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.setState({
            page: props.page,
            projects: props.projects,
            activeProject: props.activeProject,
            userMenu: false,
            palette: false,
            locales: false,
            reseted: false,
        });
    }

    toggleUser() {
        this.setState({
            userMenu: !this.state.userMenu,
            palette: false,
            locales: false,
        });
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            if (this.state.userMenu) {
                this.setState({
                    userMenu: false,
                });
            }

            if (this.state.palette) {
                this.setState({
                    palette: false,
                });
            }
        }
    }

    click(e) {
        if (!this.state.userMenu && !this.state.palette && !this.state.locales) return;

        let is = (el1, el2) => {
            if (!el1) return false;
            if (el1 === el2) return true;
            if (el1.id === 'loadingContainer') return true;
            return is(el1.parentNode, el2);
        };

        let el = document.getElementById('headerUsername');
        if (this.state.userMenu && !is(e.target, el)) {
            return this.setState({userMenu: false});
        }

        el = document.getElementById('paletteContainer');
        if (this.state.palette && !is(e.target, el)) {
            return this.setState({palette: false});
        }

        el = document.getElementById('localesContainer');
        if (this.state.locales && !is(e.target, el)) {
            return this.setState({palette: false});
        }
    }

    changeConfigHost(project) {
        if (project === window.config.project) return;

        window.storage.set('project', project);

        clearData();

        let host = window.user.projects[0][1];
        for (let k in window.user.projects) {
            if (window.user.projects[k][0] === project) host = window.user.projects[k][1];
        }

        window.config.host = 'https://' + host + '/';
        window.config.project = project;

        if (this.state.page === 'projects') {
            /*window.reloadPortal();*/
        } else {
            window.config.reloadPortal = true;
            goTo('projects');
        }

        window.location.reload();
    }

    signOut() {
        signOut().then(() => {
            goTo('');
        });
    }

    localesState() {
        return this.setState({
            locales: !this.state.locales,
            palette: false,
        });
    }

    changeLocale(locale) {
        this.setState({locales: false}, () => {
            window.storage.set('currentLocal', locale);
            window.setLocales(locale);
            window.reloadPortal();
        });
    }

    paletteState() {
        return this.setState({
            palette: !this.state.palette,
            locales: false,
        });
    }

    changeTheme(theme) {
        this.setState({palette: false}, () => {
            window.storage.set('css_color', theme);
        });
    }

    resetPassword() {
        window.Loading.show();
        resetPassword(window.user.name)
            .then(() => {
                this.setState({reseted: true}, window.Loading.hide);
            })
            .catch(() => {
                this.setState({reseted: false}, window.Loading.hide);
            });
    }

    fieldChanged(key, value) {
        this.form[key] = value;
        this.setState({data: {...this.form}});
    }

    viewProfile() {
        this.form = {
            first_name: window.user.infoFromFirebase.first_name,
            last_name: window.user.infoFromFirebase.last_name,
            company_name: window.user.infoFromFirebase.company,
        };

        this.modal.show();
    }

    hideProfile() {
        this.form = {
            first_name: window.user.infoFromFirebase.first_name,
            last_name: window.user.infoFromFirebase.last_name,
            company_name: window.user.infoFromFirebase.company,
        };

        this.modal.hide();
    }

    formSubmit() {
        window.Loading.show();
        if (!this.form.company_name) {
            return;
        }

        this.setState({companyName: this.form.company_name});

        window.fb.default
            .database()
            .ref('/users/' + window.user.uid)
            .once('value')
            .then((result) => {
                result = result && result.val && result.val() ? result.val() : {};
                result.company = this.form.company_name ? this.form.company_name : '';
                result.last_name = this.form.last_name ? this.form.last_name : '';
                result.first_name = this.form.first_name ? this.form.first_name : '';

                window.fb.default
                    .database()
                    .ref('/users/' + window.user.uid)
                    .set(result)
                    .then(() => {
                        window.user.infoFromFirebase.company = this.form.company_name;
                        window.user.infoFromFirebase.last_name = this.form.last_name;
                        window.user.infoFromFirebase.first_name = this.form.first_name;
                        window.Loading.hide();
                    });
            });
    }

    render() {
        let projects;
        if (window.user.projects.length > 1) {
            projects = [];
            for (let k in window.user.projects) {
                let project = window.user.projects[k];
                projects.push(
                    <div
                        id={'HEADER_PROJECT_' + project[0].toUpperCase()}
                        className={'projectElement' + (window.config.project === project[0] ? ' active' : '')}
                        onClick={this.changeConfigHost.bind(this, project[0])}
                        key={project[0]}
                    >
                        {project[0]}
                    </div>,
                );
            }
        }

        const linkHelp =
            window.storage.get('currentLocal') === 'jp'
                ? 'https://wifisb.zendesk.com/hc/ja/sections/360002335771-%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB'
                : 'https://wifisb.zendesk.com/hc/en-us/sections/360002335771-FAQ';

        const isProduction = window.config.project === 'production';

        return (
            <div id="portalHeader">
                <ProjectsSelector
                    updateActiveProject={this.props.updateActiveProject}
                    projects={this.state.projects}
                    activeProject={this.state.activeProject}
                    page={this.state.page}
                />
                <div className="environmentSelector">{projects ? projects : undefined}</div>
                <div className="username" id="headerUsername">
                    <div className={'iconsContainer'}>
                        <a id={'ICON_QUESTION'} href={linkHelp}>
                            <IconQuestion label={window.window.locales.help} theme={'white'}/>
                        </a>
                        {this.state.page !== 'projects' && <Applications/>}
                    </div>
                    <div className={'name'} onClick={this.toggleUser.bind(this)} id={'HEADER_MENU_SELECTOR'}>
                        {this.state.page !== 'projects' &&
                        this.state.activeProject &&
                        this.state.activeProject.current_user_role && (
                            <div className={'userRole'}>
                                {window.window.locales.roles[this.state.activeProject.current_user_role.name]
                                    ? window.window.locales.roles[this.state.activeProject.current_user_role.name]
                                        .name
                                    : this.state.activeProject.current_user_role.name}
                                :
                            </div>
                        )}
                        <span>{window.user.name}</span>
                        <IconSmallArrow theme="blue flex" onClick={this.toggleUser.bind(this)}/>
                    </div>
                    {this.state.userMenu && (
                        <div className="userMenu">
                            <div className={'paletteContainer'}>
                                <IconProfile
                                    label={window.window.locales.viewProfile}
                                    theme={'light'}
                                    id={'ICON_VIEW_PROFILE'}
                                    onClick={this.viewProfile.bind(this)}
                                />
                            </div>
                            <div className={'paletteContainer'} id={'localesContainer'}>
                                <IconLanguage
                                    id={'ICON_LANGUAGE'}
                                    theme={'light'}
                                    onClick={this.localesState.bind(this)}
                                    label={window.window.locales.locales}
                                />
                                {this.state.locales && (
                                    <div className="userMenu2">
                                        <div onClick={this.changeLocale.bind(this, 'en')}>
                                            {window.window.locales.english}
                                        </div>
                                        <div onClick={this.changeLocale.bind(this, 'jp')}>
                                            {window.window.locales.japanese}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={'paletteContainer'} id={'paletteContainer'}>
                                <IconPalette
                                    id={'ICON_THEME'}
                                    ICON_LANGUAGE
                                    theme={'light'}
                                    label={window.window.locales.theme}
                                    onClick={this.paletteState.bind(this)}
                                />
                                {this.state.palette && (
                                    <ThemeContext.Consumer>
                                        {(context) => (
                                            <div className="userMenu2">
                                                <NameTheme
                                                    isProduction={isProduction}
                                                    isSelected={context.theme === ''}
                                                    onClick={() => context.toggleTheme('', this.changeTheme.bind(this, ''))}
                                                >
                                                    {window.window.locales.default}
                                                </NameTheme>
                                                <NameTheme
                                                    isProduction={isProduction}
                                                    isSelected={context.theme === 'light'}
                                                    onClick={() =>
                                                        context.toggleTheme('light', this.changeTheme.bind(this, 'light'))
                                                    }
                                                >
                                                    {window.window.locales.light}
                                                </NameTheme>
                                            </div>
                                        )}
                                    </ThemeContext.Consumer>
                                )}
                            </div>
                            <IconSignOut
                                theme={'light'}
                                onClick={this.signOut.bind(this)}
                                label={window.window.locales.signOut}
                                id={'HEADER_MENU_SIGNOUT'}
                            />
                        </div>
                    )}
                </div>
                <Modal
                    onRef={(ref) => {
                        this.modal = ref;
                    }}
                    title={window.locales.profile}
                    cancel={{
                        label: window.locales.close,
                        click: this.hideProfile.bind(this),
                    }}
                    submit={{
                        label: window.locales.saveChanges,
                        click: this.formSubmit.bind(this),
                    }}
                >
                    <Input value={window.user.name} disabled={true}/>
                    <div style={{display: 'flex'}}>
                        <Input
                            label={window.locales.firstName}
                            value={this.form.first_name}
                            onChange={this.fieldChanged.bind(this, 'first_name')}
                            onEnter={this.formSubmit.bind(this)}
                        />
                        <div style={{width: '20px', height: '100%'}}/>
                        <Input
                            label={window.locales.lastName}
                            value={this.form.last_name}
                            onChange={this.fieldChanged.bind(this, 'last_name')}
                            onEnter={this.formSubmit.bind(this)}
                        />
                    </div>
                    <div style={{display: 'flex'}}>
                        <Input
                            value={window.user.infoFromFirebase.company_code}
                            disabled={true}
                            style={{width: '80px'}}
                        />
                        <div
                            style={{
                                width: '20px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                            }}
                        />
                        <Input
                            label={window.locales.companyName}
                            value={this.form.company_name}
                            notValid={!this.state.companyName}
                            onChange={this.fieldChanged.bind(this, 'company_name')}
                            onEnter={this.formSubmit.bind(this)}
                            style={{width: '296px'}}
                        />
                    </div>
                    {!this.state.reseted && (
                        <div className={'resetPassword'} onClick={this.resetPassword.bind(this)}>
                            <span className={'pointer'}>{window.locales.resetPassword}</span>
                        </div>
                    )}
                    {this.state.reseted && (
                        <div className={'resetPassword'}>
                            <span>{window.locales.reseted}</span>
                        </div>
                    )}
                </Modal>
                )
            </div>
        );
    }

}

export default index;
