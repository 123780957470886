export default () => {
  let path = [],
    params = {};

  let p = window.location.hash.replace(/^#\/?/, '').split('?');

  p[0] = p[0].split('/');
  p[1] = p[1] ? p[1].split('&') : {};

  let temp = p[0] && p[0][0] !== '' ? p[0] : ['main'];
  for (let k in temp) {
    if (temp[k] !== '') path.push(temp[k]);
  }

  for (let k in p[1]) {
    let param = p[1][k];
    param = param.split('=');
    if (!param || !param[1] || param[0] === '') continue;
    params[param[0]] = decodeURIComponent(param[1]);
  }

  return { path: path, params: params };
};
