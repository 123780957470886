import React, {useContext} from "react";
import {ThemeContext} from "../../../ThemeContext";
import {TabContainer, TabsContainer, TabsHeaderContainer, TitlePageContainer} from "../../components";

export default (props: {chosenTab: string, setChosenType: (tabName: string) => void, activeProject: any}) => {
    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';

    return <TabsHeaderContainer nameTheme={context.theme}>
        <TitlePageContainer>
            Billing
        </TitlePageContainer>
        <TabsContainer>
            <TabContainer
                isProduction={isProduction}
                nameTheme={context.theme}
                onClick={() => props.setChosenType('Plans')}
                isSelected={props.chosenTab ==='Plans'}>
                Plans
            </TabContainer>
            <TabContainer
                isProduction={isProduction}
                nameTheme={context.theme}
                onClick={() => props.setChosenType('Invoices')}
                isSelected={props.chosenTab ==='Invoices'}>
                Invoices
            </TabContainer>
        </TabsContainer>
    </TabsHeaderContainer>
}