import React, {Component} from 'react';
import Auth from './screens/Auth';
import Portal from './screens/Portal';
import BusinessNotFound from './screens/NotFound';

import {
    goTo,
    parseUrl
} from './common/methods';

class Controller extends Component {
    constructor(props) {
        super(props);

        let path = parseUrl();

        this.state = {
            path: path.path,
            params: path.params
        };

        window.onhashchange = () => {
            let path = parseUrl();
            if (path && path.path && path.path[0] && path.path[0] === '404') {
                return window.location.reload();
            }

            this.setState({
                path: path.path,
                params: path.params
            });
        };
    }

    render() {
        switch (this.state.path[0]) {
            case 'user':
            case 'vpn':
            case 'users':
            case 'network':
            case 'settings':
            case 'projects':
            case 'createProject':
            case 'noprojects':
            case 'dashboard':
            case 'export':
            case 'active_sessions':
            case 'event':
            case 'pools':
            case 'billing':
                return <Portal path={this.state.path} params={this.state.params}/>;
            case 'auth':
                return <Auth path={this.state.path[1]} params={this.state.params}/>;
            case '404':
                return <BusinessNotFound/>;
            default:
                goTo('auth/signin')
                return <div/>;
        }
    }
}

export default Controller;