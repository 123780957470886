import en from './en';
import jp from './jp';
import {CURRENT_LOCAL} from "../constants"

let localesList = {
  en: en,
  jp: jp,
};

window.setLocales = (locale) => {
  locale = locale
    ? locale
    : window.navigator && navigator.language && window.navigator.language === 'ja'
    ? 'jp'
    : 'en';

  if (!localesList[locale]) locale = 'en';

  let temp = {};
  for (let k in localesList.en) {
    temp[k] = localesList[locale][k] ? localesList[locale][k] : localesList.en[k];
  }

  window.locales = temp;
};

window.setLocales(window.storage.get(CURRENT_LOCAL));
