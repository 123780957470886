import React, { Component } from 'react';

class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 30,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 30,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 addUsers ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path d="M17.47 8.155a4.548 4.548 0 0 0 1.767-3.596C19.237 2.045 17.183 0 14.66 0c-2.524 0-4.577 2.045-4.577 4.56 0 1.459.692 2.76 1.766 3.595-2.38 1.07-4.041 3.456-4.041 6.22v4.844c0 .431.35.781.784.781h12.136c.433 0 .784-.35.784-.781v-4.843c0-2.766-1.66-5.152-4.041-6.22zM11.652 4.56a3.006 3.006 0 0 1 3.009-2.997 3.006 3.006 0 0 1 3.008 2.997 3.006 3.006 0 0 1-3.008 2.997A3.006 3.006 0 0 1 11.65 4.56zm8.293 13.878H9.376v-4.061c0-2.899 2.368-5.257 5.277-5.257h.013c2.91 0 5.278 2.358 5.278 5.257v4.062z" />
          <path
            strokeWidth=".5"
            d="M25.654 7.776h3.331V8.89h-3.331v3.331h-1.116V8.891h-3.33V7.776h3.33V4.444h1.116v3.332z"
          />
          <path d="M1.807 16.55h5.896l.1.1v1.128l-.1.1h-6.57a.67.67 0 0 1-.675-.664v-3.498c0-1.94 1.134-3.68 2.872-4.516a3.362 3.362 0 0 1-1.206-2.574c0-1.871 1.55-3.393 3.453-3.393 1.903 0 3.452 1.522 3.452 3.393A3.362 3.362 0 0 1 7.824 9.2c.63.303 1.19.731 1.647 1.256l-.008.139a5.205 5.205 0 0 0-.744.855l-.16.005a3.786 3.786 0 0 0-2.977-1.436h-.01c-2.077 0-3.765 1.659-3.765 3.697v2.833zm1.666-9.924c0 1.138.943 2.065 2.104 2.065 1.16 0 2.103-.927 2.103-2.065s-.943-2.064-2.103-2.064c-1.16 0-2.104.926-2.104 2.064z" />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default AddUsers;
