import React, {Component} from 'react';

import Table from '../../../../common/table';

import {
  IconPen,
  IconPlus,
  IconDelete,
  IconDownload
} from '../../../../common/icons';

import {
  Select,
  Textarea
} from '../../../../common/form';

import {
  saveDataToFile,
  loadDataFromFile2 as loadDataFromFile
} from '../../../../components/Bypass';

import {
  getProjects,
  getActiveProject
} from '../../../../components/Projects';

import Modal from '../../../../common/modal';
import styled from "styled-components";

const PREFIXES = {
  "production": "af-prod",
  "QA": "af-qa",
  "DEV": "af-dev",
  "DEV1": "af-dev1",
  "DEV2": "af-dev2",
  "DEV3": "af-dev3",
  "STAGE": "af-stage"
};

const ClientNetworkContainer = styled.div`
  padding-top: 65px;
  
  td {
      .flexTd {
        height: 30px;
        align-items: center;
  
        .form {
          margin: 0;
          width: 300px;
  
          input {
            padding: 5px 15px;
            height: auto;
          }
  
          .label {
            left: 15px;
            right: 15px;
          }
        }
      }
    }
`;

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProject: props.activeProject,
      search: '',
      form: {
        type: false,
        ssid: false,
        action: false
      },
      message: "",
      i: 0
    };

    this.modals = {};
    this.data = [];

    this.form = {
      type: "",
      ssid: "",
      bssid: "",
      authorized: "",
      action: ""
    };

    this.inputs = {};
    this.dump = JSON.stringify(props.dump);
    this.updateLog = [];
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.data = [];
    this.setState({
      add: false,
      activeProject: props.activeProject,
      search: '',
      errors: {}
    }, this.componentDidMount.bind(this));
  }

  componentDidMount() {
    this.checkUrl();

    if (!this.fileUrl) return;

    window.Loading.show();
    loadDataFromFile(this.fileUrl).then((result) => {
      this.data = result;
      this.setState({i: this.state.i + 1}, window.Loading.hide);
    }).catch((e) => {
      window.Loading.hide();
    });
  }

  checkUrl() {
    this.fileUrl = undefined;
    try {
      this.fileUrl = JSON.parse(this.state.activeProject.config).files.cnl;
      if (!this.fileUrl.match(/^https?:\/\//)) {
        this.fileUrl = 'https://storage.googleapis.com/' + PREFIXES[window.config.project] + '/project/' + this.state.activeProject.publickey + '/files/cnl/' + this.fileUrl;
      }
    } catch (e) {
    }
  }

  toggleAdd() {
    let state = this.state;
    state.form = {
      action: false
    };
    state.message = "";
    this.form = {
      type: "",
      ssid: "",
      bssid: "",
      authorized: "",
      action: ""
    };

    this.modalType = "add";
    this.setState(state, () => {
      this.modals.add.show()
    });
  }

  toggleEdit(k) {
    this.form = {
      type: this.data[k].type,
      ssid: this.data[k].ssid ? this.data[k].ssid.join(", ") : "",
      bssid: this.data[k].bssid ? this.data[k].bssid.join(", ") : "",
      authorized: this.data[k].authorized ? this.data[k].authorized : "",
      action: this.data[k].action,
    };

    this.editCandidate = k;
    this.modalType = "edit";
    this.setState({i: this.state.i + 1}, () => {
      this.modals.add.show();
    });
  }

  downloadFile() {
    window.open(this.fileUrl);
  }

  searchChanged(search) {
    this.setState({
      search: search
    });
  }

  render() {
    let head = [
      ["", {width: 50}],
      [window.locales.type, {width: 100}],
      ["SSID"],
      ["BSSID"],
      [window.locales.action, {width: 100}],
      [window.locales.authorized, {width: 100}],
      ['', {width: 50}],
      ['', {width: 50}]
    ];

    let map = {
      wifi: "WiFi",
      wwan: "WWAN",
      lan: "LAN",
      enable: window.locales.enable,
      disable: window.locales.disable,
      yes: window.locales.yes,
      no: window.locales.no,
    };

    let body = [];

    for (let k in this.data) {
      let r = this.data[k];

      let ssid = (r.ssid&&r.ssid.join(", ").length>0)?r.ssid.join(", "):window.locales.any;
      let bssid = (r.bssid&&r.bssid.join(", ").length>0)?r.bssid.join(", "):window.locales.any;

      body.push([
        [[<span className={"dragDots"} key={0}/>, <span key={1}>{(Number(k) + 1)}</span>]],
        [map[r.type] ? map[r.type] : (r.type?r.type:window.locales.any)],
        [ssid],
        [bssid],
        [map[r.action] ? map[r.action] : r.action],
        [map[r.authorized] ? map[r.authorized] : (r.authorized?r.authorized:window.locales.any)],
        [<IconPen theme={"blue"} onClick={this.toggleEdit.bind(this, k)}/>],
        [<IconDelete theme={"blue"} onClick={() => {
          this.deleteCandidate = k;
          this.modals.delete.show();
        }}/>]
      ])
    }

    /*
    <Search
            publickey={this.state.activeProject.publickey}
            searchChanged={this.searchChanged.bind(this)}
          />
     */

    return (
      <ClientNetworkContainer>
        <div style={{position: 'absolute', right: '0', top: '30px', display: 'flex', alignItems: 'center'}}>
          {this.state.activeProject.role !== 'SUPPORT' &&
          <IconPlus theme={'blue'} label={window.locales.add} onClick={this.toggleAdd.bind(this)}/>}
          {this.fileUrl && <IconDownload style={{marginLeft: '10px'}} theme={'blue'} label={window.locales.download}
                                         onClick={this.downloadFile.bind(this)}/>}
        </div>
        <Table head={head} body={body} dragAndDrop={true} onDragEnd={this.onDragEnd.bind(this)}/>
        {this.renderAddModal()}
        {this.renderDeleteModal()}
      </ClientNetworkContainer>
    );
  }

  onDragEnd(from, to) {
    const item = this.data.splice(from, 1)[0];
    this.data.splice(to, 0, item);
    window.Loading.show();
    this.saveDataToFile().then((state) => {
      this.setState(state, () => {
        window.Loading.hide();
      });
    }).catch((e) => {
      window.Loading.hide();
    });
  }

  renderAddModal() {
    let title = window.locales.addClientNetworkTitle;
    let submitFunc = this.formSubmit.bind(this);
    if (this.modalType === "edit") {
      title = window.locales.editClientNetworkTitle;
      submitFunc = this.formSubmit.bind(this, this.editCandidate);
    }

    let options1 = [
      ["wifi", "WiFi"],
      ["wwan", "WWAN"],
      ["lan", "LAN"],
    ];

    let options2 = [
      ["enable", window.locales.enable],
      ["disable", window.locales.disable]
    ];

    let options3 = [
      ["yes", window.locales.yes],
      ["no", window.locales.no],
      ["na", window.locales.doesntMatter]
    ];
    return (
      <Modal
        title={title}
        onRef={ref => {
          this.modals.add = ref;
        }}
        message={this.state.message}
        cancel={{
          label: window.locales.cancel,
          click: () => {
            this.modals.add.hide()
          }
        }}
        submit={{
          label: window.locales.save,
          click: submitFunc
        }}
      >
        <Select options={options1} notValid={this.state.form.type} value={this.form.type} theme={"dark"} label={window.locales.type}
                onChange={this.fieldChanged.bind(this, "type")}/>
        {this.form.type === "wifi" &&
        <Textarea value={this.form.ssid} notValid={this.state.form.ssid} theme={"dark"} label={window.locales.ssidLabel}
                  onChange={this.fieldChanged.bind(this, "ssid")}/>}
        {this.form.type === "wifi" && <Textarea value={this.form.bssid} notValid={this.state.form.bssid} theme={"dark"}
                                                label={window.locales.bssidLabel}
                                                onChange={this.fieldChanged.bind(this, "bssid")}/>}
        {this.form.type === "wifi" &&
        <Select options={options3} notValid={this.state.form.authorized} value={this.form.authorized} theme={"dark"}
                label={window.locales.authorized} onChange={this.fieldChanged.bind(this, "authorized")}/>}
        <Select options={options2} notValid={this.state.form.action} value={this.form.action} theme={"dark"}
                label={window.locales.action} onChange={this.fieldChanged.bind(this, "action")}/>
      </Modal>
    );
  }

  deleteElement() {
    let t = this.data.splice(this.deleteCandidate, 1);

    window.Loading.show();
    this.saveDataToFile().then((state) => {
      this.setState(state, () => {
        window.Loading.hide();
        this.modals.delete.hide();
      });
    }).catch((e) => {
      this.data.push(t);
      window.Loading.hide();
    });
  }

  renderDeleteModal() {
    return (
      <Modal
        title={window.locales.deleteClientNetworkTitle}
        onRef={ref => {
          this.modals.delete = ref;
        }}
        message={this.state.message}
        cancel={{
          label: window.locales.cancel,
          click: () => {
            this.modals.delete.hide()
          }
        }}
        submit={{
          label: window.locales.delete,
          click: this.deleteElement.bind(this)
        }}
      >
        {window.locales.deleteClientNetworkText}
      </Modal>
    );
  }

  formSubmit(id) {
    let state = this.state;
    state.message = "";
    state.form = {
      action: false
    };

    let valid = true;

    if (!this.form.action.trim()) {
      state.form.action = true;
      state.message = window.locales.fillInFields;
      valid = false;
    }

    if (!valid) return this.setState(state);

    window.Loading.show();
    let ssid = this.form.ssid.split(",");
    for (let k in ssid) {
      ssid[k] = ssid[k].trim();
    }
    let bssid = this.form.bssid.split(",");
    for (let k in bssid) {
      bssid[k] = bssid[k].trim();
    }

    if (bssid.length === 0) bssid = undefined;

    let content = {
      type: this.form.type ? this.form.type : undefined,
      ssid: ssid,
      bssid: bssid,
      authorized: (this.form.authorized && this.form.authorized !== "na") ? this.form.authorized : undefined,
      action: this.form.action
    };

    if (content.type !== "wifi") {
      delete content.ssid;
      delete content.bssid;
      delete content.authorized;
    }

    if (id) {
      this.data[id] = content;
    } else {
      this.data.push(content);
    }

    this.saveDataToFile().then((state) => {
      this.setState(state, () => {
        window.Loading.hide();
        this.modals.add.hide();
      });
    }).catch((e) => {
      state.message = e && e.message ? e.message : "Internal server error";
      this.setState(state, () => {
        window.Loading.hide();
      });
    });
  }

  saveDataToFile() {
    let state = this.state;
    return new Promise((resolve, reject) => {
      saveDataToFile(this.state.activeProject.publickey, JSON.stringify(this.data), "cnl").then(() => {
        getProjects(true).then(() => {
          ++state.i;
          state.activeProject = getActiveProject();
          try {
            this.fileUrl = JSON.parse(state.activeProject.config).files.cnl;
            if (!this.fileUrl.match(/^https?:\/\//)) {
              this.fileUrl = 'https://storage.googleapis.com/' + PREFIXES[window.config.project] + '/project/' + this.state.activeProject.publickey + '/files/cnl/' + this.fileUrl;
            }
          } catch (e) {
          }

          return resolve(state);
        });
      }).catch((e) => {
        return reject(e);
      })
    })
  }

  fieldChanged(key, value) {
    this.form[key] = value;
    if (key === "type") {
      this.setState({i: this.state.i + 1});
    }
  }
}

export default index;
