import React, {Component} from 'react';

import Devices from './Devices';
import Sessions from './Sessions';
import Purchases from './Purchases';
import Parameters from './Parameters';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      params: props.params,
      traffic: props.traffic,
      activeProject: props.activeProject
    };

    this.reload = props.reload;
    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      user: props.user,
      params: props.params,
      traffic: props.traffic,
      activeProject: props.activeProject
    });
  }

  render() {
    let content;

    switch (this.state.params.tab) {
      case 'sessions':
        content = <Sessions user={this.state.user} activeProject={this.state.activeProject}/>;
        break;
      case 'purchases':
        content = <Purchases user={this.state.user}/>;
        break;
      case 'devices':
        content =
          <Devices traffic={this.state.traffic} user={this.state.user} activeProject={this.state.activeProject}/>;
        break;
      default:
        content =
          <Parameters user={this.state.user} traffic={this.state.traffic} activeProject={this.state.activeProject}
                      reload={this.reload}/>;
        break;
    }

    return (
      <div className={'userContentContainer'}>
        {content}
      </div>
    );
  }
}

export default index;