import React, { Component } from 'react';

import Table from '../../common/table';

import { getNetwork, setVisibility } from '../../components/Network';

import { Select } from '../../common/form';

import { goTo, getCountryName } from '../../common/methods';

import { IconPlus, IconDelete, IconArrow } from '../../common/icons';

import { editProject } from '../../components/Project';

window.SAVED_LOCATIONS = {};

class ComponentNetwork extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: props.path,
      network: undefined,
      activeProject: props.activeProject,
      permissions: props.permissions,
      edit: !(props.permissions.indexOf('project_countries_update') === -1),
      add: false,
    };

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.componentDidMount(props);
  }

  componentDidMount(props) {
    window.Loading.show();

    let state = this.state;

    if (props) {
      state.activeProject = props.activeProject;
      state.permissions = props.permissions;
      state.edit = !(props.permissions.indexOf('project_countries_update') === -1);
      state.path = props.path;
      state.add = false;
    }

    if (state.permissions.indexOf('project_countries_read') === -1) return goTo('404');

    getNetwork(state.activeProject.publickey).then((network) => {
      state.network = network;
      this.setState(state, window.Loading.hide);
    });
  }

  changeGroupStatus(name, status) {
    let config = {};
    try {
      config = JSON.parse(this.props.activeProject.config);
    } catch (e) {}

    if (!config.private_groups) config.private_groups = [];
    if (status === 'on') {
      config.private_groups.push(name);
    } else {
      if (config.private_groups.indexOf(name) !== -1) {
        config.private_groups.splice(config.private_groups.indexOf(name), 1);
      }
    }

    window.Loading.show();
    editProject(this.props.activeProject.publickey, { config: JSON.stringify(config) })
      .then(() => {
        window.Loading.hide();
        let p = this.props.activeProject;
        p.config = JSON.stringify(config);
        this.props.updateActiveProject(p);
      })
      .catch((e) => {
        window.Loading.hide();
      });
  }

  changed(tba, k) {
    if (tba.indexOf(k) !== -1) {
      return this.changeGroupStatus(k, 'on');
    }
    if (!this.state.network[k]) return;

    window.Loading.show();
    setVisibility(
      this.state.activeProject.publickey,
      this.state.network[k].country,
      !this.state.network[k].visibility,
    )
      .then(() => {
        let network = this.state.network;
        network[k].visibility = !network[k].visibility;

        this.setState(
          {
            network: network,
            add: false,
          },
          window.Loading.hide,
        );
      })
      .catch(() => {
        this.setState(
          {
            add: false,
          },
          window.Loading.hide,
        );
      });
  }

  networkClick(k, e) {
    let is = (e, className) => {
      if (!e) return false;
      if (!e.className || !e.className.indexOf || typeof e.className !== 'string')
        return is(e.parentNode, className);
      if (e.className.indexOf(className) !== -1) return true;
      return is(e.parentNode, className);
    };

    if (is(e.target, 'icons delete')) {
      return;
    }

    goTo(
      'network/' +
        this.state.activeProject.publickey +
        '/' +
        encodeURIComponent(this.state.network[k].country),
    );
  }

  render() {
    if (!this.state.network) return <div />;

    if (this.state.path[2]) return this.renderNetworkPage();

    let head = [
      [window.locales.location, { width: '111px' }],
      [window.locales.country, { width: '150px' }],
      [window.locales.type],
      ['', { width: '50px' }],
    ];

    if (this.state.edit) {
      head.push();
    }

    let config = {};
    try {
      config = JSON.parse(this.state.activeProject.config);
    } catch (e) {}

    let body = [];

    if (this.state.add && this.state.edit) {
      let options = [];
      for (let k in this.state.network) {
        let location = this.state.network[k];
        if (location.visibility) continue;

        options.push([
          k,
          getCountryName(location.country) + ' (' + location.country.toUpperCase() + ')',
        ]);
      }

      let pp = [];
      if (config.private_pools) pp = config.private_pools;
      let pg = [];
      if (config.private_groups) pg = config.private_groups;
      let tba = [];
      for (let k in this.props.pools) {
        let pool = this.props.pools[k];
        if (pp.indexOf(pool.name) === -1) continue;

        for (let j in pool.groups) {
          let group = pool.groups[j];
          if (pg.indexOf(group.name) !== -1 || tba.indexOf(group.name) !== -1) continue;
          tba.push(group.name);
        }
      }
      for (let k in tba) {
        options.push([tba[k], tba[k]]);
      }

      body.push([
        [
          <Select
            label={window.locales.location}
            theme={'dark capitalize'}
            options={options}
            value={''}
            disabled={this.state.disabled}
            onChange={this.changed.bind(this, tba)}
          />,
        ],
        [''],
        [''],
        [''],
      ]);
    }

    for (let k in this.state.network) {
      let location = this.state.network[k];
      if (!location.visibility) continue;

      body.push({
        row: [
          [
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt=""
                className="flag"
                src={'/static/flags/' + location.country.toLowerCase() + '.svg'}
                onError={(e) => {
                  e.target.style.display = 'none';
                }}
              />
              {location.country.toUpperCase()}
            </div>,
          ],
          //[location.protocols.join(', ')]
          [getCountryName(location.country)],
          [window.locales.public],
          [
            this.state.edit ? (
              <IconDelete theme={'blue'} onClick={this.changed.bind(this, [], k)} />
            ) : (
              ''
            ),
          ],
        ],
        click: this.networkClick.bind(this, k),
      });
    }

    for (let k in config.private_groups) {
      body.push({
        row: [
          [config.private_groups[k]],
          //[location.protocols.join(', ')]
          [''],
          ['Private'],
          [
            this.state.edit ? (
              <IconDelete
                theme={'blue'}
                onClick={this.changeGroupStatus.bind(this, config.private_groups[k], 'off')}
              />
            ) : (
              ''
            ),
          ],
        ],
        //click: this.networkClick.bind(this, k)
      });
    }

    return (
      <div id={'screenNetwork'} className="portalContentContainer">
        <h1>{window.locales.network}</h1>
        <div className={'navigation'}>
          <div className={'active'}>
            {window.locales.locations}
            <div className={'line'} />
          </div>
        </div>
        {this.state.edit && (
          <IconPlus
            theme={'blue'}
            label={window.locales.addLocation}
            onClick={() => {
              this.setState({ add: !this.state.add });
            }}
          />
        )}
        <Table head={head} body={body} />
      </div>
    );
  }

  changeTab() {
    goTo('pools/' + this.state.activeProject.publickey);
  }

  back() {
    goTo('network/' + this.state.activeProject.publickey);
  }

  renderNetworkPage() {
    let p = decodeURIComponent(this.state.path[2]);

    let network;
    for (let k in this.state.network) {
      if (this.state.network[k].country === p) {
        network = this.state.network[k];
        break;
      }
    }

    if (!network) {
      setTimeout(() => {
        goTo('404');
      }, 200);
      return <div />;
    }

    let body = [
      [[window.locales.type], [window.locales.public]],
      [[window.locales.protocols], [network.protocols.join(', ')]],
    ];

    return (
      <div id={'screenNetworkPage'} className="portalContentContainer">
        <div className="header">
          <div className="navi">
            <div onClick={this.back.bind(this)}>{window.locales.locations}</div> {'>'}{' '}
            <span>{network.country}</span>
          </div>
          <h2>
            {this.state.activeProject.publickey}
            <span>_{network.country}</span>
          </h2>
          <IconArrow onClick={this.back.bind(this)} />
        </div>
        <div className={'networkContentContainer'} style={{ minHeight: 'calc(100vh - 245px)' }}>
          <Table body={body} />
        </div>
      </div>
    );
  }
}

export default ComponentNetwork;
