import React, {useCallback, useState} from "react";
import {useTheme} from "../../ThemeContext";
import {ButtonContainer, ButtonSubmit, PopupContainer, InfoPopup, Title, BackButton} from "../components";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Checkbox} from "../../common/form";
import {signOut} from "../../common/firebase";
import {goTo} from "../../common/methods";

const LinkTerms = styled(NavLink)`
  text-decoration-line: underline;
  color: #00A2FF;
`;

const Header = styled.div`
  display: grid;
  row-gap: 16px;
`;

const InfoPopupContent = styled(InfoPopup)`
  justify-items: flex-start;
  row-gap: 32px;
`;

const Description = styled.div`
  color: #B2B9C4;
  font-size: 16px;
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 12px;
  align-items: center;
`;

const NewToS = Number(new Date('01/01/2021')) / 1000;

export default ({showViewProfile, prefixProjectCode}: { showViewProfile: () => void, prefixProjectCode: string }) => {
    const {tos} = window.user.infoFromFirebase;
    const [agreed, setAgreed] = useState(false)
    const [showNewToS, setShowNewToS] = useState(NewToS >= tos || !tos)
    const confirmToS = useCallback(() => {
        if (!agreed) return;
        window.Loading.show();
        const newDate = Math.floor(Number(new Date()) / 1000);
        window.fb.default
            .database()
            .ref('/users/' + window.user.uid)
            .set({...window.user.infoFromFirebase, tos: newDate})
            .then(() => {
                window.user.infoFromFirebase = {...window.user.infoFromFirebase, tos: newDate}
                window.Loading.hide();
            });

        !prefixProjectCode && showViewProfile()

        setShowNewToS(false);
    }, [showViewProfile, prefixProjectCode, agreed])

    const logOut = async () => {
        await signOut();
        goTo('');
    }

    const [themeContext, env] = useTheme();
    return showNewToS ? <PopupContainer>
        <InfoPopupContent nameTheme={themeContext.theme}>
            <Header>
                <Title>
                    {window.locales.updateToS}
                </Title>
                <Description>
                    To continue you have to agree with <LinkTerms to={'/termsOfService'}>the Terms of
                    Service</LinkTerms>
                </Description>
            </Header>
            <CheckboxContainer>
                <Checkbox changed={(e: string) => setAgreed(e === 'on')} theme={'blue'} status={agreed}/>
                I agree with
            </CheckboxContainer>
            <ButtonContainer>
                <BackButton onClick={logOut} isProduction={env}>
                    {window.locales.signOut}
                </BackButton>
                <ButtonSubmit disabled={!agreed} isProduction={env} onClick={confirmToS}>
                    {window.locales.continue}
                </ButtonSubmit>
            </ButtonContainer>
        </InfoPopupContent>
    </PopupContainer> : <></>
}