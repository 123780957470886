import React, {FC, useContext, useRef, useState} from "react";
import styled from "styled-components";
import {IconClose} from "../../common/icons";
import Logo from "../Logo";
import {ThemeContext} from "../../ThemeContext";
import {Field, Formik} from "formik";
import {
    CustomInput,
    ButtonSubmit,
    PopupContainer,
    ButtonContainer,
    BackButton,
    CommonText,
    Popup,
    PopupHeader,
    NamePopup,
    PopupContentContainer,
    Title,
    NameContainer,
    FieldsContainer, CustomSelect, ColorEnvironment
} from "../components";
import {resetPassword} from '../../common/firebase';
import countryList from "country-list";
import {StateList} from "../Header/ExtraProfileFields/StateList";
import {IAddress} from "../../interfaces";
import reCAPTCHA from "../../common/firebase/reCAPTCHA";
import {sendSMS, confirmCode} from "../../common/firebase/2fa"

const ButtonResetPassword = styled.div<{ isProduction: boolean }>`
  color: ${ColorEnvironment};
  cursor: pointer;

  :hover {
    color: ${ColorEnvironment};
  }
`;

const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 21px;
`;

const optionsCountry = Object.entries(countryList.getCodeList());
const optionsState = Object.entries(StateList);

export const AddressInfo: FC<IAddress> = ({values}) => {
    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';
    const {country, state, city, postalCode, line1, line2} = values

    return <>
        <NameContainer nameTheme={context.theme}>
            {window.locales.addressInfo}
        </NameContainer>
        <FieldsContainer>
            <Field as="select" name={"country"}
                   selected={country}
                   options={optionsCountry}
                   placeholder={window.locales.country} component={CustomSelect}/>
            {
                values.country === "us" ?
                    <Field as="select"
                           name={"state"}
                           options={optionsState}
                           selected={state}
                           placeholder={window.locales.state}
                           component={CustomSelect}/> :
                    <Field component={CustomInput}
                           isProduction={isProduction}
                           value={state}
                           type={"text"}
                           name={"state"}
                           placeholder={"State"}/>
            }
            <Field component={CustomInput}
                   isProduction={isProduction}
                   value={city}
                   type={"text"}
                   name={"city"}
                   placeholder={"City"}/>
            <Field component={CustomInput}
                   isProduction={isProduction}
                   value={postalCode}
                   type={"text"}
                   name={"postalCode"}
                   placeholder={"Postal Code"}/>
            <Field component={CustomInput}
                   isProduction={isProduction}
                   value={line1}
                   type={"text"}
                   name={"line1"}
                   placeholder={"Line 1"}/>
            <Field component={CustomInput}
                   isProduction={isProduction}
                   value={line2}
                   type={"text"}
                   name={"line2"}
                   placeholder={"Line 2"}/>
        </FieldsContainer>
    </>
}

const Enable2FA = (ref: any) => {
    reCAPTCHA(ref.current)
    sendSMS()
}

const ViewProfile: React.FC<{ setShowViewProfile: () => void }> = ({setShowViewProfile}) => {
    const [projectPrefix, setProjectPrefix] = useState(() => window.user.infoFromFirebase.projectPrefix ? window.user.infoFromFirebase.projectPrefix :
        window.user.infoFromFirebase.company_code ? window.user.infoFromFirebase.company_code :
            window.user.infoFromFirebase.company?.replace(/\s/g, "").toLowerCase().substring(0, 3))
    const [passwordReseted, setPasswordReseted] = useState(false)
    const [sentCode, setSentCode] = useState(false)
    const [code, setCode] = useState("")

    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';
    const buttonRef = useRef(null);
    const sendRequestOnResetPassword = async () => {
        window.Loading.show();
        await resetPassword(window.user.name)
        setPasswordReseted(true)
        window.Loading.hide();
    }

    const SendCode = () => {
        sentCode ? confirmCode(code) : Enable2FA(buttonRef)
        setSentCode(true)
    }

    return <PopupContainer>
        <Popup nameTheme={context.theme}>
            <PopupHeader>
                <NamePopup>
                    <Logo nameTheme={context.theme}/>
                    <Title>
                        {window.locales.profile}
                    </Title>
                </NamePopup>
                {projectPrefix && <IconClose onClick={setShowViewProfile}/>}
            </PopupHeader>
            <Formik
                initialValues={{
                    companyName: window.user.infoFromFirebase.company ?? '',
                    lastName: window.user.infoFromFirebase.last_name ?? '',
                    firstName: window.user.infoFromFirebase.first_name ?? '',
                    city: window.user.infoFromFirebase.city ?? '',
                    country: window.user.infoFromFirebase.country ?? '',
                    line1: window.user.infoFromFirebase.line1 ?? '',
                    line2: window.user.infoFromFirebase.line2 ?? '',
                    postalCode: window.user.infoFromFirebase.postalCode ?? '',
                    state: window.user.infoFromFirebase.state ?? '',
                    phone: window.user.infoFromFirebase.phone ?? '',
                    email: window.user.name ?? '',
                    code: '',
                    projectPrefix: projectPrefix ?? ''
                }}
                validate={values => {
                    const errors: any = {};
                    const {firstName, lastName, companyName, projectPrefix, code} = values;
                    const {
                        invalidCompanyName,
                        invalidFirstName,
                        incorrectProjectPrefix,
                        invalidLastName,
                        invalidProjectPrefix
                    } = window.locales;
                    if (!firstName) errors.firstName = invalidFirstName;
                    if (!lastName) errors.lastName = invalidLastName;
                    if (!companyName) errors.companyName = invalidCompanyName;
                    if (!projectPrefix) errors.projectPrefix = invalidProjectPrefix;
                    if (projectPrefix?.length < 3) errors.projectPrefix = incorrectProjectPrefix;
                    setCode(code)
                    return errors;
                }}
                onSubmit={(values) => {
                    window.Loading.show();
                    const {
                        companyName,
                        lastName,
                        firstName,
                        city,
                        line1,
                        country,
                        line2,
                        postalCode,
                        state,
                        phone,
                        projectPrefix
                    } = values;

                    setProjectPrefix(projectPrefix);
                    window.fb.default
                        .database()
                        .ref('/users/' + window.user.uid)
                        .once('value')
                        .then((result: any) => {
                            result = result && result.val && result.val() ? result.val() : {};
                            const data = {
                                ...result,
                                company: companyName,
                                last_name: lastName,
                                first_name: firstName,
                                city: city,
                                country: country,
                                line1: line1,
                                line2: line2,
                                postalCode: postalCode,
                                state: state,
                                phone: phone,
                                projectPrefix: projectPrefix,
                            }

                            window.fb.default
                                .database()
                                .ref('/users/' + window.user.uid)
                                .set(data)
                                .then(() => {
                                    window.user.infoFromFirebase = {...window.user.infoFromFirebase, ...values}
                                    window.Loading.hide();
                                });
                        });
                }}>
                {({
                      values,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <PopupContentContainer>
                            <PopupHeader>
                                <NameContainer nameTheme={context.theme}>
                                    {window.locales.mainInfo}
                                </NameContainer>
                                {!passwordReseted ? <ButtonResetPassword onClick={sendRequestOnResetPassword}
                                                                         isProduction={isProduction}>
                                        {window.locales.resetPassword}
                                    </ButtonResetPassword> :
                                    <CommonText nameTheme={context.theme}>{window.locales.reseted}</CommonText>}
                            </PopupHeader>
                            <FieldsContainer>
                                <Field component={CustomInput}
                                       isProduction={isProduction}
                                       value={values.email}
                                       type={"text"}
                                       name={"email"}
                                       isDisabled={true}
                                       placeholder={"Email"}/>
                                <PhoneContainer>
                                    <Field component={CustomInput}
                                           isProduction={isProduction}
                                           value={values.phone}
                                           type={"text"}
                                           name={"phone"}
                                           placeholder={"Phone"}/>
                                    {sentCode && <Field component={CustomInput}
                                                        isProduction={isProduction}
                                                        value={values.phone}
                                                        type={"text"}
                                                        name={"code"}
                                                        placeholder={"Code"}/>}
                                    <button ref={buttonRef} onClick={SendCode}>
                                        {sentCode ? "Confirm code" : "Send code and enable 2FA"}
                                    </button>
                                </PhoneContainer>
                                <Field component={CustomInput}
                                       isProduction={isProduction}
                                       value={values.firstName}
                                       type={"text"}
                                       name={"firstName"}
                                       placeholder={"First name"}/>
                                <Field component={CustomInput}
                                       isProduction={isProduction}
                                       value={values.lastName}
                                       type={"text"}
                                       name={"lastName"}
                                       placeholder={"Last name"}/>
                                <Field component={CustomInput}
                                       isProduction={isProduction}
                                       value={values.companyName}
                                       type={"text"}
                                       name={"companyName"}
                                       placeholder={"Company name"}/>
                                <Field component={CustomInput}
                                       isProduction={isProduction}
                                       value={values.projectPrefix}
                                       type={"text"}
                                       name={"projectPrefix"}
                                       placeholder={"Project prefix"}/>
                            </FieldsContainer>
                            <AddressInfo values={values}/>
                        </PopupContentContainer>
                        <ButtonContainer>
                            {projectPrefix && <BackButton isProduction={isProduction} onClick={setShowViewProfile}>
                                {window.locales.close}
                            </BackButton>}
                            <ButtonSubmit nameTheme={context.theme} isProduction={isProduction}
                                          type={"submit"}>
                                {window.locales.saveChanges}
                            </ButtonSubmit>
                        </ButtonContainer>
                    </form>
                )}
            </Formik>
        </Popup>
    </PopupContainer>
}

export default ViewProfile;