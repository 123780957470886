import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconDataRefresh } from '../../common/icons';

import styled from 'styled-components';
import Select2 from '../../common/form/Select2';
import { ThemeContext } from '../../ThemeContext';

const DataRefreshContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (!!props.contextTheme ? '#4e5764' : '#b3b9c3')};
  font-size: 14px;
  cursor: pointer;
  user-select: none;
`;

const PeriodFormContainer = styled.div`
  position: absolute;
  flex-wrap: wrap;
  right: 100px;
  top: 40px;
  color: ${(props) => (!props.contextTheme ? '#ffffff' : '#4e5764')};
  background: ${(props) => {
    if (props.contextTheme === '') return '#252b33';
    if (props.contextTheme === 'light') return '#f5f6f9';
    return '#fff';
  }};
  padding: 20px 20px 0 20px;
  width: 500px;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(37, 43, 51, 0.6);
  z-index: 5;
  display: flex;
`;

const PeriodFormRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonConfirm = styled.div`
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => props.color};
  height: 40px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const ButtonReset = styled.div`
  color: ${(props) =>
    !!props.contextTheme ? 'rgba(62, 70, 81, 0.5)' : 'rgba(255, 255, 255, 0.5)'};
  cursor: pointer;
`;

export default (props) => {
  const Periods = [
    ['0', window.locales.off],
    ['5', `5${window.locales.secondSymbol}`],
    ['10', `10${window.locales.secondSymbol}`],
    ['30', `30${window.locales.secondSymbol}`],
    ['60', `1${window.locales.minuteSymbol}`],
    ['300', `5${window.locales.minuteSymbol}`],
    ['900', `15${window.locales.minuteSymbol}`],
    ['1800', `30${window.locales.minuteSymbol}`],
    ['3600', `1${window.locales.hourSymbol}`],
    ['7200', `2${window.locales.hourSymbol}`],
    ['86400', `1${window.locales.daySymbol}`],
  ];

  const wrapperRef = useRef(null);
  const defaultPeriod = '30';
  const colorTheme = window.config.project === 'production' ? '#2ea6f8' : '#ffa823';
  const [opened, setOpened] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const context = useContext(ThemeContext);

  useEffect(() => {
    const handleClickOutside = (event) =>
      wrapperRef.current && !wrapperRef.current.contains(event.target) && setOpened(!opened);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, opened]);

  const confirmPeriod = () => {
    props.applyInterval(selectedPeriod);
    setOpened(!opened);
  };

  const resetPeriod = () => {
    setSelectedPeriod(defaultPeriod);
    props.applyInterval(defaultPeriod);
  };

  const changePeriod = (key) => {
    setSelectedPeriod(key);
  };

  return (
    <div>
      <DataRefreshContainer contextTheme={context.theme} onClick={() => setOpened(!opened)}>
        <IconDataRefresh color={colorTheme} width={22} />
        {window.locales.dataRefresh}
      </DataRefreshContainer>
      {opened && (
        <PeriodFormContainer contextTheme={context.theme} ref={wrapperRef}>
          <PeriodFormRow>
            <div>{window.locales.period}</div>
            <Select2
              onChange={changePeriod}
              options={Periods}
              isMulti={false}
              value={selectedPeriod}
              label={'Pick'}
              theme={'dark'}
            />
          </PeriodFormRow>
          <PeriodFormRow>
            <ButtonsContainer>
              <ButtonConfirm color={colorTheme} onClick={confirmPeriod}>
                {window.locales.confirm}
              </ButtonConfirm>
              <ButtonReset contextTheme={context.theme} onClick={resetPeriod}>
                {window.locales.reset}
              </ButtonReset>
            </ButtonsContainer>
          </PeriodFormRow>
        </PeriodFormContainer>
      )}
    </div>
  );
};
