import React, { Component } from 'react';

import { IconClose } from '../../common/icons';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      style: props.style ? props.style : {},
      title: props.title ? props.title : '',
      children: props.children ? props.children : '',
      message: props.message && props.message !== '' ? props.message : undefined,
      cancel: props.cancel,
      submit: props.submit,
      disabled: props.disabled ? props.disabled : false,
    };

    this.visibility = props.visibility ? props.visibility : () => {};
    this._escFunction = this.escFunction.bind(this);

    document.addEventListener('keydown', this._escFunction);
    this.id = 'modalContainerContent' + Date.now() + Math.random();
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  componentWillUnmount() {
    document.body.className = '';
    document.removeEventListener('keydown', this._escFunction);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      style: props.style ? props.style : {},
      title: props.title ? props.title : '',
      children: props.children ? props.children : '',
      message: props.message && props.message !== '' ? props.message : undefined,
      cancel: props.cancel,
      submit: props.submit,
      disabled: props.disabled ? props.disabled : false,
    });
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.show) {
      this.hide();
    }
  }

  show() {
    document.body.className = 'noScroll';
    this.setState({ show: true }, () => {
      this.visibility(true);
    });
  }

  click(e) {
    let is = (el1, el2) => {
      if (!el1) return false;
      if (el1 === el2) return true;
      if (el1.id === 'loadingContainer') return true;
      return is(el1.parentNode, el2);
    };

    let el;

    el = document.getElementById(this.id);
    if (e && e.target && el && !is(e.target, el)) {
      document.body.className = '';
      this.setState({ show: false }, () => {
        this.visibility(false);
      });
    }
  }

  hide() {
    document.body.className = '';
    this.setState({ show: false }, () => {
      this.visibility(false);
    });
  }

  submitClick() {
    if (this.state.disabled) return;
    this.state.submit.click();
  }

  render() {
    return (
      <div className={'modalContainer' + (this.state.show ? ' show' : '')}>
        <div className={'modalBackground'} />
        <div className={'modalContainerContent'} onClick={this.click.bind(this)}>
          <div className="modalContent" id={this.id} style={this.state.style}>
            <div className="header">
              {!this.state.message ? this.state.title : ''}
              {this.state.message && <div className={'errorMessage'}>{this.state.message}</div>}
              <IconClose
                onClick={this.hide.bind(this)}
                theme={'blue'}
                id={this.props.closeId ? this.props.closeId : ''}
              />
            </div>
            <div className="body">{this.state.children}</div>
            <div className="footer">
              {this.state.cancel && (
                <div
                  id={this.state.cancel.id ? this.state.cancel.id : ''}
                  className="cancel"
                  onClick={this.state.cancel.click}
                >
                  {this.state.cancel.label}
                </div>
              )}
              {this.state.submit && (
                <div
                  id={this.state.submit.id ? this.state.submit.id : ''}
                  className={'submit' + (this.state.disabled ? ' disabled' : '')}
                  onClick={this.submitClick.bind(this)}
                >
                  {this.state.submit.label}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
