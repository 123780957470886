export default (project, theme) => {
  project = project ?? window.config.project;
  theme = theme ?? window.storage.get('css_color') ?? '';

  if (project === 'production') {
    switch (theme) {
      case '':
        import('../../less/production.less').then();
        break;
      case 'light':
        import('../../less/production_light.less').then();
        break;
      case 'white':
        import('../../less/production_white.less').then();
        break;
      case 'blue':
        import('../../less/production_blue.less').then();
        break;
      default:
        import('../../less/production.less').then();
        break;
    }
  } else {
    switch (theme) {
      case '':
        import('../../less/sandbox.less').then();
        break;
      case 'light':
        import('../../less/sandbox_light.less').then();
        break;
      case 'white':
        import('../../less/sandbox_white.less').then();
        break;
      case 'blue':
        import('../../less/sandbox_blue.less').then();
        break;
      default:
        import('../../less/sandbox.less').then();
        break;
    }
  }
};
