import React, {Component} from 'react';

import {
  Input,
} from '../../../common/form';

import {
  IconPerson
} from '../../../common/icons';

import {
  resetPassword
} from '../../../common/firebase';

import {
  goTo
} from '../../../common/methods';

class Reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: false
      },
      message: '',
      disabled: false
    };

    this.form = {
      email: '',
      button: window.locales.resetPassword
    };
  }

  componentDidMount() {
    window.Loading.hide();
  }

  submitForm() {
    if (this.state.disabled) return;
    let state = this.state;
    state.form = {email: false};

    if (this.form.email === '') {
      state.form.email = true;
      state.message = window.locales.fillInFields;
      return this.setState(state);
    }

    if (!this.form.email.match(/^(([^<>()\],;:\s@]+(\.[^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/)) {
      state.form.email = true;
      state.message = 'Enter valid email.';
      return this.setState(state);
    }

    this.form.button = 'Please wait';
    state.disabled = true;
    state.message = '';
    window.Loading.show();
    this.setState(state, () => {
      resetPassword(this.form.email).then(() => {
        let state = this.state;
        state.message = '';
        state.disabled = false;
        this.form.button = window.locales.resetPassword;
        this.setState(state, () => {
          window.Loading.hide();
          goTo('auth/reseted');
        });
      }).catch((e) => {
        let state = this.state;
        state.message = e.message;
        state.disabled = false;
        this.form.button = window.locales.resetPassword;
        this.setState(state, window.Loading.hide);
      })
    });
  }

  onChange(name, value) {
    this.form[name] = value;
  }

  render() {
    return (
      <div className="signUpForm signIn">
        <div className={'logo business'} onClick={() => {
          goTo('')
        }}/>
        <div className="errorMessage">
          {this.state.message}
        </div>
        <div className="pageSwitcher">
          <h3>{window.locales.resetPassword}</h3>
          {window.location.href.indexOf('business.anchorfree.com') === -1 &&
          <a href="#/auth/signup">{window.locales.dontHaveAccount}</a>}
        </div>
        <div className="row">
          <Input
            theme={'auth'}
            type="text"
            label={window.locales.email}
            value={this.form.email}
            onChange={this.onChange.bind(this, 'email')}
            onEnter={this.submitForm.bind(this)}
            notValid={this.state.form.email}
            disabled={this.state.disabled}
            icon={<IconPerson theme={'auth'}/>}
            autoComplete={true}
          />
        </div>
        <div className={'saveButton' + (this.state.disabled ? ' disabled' : '')} style={{marginTop: '10px'}}
             onClick={this.submitForm.bind(this)}>{this.form.button}</div>
      </div>
    );
  }
}

export default Reset;