import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: Helvetica;
    src: url("/static/fonts/Helvetica.eot");
    src: url('/static/fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Helvetica.woff2') format('woff2'),
    url('/static/fonts/Helvetica.woff') format('font-woff'),
    url('/static/fonts/Helvetica.ttf') format('truetype'),
  }

  @font-face {
    font-family: Gordita-Regular;
    src: url("/static/fonts/Gordita-Regular.ttf");
  }

  @font-face {
    font-family: Gordita-Medium;
    src: url("/static/fonts/Gordita-Medium.ttf");
  }

  @font-face {
    font-family: Gordita-Bold;
    src: url("/static/fonts/Gordita-Bold.ttf");
  }

  @font-face {
    font-family: Hind-Bold;
    src: url("/static/fonts/Hind-Bold.ttf");
  }

  @font-face {
    font-family: Hind-Light;
    src: url("/static/fonts/Hind-Light.ttf");
  }

  @font-face {
    font-family: Hind-Medium;
    src: url("/static/fonts/Hind-Medium.ttf");
  }

  @font-face {
    font-family: Hind-Regular;
    src: url('/static/fonts/Hind-Regular.eot'); /* IE9 Compat Modes */
    src: url('/static/fonts/Hind-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/static/fonts/Hind-Regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/static/fonts/Hind-Regular.woff') format('font-woff'), /* Pretty Modern Browsers */ url('/static/fonts/Hind-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/static/fonts/Hind-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: MinionPro-Bold;
    src: url("/static/fonts/MinionPro-Bold.ttf");
  }

  @font-face {
    font-family: MinionPro-BoldCn;
    src: url("/static/fonts/MinionPro-BoldCn.ttf");
  }

  @font-face {
    font-family: MinionPro-Medium;
    src: url("/static/fonts/MinionPro-Medium.ttf");
  }

  @font-face {
    font-family: MinionPro-Regular;
    src: url("/static/fonts/MinionPro-Regular.ttf");
  }
`;
