import { signOut } from './index';

export default (email, password) => {
  return new Promise((resolve, reject) => {
    const fb = window.fb;
    const storage = window.storage;
    const config = window.config;
    const user = window.user;
    let signIn = () => {
      fb.default
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          response = response.user;
          if (!response.emailVerified) {
            return reject({ status: false, message: 'Please, verify your email before Sign In!' });
          }

          storage.set('project', 'production');

          let host = user.projects[0][1];
          let project = 'production';

          config.host = 'https://' + host + '/';
          config.project = project;

          window.signInResponseFromFB = false;

          let i = setInterval(() => {
            if (!window.signInResponseFromFB) return;

            clearInterval(i);
            return resolve({ status: true });
          }, 50);
        })
        .catch((error) => {
          return reject({ status: false, message: error.message });
        });
    };

    signOut().then(() => {
      signIn();
    });
  });
};
