import React, { Component } from 'react';

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 22,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 22,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 addUser ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path d="M9.575 8.155a4.562 4.562 0 0 0 1.75-3.596C11.325 2.045 9.291 0 6.79 0 4.289 0 2.254 2.045 2.254 4.56c0 1.459.686 2.76 1.75 3.595A6.83 6.83 0 0 0 0 14.375v4.844A.78.78 0 0 0 .777 20h12.026a.78.78 0 0 0 .777-.781v-4.843a6.83 6.83 0 0 0-4.005-6.22zM3.81 4.56a2.993 2.993 0 0 1 2.98-2.997A2.993 2.993 0 0 1 9.772 4.56 2.992 2.992 0 0 1 6.79 7.556 2.992 2.992 0 0 1 3.809 4.56zm8.217 13.878H1.554v-4.061c0-2.899 2.346-5.257 5.23-5.257h.012c2.884 0 5.23 2.358 5.23 5.257v4.062z" />
          <path
            strokeWidth=".5"
            d="M17.684 7.78h3.336v1.106h-3.336v3.336H16.58V8.886h-3.336V7.781h3.336V4.444h1.105v3.337z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default AddUser;
