import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    ButtonPlanContainer,
    CurrentPlanButton, ButtonUpgrade
} from "../../components";
import {ThemeContext} from "../../../ThemeContext";
import {
    CreateCheckoutSessionRequest,
    GetSubscriptionsRequest,
    GetSessionByIdRequest,
    GetPaymentMethodIdBySessionIdRequest,
    CreateSubscriptionRequest, CustomerRequest
} from "../../../components/PaymentService";
import {useHistory} from "react-router-dom";
import {IPage} from "../../../interfaces";
import Plans from "../../Plans";
import CancelResponse from "../Responses/CancelResponse";
import SuccessResponse from "../Responses/SuccessResponse";
import ErrorResponse from "../Responses/ErrorResponse";
import Popup from "../Responses"
import {goTo} from "../../../common/methods";
import {StripeResponse} from "../../../enums";

type CheckoutSession = {
    sessionId?: string,
    response?: string
}

export default ({activeProject, stripePromise}: IPage) => {
    let history = useHistory();
    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';
    const [chosenPlan, setChosenPlan] = useState('')
    const [disabled, setDisabled] = useState(true);
    const [checkoutResponse, setCheckoutResponse] = useState<CheckoutSession | null>();

    const downgradePlan = () => history.push("/contactUs");

    const upgradePlan = async () => {
        window.Loading.show();
        const {session_id} = await CreateCheckoutSessionRequest();
        const stripe = await stripePromise;
        const {error} = await stripe.redirectToCheckout({
            sessionId: session_id,
        });

        error &&
        setCheckoutResponse({
            response: error
        })

        window.Loading.hide();
    };

    const checkSubscriptions = useCallback(async () => {
        const subscriptions = await GetSubscriptionsRequest(activeProject.publickey);
        subscriptions.length === 0 ? setChosenPlan('free') :
            setChosenPlan('pay');

        setDisabled(activeProject.current_user_role.name !== "OWNER");
    }, [activeProject.publickey, activeProject.current_user_role.name])

    useEffect(() => {
        window.Loading.show()
        const {email, first_name, last_name} = window.user.infoFromFirebase;
        CustomerRequest(
            {
                email: email,
                name: `${first_name} ${last_name}`,
            }
        ).then(async (customer: any) => {
            window.Loading.hide()
        }).catch((error) => {
            throw error
        });
    }, [])

    useEffect(() => {
        checkSubscriptions().then();
    }, [checkSubscriptions]);

    useEffect(() => {
        const url = new URL(window.location.href.replace(/#/g, ""));
        const sessionId = url.searchParams.get("session_id")!;
        const response = url.searchParams.get("response")!;
        if (!sessionId && !response) return;
        setCheckoutResponse({
            sessionId, response
        })

        response === StripeResponse.Success && GetSessionByIdRequest(sessionId).then(async (session) => {
            const {setup_intent} = session
            const setupIntent = setup_intent && await GetPaymentMethodIdBySessionIdRequest(setup_intent.id);
            const {payment_method} = setupIntent;
            await CreateSubscriptionRequest({
                default_payment_method: payment_method.id,
                project_id: activeProject.publickey
            });
        })
    }, [activeProject.publickey])


    const buttons = (planType: string, togglePlan: () => void) => <ButtonPlanContainer>
        {chosenPlan === planType ? <CurrentPlanButton/> :
            <ButtonUpgrade nameTheme={context.theme} disabled={disabled}
                           isProduction={isProduction} onClick={togglePlan}>
                {chosenPlan === planType ? window.locales.downgradeNow : window.locales.upgradeNow}
            </ButtonUpgrade>
        }
    </ButtonPlanContainer>

    const hidePopup = useCallback(async () => {
        await checkSubscriptions()
        setCheckoutResponse(null)
        goTo(`billing/${activeProject.publickey}`)
    }, [activeProject.publickey, checkSubscriptions])

    const popupResponse = useMemo(() => {
        if (checkoutResponse?.response === StripeResponse.Success) return <SuccessResponse/>;
        if (checkoutResponse?.response === StripeResponse.Cancel) return <CancelResponse/>;
        if (checkoutResponse?.response === StripeResponse.Error) return <ErrorResponse/>;
        return null;
    }, [checkoutResponse])

    return <>
        <Plans freePlanButtons={buttons('free', downgradePlan)}
               payPlanButtons={buttons('pay', upgradePlan)}/>
        {popupResponse && <Popup hidePopup={hidePopup}>
            {popupResponse}
        </Popup>}
    </>
}