import React, { Component } from 'react';

import { getUsers } from '../../components/Users';

import { loadPayments } from '../../components/Payments';

import { goTo, setParams, getDateString } from '../../common/methods';

import Table from '../../common/table';

import Search from './Search';
import PrivateSearch from './PrivateSearch';
import AddContainer from './AddContainer';
import UpdateContainer from './UpdateContainer';

import { IconPlus, IconStatus } from '../../common/icons';

import { Empty, Checkbox } from '../../common/form';

import { signOut } from '../../common/firebase';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: undefined,
      users: [],
      payments: false,
      activeProject: props.activeProject,
      checkboxes: {
        all: 'off',
      },
      i: 0,
    };

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.componentDidMount(props);
  }

  componentDidMount(props) {
    return new Promise((resolve) => {
      window.Loading.show();
      let state = this.state;

      if (
        props &&
        props.activeProject &&
        state.activeProject.publickey !== props.activeProject.publickey
      ) {
        state = {
          page: undefined,
          users: [],
          payments: false,
          activeProject: props.activeProject,
          checkboxes: {
            all: 'off',
          },
          i: 0,
        };
      } else if (props) {
        if (props.page) state.page = props.page;
        if (props.params) {
          let j1, j2;
          try {
            j1 = JSON.stringify(this.props.params);
            j2 = JSON.stringify(props.params);

            if (j1 !== j2) {
              state.page = undefined;
            }
          } catch (e) {}
        }
        if (props.checkboxes) state.checkboxes = props.checkboxes;
      }

      let params =
        props && props.params ? props.params : this.props.params ? this.props.params : {};
      getUsers(params, state.page)
        .then((users) => {
          users = users ? users : [];
          if (state.page && state.page !== '0') {
            state.users = state.users.concat(users);
          } else {
            state.users = users;
          }

          loadPayments().then((payments) => {
            state.payments = payments.length > 0;
            this.setState(state, () => {
              window.Loading.hide();
              return resolve();
            });
          });
        })
        .catch((e) => {
          window.error(e);
          signOut().then(() => {
            goTo('');
            return resolve();
          });
        });
    });
  }

  openUserPage(uid) {
    window.tempStorage.search = this.props.params ? this.props.params : undefined;
    goTo('user/' + this.state.activeProject.publickey + '/' + uid);
  }

  loadMore() {
    let state = this.state;
    state.page = window.data.usersCursor
      ? window.data.usersCursor
      : this.state.page
      ? this.state.page + 1
      : 1;
    state.checkboxes.all = 'off';
    this.componentDidMount(state);
  }

  render() {
    if (!this.state.users) return <div />;
    let size = this.state.page ? (this.state.page + 1) * 50 : 50;

    return (
      <div id="screenUsers" className="portalContentContainer">
        <div className="head">
          <h1>{window.locales.users}</h1>
          {this.state.activeProject.project_type !== 'private_vpn' && (
            <Search params={this.props.params} />
          )}
          {this.state.activeProject.project_type === 'private_vpn' && (
            <PrivateSearch params={this.props.params} />
          )}
          <UpdateContainer
            checkboxes={this.state.checkboxes}
            activeProject={this.state.activeProject}
            loadUsers={this.componentDidMount.bind(this)}
          />
          {this.state.activeProject.role !== 'SUPPORT' && (
            <AddContainer
              activeProject={this.state.activeProject}
              loadUsers={this.componentDidMount.bind(this)}
            />
          )}
        </div>
        {this.renderTable()}
        {(this.state.users.length === size || window.data.usersCursor) && (
          <div className={'loadMore'}>
            <IconPlus
              onClick={this.loadMore.bind(this)}
              label={window.locales.loadMore}
              theme={'blue'}
            />
          </div>
        )}
      </div>
    );
  }

  allCheckboxesChanged(v) {
    let state = this.state;
    state.checkboxes.all = v;

    for (let k in this.state.users) {
      state.checkboxes[this.state.users[k].name] = v;
    }

    this.setState(state);
  }

  checkboxChanged(uid, v) {
    let state = this.state;
    state.checkboxes[uid] = v;

    if (state.checkboxes.all === 'on') state.checkboxes.all = 'off';

    this.setState(state);
  }

  sortBy(type) {
    if (this.state.activeProject.project_type !== 'private_vpn') return;

    let params = this.props.params;
    if (!params.order_field || params.order_field !== type) params.order_value = 'asc';
    else if (params.order_value === 'asc') params.order_value = 'desc';
    else params.order_value = 'asc';
    params.order_field = type;

    this.setState(
      {
        page: undefined,
      },
      () => {
        setParams(params);
      },
    );
  }

  renderOrderArrow(type) {
    if (
      this.state.activeProject.project_type !== 'private_vpn' ||
      !this.props.params.order_field ||
      this.props.params.order_field !== type
    )
      return '';
    let t = this.props.params.order_value === 'asc' ? 'down' : 'up';

    return (
      <img
        alt=""
        src={'/static/assets/icons/arrow_' + t + '.png'}
        style={{ width: '10px', marginLeft: '5px' }}
      />
    );
  }

  renderTable() {
    let s = { display: 'flex', alignItems: 'center' };
    let head = [
      [
        this.state.activeProject.project_type !== 'public_vpn' && (
          <Checkbox
            theme={'blue small'}
            changed={this.allCheckboxesChanged.bind(this)}
            status={this.state.checkboxes.all}
          />
        ),
        { width: '25px' },
      ],
      [
        <div style={s}>
          {window.locales.id}
          {this.renderOrderArrow('id')}
        </div>,
        { width: '100px' },
        this.sortBy.bind(this, 'id'),
      ],
      [
        <div style={s}>
          {window.locales.accountName}
          {this.renderOrderArrow('name')}
        </div>,
        {},
        this.sortBy.bind(this, 'name'),
      ],
      [
        <div style={s}>
          {window.locales.description}
          {this.renderOrderArrow('description')}
        </div>,
        {},
        this.sortBy.bind(this, 'description'),
      ],
      [window.locales.activatedDevices, { width: '80px' }],
      [window.locales.condition, { width: '100px' }],
      [
        <div style={s}>
          {window.locales.registrationTime}
          {this.renderOrderArrow('register')}
        </div>,
        { width: '150px' },
        this.sortBy.bind(this, 'register'),
      ],
    ];

    if (this.state.payments) {
      head.push([window.locales.purchases, { width: '100px' }]);
    }

    let i = 0;
    let body = [];
    for (let k in this.state.users) {
      let user = this.state.users[k];
      let name;
      let provider;

      let temp;
      try {
        temp = JSON.parse(user.social.firebase);
      } catch (e) {
        temp = {};
      }

      provider = [];
      if (
        (!temp.providerUserInfo || temp.providerUserInfo.length === 0) &&
        (!temp.email || temp.email === '')
      ) {
        provider.push(<img alt="" src="/static/assets/auth_service/anonymous.svg" key={++i} />);
      }

      let secondRow;

      if (temp.providerUserInfo && temp.providerUserInfo.length > 0) {
        for (let j in temp.providerUserInfo) {
          let p = temp.providerUserInfo[j];

          if (!secondRow && p.displayName) secondRow = p.displayName;
          if (p.email) secondRow = p.email;

          if (p.providerId === 'twitter.com')
            provider.push(<img alt="" src="/static/assets/auth_service/twitter.svg" key={++i} />);
          else if (p.providerId === 'facebook.com')
            provider.push(<img alt="" src="/static/assets/auth_service/facebook.svg" key={++i} />);
          else if (p.providerId === 'google.com')
            provider.push(<img alt="" src="/static/assets/auth_service/google.svg" key={++i} />);
          else if (p.providerId === 'github.com')
            provider.push(<img alt="" src="/static/assets/auth_service/github.svg" key={++i} />);
          else if (p.providerId === 'password')
            provider.push(<img alt="" src="/static/assets/auth_service/email.svg" key={++i} />);
          else if (p.providerId === 'phone')
            provider.push(<img alt="" src="/static/assets/auth_service/phone.svg" key={++i} />);
        }
      }

      let n = user.name;

      if (!secondRow && temp.email && temp.email !== '') secondRow = temp.email;
      if (!secondRow && user.name.match(/^(anonymous|firebase)_.*$/)) {
        n = user.name.match(/^([^_]+)_(.*)$/);

        name = (
          <div className={'nameContainer'}>
            <div className={'name'}>
              {n[1].charAt(0).toUpperCase() + n[1].substr(1)}
              <div className={'lightGray'}>{n[2]}</div>
            </div>
            {provider}
          </div>
        );
      } else {
        name = (
          <div className={'nameContainer'}>
            <div className={'name'}>
              {user.name}
              {secondRow && secondRow !== '' ? <div className={'lightGray'}>{secondRow}</div> : ''}
            </div>
            {provider}
          </div>
        );
      }

      let date = getDateString(user.registration_time);
      if (date) {
        date = (
          <div>
            {date.date}
            <div className={'lightGray'}>{date.time}</div>
          </div>
        );
      } else {
        date = '';
      }

      body.push({
        row: [
          [
            this.state.activeProject.project_type !== 'public_vpn' && (
              <Checkbox
                theme={'blue small'}
                status={this.state.checkboxes[user.name] === 'on' ? 'on' : 'off'}
                changed={this.checkboxChanged.bind(this, user.name)}
              />
            ),
            { noClick: true },
          ],
          [user.id, { wordBreak: 'normal' }],
          [name],
          [user.given_name ? user.given_name : ''],
          [user.activated_devices],
          [
            <IconStatus
              status={user.condition === 0 ? 'on' : 'off'}
              label={
                user.condition === 0
                  ? user.activated_devices === 0 && user.auth_method === 'carrier_admin'
                    ? window.locales.invited
                    : window.locales.active
                  : window.locales.disabled
              }
            />,
          ],
          [date],
        ],
        click: this.openUserPage.bind(this, user.id),
      });

      if (this.state.payments) {
        body[body.length - 1].row.push([
          <IconStatus
            status={user.purchases.length === 0 ? 'off' : 'on'}
            label={user.purchases.length === 0 ? 'Free' : 'Paid'}
          />,
        ]);
      }
    }

    if (body.length === 0) {
      return <Empty row1={window.locales.noUsers} />;
    }

    return <Table head={head} body={body} theme={'default pointer'} />;
  }
}

export default index;
