import React, {Component} from 'react';

import {Input, Select, Textarea} from '../../common/form';

import Modal from '../../common/modal';

import {addProject, editProject} from '../../components/Project';

import {addUser, setRole} from '../../components/Access';

import {
    loadAuthentifications,
    addAuthentification,
    deleteAuthentifications,
} from '../../components/Authentifications';

import {goTo} from '../../common/methods';

import {setVisibility} from '../../components/Network';


let OPTIONS2 = [
    ['default', window.locales.email],
    ['window.userId', window.locales.uid],
];

class Add extends Component {
    constructor(props) {
        super(props);

        let prefixProjectCode = window.user.infoFromFirebase.company_code
            ? window.user.infoFromFirebase.company_code
            : window.user.infoFromFirebase.company;
        prefixProjectCode = prefixProjectCode ?? '';

        this.state = {
            type: props.type,
            form: {
                carrier_name: false,
                publickey: false,
                admin: false,
                description: false,
                project_type: false,
                icon: false,
            },
            message: '',
            disabled: false,
            i: 0,
            prefixProjectCode: prefixProjectCode,
        };

        this.form = {
            carrier_name: '',
            publickey: '',
            admin: '',
            description: '',
            project_type: 'private_vpn',
            authorizationType: 'default',
            icon: undefined,
        };

        this.modal = {};
        this.onMount = props.onMount;
        this.projectAdded = props.projectAdded;

        OPTIONS2 = [
            ['default', window.locales.email],
            ['window.userId', window.locales.uid],
        ];
    }

    UNSAFE_componentWillReceiveProps(props) {
        OPTIONS2 = [
            ['default', window.locales.email],
            ['window.userId', window.locales.uid],
        ];

        this.projectAdded = props.projectAdded;
        this.setState({
            type: props.type,
        });
    }

    componentDidMount() {
        if (this.modal.add && this.onMount) this.onMount(this.modal.add, this.input);
    }

    fieldChanged(field, value) {
        this.form[field] = value;

        if (field === 'project_type') {
            this.setState({i: this.state.i + 1});
        }
    }

    handleFileUpload(event) {
        let icon = event.target.files[0];
        this.form.icon = undefined;
        if (!icon.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
            return this.setState({iconError: window.locales.uploadFormats});
        } else if (icon.size > 5000000) {
            return this.setState({iconError: window.locales.uploadSize});
        }

        this.form.icon = icon;
        this.setState({i: this.state.i + 1, iconError: undefined});
    }

    addSubmit() {
        let state = this.state,
            valid = true;

        state.form = {
            carrier_name: false,
            publickey: false,
            admin: false,
            description: false,
            project_type: false,
            icon: false,
        };

        if (this.form.carrier_name.trim() === '') {
            valid = false;
            state.form.carrier_name = true;
        }

        if (
            this.form.publickey.trim() === '' ||
            !this.form.publickey.match(/[a-z0-9_]+/) ||
            this.form.publickey.length < 5
        ) {
            valid = false;
            state.form.publickey = true;
        }

        if (this.form.project_type.trim() === '') {
            valid = false;
            state.form.project_type = true;
        }

        if (!valid) {
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (
            this.form.admin !== '' &&
            !this.form.admin.match(/^([a-zA-Z0-9_\-+]+)@([a-zA-Z0-9_]+)\.([a-zA-Z]{2,5})$/)
        ) {
            state.message = window.locales.enterCorrectEmail;
            state.form.admin = true;
            return this.setState(state);
        }

        let icon;

        if (this.form.icon && this.form.icon.name && this.form.icon.name !== '') {
            icon = this.form.icon;
        }

        state.disabled = true;
        window.Loading.show();
        this.setState(state, () => {
            addProject(
                this.state.prefixProjectCode + '_' + this.form.publickey,
                this.form.description,
                this.form.project_type,
                this.form.carrier_name,
                icon,
            )
                .then(() => {
                    if (this.form.admin !== '') {
                        addUser(this.form.admin, this.state.prefixProjectCode + '_' + this.form.publickey)
                            .then(() => {
                                setRole(
                                    this.form.admin,
                                    'ADMIN',
                                    this.state.prefixProjectCode + '_' + this.form.publickey,
                                )
                                    .then(() => {
                                        this.added();
                                    })
                                    .catch((e) => {
                                        this.added();
                                    });
                            })
                            .catch((e) => {
                                this.added();
                            });
                    } else {
                        this.added();
                    }
                })
                .catch((e) => {
                    window.error(e);
                    this.setState(
                        {
                            message: e.message,
                            disabled: false,
                        },
                        window.Loading.hide,
                    );
                });
        });
    }

    addStep2() {
        return new Promise((resolve) => {
            this.addFirebase().then(() => {
                this.addNetworks().then(() => {
                    return resolve();
                });
            });
        });
    }

    addNetworks(i) {
        return new Promise((resolve) => {
            if (typeof i === 'undefined') {
                return editProject(this.state.prefixProjectCode + '_' + this.form.publickey, {})
                    .then(() => {
                        return resolve(this.addNetworks(0));
                    })
                    .catch((e) => {
                        return resolve();
                    });
            }

            let networks = [
                'no',
                'de',
                'hk',
                'ru',
                'jp',
                'dk',
                'fr',
                'ua',
                'br',
                'se',
                'sg',
                'gb',
                'id',
                'ie',
                'ca',
                'us',
                'ch',
                'in',
                'mx',
                'it',
                'es',
                'ar',
                'au',
                'us-south',
                'cz',
                'ro',
                'tr',
                'nl',
            ];
            if (i === networks.length) return resolve();

            setVisibility(this.state.prefixProjectCode + '_' + this.form.publickey, networks[i], 'true')
                .then(() => {
                    return resolve(this.addNetworks(++i));
                })
                .catch(() => {
                    return resolve();
                });
        });
    }

    addFirebase() {
        return new Promise((resolve) => {
            let firebase_api_key = 'AIzaSyC2BgHGciUuhzo6nCPIsbM4CDL0hrOQMZ8';
            let databaseURL = 'https://oauth-default-4d635.firebaseio.com';

            if (window.config.project === 'STAGE') {
                firebase_api_key = 'AIzaSyCAPDUvGbS2appTtJTfiYhx2Q-Efc3f3BE';
                databaseURL = 'https://oauth-default-stage.firebaseio.com';
            }

            let f = '{"firebase_api_key":"' + firebase_api_key + '","databaseURL":"' + databaseURL + '"}';

            if (this.form.authorizationType !== 'default')
                f =
                    '{"firebase_api_key":"' +
                    firebase_api_key +
                    '","databaseURL":"' +
                    databaseURL +
                    '", "type": "window.userid"}';

            addAuthentification(this.state.prefixProjectCode + '_' + this.form.publickey, 'firebase', f)
                .then(() => {
                    loadAuthentifications(this.state.prefixProjectCode + '_' + this.form.publickey)
                        .then((result) => {
                            let a;
                            for (let k in result.response.all_auth_settings) {
                                if (result.response.all_auth_settings[k].auth_method === 'anonymous') {
                                    a = result.response.all_auth_settings[k];
                                    break;
                                }
                            }

                            if (!a) return resolve();

                            deleteAuthentifications(
                                this.state.prefixProjectCode + '_' + this.form.publickey,
                                a.auth_method,
                                a.auth_method_id,
                            )
                                .then(() => {
                                    return resolve();
                                })
                                .catch((e) => {
                                    return resolve();
                                });
                        })
                        .catch((e) => {
                            return resolve();
                        });
                })
                .catch((e) => {
                    return resolve();
                });
        });
    }

    added() {
        this.addStep2().then(() => {
            this.setState(
                {
                    disabled: false,
                    message: false,
                },
                () => {
                    window.Loading.hide();
                    if (this.projectAdded) {
                        this.projectAdded(this.form.publickey);
                    } else if (this.modal.add) {
                        this.hideModal().then(() => {
                            window.reloadPortal(undefined, true);
                        });
                    } else {
                        goTo('projects');
                    }
                },
            );
        });
    }

    hideModal() {
        this.form = {
            carrier_name: '',
            publickey: '',
            admin: '',
            description: '',
            project_type: 'private_vpn',
            authorizationType: 'default',
        };

        let state = this.state;
        state.form = {
            carrier_name: false,
            publickey: false,
            admin: false,
            description: false,
            project_type: false,
        };
        state.message = '';
        state.disabled = false;

        return new Promise((resolve) => {
            this.setState(state, () => {
                this.modal.add.hide();
                return resolve();
            });
        });
    }

    renderPage() {
        return (
            <div id="firstProject">
                <div className="title">{window.locales.createProject}</div>
                <Input
                    id={'PROJECT_ADD_PROJECT_NAME'}
                    onRef={(ref) => {
                        this.input = ref;
                    }}
                    label={window.locales.projectName}
                    theme={'dark'}
                    notValid={this.state.form.carrier_name}
                    value={this.form.carrier_name}
                    disabled={this.state.disabled}
                    onChange={this.fieldChanged.bind(this, 'carrier_name')}
                    onEnter={this.addSubmit.bind(this)}
                />
                <Input
                    id={'PROJECT_ADD_PROJECT_ID'}
                    label={window.locales.publickey}
                    theme={'dark'}
                    notValid={this.state.form.publickey}
                    value={this.form.publickey}
                    disabled={this.state.disabled}
                    prefix={this.state.prefixProjectCode + '_'}
                    onChange={this.fieldChanged.bind(this, 'publickey')}
                    onEnter={this.addSubmit.bind(this)}
                    limit={40 - 1 - this.state.prefixProjectCode.length}
                />
                <Textarea
                    id={'PROJECT_ADD_PROJECT_DESCRIPTION'}
                    label={window.locales.description}
                    theme={'dark'}
                    notValid={this.state.form.description}
                    value={this.form.description}
                    disabled={this.state.disabled}
                    onChange={this.fieldChanged.bind(this, 'description')}
                />
                {this.form.project_type === 'private_vpn' && (
                    <Select
                        id={'PROJECT_ADD_PROJECT_AUTHORIZATION_TYPE'}
                        label={window.locales.authorizationType}
                        theme={'dark'}
                        value={this.form.authorizationType}
                        onChange={this.fieldChanged.bind(this, 'authorizationType')}
                        options={OPTIONS2}
                        disabled={this.state.disabled}
                    />
                )}
                <Input
                    id={'PROJECT_ADD_PROJECT_ADMIN_EMAIL'}
                    label={window.locales.adminOptional}
                    theme={'dark'}
                    notValid={this.state.form.admin}
                    value={this.form.admin}
                    disabled={this.state.disabled}
                    onChange={this.fieldChanged.bind(this, 'admin')}
                    onEnter={this.addSubmit.bind(this)}
                />
                <div className={'buttonContainer'}>
                    <div className="button">Upload image</div>
                    <input
                        id={'PROJECT_ADD_PROJECT_UPLOAD_IMAGE'}
                        onChange={this.handleFileUpload.bind(this)}
                        type="file"
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '147px',
                            height: '100%',
                            opacity: '0',
                        }}
                    />
                    {this.form.icon && this.form.icon.name && (
                        <div style={{marginLeft: '15px'}}>{this.form.icon.name}</div>
                    )}
                    {this.state.iconError && (
                        <div style={{color: '#C50000', marginLeft: '15px'}}>{this.state.iconError}</div>
                    )}
                </div>
                <div className="footer">
                    <div
                        className={'button blue' + (this.state.disabled ? ' disabled' : '')}
                        id={'PROJECT_ADD_PROJECT_SUBMIT'}
                        onClick={this.addSubmit.bind(this)}
                    >
                        {window.locales.addProject}
                    </div>
                </div>
            </div>
        );
    }

    renderModal() {
        return (
            <Modal
                title={window.locales.addProject}
                onRef={(ref) => {
                    this.modal.add = ref;
                }}
                message={this.state.message}
                cancel={{
                    id: 'PROJECT_ADD_PROJECT_CANCEL',
                    label: window.locales.cancel,
                    click: this.hideModal.bind(this),
                }}
                submit={{
                    id: 'PROJECT_ADD_PROJECT_SUBMIT',
                    label: window.locales.addProject,
                    click: this.addSubmit.bind(this),
                }}
            >
                <Input
                    id={'PROJECT_ADD_PROJECT_NAME'}
                    onRef={(ref) => {
                        this.input = ref;
                    }}
                    label={window.locales.projectName}
                    notValid={this.state.form.carrier_name}
                    value={this.form.carrier_name}
                    disabled={this.state.disabled}
                    onChange={this.fieldChanged.bind(this, 'carrier_name')}
                    onEnter={this.addSubmit.bind(this)}
                />
                <Input
                    id={'PROJECT_ADD_PROJECT_ID'}
                    label={window.locales.publickey}
                    notValid={this.state.form.publickey}
                    value={this.form.publickey}
                    disabled={this.state.disabled}
                    prefix={this.state.prefixProjectCode + '_'}
                    onChange={this.fieldChanged.bind(this, 'publickey')}
                    onEnter={this.addSubmit.bind(this)}
                    limit={40 - 1 - this.state.prefixProjectCode.length}
                />
                <Textarea
                    id={'PROJECT_ADD_PROJECT_DESCRIPTION'}
                    label={window.locales.description}
                    notValid={this.state.form.description}
                    value={this.form.description}
                    disabled={this.state.disabled}
                    onChange={this.fieldChanged.bind(this, 'description')}
                />
                {this.form.project_type === 'private_vpn' && (
                    <Select
                        id={'PROJECT_ADD_PROJECT_AUTHORIZATION_TYPE'}
                        label={window.locales.authorizationType}
                        value={this.form.authorizationType}
                        onChange={this.fieldChanged.bind(this, 'authorizationType')}
                        options={OPTIONS2}
                        disabled={this.state.disabled}
                    />
                )}
                <Input
                    id={'PROJECT_ADD_PROJECT_ADMIN_EMAIL'}
                    label={window.locales.adminOptional}
                    notValid={this.state.form.admin}
                    value={this.form.admin}
                    disabled={this.state.disabled}
                    onChange={this.fieldChanged.bind(this, 'admin')}
                    onEnter={this.addSubmit.bind(this)}
                />
                <div className={'buttonContainer'} style={{marginBottom: '0'}}>
                    <div className="button">{window.locales.uploadImage}</div>
                    <input
                        id={'PROJECT_ADD_PROJECT_UPLOAD_IMAGE'}
                        onChange={this.handleFileUpload.bind(this)}
                        type="file"
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '147px',
                            height: '100%',
                            opacity: '0',
                        }}
                    />
                    {this.form.icon && this.form.icon.name && (
                        <div style={{marginLeft: '15px'}}>{this.form.icon.name}</div>
                    )}
                    {this.state.iconError && (
                        <div style={{color: '#C50000', marginLeft: '15px'}}>{this.state.iconError}</div>
                    )}
                </div>
            </Modal>
        );
    }

    render() {
        if (this.state.type === 'modal') return this.renderModal();
        if (this.state.type === 'firstproject') return this.renderPage();
    }
}

export default Add;
