import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';
import { getActiveProject } from '../../components/Projects';
window.data.purchases = {};

const getPurchases = (uid, load) => {
  if (load && window.data.purchases[uid]) delete window.data.purchases[uid];

  return new Promise((resolve) => {
    if (window.data.purchases[uid]) return resolve(window.data.purchases[uid]);

    loadPurchases(uid)
      .then((purchase) => {
        window.data.purchases[uid] = purchase;
        return resolve(window.data.purchases[uid]);
      })
      .catch((e) => {
        return resolve([]);
      });
  });
};

const loadPurchases = (uid) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/subscribers/' + uid + '/purchase';
    let params = {
      access_token: getActiveToken(),
    };

    Network.get(request, params)
      .then((response) => {
        return resolve(response.response.purchases);
      })
      .catch(() => {
        return reject();
      });
  });
};

const deletePurchases = (uid, pid) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/subscribers/' + uid + '/purchase';
    let params = {
      access_token: getActiveToken(),
      purchase_id: pid,
    };

    Network.delete(request, params)
      .then((response) => {
        if (response.response.result === 'NOT_FOUND') {
          return reject({ message: 'Purchases not found' });
        }
        return resolve(true);
      })
      .catch((e) => {
        return reject({ message: 'Internal server error' });
      });
  });
};

const activatePurchase = (uid, pid) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'portal/purchase/recovery';
    let params = {
      publickey: getActiveProject().publickey,
      access_token: window.user.firebaseToken,
      purchase_history_id: pid,
    };

    Network.post(request, params)
      .then((response) => {
        return resolve(true);
      })
      .catch((e) => {
        return reject({ message: 'Internal server error' });
      });
  });
};

export { getPurchases, loadPurchases, deletePurchases, activatePurchase };
