import React, {useContext} from "react";
import styled from "styled-components";
import goTo from "../../../../common/methods/goTo";
import {ThemeContext} from "../../../../ThemeContext";
import {
    TabsHeaderContainer,
    TabsContainer,
    TabContainer,
    TitlePageContainer,
    ColorEnvironment
} from "../../../components";
import {IVpn, IColor} from "../../../../interfaces";


const SettingsLinkContainer = styled.div<{ isProduction: boolean }>`
  display: flex;
  cursor: pointer;
  :hover {
    color: ${ColorEnvironment};
  }
`;

const Separator = styled.div`
  border: 1px solid;
  width: 40px;
  height: 0;
  margin-left: 8px;
  margin-right: 8px;
`;

const ArrowIcon: React.FC<IColor> = ({nameTheme}) => <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={20}
    viewBox={'0 0 10 20'}
    stroke={nameTheme ? '#000000' : '#FFFFFF'}>
    <path fill="none" fillRule="evenodd" strokeWidth="2" d={'M11 1L1 11.315 10.389 21'}/>
</svg>;

const IconContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export default ({activeProjectId, setChosenType, chosenTab}: IVpn) => {
    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';

    return <TabsHeaderContainer nameTheme={context.theme}>
        <TitlePageContainer>
            <SettingsLinkContainer isProduction={isProduction} onClick={() => goTo(`settings/${activeProjectId}`)}>
                <IconContainer>
                    <ArrowIcon nameTheme={context.theme}/>
                </IconContainer>
                <span>Settings</span>
            </SettingsLinkContainer>
            <Separator/>
            <span>VPN</span>
        </TitlePageContainer>
        <TabsContainer>
            <TabContainer
                isProduction={isProduction}
                nameTheme={context.theme}
                onClick={() => setChosenType('Bypass')}
                isSelected={chosenTab === 'Bypass'}>
                VPN Bypass list
            </TabContainer>
        </TabsContainer>
    </TabsHeaderContainer>
}