import React, { Component } from 'react';

class IOS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 16,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 16,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 Applications ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <g fillRule="evenodd">
            <path d="M.876 7.352C-.768 10.286.277 14.74 2.095 17.44 3.002 18.789 3.92 20 5.179 20l.071-.001c.592-.025 1.019-.212 1.432-.393.463-.203.943-.413 1.695-.413.716 0 1.172.203 1.614.4.435.193.884.394 1.546.381 1.417-.027 2.287-1.335 3.055-2.488a11.17 11.17 0 0 0 1.339-2.82l.005-.018a.166.166 0 0 0-.085-.201l-.011-.005c-.25-.105-2.439-1.104-2.462-3.827-.021-2.212 1.642-3.384 1.976-3.596l.015-.01a.168.168 0 0 0 .045-.23c-1.148-1.729-2.908-1.99-3.616-2.02a3.102 3.102 0 0 0-.316-.017c-.832 0-1.63.324-2.27.583-.442.18-.824.335-1.087.335-.296 0-.68-.157-1.125-.339-.595-.242-1.269-.518-1.982-.518h-.05C3.307 4.83 1.74 5.807.875 7.354z" />
            <path d="M11.8 0c-1.006.042-2.21.678-2.931 1.547-.613.73-1.21 1.946-1.053 3.172.01.077.07.136.145.142.068.005.137.008.206.008.983 0 2.043-.559 2.767-1.46.761-.95 1.147-2.17 1.03-3.263A.162.162 0 0 0 11.8 0z" />
          </g>
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default IOS;
