import styled from 'styled-components';

export const DataRefreshSettings = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

export const MapContainer = styled.div`
  width: 80%;
  padding: 24px 32px 30px 24px;
  position: relative;
`;

export const Map = styled.div`
  margin-top: 40px;
`;

export const StatsContainer = styled.div<{isDefaultTheme: string}>`
  padding: 24px 17px 0 24px;
  border-left: ${(props) =>
    props.isDefaultTheme === 'light' ? '1px solid #B2B9C4' : '1px solid rgba(175, 190, 216, 0.2)'};
  width: 20%;
`;

export const LocationContainer = styled.div<{isDefaultTheme: string}>`
  display: flex;
  width: 100%;
  background: ${(props) => (!props.isDefaultTheme ? '#505763' : '#FFFFFF')};
  color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  border: ${(props) => (props.isDefaultTheme === 'light' ? '1px solid #B2B9C4' : 'unset')};
`;

export const StatsListContainer = styled.div`
  margin-top: 20px;
  height: 700px;
  overflow: scroll;
  padding-right: 15px;
`;

export const LegendContainer = styled.div`
  position: absolute;
  top: 640px;
  left: 90px;
  padding: 10px;
`;

export const LegendItemContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const ColorItem = styled.div`
  background: ${(props) => props.color};
  border-radius: 4px;
  width: 20px;
  height: 20px;
`;

export const TextItem = styled.div<{isDefaultTheme: string}>`
  margin-left: 6px;
  color: ${(props) => (props.isDefaultTheme ? '#878C95' : '#FFFFFF')};
  font-size: 14px;
`;

export const TitleContainer = styled.div<{isDefaultTheme: string}>`
  color: ${(props) => (!props.isDefaultTheme ? '#FFFFFF' : '#000000')};
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
`;

export const SortButton = styled.div<{selected: boolean}>`
  cursor: pointer;
  color: ${(props) => (props.selected ? '#00A2FF' : 'inherit')};
  :hover {
    color: #00a2ff;
  }
  :active {
    color: inherit;
  }
`;

export const MapTitleContainer = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
`;
