import React from "react"
import styled from "styled-components";

const Icon = styled.path<{isSelected: string, isProduction: string, nameTheme: string}>`
  fill: ${props => props.isSelected ? props.isProduction ? '#2ea6f8' : '#ffa823' : props.nameTheme ? '#656D7A' : '#ffffff'}
`;

export default (props: {isSelected: string, isProduction: string, nameTheme: string}) =>
    <svg className={'icons default'} width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Icon isSelected={props.isSelected} isProduction={props.isProduction} nameTheme={props.nameTheme} d="M30.6882 17.2159L15.4562 1.98394C14.6242 1.15194 13.4722 0.73594 12.2882 0.83194L3.1682 1.59994C2.3362 1.66394 1.6642 2.33594 1.6002 3.13594L0.800201 12.2879C0.704201 13.4719 1.1202 14.6239 1.9522 15.4559L17.1842 30.6879C17.8562 31.3599 18.9442 31.3599 19.6162 30.6879L30.6882 19.6159C31.3602 18.9439 31.3602 17.8879 30.6882 17.2159ZM8.5762 11.2959C7.0722 11.2959 5.8562 10.0799 5.8562 8.57594C5.8562 7.07194 7.0722 5.85594 8.5762 5.85594C10.0802 5.85594 11.2962 7.07194 11.2962 8.57594C11.2962 10.0799 10.0802 11.2959 8.5762 11.2959ZM18.1762 23.3919V24.3839C18.1762 24.6079 17.9842 24.7999 17.7602 24.7999H16.6722C16.4482 24.7999 16.2562 24.6079 16.2562 24.3839V23.2959C15.3282 23.0399 14.5602 22.4959 14.0802 22.1119C13.8562 21.9199 13.8242 21.5679 14.0482 21.3759L14.7842 20.6079C14.9762 20.4159 15.2642 20.4159 15.4562 20.5759C15.9362 20.9279 16.6722 21.4079 17.3762 21.4079C18.7202 21.4079 18.7202 20.6079 18.7202 20.3199C18.7202 19.8399 18.5922 19.4559 17.1202 18.9119C15.0082 18.1759 13.9522 16.9919 13.9522 15.4239C13.9522 13.9839 14.8482 12.8959 16.2562 12.4799V11.3919C16.2562 11.1679 16.4482 10.9759 16.6722 10.9759H17.7602C17.9842 10.9759 18.1762 11.1679 18.1762 11.3919V12.3839C18.9442 12.5439 19.6162 12.9279 20.0002 13.1839C20.2562 13.3439 20.2882 13.7279 20.0642 13.9519L19.3282 14.7519C19.1682 14.9439 18.8802 14.9759 18.6882 14.8479C18.3682 14.6239 17.8562 14.3999 17.3762 14.3999C17.0562 14.3999 16.0002 14.4959 16.0002 15.4239C16.0002 15.6799 16.0002 16.3199 17.7922 16.9599C18.6882 17.2799 20.7682 18.0159 20.7682 20.3199C20.8002 21.9519 19.8082 23.1039 18.1762 23.3919Z" fill="#00A2FF"/>
    </svg>