import React, { Component } from 'react';

class Textarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      type: props.type ? props.type : 'text',
      icon: props.icon ? props.icon : undefined,
      theme: props.theme ? props.theme : 'default',
      value: props.value ? props.value : '',
      label: props.label ? props.label : undefined,
      notValid: props.notValid ? props.notValid : false,
      disabled: props.disabled ? props.disabled : false,
      className: props.className ? props.className : false,
      iconPosition: props.iconPosition ? props.iconPosition : 'left',
    };

    this.callbacs = {};
    this.callbacs.onBlur = props.onBlur ? props.onBlur : () => {};
    this.callbacs.onFocus = props.onFocus ? props.onFocus : () => {};
    this.callbacs.onEnter = props.onEnter ? props.onEnter : () => {};
    this.callbacs.onChange = props.onChange ? props.onChange : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      type: props.type ? props.type : 'text',
      icon: props.icon ? props.icon : undefined,
      theme: props.theme ? props.theme : 'default',
      value: props.value ? props.value : '',
      label: props.label ? props.label : undefined,
      notValid: props.notValid ? props.notValid : false,
      disabled: props.disabled ? props.disabled : false,
      className: props.className ? props.className : false,
      iconPosition: props.iconPosition ? props.iconPosition : 'left',
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  onBlur() {
    let state = this.state;
    state.focus = false;

    this.callbacs.onBlur();
    this.setState(state);
  }

  onFocus() {
    let state = this.state;
    state.focus = true;

    this.callbacs.onFocus();
    this.setState(state);
  }

  onChange(event) {
    let state = this.state;
    state.value = event.target.value;

    this.callbacs.onChange(state.value);
    this.setState(state);
  }

  onEnter(event) {
    if (event.key !== 'Enter' || this.state.disabled) {
      return;
    }

    this.callbacs.onEnter();
  }

  setFocus() {
    this.input.focus();
  }

  render() {
    let style = this.props.style ? this.props.style : {};

    return (
      <div
        className={
          'form textarea ' +
          this.state.theme +
          (this.state.icon ? ' withIcon ' + this.state.iconPosition + 'IconPosition' : '') +
          (this.state.notValid ? ' notValid' : '') +
          (this.state.disabled ? ' disabled' : '')
        }
        style={style}
      >
        {this.state.label && this.state.value === '' && (
          <div className={'label'}>{this.state.label}</div>
        )}
        {this.state.icon && this.state.iconPosition === 'left' ? this.state.icon : ''}
        <textarea
          id={this.state.id}
          type={this.state.type}
          value={this.state.value}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus.bind(this)}
          onChange={this.onChange.bind(this)}
          onKeyPress={this.onEnter.bind(this)}
          disabled={this.state.disabled ? 'disabled' : ''}
          ref={(input) => {
            this.input = input;
          }}
        />
        {this.state.icon && this.state.iconPosition === 'right' ? this.state.icon : ''}
      </div>
    );
  }
}

export default Textarea;
