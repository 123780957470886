import React, { Component } from 'react';

class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      row1: props.row1,
      row2: props.row2,
      theme: props.theme ? props.theme : undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      row1: props.row1,
      row2: props.row2,
      theme: props.theme ? props.theme : undefined,
    });
  }

  render() {
    return (
      <div className={'emptyResultContainer' + (this.state.theme ? ' ' + this.state.theme : '')}>
        <div>
          <img alt="" src="/static/assets/icons/empty.png" />
          <div className={'text'}>
            {this.state.row1 && <div>{this.state.row1}</div>}
            {this.state.row2 ? this.state.row2 : ''}
          </div>
        </div>
      </div>
    );
  }
}

export default Empty;
