export default (inObj, pn) => {
  let res = [];
  let ks = [];
  for (let k in inObj) {
    if (ks.indexOf(inObj[k][pn]) === -1) {
      ks.push(inObj[k][pn]);
      res.push(inObj[k]);
    }
  }
  return res;
};
