import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';

window.data.licenses = {};

const getLicenses = () => {
  return new Promise((resolve) => {
    let activeToken = getActiveToken();
    if (window.data.licenses[activeToken]) return resolve(window.data.licenses[activeToken]);

    loadLicenses()
      .then((response) => {
        try {
          window.data.licenses[activeToken] = response.response.licenses;
          return resolve(window.data.licenses[activeToken]);
        } catch (e) {
          return resolve([]);
        }
      })
      .catch((e) => {
        return resolve([]);
      });
  });
};

const loadLicenses = () => {
  let request = window.config.host + 'partner/licenses';
  let params = {
    access_token: getActiveToken(),
  };

  return Network.get(request, params);
};

export { getLicenses, loadLicenses };
