import React, { Component } from 'react';

import { goTo } from '../../common/methods';

import { IconExport } from '../../common/icons';

import { Empty } from '../../common/form';

import Filter from './Filter';
import GeoChart from './GeoChart/index';
import DataRefresh from './DataRefresh';
import GeneralGraphs from './GeneralGraphs';
import { DataRefreshSettings } from './GeoChart/components.tsx';
import graphsMetadata from '../../graphs.config.json';

class index extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const startDate = date.setDate(date.getDate() - 1);

    this.state = {
      tab: props.tab,
      theme: window.storage.get('css_color'),
      local: window.storage.get('currentLocal'),
      activeProject: props.activeProject,
      period: { endDate: new Date(), startDate: startDate },
      interval: '30',
    };

    this.i = 0;
    this.publickey = this.state.activeProject.publickey;

    window.addEventListener('scroll', () => {
      if (document.getElementById('topanchor')) {
        document.getElementById('topanchor').focus();
      }
    });

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (
      dump === this.dump &&
      window.storage.get('css_color') === this.state.theme &&
      this.state.local === window.storage.get('currentLocal')
    )
      return;
    this.dump = dump;

    this.publickey = props.activeProject.publickey;

    let state = this.state;
    state.tab = props.tab;
    state.theme = window.storage.get('css_color');
    state.local = window.storage.get('currentLocal');
    state.activeProject = props.activeProject;
    state.active = 1;

    this.setState(state);
  }

  onDragOver(ev) {
    ev.preventDefault();

    if (ev.target.className.indexOf('hover') === -1)
      ev.target.className = ev.target.className + ' hover';
  }

  changeTab(tab) {
    if (this.state.tab === tab) return;

    goTo('dashboard/' + this.state.activeProject.publickey + '/' + tab);
  }

  applyInterval(interval) {
    this.setState({ interval });
  }

  applyPeriod(startDate, endDate) {
    window.Loading.show();
    this.setState({ period: { startDate, endDate } });
  }

  render() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') === -1) {
        return <Empty row1={window.locales.dashboardSafari} />;
      }
    }

    return (
      <div id={'dashboardContainer'} className={'portalContentContainer'}>
        <h1>{window.locales.dashboard}</h1>
        <div className={'navigation'}>
          <div
            className={this.state.tab === 'general' ? 'active' : ''}
            onClick={this.changeTab.bind(this, 'general')}
          >
            {window.locales.dashboardGeneral}
            <div className={'line'} />
          </div>
          <div
            className={this.state.tab === 'location' ? 'active' : ''}
            onClick={this.changeTab.bind(this, 'location')}
          >
            {window.locales.dashboardLocation}
            <div className={'line'} />
          </div>
        </div>
        {this.state.tab === 'general' && (
          <div className={'header'}>
            <DataRefreshSettings>
              <DataRefresh applyInterval={(period) => this.applyInterval(period)} />
            </DataRefreshSettings>
            <Filter
              params={this.props.params}
              applyPeriod={(startDate, endDate) => this.applyPeriod(startDate, endDate)}
            />
            <div className={'exportData'}>
              <IconExport
                theme={'blue'}
                onClick={this.goToExport.bind(this, 30)}
                label={window.locales.exportData}
              />
            </div>
          </div>
        )}
        <div className={this.state.tab === 'general' ? 'frameContainer' : 'locationFrameContainer'}>
          {this.state.tab === 'general' ? (
            <GeneralGraphs
              graphsMetadata={graphsMetadata.general}
              period={this.state.period}
              interval={this.state.interval}
              publickKey={this.props.activeProject.publickey}
            />
          ) : (
            <GeoChart
              interval={this.state.interval}
              graphsMetadata={graphsMetadata.location}
              activeProject={this.props.activeProject}
            />
          )}
        </div>
      </div>
    );
  }

  goToExport() {
    goTo('export/' + this.state.activeProject.publickey);
  }
}

export default index;
