import React, { Component } from 'react';

class Network extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 dashboard ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M11.06 0A9.544 9.544 0 0 1 20 8.94h-8.94V0zM8.968.897v9.528c0 .33.267.598.598.598h9.537C18.795 16.033 14.654 20 9.566 20 4.276 20 0 15.715 0 10.425 0 5.337 3.96 1.207 8.968.897z"
            opacity=".6"
          />
        </svg>
      </div>
    );
  }
}

export default Network;
