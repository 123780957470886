import React, {useContext, useEffect, useState} from 'react'
import {
    GetInvoicesRequest, GetSubscriptionsRequest
} from "../../../components/PaymentService";
import {IconExport} from "../../../common/icons/index";
import RepayButton from "./RepayButton";
import {
    HeadTable,
    InfoText,
    InvoiceRange,
    InvoiceStatusCell,
    SortCell,
    Symbol,
    Table
} from './components';
import {Empty} from "../../../common/form/index";
import {ThemeContext} from "../../../ThemeContext";

const InvoicesRange = (props: {invoices: any[], showRepayForm: () => void}) => {
    const SymbolCurrency = (props: { currency: string }) => {
        let text;
        switch (props.currency) {
            case 'usd':
                text = '$'
                break;
            case 'euro':
                text = '€'
                break;
            case 'pound':
                text = '£'
                break;
            default:
                text = props.currency
                break;
        }
        return <Symbol>{text}</Symbol>;
    }

    const GetLocalDate = (date: number) =>
        new Date(date*1000).toLocaleString()

    const GetBillingPeriod = (period: { start: number, end: number }) => {
        return period.start === period.end ? GetLocalDate(period.start) :
            `${GetLocalDate(period.start)} - ${GetLocalDate(period.end)}`
    }

    const OpenRepayForm = (e:  any) => {
        props.showRepayForm()
        /* setRepayInvoice(e.currentTarget.dataset.value)*/
    }

    const StatusCell = (props: any) => {
        const invoice = props.invoice
        return invoice.charge ? <InvoiceStatusCell isPaid={invoice.charge.paid}>
            {invoice.charge.status === "failed" ?
                <>
                    {invoice.charge.status}
                    <InfoText>
                        {invoice.charge.failure_message}
                    </InfoText>
                    <div onClick={OpenRepayForm} data-value={invoice.id}>
                        <RepayButton/>
                    </div>
                </> : invoice.status
            }
        </InvoiceStatusCell> : invoice.status
    }

    return <>{
        props.invoices.map((invoice: {
            number: string,
            period_start: number,
            period_end: number,
            currency: string,
            total: number,
            created: number,
            invoice_pdf: string
        }, index) => {
            return <InvoiceRange key={index}>
                <td>
                    {invoice.number}
                </td>
                <td>
                    {GetBillingPeriod({start: invoice.period_start, end: invoice.period_end})}
                </td>
                <td>
                    <SymbolCurrency currency={invoice.currency}/>
                    {invoice.total / 100}
                </td>
                <td>
                    <StatusCell invoice={invoice}/>
                </td>
                <td>
                    {GetLocalDate(invoice.created)}
                </td>
                <td>
                    {
                        invoice.invoice_pdf ?
                            <a href={invoice.invoice_pdf}>
                                <IconExport theme={'blue'}/>
                            </a> : null
                    }
                </td>
            </InvoiceRange>
        })}
    </>
}

const TableInvoices = (props: {projectId: string, invoices: any[], sortBy: (name: string) => void}) => {
    const [showRepayForm, setShowRepayForm] = useState(false);
    const context = useContext(ThemeContext);

    return <>
        <Table nameTheme={context.theme}>
            <HeadTable>
                <tr>
                    <td>
                        {window.locales.invoiceNumber}
                    </td>
                    <td>
                        {window.locales.billingPeriod}
                    </td>
                    <SortCell onClick={() => props.sortBy('total')}>
                        {window.locales.amount}
                    </SortCell>
                    <td>
                        {window.locales.payStatus}
                    </td>
                    <SortCell onClick={() => props.sortBy('created')}>
                        {window.locales.created}
                    </SortCell>
                    <td>
                        {window.locales.pdf}
                    </td>
                </tr>
            </HeadTable>
            <tbody>
            <InvoicesRange showRepayForm={() => setShowRepayForm(!showRepayForm)} invoices={props.invoices}/>
            </tbody>
        </Table>
        {/*{showRepayForm && <RepayForm togglePopup={() => setShowRepayForm(!showRepayForm)} projectId={props.projectId}/>}*/}
    </>
}

const compareBy = (key: any) => {
    return (a: any, b: any) => {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
    }
};

export default (props: {projectId: string}) => {
    const [invoices, setInvoices] = useState<any[]>([]);
    useEffect(() => {
        window.Loading.show()
        GetSubscriptionsRequest(props.projectId).then(async (result) => {
            const invoices = result.length !== 0 ? await GetInvoicesRequest(result[result.length - 1].id) : [];
            setInvoices(invoices);
            window.Loading.hide();
        })
    }, [props.projectId]);

    const sortBy = (key: any) => {
        let arrayCopy = invoices.slice()
        arrayCopy.sort(compareBy(key));
        setInvoices(arrayCopy)
    }

    return (invoices.length !== 0 ? <TableInvoices projectId={props.projectId} sortBy={sortBy} invoices={invoices}/> :
        <Empty row1={window.locales.noInvoices} />)
}