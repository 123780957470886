import React, { Component } from 'react';

import { IconAddUser } from '../../common/icons';

import Modal from '../../common/modal';

import { Input } from '../../common/form';

class Add extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProject: props.activeProject,
      form: {
        username: false,
        password: false,
      },
      isUid: props.isUid,
      message: '',
    };

    this.form = {
      username: '',
      pasword: '',
      given_name: '',
    };

    this.addUser = props.addUser;
    this.loadUsers = props.loadUsers;

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      isUid: props.isUid,
      activeProject: props.activeProject,
    });
  }

  showModal() {
    this.form = {
      username: '',
      password: '',
      given_name: '',
    };

    let state = this.state;
    state.message = '';

    state.form = {
      username: false,
      password: false,
    };

    this.setState(state, () => {
      this.modal.show();
      this.input.setFocus();
    });
  }

  hideModal() {
    this.modal.hide();
  }

  fieldChanged(key, value) {
    this.form[key] = value;
  }

  formSubmit() {
    let state = this.state;
    state.form = {
      username: false,
      password: false,
    };
    state.message = '';
    window.Loading.show();

    this.addUser(this.form).then((result) => {
      if (!result.status) {
        if (result.field) {
          state.form[result.field] = true;
          state.message = result.message;
          return this.setState(state, window.Loading.hide);
        } else {
          state.message = result.message;
          return this.setState(state, window.Loading.hide);
        }
      } else {
        return this.setState(state, () => {
          this.modal.hide();
          window.Loading.hide();
          this.loadUsers();
        });
      }
    });
  }

  render() {
    return (
      <div className={'addUserContainer'}>
        <IconAddUser
          id={'ADD_USER_ADD'}
          label={window.locales.usersAddOne}
          style={{ marginLeft: '7px' }}
          theme="blue"
          onClick={this.showModal.bind(this)}
        />
        <Modal
          title={window.locales.addUser}
          onRef={(ref) => {
            this.modal = ref;
          }}
          visibility={(visible) => {
            if (visible) {
              this.input.setFocus();
            }
          }}
          message={this.state.message}
          cancel={{
            id: 'ADD_USER_CANCEL',
            label: window.locales.cancel,
            click: this.hideModal.bind(this),
          }}
          submit={{
            id: 'ADD_USER_SUBMIT',
            label: window.locales.addUser,
            click: this.formSubmit.bind(this),
          }}
          closeId={'ADD_USER_CLOSE'}
        >
          <Input
            id={'ADD_USER_EMAIL'}
            label={this.state.isUid ? window.locales.uid : window.locales.email}
            onRef={(ref) => {
              this.input = ref;
            }}
            notValid={this.state.form.username}
            value={this.form.username}
            disabled={this.state.disabled}
            suffix={this.state.isUid ? '.' + this.state.activeProject.publickey : undefined}
            onChange={this.fieldChanged.bind(this, 'username')}
            onEnter={this.formSubmit.bind(this)}
          />
          <Input
            id={'ADD_USER_PASSWORD'}
            type={'password'}
            label={this.state.isUid ? window.locales.password : window.locales.passwordOptional}
            notValid={this.state.form.password}
            value={this.form.password}
            disabled={this.state.disabled}
            onChange={this.fieldChanged.bind(this, 'password')}
            onEnter={this.formSubmit.bind(this)}
          />
          <Input
            id={'ADD_USER_DESCRIPTION'}
            label={window.locales.description}
            value={this.form.given_name}
            disabled={this.state.disabled}
            onChange={this.fieldChanged.bind(this, 'given_name')}
            onEnter={this.formSubmit.bind(this)}
          />
        </Modal>
      </div>
    );
  }
}

export default Add;
