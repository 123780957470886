import { goTo, parseUrl } from '../../common/methods';

export default (name, value) => {
  let path = parseUrl();

  let change = (name, value) => {
    if (typeof value !== 'undefined') {
      path.params[name] = encodeURIComponent(value);
    } else if (path.params[name]) {
      delete path.params[name];
    }
  };

  if (typeof name === 'object') {
    for (let k in name) {
      change(name[k][0], name[k][1]);
    }
  } else {
    change(name, value);
  }

  let temp = [];
  for (let k in path.params) {
    temp.push(k + '=' + path.params[k]);
  }

  goTo(path.path.join('/') + (temp.length > 0 ? '?' + temp.join('&') : ''));
};
