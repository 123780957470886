import React, { Component } from 'react';
import ReactDragListView from 'react-drag-listview';

var ind = 0;

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: props.head ? props.head : [],
      body: props.body ? props.body : [],
      theme: props.theme ? props.theme : 'default',
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      head: props.head ? props.head : [],
      body: props.body ? props.body : [],
      theme: props.theme ? props.theme : 'default',
    });
  }

  onClick(callback, e) {
    callback(e);
  }

  render() {
    let head;
    let body;
    if (this.state.head && this.state.head.length > 0) {
      head = [];
      for (let k in this.state.head) {
        let el = this.state.head[k];
        let style = {};
        let callback = el[2] && typeof el[2] === 'function' ? el[2] : () => {};
        if (el[1]) {
          if (el[1].width) style.width = el[1].width;
          if (el[1].height) style.height = el[1].height;
          if (el[1].wordBreak) style.wordBreak = el[1].wordBreak;
        }

        head.push(
          <td key={++ind} style={style} onClick={callback}>
            <div
              className={
                (!el[1] || !el[1].notFlex ? 'flexTd' : '') +
                (el[1] && el[1].center ? ' center' : '')
              }
            >
              {el[0]}
            </div>
          </td>,
        );
      }
    }

    if (this.state.body && this.state.body.length > 0) {
      body = [];

      for (let k in this.state.body) {
        let row = this.state.body[k].row ? this.state.body[k].row : this.state.body[k];
        let temp = [];
        let callback = this.state.body[k].click ? this.state.body[k].click : () => {};
        let className = this.state.body[k].className ? this.state.body[k].className : '';

        for (let j in row) {
          let style = {};
          let className = '';
          if (row[j][1]) {
            if (row[j][1].width) style.width = row[j][1].width;
            if (row[j][1].height) style.height = row[j][1].height;
            if (row[j][1].wordBreak) style.wordBreak = row[j][1].wordBreak;
            if (row[j][1].noClick) className = 'tdNoClick';
          }

          if (this.props.dragAndDrop) className += ' tdDragAndDrop';

          if (row[j][1] && row[j][1].noClick) {
            temp.push(
              <td key={++ind} className={className} style={style}>
                <div
                  className={
                    (!row[j][1] || !row[j][1].notFlex ? 'flexTd' : '') +
                    (row[j][1] && row[j][1].center ? ' center' : '')
                  }
                >
                  {row[j][0]}
                </div>
              </td>,
            );
          } else {
            temp.push(
              <td
                key={++ind}
                className={className}
                style={style}
                onClick={this.onClick.bind(this, callback)}
              >
                <div
                  className={
                    (!row[j][1] || !row[j][1].notFlex ? 'flexTd' : '') +
                    (row[j][1] && row[j][1].center ? ' center' : '')
                  }
                >
                  {row[j][0]}
                </div>
              </td>,
            );
          }
        }

        //body.push(<tr className={className} key={++ind}>{temp}</tr>)
        body.push(
          <tr className={className} key={k}>
            {temp}
          </tr>,
        );
      }
    }

    if (this.props.dragAndDrop) {
      const that = this;
      const dragProps = {
        onDragEnd(fromIndex, toIndex) {
          const data = that.state.body;
          const item = data.splice(fromIndex, 1)[0];
          data.splice(toIndex, 0, item);
          that.setState({ body: data }, () => {
            if (that.props.onDragEnd) that.props.onDragEnd(fromIndex, toIndex);
          });
        },
        nodeSelector: 'tr',
        handleSelector: 'tr',
      };

      return (
        <ReactDragListView {...dragProps}>
          <table className={'table ' + this.state.theme}>
            {head && (
              <thead>
                <tr>{head}</tr>
              </thead>
            )}
            {body && <tbody>{body}</tbody>}
          </table>
        </ReactDragListView>
      );
    } else {
      return (
        <table className={'table ' + this.state.theme}>
          {head && (
            <thead>
              <tr>{head}</tr>
            </thead>
          )}
          {body && <tbody>{body}</tbody>}
        </table>
      );
    }
  }
}

export default index;
