import React, { Component } from 'react';

import { loadEvents } from '../../components/Event';

import Table from '../../common/table';

import { goTo, jsonBeautifier, getDateString, getCountryName } from '../../common/methods';

import { Empty } from '../../common/form';

import { IconPlus, IconDownload } from '../../common/icons';

import { loadUsers } from '../../components/Access';

import Filter from './Filter';

class Event extends Component {
  constructor(props) {
    super(props);

    let active = 1;
    if (window.tempStorage.eventActive) {
      active = window.tempStorage.eventActive;
      delete window.tempStorage.eventActive;
    }

    this.state = {
      events: [],
      activeProject: props.activeProject,
      cursor: '0',
      active: active,
    };

    this.dump = JSON.stringify(props.dump);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    let active = 1;
    if (window.tempStorage.eventActive) {
      active = window.tempStorage.eventActive;
      delete window.tempStorage.eventActive;
    }

    this.setState(
      {
        events: [],
        activeProject: props.activeProject,
        cursor: '0',
        active: active,
      },
      () => {
        this.componentDidMount();
      },
    );
  }

  componentDidMount() {
    let state = this.state;
    window.Loading.show();
    loadEvents(this.props.params, this.state.cursor)
      .then((response) => {
        state.events = response.events;
        state.cursor = response.cursor;
        loadUsers().then((admins) => {
          state.admins = admins;
          this.setState(state, () => {
            window.Loading.hide();
          });
        });
      })
      .catch(() => {
        window.Loading.hide();
      });
  }

  loadMore() {
    let state = this.state;
    window.Loading.show();
    loadEvents(this.props.params, this.state.cursor)
      .then((response) => {
        state.events = state.events.concat(response.events);
        state.cursor = response.cursor ? response.cursor : '0';

        this.setState(state, () => {
          window.Loading.hide();
        });
      })
      .catch(() => {
        window.Loading.hide();
      });
  }

  openUserPage(json) {
    if (typeof json !== 'object') return;

    let uid;
    for (let k in json) {
      if (json[k].property_name === 'id' || json[k].property_name === 'userName') {
        uid = json[k].property_value;
        break;
      }
    }

    if (!uid) return;
    window.tempStorage.event = true;
    window.tempStorage.eventActive = this.state.active;
    goTo('user/' + this.state.activeProject.publickey + '/' + uid);
  }

  render() {
    const state = this.state;

    let head = [
      [window.locales.eventDate, { width: 150 }],
      [window.locales.eventAuthor, { width: 250 }],
      [window.locales.eventType, { width: 250 }],
      [window.locales.eventChanges],
    ];

    let body = this.createBody(state);
    let content =
      body.length > 0 ? <Table head={head} body={body} /> : <Empty row1={'No events'} />;

    return (
      <div className={'portalContentContainer EventLog'}>
        <div className="head">
          <h1>{window.locales.log}</h1>
          <div className={'EventLogHeader'}>
            <Filter admins={this.state.admins} params={this.props.params} />
            {body.length > 0 && (
              <IconDownload
                theme={'blue'}
                label={window.locales.download}
                onClick={this.downloadFile.bind(this)}
              />
            )}
          </div>
        </div>
        {content}
        {state.cursor !== '0' && body.length > 0 && (
          <div className={'loadMore'}>
            <IconPlus
              onClick={this.loadMore.bind(this)}
              label={window.locales.loadMore}
              theme={'blue'}
            />
          </div>
        )}
      </div>
    );
  }

  createBody(state) {
    let body = [];
    for (let k in state.events) {
      let event = state.events[k];
      if (['PARTNER_LOGIN', 'PARTNER_LOGOUT', 'CREATE_CARRIER_ADMIN'].indexOf(event.type) !== -1)
        continue;

      let changes = JSON.stringify(event.event_changes);
      let authorJson = event.event_author ? event.event_author : {};
      let changesJson = event.event_changes ? event.event_changes : {};
      let c = jsonBeautifier(changes);
      if (c) {
        c = (
          <div className={'absoluteDiv'}>
            <div className={'row'}>{c}</div>
          </div>
        );
      }

      let date = getDateString(event.time);
      if (date) {
        date = (
          <div>
            {date.date}
            <div className={'lightGray'}>{date.time}</div>
          </div>
        );
      } else {
        date = '';
      }

      let author;
      if (authorJson.firebaseEmail) author = authorJson.firebaseEmail;
      else if (authorJson.firebase_email) author = authorJson.firebase_email;
      else if (authorJson.carrierAdmin) author = authorJson.carrierAdmin;
      else if (authorJson.carrier_admin) author = authorJson.carrier_admin;
      else authorJson = event.author;

      try {
        switch (event.type) {
          case 'ADD_FILE':
            for (let k in changesJson) {
              if (changesJson[k].property_name === 'filename') {
                if (changesJson[k].property_value === 'bpl')
                  changes = window.locales.events.ADD_FILE_BPL;
                else if (changesJson[k].property_value === 'cnl')
                  changes = window.locales.events.ADD_FILE_CNL;
                else changes = window.locales.events.ADD_FILE_CHANGES;
                break;
              }
            }
            c = undefined;
            break;
          case 'UPDATE_CARRIER':
            changes = window.locales.settings;
            break;
          case 'CREATE_FIREBASE_ADMIN':
          case 'UPDATE_CARRIER_FIREBASE_ADMIN_ROLE':
            let email, role;
            for (let k in changesJson) {
              if (changesJson[k].property_name === 'email') {
                email = changesJson[k].property_value;
              } else if (changesJson[k].property_name === 'roleName') {
                role = window.locales.roles[changesJson[k].property_value]
                  ? window.locales.roles[changesJson[k].property_value].name
                  : changesJson[k].property_value;
              }
            }
            changes = email + ': ' + role;
            break;
          case 'CREATE_CARRIER_TEST_PURCHASE_SETTINGS':
          case 'UPDATE_CARRIER_TEST_PURCHASE_SETTINGS':
            try {
              for (let k in changesJson) {
                if (changesJson[k].property_name === 'paymentSettings') {
                  let j = JSON.parse(changesJson[k].property_value);
                  changes = Object.keys(j)[0];
                }
              }
            } catch (e) {}
            break;
          case 'DELETE_CARRIER_PURCHASE_SETTINGS':
            changes = changesJson[0].property_value;
            break;
          case 'DELETE_CARRIER_FIREBASE_ADMIN':
            for (let k in changesJson) {
              if (changesJson[k].property_name === 'email') {
                changes = changesJson[k].property_value;
              }
            }
            c = undefined;
            break;
          case 'CREATE_AUTH_METHOD':
          case 'UPDATE_CARRIER_AUTH_METHOD':
          case 'DELETE_CARRIER_AUTH_METHOD':
            for (let k in changesJson) {
              if (
                changesJson[k].property_name === 'authMethod' ||
                changesJson[k].property_name === 'authMehtodName'
              ) {
                changes = changesJson[k].property_value;
              }
            }
            break;
          case 'CREATE_CARRIER':
            for (let k in changesJson) {
              if (changesJson[k].property_name === 'identity') {
                changes = changesJson[k].property_value;
              }
            }
            break;
          case 'UPDATE_CARRIER_ICON':
            changes = window.locales.events.UPDATE_CARRIER_ICON_CHANGES;
            c = (
              <div className={'absoluteDiv'}>
                <div className={'row'}>
                  <img alt="" src={changesJson[0].property_value} style={{ height: '100px' }} />
                </div>
              </div>
            );
            break;
          case 'CREATE_SUBSCRIBER':
          case 'DISABLE_USER':
          case 'UPDATE_SUBSCRIBER':
          case 'SET_TRAFFIC_LIMIT':
          case 'DELETE_TRAFFIC_LIMIT':
            changes = ( // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={this.openUserPage.bind(this, changesJson)}
                style={{ textDecoration: 'underline' }}
              >
                {window.locales.events.USER}
              </a>
            );
            break;
          case 'DELETE_USER_DATA':
            changes = window.locales.events.USER;
            break;
          case 'DELETE_DEVICE':
            changes = window.locales.events.DEVICE;
            break;
          case 'CREATE_CARRIER_COUNTRY':
          case 'DELETE_CARRIER_COUNTRY':
            changes = getCountryName(changesJson[0].property_value);
            break;
          case 'PARTNER_LOGIN':
          case 'PARTNER_LOGOUT':
          case 'CREATE_CARRIER_ADMIN':
            changes = '';
            c = undefined;
            break;
          case 'GENERATE_REPORT':
            let m = {
              BY_CARRIER: window.locales.BY_CARRIER,
              BY_ALL_USERS: window.locales.BY_ALL_USERS,
              BY_DEVICE_ID: window.locales.BY_ALL_DEVICES,
              BY_ALL_DEVICES: window.locales.BY_ALL_DEVICES,
              BY_USER: window.locales.BY_USER,
              BY_USER_ID: window.locales.BY_USER_ID,
            };
            try {
              for (let k in changesJson) {
                if (changesJson[k].property_name !== 'type') continue;

                changes = m[changesJson[k].property_value];
              }
            } catch (e) {
              window.log('Error');
              window.log(e);
            }
            break;
          case 'MASS_USER_UPDATE':
            changes = window.locales.users;
            try {
              changesJson = JSON.parse(event.changes);
            } catch (e) {}
            break;
          default:
            break;
        }
      } catch (e) {}

      let map = {
        CREATE_FIREBASE_ADMIN: window.locales.events.CREATE_FIREBASE_ADMIN,
        UPDATE_CARRIER_FIREBASE_ADMIN_ROLE:
          window.locales.events.UPDATE_CARRIER_FIREBASE_ADMIN_ROLE,
        DELETE_CARRIER_FIREBASE_ADMIN: window.locales.events.DELETE_CARRIER_FIREBASE_ADMIN,
        CREATE_CARRIER_TEST_PURCHASE_SETTINGS:
          window.locales.events.CREATE_CARRIER_TEST_PURCHASE_SETTINGS,
        UPDATE_CARRIER_TEST_PURCHASE_SETTINGS:
          window.locales.events.UPDATE_CARRIER_TEST_PURCHASE_SETTINGS,
        DELETE_CARRIER_PURCHASE_SETTINGS: window.locales.events.DELETE_CARRIER_PURCHASE_SETTINGS,
        CREATE_AUTH_METHOD: window.locales.events.CREATE_AUTH_METHOD,
        DELETE_CARRIER_AUTH_METHOD: window.locales.events.DELETE_CARRIER_AUTH_METHOD,
        UPDATE_CARRIER_AUTH_METHOD: window.locales.events.UPDATE_CARRIER_AUTH_METHOD,
        UPDATE_CARRIER: window.locales.events.UPDATE_CARRIER,
        UPDATE_CARRIER_ICON: window.locales.events.UPDATE_CARRIER_ICON,
        ADD_FILE: window.locales.events.ADD_FILE,
        CREATE_SUBSCRIBER: window.locales.events.CREATE_SUBSCRIBER,
        UPDATE_SUBSCRIBER: window.locales.events.UPDATE_SUBSCRIBER,
        MASS_USER_UPDATE: window.locales.events.MASS_USER_UPDATE,
        DELETE_USER_DATA: window.locales.events.DELETE_USER_DATA,
        SET_TRAFFIC_LIMIT: window.locales.events.SET_TRAFFIC_LIMIT,
        DELETE_TRAFFIC_LIMIT: window.locales.events.DELETE_TRAFFIC_LIMIT,
        DELETE_DEVICE: window.locales.events.DELETE_DEVICE,
        CREATE_CARRIER_COUNTRY: window.locales.events.CREATE_CARRIER_COUNTRY,
        DELETE_CARRIER_COUNTRY: window.locales.events.DELETE_CARRIER_COUNTRY,
        DISABLE_USER: window.locales.events.DISABLE_USER,
        PARTNER_LOGIN: window.locales.events.PARTNER_LOGIN,
        PARTNER_LOGOUT: window.locales.events.PARTNER_LOGOUT,
        GENERATE_REPORT: window.locales.events.GENERATE_REPORT,
        CREATE_CARRIER: window.locales.events.CREATE_CARRIER,
      };

      if (changes && changes.length && changes.length > 60) {
        changes = changes.substr(0, 60) + '...';
      }

      body.push([
        [date],
        [<div>{author}</div>],
        [map[event.type] ? map[event.type] : event.type],
        [
          <div>
            {changes}
            {c}
          </div>,
        ],
      ]);
    }

    return body;
  }

  downloadFile() {
    let element = document.createElement('a');
    let log = [];
    for (let k in this.state.events) {
      let event = this.state.events[k];
      let date = getDateString(event.time);
      if (date) {
        date = date.date + ' ' + date.time;
      } else {
        date = '';
      }
      log.push(
        date +
          '   ' +
          event.api +
          '   ' +
          event.author +
          '   ' +
          event.changes +
          '   ' +
          event.type,
      );
    }

    let from = new Date(
      this.props.params.time_start
        ? this.props.params.time_start
        : Date.now() - 24 * 60 * 60 * 1000,
    );
    let till = new Date(this.props.params.time_end ? this.props.params.time_end : Date.now());

    from =
      from.getFullYear().toString() +
      (from.getMonth() + 1) +
      from.getDate() +
      from.getHours() +
      from.getMinutes() +
      from.getSeconds();
    till =
      till.getFullYear().toString() +
      (till.getMonth() + 1) +
      till.getDate() +
      till.getHours() +
      till.getMinutes() +
      till.getSeconds();

    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(log.join('\n')),
    );
    element.setAttribute(
      'download',
      'EVENTS-LOG_' +
        this.state.activeProject.publickey +
        '_FROM_' +
        from +
        '_TILL_' +
        till +
        '.txt',
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}

export default Event;
