import React, { Component } from 'react';

import { IconSearch, IconClose, IconFilter } from '../../common/icons';

import { Input, Select } from '../../common/form';

import { changeParams, setParams } from '../../common/methods';

import Calendar from './Calendar';

class PrivateSearch extends Component {
  constructor(props) {
    super(props);

    this.privateSearchId = 'privateSearch' + ++window.formId;
    this.state = {
      i: 0,
      showFilters: false,
      notValid: false,
    };

    this.form = {
      search: this.props.params.search ? this.props.params.search : '',
      devices: this.props.params.devices ? this.props.params.devices : '0',
      devicesSign: this.props.params.devices_sign ? this.props.params.devices_sign : 'any',
      status: this.props.params.status ? this.props.params.status : 'all',
      registration_time_from: this.props.params.registration_time_from
        ? this.props.params.registration_time_from
        : undefined,
      registration_time_till: this.props.params.registration_time_till
        ? this.props.params.registration_time_till
        : undefined,
    };

    this._escFunction = this.escFunction.bind(this);
    this._click = this.click.bind(this);

    document.addEventListener('keydown', this._escFunction);
    document.addEventListener('click', this._click);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._escFunction);
    document.removeEventListener('click', this._click);
  }

  click(e) {
    if (!this.state.showFilters) return;
    let is = (el1, el2) => {
      if (!el1) return false;
      if (el1 === el2) return true;
      if (el1.id === 'loadingContainer') return true;
      return is(el1.parentNode, el2);
    };

    let el;

    el = document.getElementById(this.privateSearchId);
    if (!is(e.target, el)) {
      return this.setState({ showFilters: false });
    }
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.showFilters) {
      this.setState({ showFilters: false });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {
        notValid: false,
      },
      () => {
        this.form = {
          search: this.props.params.search ? this.props.params.search : '',
          devices: this.props.params.devices ? this.props.params.devices : '0',
          devicesSign: this.props.params.devices_sign ? this.props.params.devices_sign : 'any',
          status: this.props.params.status ? this.props.params.status : 'all',
          registration_time_from: this.props.params.registration_time_from
            ? this.props.params.registration_time_from
            : undefined,
          registration_time_till: this.props.params.registration_time_till
            ? this.props.params.registration_time_till
            : undefined,
        };
      },
    );
  }

  onChange(key, value) {
    this.form[key] = value;
    this.setState({ i: this.state.i + 1 });
  }

  render() {
    return (
      <div className={'userSearch show'} id={this.privateSearchId}>
        <div className={'searchContainer' + (this.state.notValid ? ' notValid' : '')}>
          <IconSearch theme="default flex" onClick={this.search.bind(this)} />
          <Input
            label={window.locales.searchUsers}
            value={this.form.search}
            onChange={this.onChange.bind(this, 'search')}
            onEnter={this.search.bind(this)}
          />
          <div
            className={'privateFiltersContainer ' + (this.state.showFilters ? 'show' : '')}
            onClick={this.changeFilterVisibility.bind(this)}
          >
            <IconFilter theme={'blue'} />
          </div>
          <IconClose onClick={this.closeSearch.bind(this)} />
          <div className={'errorMessage'}>{window.locales.fullTextSearchMsg}</div>
        </div>
        {this.renderPrivateFilter()}
      </div>
    );
  }

  changeFilterVisibility() {
    this.setState({ showFilters: !this.state.showFilters });
  }

  search() {
    let params = {};
    try {
      params = JSON.parse(JSON.stringify(this.props.params));
    } catch (e) {}

    let search = this.form.search.trim();
    if (!search) delete params.search;
    else params.search = search;

    if (search.length > 0 && search.length < 3 && !Number(search)) {
      return this.setState({ notValid: true });
    }

    let status = this.form.status;
    if (status === 'all') delete params.status;
    else params.status = status;

    let devicesSign = this.form.devicesSign;
    if (devicesSign === 'any') {
      delete params.devices;
      delete params.devices_sign;
    } else {
      params.devices =
        typeof this.form.devices === 'undefined'
          ? 0
          : this.form.devices.match(/^[0-9]+$/)
          ? Number(this.form.devices)
          : '0';
      params.devices_sign = devicesSign;
    }

    let registration_time_from = this.form.registration_time_from;
    if (!registration_time_from) delete params.registration_time_from;
    else params.registration_time_from = registration_time_from;

    let registration_time_till = this.form.registration_time_till;
    if (!registration_time_till) delete params.registration_time_till;
    else params.registration_time_till = registration_time_till;

    this.setState({ notValid: false }, () => {
      setParams(params);
    });
  }

  closeSearch() {
    this.form.search = '';
    this.setState({ show: false, showFilters: false, notValid: false }, () => {
      changeParams('search');
    });
  }

  renderPrivateFilter() {
    if (!this.state.showFilters) return '';

    let disabled = !!(this.form.status === 'invited' || this.form.status === 'invited');

    return (
      <div className={'FilterContainer'}>
        <div className={'row'}>
          <div className={'name'}>{window.locales.condition}</div>
          <Select
            theme={'dark'}
            value={this.form.status}
            options={[
              ['all', window.locales.usersAll],
              ['active', window.locales.usersActive],
              ['disabled', window.locales.usersDisabled],
              ['invited', window.locales.usersInvited],
            ]}
            onChange={this.onChange.bind(this, 'status')}
          />
        </div>
        <div className={'row'}>
          <div className={'name'}>{window.locales.devicesNumber}</div>
          <Select
            theme={'dark'}
            value={this.form.devicesSign}
            options={[
              ['any', window.locales.devicesAny],
              ['more', window.locales.devicesMore],
              ['less', window.locales.devicesLess],
              ['exact', window.locales.devicesExact],
            ]}
            disabled={disabled}
            onChange={this.onChange.bind(this, 'devicesSign')}
          />
          {this.form.devicesSign !== 'any' && (
            <Input
              theme={'dark'}
              value={this.form.devices}
              label={window.locales.devicesNumber}
              disabled={disabled}
              onChange={this.onChange.bind(this, 'devices')}
            />
          )}
        </div>
        <div className={'row'}>
          <div className={'name'} style={{ marginRight: '20px' }}>
            {window.locales.registrationDate}
          </div>
          {window.locales.from}:
          <div className={'rootCalendarContainer'}>
            <Calendar
              time={this.form.registration_time_from}
              onChange={this.onChange.bind(this, 'registration_time_from')}
            />
          </div>
          {window.locales.till}:
          <div className={'rootCalendarContainer'}>
            <Calendar
              time={this.form.registration_time_till}
              onChange={this.onChange.bind(this, 'registration_time_till')}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'findBtn'} onClick={this.search.bind(this)}>
            {window.locales.find}
          </div>
          <div className={'resetBtn'} onClick={this.resetSearch.bind(this)}>
            {window.locales.reset}
          </div>
        </div>
      </div>
    );
  }

  resetSearch() {
    let params = this.props.params;
    delete params.search;
    delete params.devices;
    delete params.devices_sign;
    delete params.status;
    delete params.registration_time_from;
    delete params.registration_time_till;

    this.form = {
      search: '',
      devices: '0',
      devicesSign: 'any',
      status: 'all',
      registration_time_from: undefined,
      registration_time_till: undefined,
    };

    this.setState({ i: this.state.i + 1, notValid: false }, () => {
      setParams(params);
    });
  }
}

export default PrivateSearch;
