import React, { Component } from 'react';

import { IconPlus } from '../../common/icons';

import Modal from '../../common/modal';

import { exportData } from '../../components/Grafana';

import Calendar from './Calendar';

class Export extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: props.inProgress,
      activeProject: props.activeProject,
      message: '',
      disabled: true,
    };

    this.form = {
      days: {
        from: undefined,
        till: undefined,
      },
      time: {
        from: '00:00',
        till: '23:59',
      },
    };

    this.exported = props.exported;

    this._escFunction = this.escFunction.bind(this);
    this._click = this.click.bind(this);

    document.addEventListener('keydown', this._escFunction);
    document.addEventListener('click', this._click);

    this.modals = {};

    this.dump = JSON.stringify(props);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._escFunction);
    document.removeEventListener('click', this._click);
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.showPopUp) {
      this.setState({ showPopUp: false });
    }
  }

  click(e) {
    if (!this.state.showPopUp) return;

    let is = (el1, el2) => {
      if (!el1) return false;
      if (el1 === el2) return true;
      if (el1.id === 'loadingContainer') return true;
      return is(el1.parentNode, el2);
    };

    let el = document.getElementById('dashboardExport');
    if (!is(e.target, el)) {
      return this.setState({ showPopUp: false });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      inProgress: props.inProgress,
      activeProject: props.activeProject,
    });
  }

  showModal(type) {
    this.form = {
      days: {
        from: undefined,
        till: undefined,
      },
      time: {
        from: '00:00',
        till: '23:59',
      },
    };

    let state = this.state;
    state.message = '';
    state.disabled = true;

    this.setState(state, () => {
      this.modals[type].show();
    });
  }

  formSubmit(type) {
    let from = this.form.days.from
      ? new Date(this.form.days.from + ' ' + this.form.time.from.replace(/_/g, '0')).getTime()
      : false;
    let till = this.form.days.till
      ? new Date(this.form.days.till + ' ' + this.form.time.till.replace(/_/g, '0')).getTime()
      : false;

    //if (from && this.form.days.from === this.form.days.till) from -= 2 * 60 * 1000;

    let mask = {
      users: 'users',
      devices: 'devices',
      sessions: 'sessionsbycarrier',
    };

    window.Loading.show();
    exportData(from, till, mask[type])
      .then((result) => {
        window.Loading.hide();
        this.modals[type].hide();
        this.exported();
      })
      .catch((e) => {
        window.Loading.hide();
        this.modals[type].hide();
      });
  }

  hideModal(type) {
    this.modals[type].hide();
  }

  updateSearch(data) {
    this.form.days = {
      from: data.days.from,
      till: data.days.till,
    };

    this.form.time = {
      from: data.time.from,
      till: data.time.till,
    };

    if (this.state.disabled) {
      return this.setState({ disabled: false });
    }
  }

  render() {
    let noPopup = false;
    if (
      this.state.inProgress.BY_ALL_USERS &&
      this.state.inProgress.BY_DEVICE_ID &&
      this.state.inProgress.BY_CARRIER
    )
      noPopup = true;

    return (
      <div id={'dashboardExport'}>
        <div
          className={'export'}
          onClick={() => {
            this.setState({ showPopUp: !this.state.showPopUp });
          }}
        >
          {!noPopup &&
            (!this.state.inProgress.BY_ALL_USERS ||
              !this.state.inProgress.BY_ALL_DEVICES ||
              !this.state.inProgress.BY_CARRIER) &&
            this.state.activeProject.role !== 'SUPPORT' && (
              <IconPlus
                label={window.locales.exportAdd}
                theme="blue flex"
                onClick={() => {
                  this.setState({ showPopUp: !this.state.showPopUp });
                }}
              />
            )}
        </div>
        {!noPopup && (
          <div className={'DashboardExportPopUp' + (this.state.showPopUp ? ' show' : '')}>
            {!this.state.inProgress.BY_ALL_USERS && (
              <div className={'row'}>
                <span onClick={this.showModal.bind(this, 'users')}>
                  {window.locales.exportUsers}
                </span>
              </div>
            )}
            {!this.state.inProgress.BY_DEVICE_ID && !this.state.inProgress.BY_ALL_DEVICES && (
              <div className={'row'}>
                <span onClick={this.showModal.bind(this, 'devices')}>
                  {window.locales.exportDevices}
                </span>
              </div>
            )}
            {!this.state.inProgress.BY_CARRIER && (
              <div className={'row'}>
                <span onClick={this.showModal.bind(this, 'sessions')}>
                  {window.locales.exportSessions}
                </span>
              </div>
            )}
          </div>
        )}
        <Modal
          title={window.locales.exportUsersData}
          onRef={(ref) => {
            this.modals.users = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this, 'users'),
          }}
          submit={{
            label: window.locales.exportDataSubmit,
            click: this.formSubmit.bind(this, 'users'),
          }}
        >
          {window.locales.exportUsersDataText}
        </Modal>
        <Modal
          title={window.locales.exportDevicesData}
          onRef={(ref) => {
            this.modals.devices = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this, 'devices'),
          }}
          submit={{
            label: window.locales.exportDataSubmit,
            click: this.formSubmit.bind(this, 'devices'),
          }}
        >
          {window.locales.exportDevicesDataText}
        </Modal>
        <Modal
          title={window.locales.exportSessionsData}
          onRef={(ref) => {
            this.modals.sessions = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this, 'sessions'),
          }}
          submit={{
            label: window.locales.exportDataSubmit,
            click: this.formSubmit.bind(this, 'sessions'),
          }}
          disabled={this.state.disabled}
        >
          <div id={'calendar_dropdown'} className={'field'}>
            <Calendar
              days={{
                from: this.form.days.from,
                till: this.form.days.till,
              }}
              time={{
                from: this.form.time.from,
                till: this.form.time.till,
              }}
              updateSearch={this.updateSearch.bind(this)}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Export;
