import React, {Component} from 'react';

import {
    Input,
    Select,
    Textarea
} from '../../../common/form';

import {
    editProject,
    deleteProject
} from '../../../components/Project';

import {
    goTo,
    jsonBeautifier
} from "../../../common/methods";

import Modal from '../../../common/modal';

class index extends Component {
    constructor(props) {
        super(props);

        let disabled = false;
        if (props.permissions.indexOf('portal_project_update') === -1 ||
            props.activeProject.current_user_role.name !== 'OWNER') disabled = true;

        this.state = {
            activeProject: props.activeProject,
            permissions: props.permissions,
            disabled: disabled,
            form: this.setFormNotValid(),
            iconError: undefined
        };

        this.form = this.fillFormFields(props.activeProject);

        this.modal = {};
        this.dump = JSON.stringify(props.dump);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.form = this.fillFormFields(props.activeProject);

        let disabled = false;
        if (props.permissions.indexOf('portal_project_update') === -1 ||
            props.activeProject.current_user_role.name !== 'OWNER') disabled = true;

        this.setState({
            activeProject: props.activeProject,
            permissions: props.permissions,
            disabled: disabled,
            form: this.setFormNotValid(),
            iconError: undefined
        });
    }

    fieldChanged(key, value) {
        this.form[key] = value;

        if (key === 'authentifications') {
            this.setState({i: this.state.i + 1});
        }
    }

    fillFormFields(project) {
        let config = {},
            default_traffic_limit = '',
            default_license_id = '',
            disable_optimal_location = 'false';

        try {
            config = JSON.parse(project.config);
            if (config.default_traffic_limit) {
                default_traffic_limit = Math.round(Number(config.default_traffic_limit) / 1024 / 1024);
            }

            if (config.default_license_id) {
                default_license_id = Number(config.default_license_id);
            }

            disable_optimal_location = config.disable_optimal_location ? "true" : "false";

            config = JSON.stringify(config);
        } catch (e) {
            config = '';
        }


        return {
            icon: undefined,
            config: config,
            deleteKey: '',
            privatekey: project.privatekey,
            description: project.description,
            carrier_name: project.carrier_name ? project.carrier_name : '',
            default_traffic_limit: default_traffic_limit,
            default_license_id: default_license_id,
            disable_optimal_location: disable_optimal_location
        };
    }

    setFormNotValid() {
        return {
            privatekey: false,
            config: false,
            carrier_name: false,
            icon: false,
            description: false,
            deleteKey: false,
            default_traffic_limit: false,
        };
    }

    handleFileUpload(event) {
        let icon = event.target.files[0];
        this.form.icon = undefined;
        if (!icon.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
            return this.setState({iconError: 'Please use jpg, jpeg, png or gif'});
        } else if (icon.size > 5000000) {
            return this.setState({iconError: 'Maximum file size is 5Mb'});
        }

        this.form.icon = icon;
        this.setState({i: this.state.i + 1, iconError: undefined});
    }

    submitForm() {
        if (this.state.disabled) return;
        let state = this.state;
        state.form = this.setFormNotValid();

        let data = {};
        let changed = false;

        if (this.form.privatekey !== this.state.activeProject.privatekey) {
            if (this.form.privatekey.trim() === '' || this.form.privatekey.length < 6) {
                state.form.privatekey = true;
                return this.setState(state);
            }

            data.oldPrivatekey = this.state.activeProject.privatekey;
            data.newPrivatekey = this.form.privatekey;
            changed = true;
        }

        let config = {};
        try {
            config = JSON.parse(this.form.config)
        } catch (e) {
            console.error(e)
        }

        if (this.form.default_traffic_limit) {
            if (this.form.default_traffic_limit === 0 || this.form.default_traffic_limit === "0" || this.form.default_traffic_limit === "" || this.form.default_traffic_limit === "unlimited") {
                delete config.default_traffic_limit;
                this.form.default_traffic_limit = "";
            } else if (!Number(this.form.default_traffic_limit)) {
                state.form.default_traffic_limit = true;
                return this.setState(state);
            } else {
                config.default_traffic_limit = Number(this.form.default_traffic_limit) * 1024 * 1024;
            }
        }

        if (this.form.default_license_id) {
            if (!Number(this.form.default_license_id)) {
                state.form.default_license_id = true;
                return this.setState(state);
            }

            config.default_license_id = Number(this.form.default_license_id);
        }

        config.disable_optimal_location = this.form.disable_optimal_location && this.form.disable_optimal_location === "true";
        config = JSON.stringify(config).replaceAll(String.fromCharCode(92), String.fromCharCode(92, 92, 92, 92));

        if (this.state.activeProject.config !== config) {
            data.config = config;
            changed = true;
        }

        if (this.form.description !== this.state.activeProject.description) {
            data.description = this.form.description;
            changed = true;
        }

        if (this.form.carrier_name !== this.state.activeProject.carrier_name) {
            data.carrier_name = this.form.carrier_name;
            changed = true;
        }

        if (this.form.icon && this.form.icon.name && this.form.icon.name !== '') {
            data.icon = this.form.icon;
            changed = true;
        }

        state.disabled = changed;
        if (changed) window.Loading.show();
        this.setState(state, () => {
            if (!changed) return;
            let state = this.state;
            state.disabled = false;
            editProject(this.state.activeProject.publickey, data).then(() => {
                state.activeProject.config = config;
                state.activeProject.privatekey = this.form.privatekey;
                state.activeProject.description = this.form.description;
                state.activeProject.carrier_name = this.form.carrier_name;

                if (data.icon) {
                    config.reloadPortal = true;
                    window.reloadPortal();
                } else {
                    this.form.config = config;
                    this.setState(state, window.Loading.hide);
                }
            }).catch((e) => {
                this.form = {
                    config: this.state.activeProject.config ? this.state.activeProject.config : '',
                    privatekey: this.state.activeProject.privatekey,
                    description: this.state.activeProject.description,
                    carrier_name: this.state.activeProject.carrier_name,
                };

                this.setState(state, window.Loading.hide);
            });
        });
    }

    toggleDelete() {
        this.form.deleteKey = '';
        this.modal.delete.show();
    }

    hideModal() {
        this.modal.delete.hide();
    }

    deleteProject() {
        if (this.state.activeProject.privatekey !== this.form.deleteKey) {
            let state = this.state;
            state.message = 'Enter project Private Key';
            state.form.deleteKey = true;
            return this.setState(state);
        }

        window.Loading.show();
        deleteProject(this.state.activeProject.publickey).then(() => {
            delete window.data.projects[this.state.activeProject.publickey];
            window.data.projectsDeleteQueue.push(this.state.activeProject.publickey);
            this.modal.delete.hide();
            goTo('projects');
        }).catch((e) => {
            this.setState({
                message: e.message
            }, window.Loading.hide);
        });
    }

    checkRestrictions() {
        let restriction1 = false,
            restriction2 = false,
            restriction3 = false;

        let role = this.state.activeProject.current_user_role.name;

        try {
            restriction1 = (['ADMIN', 'SUPPORT'].indexOf(role) !== -1);
        } catch (e) {
        }
        try {
            restriction2 = (['OWNER'].indexOf(role) !== -1);
        } catch (e) {
        }
        try {
            restriction3 = (['SUPPORT'].indexOf(role) !== -1);
        } catch (e) {
        }

        return [restriction1, restriction2, restriction3];
    }

    render() {
        return (
            <div className={'parametersPage'}>
                <table className={'table'}>
                    {this.renderTable()}
                </table>
                {this.renderModal()}
            </div>
        );
    }

    renderTable() {
        let [r1, r2,] = this.checkRestrictions();

        let licence = [];
        for (let k in this.state.activeProject.licenses) {
            let l = this.state.activeProject.licenses[k];
            licence.push([l.id, l.name]);
        }

        return (
            <tbody>
            {!r1 && <tr>
                <td width="150">{window.locales.privatekey}</td>
                <td>
                    <Input value={this.form.privatekey} onChange={this.fieldChanged.bind(this, 'privatekey')}
                           disabled={this.state.disabled} notValid={this.state.form.privatekey}/>
                </td>
            </tr>}

            <tr>
                <td>{window.locales.companyName}</td>
                <td>
                    <Input value={this.form.carrier_name} onChange={this.fieldChanged.bind(this, 'carrier_name')}
                           disabled={this.state.disabled} notValid={this.state.form.carrier_name}/>
                </td>
            </tr>

            {(!r1 && !r2) && <tr>
                <td>{window.locales.type}</td>
                <td>{window.locales[this.state.activeProject.project_type]}</td>
            </tr>}

            {!r1 && <tr>
                <td>{window.locales.defaultLicence}</td>
                <td>
                    <Select
                        label={window.locales.defaultLicence}
                        options={licence}
                        theme={'tableForm'}
                        value={this.form.default_license_id}
                        disabled={this.state.disabled}
                        onChange={this.fieldChanged.bind(this, 'default_license_id')}
                    />
                </td>
            </tr>}

            {(!r1 && !r2) && <tr>
                <td>{window.locales.config}</td>
                <td>
                    <Textarea value={jsonBeautifier(this.form.config, true)}
                              onChange={this.fieldChanged.bind(this, 'config')}
                              disabled={this.state.disabled} notValid={this.state.form.config}/>
                </td>
            </tr>}

            <tr>
                <td>{window.locales.description}</td>
                <td><Textarea value={this.form.description} onChange={this.fieldChanged.bind(this, 'description')}
                              disabled={this.state.disabled} notValid={this.state.form.description}/></td>
            </tr>

            <tr>
                <td>{window.locales.icon}</td>
                <td style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    <div className="saveButton">{window.locales.uploadImage}</div>
                    <input id="settingsButtonUploadImage" onChange={this.handleFileUpload.bind(this)} type="file"
                           style={{
                               position: 'absolute',
                               top: '0',
                               left: '0',
                               width: '181px',
                               height: '51px',
                               opacity: '0'
                           }}/>
                    {(this.form.icon && this.form.icon.name) && <div>{this.form.icon.name}</div>}
                    {this.state.iconError && <div style={{color: '#C50000'}}>{this.state.iconError}</div>}
                </td>
            </tr>

            <tr>
                <td></td>
                <td style={{display: 'flex'}}>
                    {(!this.state.disabled) &&
                    <div id="settingsButtonSaveChanges"
                         className={'saveButton' + (this.state.disabled ? ' disabled' : '')}
                         onClick={this.submitForm.bind(this)}>{window.locales.saveChanges}</div>}
                    {(this.state.permissions.indexOf('portal_project_delete') !== -1) &&
                    <div id="settingsButtonDeleteProject"
                         className={'saveButton' + (this.state.disabled ? ' disabled' : '')}
                         onClick={this.toggleDelete.bind(this)}>{window.locales.deleteProject}</div>}
                </td>
            </tr>
            </tbody>
        );
    }

    renderModal() {
        return (
            <Modal
                title={window.locales.deleteProject}
                onRef={ref => {
                    this.modal.delete = ref;
                }}
                message={this.state.message}
                cancel={{
                    id: "DELETE_PROJECT_CANCEL",
                    label: window.locales.cancel,
                    click: this.hideModal.bind(this, 'delete')
                }}
                submit={{
                    id: "DELETE_PROJECT_SUBMIT",
                    label: window.locales.deleteProject,
                    click: this.deleteProject.bind(this)
                }}
                closeId={"DELETE_PROJECT_CLOSE"}
            >
                <div className={'bodyText'} style={{marginBottom: '10px'}}>
                    {window.locales.deleteProjectConfirmation}
                </div>
                <Input
                    id={"DELETE_PROJECT_PRIVATE_KEY"}
                    label={window.locales.privatekey}
                    value={this.form.deleteKey}
                    onChange={this.fieldChanged.bind(this, 'deleteKey')}
                    disabled={this.state.disabled}
                    notValid={this.state.form.deleteKey}
                />
            </Modal>
        );
    }
}

export default index;
