import React, {Component} from 'react';

import {loadProjectActiveSessions} from '../../components/Sessions';

import Table from '../../common/table';

import {Empty} from '../../common/form';

import {goTo, byteConvert, getDateString, getCountryName} from '../../common/methods';

class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeProject: props.activeProject,
        };

        this.dump = JSON.stringify(props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.componentDidMount(props);
    }

    componentDidMount(props) {
        window.Loading.show();
        let state = this.state;
        if (props) {
            state.activeProject = props.activeProject;
        }

        loadProjectActiveSessions().then((sessions) => {
            state.sessions = sessions;
            this.setState(state, window.Loading.hide);
        });
    }

    openUserPage(uid) {
        goTo('user/' + this.state.activeProject.publickey + '/' + uid + '?tab=sessions');
    }

    render() {
        if (!this.state.sessions) return <div/>;

        let head = [
            [window.locales.uid, {width: '100px'}],
            [window.locales.did],
            [window.locales.location],
            [window.locales.startTime, {width: '100px'}],
            [window.locales.download, {width: '100px'}],
            [window.locales.upload, {width: '100px'}],
        ];

        let body = [];

        for (let k in this.state.sessions) {
            let session = this.state.sessions[k];

            let date = getDateString(session.start_time);
            if (date) {
                date = (
                    <div>
                        {date.date}
                        <div className={'lightGray'}>{date.time}</div>
                    </div>
                );
            } else {
                date = '';
            }

            let upload = byteConvert(session.tx);
            let download = byteConvert(session.rx);

            body.push({
                row: [
                    [session.user_id],
                    [session.device_id],
                    [getCountryName(session.server_country)],
                    [date],
                    [download ? download.size + ' ' + download.type : ''],
                    [upload ? upload.size + ' ' + upload.type : ''],
                ],
                click: this.openUserPage.bind(this, session.user_id),
            });
        }

        if (body.length === 0) {
            return <Empty row1={window.locales.noProjectSessions}/>;
        }

        return (
            <div id="screenUsers" className="portalContentContainer">
                <div className="head">
                    <h1>{window.locales.activeSessions}</h1>
                </div>
                <Table head={head} body={body} theme={'default pointer'}/>
            </div>
        );
    }
}

export default index;
