import Network from '../../common/Network';
import { getActiveProject } from '../../components/Projects';

window.data.access = {};
window.data.roles = [];

const loadUsers = (clearCache) => {
  return new Promise((resolve) => {
    let project = getActiveProject();
    if (window.data.access[project.publickey] && clearCache)
      delete window.data.access[project.publickey];

    if (window.data.access[project.publickey])
      return resolve(window.data.access[project.publickey]);

    let request = window.config.host + 'portal/project/access';
    let params = {
      access_token: window.user.firebaseToken,
      publickey: project.publickey,
    };

    return Network.get(request, params)
      .then((response) => {
        let result = [];
        try {
          for (let k in response.response) {
            if (typeof response.response[k] === 'object') {
              result = result.concat(response.response[k]);
            }
          }

          window.data.access[project.publickey] = result;
        } catch (e) {}

        return resolve(result);
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

const deleteUser = (email) => {
  let request = window.config.host + 'portal/project/access';
  let project = getActiveProject();
  let params = {
    access_token: window.user.firebaseToken,
    publickey: project.publickey,
    email: email,
  };

  return Network.delete(request, params);
};

const addUser = (email, publickey) => {
  let request = window.config.host + 'portal/project/access';
  publickey = publickey ? publickey : getActiveProject().publickey;

  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
    email: email,
  };

  return Network.post(request, params);
};

const getRoles = () => {
  return new Promise((resolve) => {
    if (window.data.roles.length > 0) return resolve(window.data.roles);

    let request = window.config.host + 'portal/roles';
    Network.get(request)
      .then((response) => {
        try {
          window.data.roles = response.response.roles;
        } catch (e) {}

        return resolve(window.data.roles);
      })
      .catch((response) => {
        return resolve([]);
      });
  });
};

const setRole = (email, role, publickey) => {
  let request = window.config.host + 'portal/project/access';
  publickey = publickey ? publickey : getActiveProject().publickey;

  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
    email: email,
    role: role,
  };

  return Network.put(request, params);
};

export { loadUsers, addUser, deleteUser, getRoles, setRole };
