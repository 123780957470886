import { goTo, parseUrl } from '../../common/methods';

export default (params) => {
  let path = parseUrl();
  params = params ? params : {};
  let temp = [];
  for (let k in params) {
    if (typeof params[k] === 'undefined') continue;
    else if (typeof params[k] === 'object')
      temp.push(k + '=' + encodeURIComponent(JSON.stringify(params[k])));
    else temp.push(k + '=' + encodeURIComponent(params[k]));
  }

  goTo(path.path.join('/') + (temp.length > 0 ? '?' + temp.join('&') : ''));
};
