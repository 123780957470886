import React, {Component} from 'react';

import {
  IconDelete
} from '../../../common/icons';

import {
  Input
} from '../../../common/form';

import {
  editUserData,
} from '../../../components/Users';

import Modal from '../../../common/modal';
import {goTo} from "../../../common/methods";

class Data extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      disabled: false,
      message: '',
      form: {
        id: false
      }
    };

    this.form = {
      id: '',
      deleteUser: 'on',
      exportUser: 'on'
    };

    this.reload = props.reload;
    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.reload = props.reload;
    this.form = {
      id: '',
      deleteUser: 'on',
      exportUser: 'on'
    };

    this.setState({
      user: props.user
    });
  }

  fieldChanged(key, value) {
    this.form[key] = value;
  }

  formSubmit() {
    if (this.state.disabled) return;
    let state = this.state;
    state.form = {
      id: false
    };
    state.message = '';

    let id = Number(this.form.id);

    if (!id || id !== this.state.user.id) {
      return this.setState({
        form: {
          id: true
        },
        message: 'Please enter User ID'
      });
    }

    if (this.form.deleteUser === 'off' && this.form.exportUser === 'off') {
      return this.setState(state, () => {
        this.modal.hide()
      });
    }

    state.disabled = true;
    this.setState(state, () => {
      let data = {
        id: this.state.user.id,
        deleteData: (this.form.deleteUser === 'on'),
        exportData: (this.form.exportUser === 'on')
      };
      editUserData(data).then(() => {
        this.setState({
          disabled: false,
        }, () => {
          goTo('#users/');
        })
      }).catch(() => {
        this.setState({
          disabled: false,
          message: 'Internal server error'
        })
      });
    });
  }

  hideModal() {
    this.modal.hide();
  }

  render() {
    return (
      <div>
        <IconDelete theme={'light'} onClick={() => {
          this.modal.show()
        }}/>
        <Modal
          title={window.locales.deleteData}
          onRef={ref => {
            this.modal = ref;
          }}
          message={this.state.message}
          visibility={visible => {
            if (visible) {
              this.input.setFocus()
            }
          }}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this)
          }}
          submit={{
            label: window.locales.deleteData,
            click: this.formSubmit.bind(this)
          }}
        >
          <Input
            label={window.locales.uid}
            notValid={this.state.form.id}
            value={this.form.id}
            disabled={this.state.disabled}
            onRef={ref => {
              this.input = ref;
            }}
            onChange={this.fieldChanged.bind(this, 'id')}
            onEnter={this.formSubmit.bind(this)}
          />
        </Modal>
      </div>
    );
  }
}

export default Data;
