import { signOut } from '../common/firebase';

import { goTo } from '../common/methods';

class Network {
  static get(url, params) {
    return Network.request('GET', url, params);
  }

  static post(url, params, body) {
    return Network.request('POST', url, params, body);
  }

  static put(url, params, body) {
    return Network.request('PUT', url, params, body);
  }

  static delete(url, params, body) {
    return Network.request('DELETE', url, params, body);
  }

  static patch(url, params, body) {
    return Network.request('PATCH', url, params, body);
  }

  static request(method, url, params, body) {
    return new Promise((resolve, reject) => {
      let p = [];
      for (let k in params) {
        p.push(k + '=' + encodeURIComponent(params[k]));
      }
      if (p.length > 0) {
        url = url.indexOf('?') === -1 ? url + '?' + p.join('&') : url + '&' + p.join('&');
      }

      let request = new XMLHttpRequest();
      request.open(method, url);

      request.onload = () => {
        let response;
        try {
          response = JSON.parse(request.responseText);
        } catch (e) {
          response = request.responseText ? request.responseText : '';
        }

        if (request.status >= 200 && request.status < 300) {
          resolve({ success: true, status: request.status, response: response });
        } else {
          if (request.status === 401) {
            setTimeout(() => {
              signOut().then(() => {
                goTo('404');
              });
            }, 100);
          }

          let message =
            response && response.error
              ? response.error
              : response && response.result
              ? response.result
              : window.locales.internalServerError;
          reject({ success: false, status: request.status, response: response, message: message });
        }
      };

      request.onerror = (e) => {
        reject({
          success: false,
          status: request.status,
          message: window.locales.internalServerError,
        });
      };

      request.ontimeout = (e) => {
        reject({ success: false, status: request.status, message: 'ComponentNetwork timeout' });
      };

      if (body && typeof body === 'object') {
        if (body.isFile && typeof body.file !== 'undefined') {
          try {
            let formData = new FormData();
            formData.append('file', new Blob([body.file], { type: 'text/plain' }), 'data');
            body = formData;
          } catch (e) {
            body = '';
          }
        } else {
          try {
            body = JSON.stringify(body);
            request.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
            request.setRequestHeader('Accept', 'application/json, text/plain, */*');
          } catch (e) {
            body = '';
          }
        }
      } else if (body && typeof body === 'string') {
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      }

      request.send(body);
    });
  }
}

export default Network;
