import React, {Component} from 'react';
import {
  addAuthentification,
  getAuthentifications,
  editAuthentification,
  deleteAuthentifications
} from "../../../components/Authentifications";

import {
  IconPen,
  IconPlus,
  IconDelete
} from '../../../common/icons';

import {
  Empty,
  Input,
  Textarea
} from '../../../common/form';

import {
  jsonBeautifier
} from '../../../common/methods';

import Modal from '../../../common/modal';

import Table from '../../../common/table';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProject: props.activeProject,
      permissions: props.permissions,
      data: undefined,
      disabled: false,
      message: '',
      form: {
        auth_method: false,
        auth_settings: false
      },
      i: 0,
      edit: !(props.permissions.indexOf('portal_project_auth_update') === -1)
    };

    this.form = {
      auth_method: '',
      auth_settings: ''
    };

    this.modal = {};
    this.dump = JSON.stringify(props.dump);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      activeProject: props.activeProject,
      permissions: props.permissions,
      data: undefined,
      edit: !(props.permissions.indexOf('portal_project_auth_update') === -1)
    }, this.componentDidMount.bind(this));
  }

  componentDidMount(clearCache) {
    window.Loading.show();
    getAuthentifications(clearCache).then((data) => {
      this.setState({
        data: data
      }, window.Loading.hide);
    });
  }

  showModal(modal, data) {
    if (!this.state.edit) return;
    let state = this.state;
    state.i++;
    state.message = '';
    state.disabled = false;

    this.data = data;

    switch (modal) {
      case 'delete':
        this.setState(state, () => {
          this.modal.delete.show();
        });
        break;
      case 'edit':
        this.form = {
          auth_method: this.state.data[this.data.id].auth_method,
          auth_settings: JSON.stringify(this.state.data[this.data.id].auth_settings)
        };

        this.setState(state, () => {
          this.modal.edit.show();
        });
        break;
      case 'add':
        this.form = {
          auth_method: '',
          auth_settings: ''
        };

        this.setState(state, () => {
          this.modal.add.show();
        });
        break;
      default:
        break;
    }
  }

  hideModal(modal) {
    this.modal[modal].hide();
  }

  deleteAuthMethod() {
    if (!this.state.edit) return;
    window.Loading.show();
    this.setState({
      disabled: true,
      message: ''
    }, () => {
      deleteAuthentifications(undefined, this.state.data[this.data.id].auth_method, this.state.data[this.data.id].auth_method_id).then(() => {
        this.setState({
          message: '',
          disabled: false
        }, () => {
          window.Loading.hide();
          this.modal.delete.hide();
          this.componentDidMount(true);
        });
      }).catch((e) => {
        window.error(e);
        this.setState({
          disabled: false,
          message: e.message
        }, window.Loading.hide);
      });
    });
  }

  fieldChanged(key, value) {
    this.form[key] = value;
  }

  editSubmit() {
    if (!this.state.edit) return;
    let valid = true;
    let state = this.state;

    if (this.form.auth_method.trim() === '') {
      valid = false;
      state.form.auth_method = true;
    }

    if (this.form.auth_settings.trim() !== '') {
      try {
        JSON.parse(this.form.auth_settings)
      } catch (e) {
        valid = false;
        state.form.auth_settings = true;
      }
    } else {
      this.form.auth_settings = "{}";
    }

    if (!valid) {
      state.message = window.locales.fillInFields;
      return this.setState(state);
    }

    state.message = '';
    state.form = {
      auth_method: false,
      auth_settings: false
    };
    state.disabled = false;

    window.Loading.show();
    this.setState(state, () => {
      editAuthentification(undefined, this.form.auth_method, this.form.auth_settings, this.state.data[this.data.id].auth_method_id).then(() => {
        this.setState({
          disabled: false,
          message: ''
        }, () => {
          window.Loading.hide();
          this.modal.edit.hide();
          this.componentDidMount(true);
        });
      }).catch(e => {
        window.error(e);
        this.setState({
          disabled: false,
          message: e.message
        }, window.Loading.hide);
      })
    });
  }

  addSubmit() {
    if (!this.state.edit) return;
    let valid = true;
    let state = this.state;

    if (this.form.auth_method.trim() === '') {
      valid = false;
      state.form.auth_method = true;
    }

    if (this.form.auth_settings.trim() !== '') {
      try {
        JSON.parse(this.form.auth_settings)
      } catch (e) {
        valid = false;
        state.form.auth_settings = true;
      }
    } else {
      this.form.auth_settings = "{}";
    }

    if (!valid) {
      state.message = window.locales.fillInFields;
      return this.setState(state);
    }

    state.message = '';
    state.form = {
      auth_method: false,
      auth_settings: false
    };
    state.disabled = false;

    window.Loading.show();
    this.setState(state, () => {
      addAuthentification(undefined, this.form.auth_method, this.form.auth_settings).then(() => {
        this.setState({
          disabled: false,
          message: ''
        }, () => {
          window.Loading.hide();
          this.modal.add.hide();
          this.componentDidMount(true);
        });
      }).catch(e => {
        window.error(e);
        this.setState({
          disabled: false,
          message: e.message
        }, window.Loading.hide);
      })
    });
  }

  render() {
    if (!this.state.data) return <div/>;

    let head = [
      [window.locales.authMethod, {width: 150}],
      [window.locales.authSettings],
    ];

    if (this.state.edit) {
      head.push(['', {width: 25}]);
      head.push(['', {width: 25}]);
    }

    let body = [];
    for (let k in this.state.data) {
      let r = this.state.data[k];

      let p = jsonBeautifier(r.auth_settings);
      if (p) {
        p = (
          <div className={'absoluteDiv'}>
            <div className={'row'}>{p}</div>
          </div>
        );
      }

      body.push([
        [r.auth_method],
        [<div>{(r.auth_settings ? JSON.stringify(r.auth_settings) : '')}{p}</div>]
      ]);

      if (this.state.edit) {
        body[(body.length - 1)].push([<IconPen theme={'blue'}
                                               onClick={this.showModal.bind(this, 'edit', {id: k})}/>, {center: true}]);
        body[(body.length - 1)].push([<IconDelete theme={'blue'}
                                                  onClick={this.showModal.bind(this, 'delete', {id: k})}/>, {center: true}]);
      }
    }

    let content = body.length > 0 ? <Table head={head} body={body}/> : <Empty row1={window.locales.noAuthentifications}/>;

    return (
      <div className={'settingsContentContainer'}>
        {this.state.edit &&
        <div style={{position: 'absolute', right: '0', top: '16px'}}><IconPlus theme={'blue'} label={window.locales.add}
                                                                               onClick={this.showModal.bind(this, 'add')}/>
        </div>}
        {content}
        <Modal
          title={window.locales.addAuthMethod}
          onRef={ref => {
            this.modal.add = ref;
          }}
          message={this.state.message}
          visibility={visible => {
            if (visible) {
              this.addInput.setFocus()
            }
          }}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this, 'add')
          }}
          submit={{
            label: window.locales.addAuthMethod,
            click: this.addSubmit.bind(this)
          }}
        >
          <Input
            onRef={ref => {
              this.addInput = ref;
            }}
            label={window.locales.authMethod}
            notValid={this.state.form.auth_method}
            value={this.form.auth_method}
            disabled={this.state.disabled}
            onChange={this.fieldChanged.bind(this, 'auth_method')}
            onEnter={this.addSubmit.bind(this)}
          />
          <Textarea
            label={window.locales.authSettings}
            notValid={this.state.form.auth_settings}
            value={this.form.auth_settings}
            disabled={this.state.disabled}
            onChange={this.fieldChanged.bind(this, 'auth_settings')}
          />
        </Modal>
        <Modal
          title={window.locales.editAuthMethod}
          onRef={ref => {
            this.modal.edit = ref;
          }}
          message={this.state.message}
          visibility={visible => {
            if (visible) {
              this.editInput.setFocus()
            }
          }}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this, 'edit')
          }}
          submit={{
            label: window.locales.editAuthMethod,
            click: this.editSubmit.bind(this)
          }}
        >
          <Input
            onRef={ref => {
              this.editInput = ref;
            }}
            label={window.locales.authMethod}
            notValid={this.state.form.auth_method}
            value={this.form.auth_method}
            disabled={this.state.disabled}
            onChange={this.fieldChanged.bind(this, 'auth_method')}
            onEnter={this.editSubmit.bind(this)}
          />
          <Textarea
            label={window.locales.authSettings}
            notValid={this.state.form.auth_settings}
            value={jsonBeautifier(this.form.auth_settings, true)}
            disabled={this.state.disabled}
            onChange={this.fieldChanged.bind(this, 'auth_settings')}
          />
        </Modal>
        <Modal
          title={window.locales.deleteAuthMethod}
          onRef={ref => {
            this.modal.delete = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this, 'delete')
          }}
          submit={{
            label: window.locales.deleteAuthMethod,
            click: this.deleteAuthMethod.bind(this)
          }}
        >
          <div className={'bodyText'}>
            {window.locales.deleteAuthMethodConfirmation}
          </div>
        </Modal>
      </div>
    );
  }
}

export default index;
