import styled from 'styled-components';
import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';

const ProgressContainer = styled.div`
  user-select: none;
  width: 100px;
  height: 20px;
  background: ${(props) => (props.isDefaultTheme ? '#D1D8E3' : '#878C95')};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.percent}%;
  background: ${(props) => props.color};
  height: 100%;
`;

const Percent = styled.div`
  color: ${(props) => (props.isDefaultTheme ? '#000000' : '#FFFFFF')};
  font-size: 14px;
  position: absolute;
  top: 0;
  height: inherit;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default (props) => {
  const context = useContext(ThemeContext);
  const getColor = (percent) => {
    if (percent < 30) return '#CAECFF';
    if (percent < 60) return '#8FD0F5';
    if (percent < 90) return '#00A2FF';
    return '#AB3962';
  };
  return (
    <ProgressContainer isDefaultTheme={context.theme}>
      <ProgressBar percent={props.percent} color={getColor(props.percent)} />
      <Percent isDefaultTheme={context.theme}>{props.percent}%</Percent>
    </ProgressContainer>
  );
};
