import React, {Component} from 'react';

import {
    IconPhone,
    IconCalendar,
    IconCheckMark,
    IconSmallArrow,
    IconPlus
} from '../../../common/icons';

import {
    Empty
} from '../../../common/form';

import {
    loadSessions,
    loadActiveSessions
} from "../../../components/Sessions";

import {
    byteConvert,
    getDateString,
    getCountryName
} from '../../../common/methods';

import Table from '../../../common/table';

import {
    changeParams
} from "../../../common/methods";

import Calendar from './Calendar';
import {getDevices} from "../../../components/Devices";

class Sessions extends Component {
    constructor(props) {
        super(props);

        let now = new Date();
        this.state = {
            i: 0,
            user: props.user,
            deviceId: undefined,
            devicesDropdown: false,
            calendarDropdown: false,
            days: {
                from: (now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear(),
                till: (now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear()
            },
            time: {
                from: '00:00',
                till: '23:59'
            },
            customTime: false,
            currentPage: 1
        };

        this.sessions = [];
        this.activeSessions = [];

        this.form = {
            activeSessions: 'off'
        };

        this.dump = JSON.stringify(props);

        this._escFunction = this.escFunction.bind(this);
        this._click = this.click.bind(this);

        document.addEventListener("keydown", this._escFunction);
        document.addEventListener("click", this._click);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.setState({
            user: props.user
        }, this.componentDidMount.bind(this));
    }

    componentDidMount() {
        let from = new Date(this.state.days.from + ' ' + this.state.time.from.replace(/_/g, '0')).getTime();
        let till = new Date(this.state.days.till + ' ' + this.state.time.till.replace(/_/g, '0')).getTime();
        window.Loading.show();
        loadSessions(this.state.user.id, from, till).then(sessions => {
            sessions = sessions.reverse();
            loadActiveSessions(this.state.user.id).then(activeSessions => {
                getDevices(this.state.user.id).then((devices) => {
                    this.sessions = sessions;
                    this.activeSessions = activeSessions;
                    this.setState({
                        currentPage: 1,
                        devices: devices,
                        order: ["desc", "start_time"]
                    }, () => {
                        window.Loading.hide();
                    });
                });
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._escFunction);
        document.removeEventListener("click", this._click);
    }

    escFunction(event) {
        if (event.keyCode === 27 && this.state.calendarDropdown) {
            this.setState({calendarDropdown: false});
        }
        if (event.keyCode === 27 && this.state.devicesDropdown) {
            this.setState({devicesDropdown: false});
        }
    }

    click(e) {
        let is = (el1, el2) => {
            if (!el1) return false;
            if (el1 === el2) return true;
            if (el1.id === "loadingContainer") return true;
            return is(el1.parentNode, el2);
        };

        let el;

        el = document.getElementById('calendar_dropdown');
        if (this.state.calendarDropdown && !is(e.target, el)) {
            return this.setState({calendarDropdown: false});
        }

        el = document.getElementById('device_dropdown');
        if (this.state.devicesDropdown && !is(e.target, el)) {
            return this.setState({devicesDropdown: false});
        }
    }

    devicesDropdownStatus() {
        this.setState({
            devicesDropdown: !this.state.devicesDropdown,
            calendarDropdown: false
        });
    }

    changeDevice(deviceId) {
        this.setState({
            deviceId: deviceId,
            devicesDropdown: false
        }, this.componentDidMount.bind(this));
    }

    dateChanged(value) {
        this.setState({
            days: {
                from: value.days.from,
                till: value.days.till
            },
            time: {
                from: value.time.from,
                till: value.time.till
            },
            calendarDropdown: false
        }, this.componentDidMount.bind(this));
    }

    calendarDropdownStatus() {
        this.setState({
            devicesDropdown: false,
            calendarDropdown: !this.state.calendarDropdown
        })
    }

    renderOrderArrow(type) {
        if (!this.state.order || this.state.order[1] !== type) return "";
        let t = this.state.order[0] === "asc" ? "down" : "up";

        return (
            <img alt="" src={"/static/assets/icons/arrow_" + t + ".png"} style={{width: "10px", marginLeft: "5px"}}/>
        );
    }

    renderSessionsList() {
        let sessions = [].concat(this.activeSessions);
        if (this.form.activeSessions === 'off') sessions = sessions.concat(this.sessions);

        let head = [
            [
                <div>{window.locales.did}{this.renderOrderArrow("device_id")}</div>, {}, this.sortBy.bind(this, "device_id")],
            [
                <div>{window.locales.location}{this.renderOrderArrow("server_country")}</div>, {}, this.sortBy.bind(this, "server_country")],
            [
                <div>{window.locales.startTime}{this.renderOrderArrow("start_time")}</div>, {}, this.sortBy.bind(this, "start_time")],
            [
                <div>{window.locales.endTime}{this.renderOrderArrow("end_time")}</div>, {}, this.sortBy.bind(this, "end_time")],
            [
                <div>{window.locales.internalIp}{this.renderOrderArrow("internal_address")}</div>, {}, this.sortBy.bind(this, "internal_address")],
            [<div>{window.locales.download}{this.renderOrderArrow("rx")}</div>, {}, this.sortBy.bind(this, "rx")],
            [<div>{window.locales.upload}{this.renderOrderArrow("tx")}</div>, {}, this.sortBy.bind(this, "tx")]
        ];

        let body = [];
        for (let k in sessions) {
            if (Number(k) === this.state.currentPage * 100) {
                break;
            }
            let session = sessions[k];
            if (this.state.deviceId && session.device_id !== this.state.deviceId) continue;

            let params = {
                start: getDateString(session.start_time),
                end: getDateString(session.end_time),
                download: byteConvert(session.rx),
                upload: byteConvert(session.tx),
            };

            let devicePopUpl;
            for (let k in this.state.devices) {
                let device = this.state.devices[k];
                if (device.device_id !== session.device_id) continue;

                let date = getDateString(device.registration_time);

                devicePopUpl = (
                    <div className={'absoluteDiv'}>
                        <div className={'row'}>{window.locales.did}: {device.device_id}</div>
                        <div className={'row'}>{window.locales.accessToken}: {device.access_token}</div>
                        <div className={'row'}>{window.locales.name}: {device.name}</div>
                        <div className={'row'}>{window.locales.deviceType}: {device.type}</div>
                        {date &&
                        <div className={'row'}>{window.locales.registrationTime}: {(date.date + ' ' + date.time)}</div>}
                    </div>
                );

                break;
            }

            body.push({
                row: [
                    [
                        <div className={'tableAbsoluteContainer'} onClick={() => {
                            changeParams('tab', 'devices');
                        }}>
                            {session.device_id}
                            {devicePopUpl}
                        </div>
                    ],
                    [getCountryName(session.server_country)],
                    [(params.start ? (params.start.date + ' ' + params.start.time) : '')],
                    [(params.end ? (params.end.date + ' ' + params.end.time) : '')],
                    [session.internal_address],
                    [(params.download ? (params.download.size + ' ' + params.download.type) : '')],
                    [(params.upload ? (params.upload.size + ' ' + params.upload.type) : '')],
                ],
                className: (params.end ? 'absoluteContentContainer' : 'absoluteContentContainer blue')
            });
        }

        if (body.length === 0) {
            return <Empty row1={window.locales.noSessionsRow1} row2={window.locales.noSessionsRow2}/>;
        }

        let total = this.sessions.length + this.activeSessions.length;
        return (
            <div className={'screenUsersContentContainer'}>
                <Table head={head} body={body} theme={"default headerPointer"}/>
                {(total > this.state.currentPage * 100) &&
                <div style={{display: "flex", marginTop: "20px", justifyContent: "center"}} className={'loadMore'}>
                    <IconPlus onClick={this.showMoreSessions.bind(this)} label={window.locales.loadMore}
                              theme={'blue'}/></div>}
            </div>
        );
    }

    showMoreSessions() {
        window.Loading.show();
        this.setState({
            currentPage: this.state.currentPage + 1
        }, window.Loading.hide())
    }

    renderCalendarSelector() {
        let from = this.state.days.from.split('/');
        from[0] = window.locales.month[(Number(from[0]) - 1)];

        let till = this.state.days.till.split('/');
        till[0] = window.locales.month[(Number(till[0]) - 1)];

        return (
            <div id={'calendar_dropdown'} className={'field'}>
                <div className={'calendar_dropdown_click'} onClick={this.calendarDropdownStatus.bind(this)}>
                    <IconCalendar theme={'blue'}/>
                    {from[1]}{window.locales.daySymbol} {from[0]} {from[2]}{window.locales.yearSymbol} {this.state.time.from} - {till[1]}{window.locales.daySymbol} {till[0]} {till[2]}{window.locales.yearSymbol} {this.state.time.till}
                    <IconSmallArrow theme={'default flex'}/>
                </div>
                {
                    this.state.calendarDropdown &&
                    <Calendar
                        days={{
                            from: this.state.days.from,
                            till: this.state.days.till,
                        }}
                        time={{
                            from: this.state.time.from,
                            till: this.state.time.till,
                        }}
                        customTime={this.state.customTime}
                        closeCalendar={() => {
                            this.setState({calendarDropdown: false});
                        }}
                        updateSearch={this.dateChanged.bind(this)}
                        activeProject={this.props.activeProject}
                    />
                }
            </div>
        );
    }

    renderDeviceSelector() {
        let content = [];
        let activeDevice = window.locales.allDevices;

        content.push(
            <div className={'row' + (!this.state.deviceId ? ' active' : '')} key={'all'}
                 onClick={this.changeDevice.bind(this, undefined)}>
                <div className='publickey'>{window.locales.allDevices}</div>
                <div className="selector"/>
                {!this.state.deviceId && <IconCheckMark/>}
            </div>
        );

        for (let k in this.state.devices) {
            let device = this.state.devices[k];
            content.push(
                <div className={'row' + (this.state.deviceId === device.device_id ? ' active' : '')} key={k}
                     onClick={this.changeDevice.bind(this, device.device_id)}>
                    <div className='publickey'>{device.name}</div>
                    <div className="selector"/>
                    {this.state.deviceId === device.device_id && <IconCheckMark/>}
                </div>
            );

            if (this.state.deviceId === device.device_id) {
                activeDevice = device.name;
            }
        }

        return (
            <div id="device_dropdown" className={'field'} onClick={this.devicesDropdownStatus.bind(this)}>
                <IconPhone theme={'blue'}/>
                {activeDevice}
                <IconSmallArrow theme={'default flex'}/>
                {
                    this.state.devicesDropdown &&
                    <div className={'list'}>
                        <div className='overflow scroll'>
                            {content}
                        </div>
                    </div>
                }
            </div>
        );
    }

    render() {
        return (
            <div className={'userContent sessions'}>
                <div className={'navigation'}>
                    {this.renderCalendarSelector()}
                    {this.renderDeviceSelector()}
                </div>
                {this.renderSessionsList()}
            </div>
        );
    }

    sortBy(type) {
        if (this.sortInProgress) return;

        this.sortInProgress = true;
        window.Loading.show();
        setTimeout(() => {
            let order = this.state.order;
            if (order[1] === type) {
                order[0] = order[0] === "asc" ? "desc" : "asc";
            } else {
                order[1] = type
                order[0] = "asc";
            }

            this.sessions.sort((a, b) => {
                if (type === "internal_address") {
                    let v1 = a[type].split(".");
                    let v2 = b[type].split(".");

                    for (let k in v1) {
                        if (Number(v1[k]) > Number(v2[k])) return -1;
                        else if (Number(v1[k]) < Number(v2[k])) return 1;
                    }

                    return 0;
                }

                let v1 = a[type],
                    v2 = b[type];
                if (type === "server_country") {
                    v1 = getCountryName(v1);
                    v2 = getCountryName(v2);
                }

                if (v1 > v2) return -1;
                else if (v1 < v2) return 1;
                else return 0;
            });

            if (order[0] === "desc") this.sessions.reverse();

            this.setState({
                i: this.state.i + 1,
                order: order
            }, () => {
                this.sortInProgress = false;
                window.Loading.hide();
            });
        }, 50);
    }
}

export default Sessions;
