import React, { Component } from 'react';

class Status extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      label: props.label ? props.label : undefined,
      status: props.status ? props.status : 'off',
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      label: props.label ? props.label : undefined,
      status: props.status ? props.status : 'off',
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    return (
      <div className={'icons status flex ' + this.state.status}>
        <div className={'circle'} />
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Status;
