import { StackedAreaChart } from './Graphs';
import React, { useEffect, useRef, useState } from 'react';
import { GetMetricsRequest } from '../../components/Graphs';

export default (props) => {
  const [dataGraphs, setDataGraphs] = useState(null);
  const componentIsMounted = useRef(true);

  const getDate = (period, time) => {
    const date = new Date(time * 1000);
    const startDate = new Date(period.startDate);
    const endDate = new Date(period.endDate);
    const oneDay = 24 * 60 * 60 * 1000;
    const days = Math.round(Math.abs((startDate - endDate) / oneDay));

    return days === 1
      ? date.toLocaleTimeString().slice(0, -3)
      : `${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleTimeString().slice(0, -3)}`;
  };

  useEffect(() => {
    componentIsMounted.current = true;
    window.Loading.show();
    setDataGraphs(null);

    function getDataGraphs() {
      const requests = props.graphsMetadata.map((graphMetadata) =>
        graphMetadata.items.map((graphId) =>
          GetMetricsRequest(props.publickKey, graphId, props.period),
        ),
      );

      requests.forEach((request, graphIndex) =>
        Promise.all(request).then((responses) => {
          let points = [];
          responses.forEach((dataMetric, index) => {
            if (responses.length === 1) points = [];
            dataMetric &&
              dataMetric.forEach((item) => {
                const nameValue = Object.keys(item.metric).length
                  ? item.metric[Object.keys(item.metric)[0]]
                  : props.graphsMetadata[graphIndex].items[index];

                if (points.length === 0) {
                  item.values.forEach((value) =>
                    points.push({
                      name: getDate(props.period, value[0]),
                      [nameValue]: Number(value[1]),
                    }),
                  );
                } else {
                  item.values.forEach((value, i) => {
                    try {
                      points[i][nameValue] = Number(value[1]);
                    } catch (e) {
                      window.error(e);
                    }
                  });
                }
              });

            props.graphsMetadata[graphIndex].points = points;
            props.graphsMetadata[graphIndex].entity =
              dataMetric && dataMetric.length > 0 ? Object.keys(dataMetric[0].metric)[0] : '';
          });

          componentIsMounted.current && setDataGraphs([...props.graphsMetadata]);
          window.Loading.hide();
        }),
      );
    }

    getDataGraphs();
    if (props.interval !== '0') {
      const intervalId = setInterval(() => {
        getDataGraphs();
      }, Number(props.interval) * 1000);

      return () => {
        clearInterval(intervalId);
        componentIsMounted.current = false;
      };
    }
  }, [props.interval, props.period, props.publickKey, props.graphsMetadata]);

  return (
    dataGraphs &&
    dataGraphs.map((dataGraph, index) => (
      <StackedAreaChart
        key={index}
        data={dataGraph.points}
        entity={dataGraph.entity}
        id={dataGraph.identity}
        info={dataGraph.info}
      />
    ))
  );
};
