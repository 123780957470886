import React, {Component} from 'react';

import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import Reset from './Reset/Reset';
import Reseted from './Reseted/Reseted';

class Auth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screen: props.path ? props.path : 'signup',
            params: props.params ? props.params : {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            screen: props.path ? props.path : 'signup',
            params: props.params ? props.params : {},
        });
    }

    render() {
        let content;

        switch (this.state.screen) {
            case 'reset':
                content = <Reset params={this.state.params}/>;
                break;
            case 'reseted':
                content = <Reseted params={this.state.params}/>;
                break;
            case 'business_signup':
                content = content = <SignUp params={this.state.params}/>;
                break;
            default:
                content = <SignIn params={this.state.params}/>;
                break;
        }

        return (
            <div id="screenAuth" className={'fullHeight business'}>
                {content}
            </div>
        );
    }
}

export default Auth;
