import { parseUrl } from '../../common/methods';
import Network from '../../common/Network';

window.data.projects = {};
window.data.projectsDeleteQueue = [];

const getProjects = (clearCache) => {
  if (clearCache) {
    window.data.projects = {};
    window.data.projectsDeleteQueue = [];
  }

  return new Promise((resolve, reject) => {
    if (Object.keys(window.data.projects).length > 0) return resolve(window.data.projects);

    loadProjects()
      .then((response) => {
        if (
          !response ||
          !response.response ||
          !response.response.projects ||
          response.response.projects.length === 0
        )
          return reject();

        let projects = {};
        let grafanakey = response.response.grafanakey;

        for (let k in response.response.projects) {
          let project = response.response.projects[k];

          if (project.deleteTaskCode) continue;
          if (project.delete_task_code) continue;
          if (!project.privatekey) continue;
          if (window.data.projectsDeleteQueue.indexOf(project.publickey) !== -1) continue;

          if (project.grafana_panels) {
            for (let j in project.grafana_panels) {
              let p = project.grafana_panels[j];
              project.grafana_panels[j].id =
                p && p.title
                  ? 'grafana_' + p.title.toLowerCase().replace(/[^A-Za-z]+/g, '')
                  : undefined;
            }
          }

          project.grafanakey = grafanakey;
          projects[project.publickey] = project;
          try {
            projects[project.publickey].icon = JSON.parse(project.config).icon;
          } catch (e) {}

          try {
            project.role = project.current_user_role.name;
          } catch (e) {
            project.role = 'UNDEFINED';
          }
        }

        if (Object.keys(projects).length === 0) return reject();

        window.data.projects = projects;
        return resolve(projects);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

const getActiveProject = () => {
  if (!Object.keys(window.data.projects).length === 0) return undefined;

  let publickey = parseUrl().path[1];
  publickey = publickey ? publickey : Object.keys(window.data.projects)[0];

  if (!window.data.projects[publickey]) return undefined;

  return window.data.projects[publickey];
};

const loadProjects = () => {
  let request = window.config.host + 'portal/projects';
  let params = {
    access_token: window.user.firebaseToken,
  };

  return Network.get(request, params);
};

export { getProjects, loadProjects, getActiveProject };
