export default (email) => {
    window.fb.default.auth().useDeviceLanguage();
    return window.fb.default
        .auth()
        .sendPasswordResetEmail(email, {
            url:
                'https://business.anchorfree.com/#auth/signin',
        })
        .then(() => {
            return true;
        })
        .then((error) => {
            return error;
        });
};
