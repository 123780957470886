import React, {Component} from 'react';

import {IconPlus, IconSmallArrow} from '../../common/icons';

import {createUser, loadRegularUsers} from '../../components/Users';

import Add from './Add';
import AddBulk from './AddBulk';

class AddContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showPopUp: false,
            activeProject: props.activeProject,
        };

        this.loadUsers = props.loadUsers;

        this._escFunction = this.escFunction.bind(this);
        this._click = this.click.bind(this);

        document.addEventListener('keydown', this._escFunction);
        document.addEventListener('click', this._click);

        this.isUid = false;
        this.dump = JSON.stringify(props);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._escFunction);
        document.removeEventListener('click', this._click);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.isUid = false;
        this.setState(
            {
                show: false,
                showPopUp: false,
                activeProject: props.activeProject,
            },
            this.checkProjectAuth.bind(this),
        );
    }

    componentDidMount() {
        this.checkProjectAuth();
    }

    checkProjectAuth() {
        this.fbAuth = false;
        let databaseURL;

        for (let k in this.state.activeProject.authentifications) {
            if (this.state.activeProject.authentifications[k].auth_method === 'firebase') {
                this.fbAuth = this.state.activeProject.authentifications[k].auth_settings.firebase_api_key;
                databaseURL = this.state.activeProject.authentifications[k].auth_settings.databaseURL;
                break;
            }
        }

        if (!this.fbAuth) return;

        try {
            let a = this.state.activeProject.authentifications;
            for (let k in a) {
                if (
                    a[k].auth_method === 'firebase' &&
                    a[k].auth_settings &&
                    a[k].auth_settings.type === 'userid'
                ) {
                    this.isUid = true;
                }
            }
        } catch (e) {
        }

        if (!window.fb.custom[this.fbAuth]) {
            let opt = {
                apiKey: this.fbAuth,
            };

            if (databaseURL) {
                opt.databaseURL = databaseURL;
            }

            window.fb.custom[this.fbAuth] = {
                fb: window.fb.default.initializeApp(opt, this.fbAuth),
                params: {},
            };
        }

        if (!window.fb.custom[this.fbAuth].params.email) {
            return window.fb.custom[this.fbAuth].fb
                .auth()
                .createUserWithEmailAndPassword(
                    'afdeveloperportal@afdeveloperportal.af',
                    'afdeveloperportal',
                )
                .then(() => {
                    window.fb.custom[this.fbAuth].params.email = 'enabled';
                    this.setState({show: true});
                    window.fb.custom[this.fbAuth].fb.auth().currentUser.delete();
                })
                .catch((e) => {
                    if (e && e.code && e.code === 'auth/email-already-in-use') {
                        window.fb.custom[this.fbAuth].params.email = 'enabled';
                        this.setState({show: true});
                        window.fb.custom[this.fbAuth].fb
                            .auth()
                            .signInWithEmailAndPassword(
                                'afdeveloperportal@afdeveloperportal.af',
                                'afdeveloperportal',
                            )
                            .then(() => {
                                window.fb.custom[this.fbAuth].fb.auth().currentUser.delete();
                            })
                            .catch((e) => {
                            });
                    } else {
                        window.fb.custom[this.fbAuth].params.email = 'disabled';
                    }
                });
        } else if (window.fb.custom[this.fbAuth].params.email === 'enabled') {
            this.setState({show: true});
        }
    }

    addUser(user) {
        return new Promise((resolve) => {
            if ((user.password.trim().length > 0 || this.isUid) && user.password.trim().length < 6) {
                return resolve({status: false, message: window.locales.error1, field: 'password'});
            }

            if (this.isUid) {
                if (user.username.length < 6) {
                    return resolve({status: false, message: window.locales.error2, field: 'username'});
                }

                if (!user.username.match(/^[A-Za-z0-9_.]+$/)) {
                    return resolve({status: false, message: window.locales.error3, field: 'username'});
                }
            } else {
                if (
                    !user.username.trim().match(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
                ) {
                    return resolve({status: false, message: window.locales.error4, field: 'username'});
                }
            }

            if (!user.given_name || user.given_name.trim() === '') {
                user.given_name = user.username;
            }

            this.saveFirebaseUser(user).then((result) => {
                return resolve(result);
            });
        });
    }

    saveFirebaseUser(user) {
        return new Promise((resolve) => {
            let reset = false;
            let password = user.password;
            if (!password || password === '') {
                reset = true;
                password = 'PORTAL' + Date.now();
            }

            let username = user.username;
            if (this.isUid) {
                username = username + '.' + this.state.activeProject.publickey + '@anchorfree.email';
            }

            window.fb.custom[this.fbAuth].fb
                .auth()
                .createUserWithEmailAndPassword(username, password)
                .then((response) => {
                    response = response.user;
                    window.fb.custom[this.fbAuth].fb.auth().currentUser.updateProfile({
                        photoURL: this.state.activeProject.publickey,
                    });

                    if (reset) {
                        window.fb.custom[this.fbAuth].fb.auth().useDeviceLanguage();
                        window.fb.custom[this.fbAuth].fb.auth().sendPasswordResetEmail(username, {
                            url: 'https://business.anchorfree.com/#auth/signin'
                        });
                    }

                    window.fb.custom[this.fbAuth].fb.auth().signOut();
                    createUser(
                        'firebase_' + response.uid,
                        this.state.activeProject.licenses[Object.keys(this.state.activeProject.licenses)[0]].id,
                        user.given_name,
                    ).then((response) => {
                        if (!response.success) {
                            return resolve({status: false, message: response.response.error});
                        }

                        return resolve({status: true});
                    });
                })
                .catch((e) => {
                    let databaseURL = false;

                    for (let k in this.state.activeProject.authentifications) {
                        let a = this.state.activeProject.authentifications[k];

                        if (a.auth_method === 'firebase' && a.auth_settings && a.auth_settings.databaseURL) {
                            databaseURL = a.auth_settings.databaseURL;
                            break;
                        }
                    }

                    if (e && e.code === 'auth/email-already-in-use' && databaseURL) {
                        this.saveFirebaseUserWithExistingEmail(username, password)
                            .then((response) => {
                                createUser(
                                    'firebase_' + response.uid,
                                    this.state.activeProject.licenses[
                                        Object.keys(this.state.activeProject.licenses)[0]
                                        ].id,
                                    user.given_name,
                                ).then((response) => {
                                    if (!response.success) {
                                        return resolve({status: false, message: response.response.error});
                                    }

                                    return resolve({status: true});
                                });
                            })
                            .catch((e) => {
                                return resolve({status: false, message: e.message});
                            });
                    } else {
                        return resolve({status: false, message: e.message});
                    }
                });
        });
    }

    saveFirebaseUserWithExistingEmail(username, password) {
        return new Promise((resolve, reject) => {
            window.fb.custom[this.fbAuth].fb
                .auth()
                .signInWithEmailAndPassword(username, password)
                .then((response) => {
                    response = response.user;
                    loadRegularUsers({search: 'unm_firebase_' + response.uid}).then((r) => {
                        if (r.length > 0) {
                            return reject({status: false, message: window.locales.error5});
                        }

                        window.fb.custom[this.fbAuth].fb
                            .database()
                            .ref('users/' + response.uid)
                            .on('value', (s) => {
                                let val = s.val();

                                if (!val) {
                                    window.fb.custom[this.fbAuth].fb.auth().signOut();
                                    return resolve(response);
                                }

                                if (typeof val.projects === 'object' && !Array.isArray(val.projects)) {
                                    let t = val.projects;
                                    val.projects = [];
                                    for (let k in t) {
                                        val.projects.push(t[k]);
                                    }
                                }

                                if (
                                    val &&
                                    val.projects &&
                                    typeof val.projects === 'object' &&
                                    val.projects.indexOf(this.state.activeProject.publickey) === -1
                                ) {
                                    val.projects.push(this.state.activeProject.publickey);
                                    window.fb.custom[this.fbAuth].fb
                                        .database()
                                        .ref('users/' + response.uid + '/projects')
                                        .set(val.projects);
                                }

                                window.fb.custom[this.fbAuth].fb.auth().signOut();
                                return resolve(response);
                            });
                    });
                })
                .catch((e) => {
                    if (window.locales[e.message]) e.message = window.locales[e.message];
                    return reject(e);
                });
        });
    }

    escFunction(event) {
        if (event.keyCode === 27 && this.state.showPopUp) {
            this.setState({showPopUp: false});
        }
    }

    click(e) {
        if (!this.state.showPopUp) return;

        let is = (el1, el2) => {
            if (!el1) return false;
            if (el1 === el2) return true;
            if (el1.id === 'loadingContainer') return true;
            return is(el1.parentNode, el2);
        };

        let el = document.getElementById('userAddContainer');
        if (!is(e.target, el)) {
            return this.setState({showPopUp: false});
        }
    }

    render() {
        if (!this.state.show) return <div/>;

        return (
            <div className={'userAddContainer'} id={'userAddContainer'} style={{marginLeft: '20px'}}>
                <IconPlus
                    id={'ADD_USER_DROPDOWN'}
                    theme="blue"
                    label={window.locales.usersAdd}
                    onClick={() => {
                        this.setState({showPopUp: !this.state.showPopUp});
                    }}
                />
                <IconSmallArrow
                    theme="blue flex"
                    onClick={() => {
                        this.setState({showPopUp: !this.state.showPopUp});
                    }}
                />
                <div className={'userAddContainerPopUp ' + (this.state.showPopUp ? 'show' : '')}>
                    <Add
                        activeProject={this.state.activeProject}
                        loadUsers={this.loadUsers}
                        addUser={this.addUser.bind(this)}
                        isUid={this.isUid}
                    />
                    <AddBulk
                        activeProject={this.state.activeProject}
                        loadUsers={this.loadUsers}
                        addUser={this.addUser.bind(this)}
                        isUid={this.isUid}
                    />
                </div>
            </div>
        );
    }
}

export default AddContainer;
