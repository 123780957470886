export const Colors = [
  '#6AC340',
  '#E0B33F',
  '#40C3A4',
  '#E0653F',
  '#C34040',
  '#408CC3',
  '#8940C3',
  '#4340C3',
  '#40C35D',
  '#E0DA3F',
  '#40C3B3',
  '#E06F3F',
  '#C34050',
  '#4074C3',
  '#9940C3',
  '#5240C3',
  '#40C36D',
  '#CAE03F',
  '#40BBC3',
  '#E0823F',
  '#E03F3F',
  '#4065C3',
  '#C040C3',
  '#6240C3',
  '#40C384',
  '#A3E03F',
  '#409CC3',
  '#E08C3F',
  '#C3406F',
  '#4055C3',
];
