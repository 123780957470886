import React, { Component } from 'react';

import UpdateByFile from './UpdateByFile';
import UpdateByCheckbox from './UpdateByCheckbox';

class UpdateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxes: props.checkboxes,
      activeProject: props.activeProject,
    };

    this.dump = JSON.stringify(props);
    this.loadUsers = props.loadUsers;
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      checkboxes: props.checkboxes,
      activeProject: props.activeProject,
    });
  }

  render() {
    return this.state.activeProject.project_type === 'private_vpn' ? (
      <div style={{ display: 'flex' }}>
        <UpdateByFile loadUsers={this.loadUsers} activeProject={this.state.activeProject} />
        <UpdateByCheckbox checkboxes={this.state.checkboxes} loadUsers={this.loadUsers} />
      </div>
    ) : null;
  }
}

export default UpdateContainer;
