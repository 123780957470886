import Network from '../../common/Network';

const addProject = (publickey, description, project_type, carrier_name, icon) => {
    let request = window.config.host + 'portal/project';
    let params = {
        access_token: window.user.firebaseToken,
        publickey: publickey,
        description: description,
        project_type: project_type,
        name: carrier_name,
        bandwidth_reset_time: 'daily-utc',
        config: `{"server_pool":"pub-default"}`,
    };

    return new Promise((resolve, reject) => {
        Network.post(request, params)
            .then((response) => {
                if (icon) {
                    let request = window.config.host + 'portal/project/icon/upload';
                    let params = {
                        access_token: window.user.firebaseToken,
                        publickey: publickey,
                    };

                    let body = new FormData();
                    body.append('icon', icon);

                    fetch(
                        request + '?access_token=' + params.access_token + '&publickey=' + params.publickey,
                        {
                            method: 'POST',
                            body: body,
                        },
                    )
                        .then(() => {
                            return resolve(response);
                        })
                        .catch((e) => {
                            return resolve(response);
                        });
                } else {
                    return resolve(response);
                }
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

const editProject = (publickey, data) => {
    let saveParams = () => {
        let fields = {
            config: 'config',
            description: 'description',
            carrier_name: 'name',
        };

        let params = {};
        let body = [];

        return new Promise((resolve, reject) => {
            for (let k in fields) {
                if (data[k]) {
                    body.push(fields[k] + '=' + encodeURIComponent(data[k]));
                    delete data[k];
                }
            }

            if (Object.keys(body).length === 0) return resolve();

            let request = window.config.host + 'portal/project';
            params.publickey = publickey;
            params.access_token = window.user.firebaseToken;

            Network.put(request, params, body.join('&'))
                .then(() => {
                    return resolve();
                })
                .catch((e) => {
                    return reject(e);
                });
        });
    };
    let savePrivateKey = () => {
        let fields = {
            oldPrivatekey: 'old_privatekey',
            newPrivatekey: 'new_privatekey',
        };

        let params = {};

        return new Promise((resolve, reject) => {
            for (let k in fields) {
                if (data[k]) {
                    params[fields[k]] = data[k];
                    delete data[k];
                }
            }

            if (Object.keys(params).length === 0) return resolve();

            let request = window.config.host + 'portal/project/privatekey';
            params.publickey = publickey;
            params.access_token = window.user.firebaseToken;

            Network.put(request, params)
                .then(() => {
                    return resolve();
                })
                .catch((e) => {
                    return reject(e);
                });
        });
    };
    let saveIcon = () => {
        return new Promise((resolve, reject) => {
            if (!data.icon) return resolve();

            let request = window.config.host + 'portal/project/icon/upload';
            let params = {
                access_token: window.user.firebaseToken,
                publickey: publickey,
            };

            let body = new FormData();
            body.append('icon', data.icon);

            fetch(request + '?access_token=' + params.access_token + '&publickey=' + params.publickey, {
                method: 'POST',
                body: body,
            })
                .then((response) => {
                    return resolve();
                })
                .catch((e) => {
                    return reject(e);
                });
        });
    };

    return new Promise((resolve, reject) => {
        saveParams()
            .then(() => {
                savePrivateKey()
                    .then(() => {
                        saveIcon()
                            .then(() => {
                                return resolve();
                            })
                            .catch((e) => {
                                return reject(e);
                            });
                    })
                    .catch((e) => {
                        return reject(e);
                    });
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

const deleteProject = (publickey) => {
    let request = window.config.host + 'portal/project';
    let params = {
        access_token: window.user.firebaseToken,
        publickey: publickey,
    };

    return Network.delete(request, params);
};

export {addProject, editProject, deleteProject};
