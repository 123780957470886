import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';

const loadTraffic = (uid) => {
  let request = window.config.host + 'partner/subscribers/' + uid + '/traffic';
  let params = {
    access_token: getActiveToken(),
  };

  return new Promise((resolve, reject) => {
    Network.get(request, params)
      .then((response) => {
        if (response.response && response.response.result && response.response.result === 'OK') {
          delete response.response.result;
          return resolve(response.response);
        }
        return reject();
      })
      .catch((e) => {
        return reject();
      });
  });
};

const editLimits = (uid, data) => {
  return new Promise((resolve, reject) => {
    let resetLimits = (url, params) => {
      return Network.delete(url, params)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    let request = window.config.host + 'partner/subscribers/' + uid + '/traffic';
    let params = {
      access_token: getActiveToken(),
    };

    if (data.unlimited || data.reset) {
      resetLimits(request, params).then((response) => {
        if (!response) return false;

        if (!data.unlimited && data.limit) {
          params.traffic_limit = data.limit;

          Network.post(request, params)
            .then((response) => {
              return resolve();
            })
            .catch(() => {
              return reject({ message: 'Internal server error' });
            });
        } else {
          return resolve();
        }
      });
    } else {
      params.traffic_limit = data.limit;

      Network.post(request, params)
        .then((response) => {
          return resolve();
        })
        .catch(() => {
          return reject({ message: 'Internal server error' });
        });
    }
  });
};

export { loadTraffic, editLimits };
