import React, {useContext, useState} from "react";

const initTheme = window.storage.get('css_color') === null ? "" : window.storage.get('css_color')
const ThemeContext = React.createContext(initTheme);

const useTheme = () => {
    return [useContext(ThemeContext), window.config.project === 'production']
}

const ThemeContextProvider = (props) => {
    const [theme] = useState(initTheme);
    const toggleTheme = (newTheme, callback) => {
        if(newTheme === theme){
            return
        }

        callback();
        window.location.reload();
    };
    return <ThemeContext.Provider value={{theme, toggleTheme}}>{props.children}</ThemeContext.Provider>;
}

export { ThemeContextProvider, ThemeContext, useTheme};
