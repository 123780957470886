import React, {Component} from 'react';

import {
  goTo
} from '../../../common/methods';

class Reseted extends Component {

  render() {
    return (
      <div className="verifyEmail">
        <div className={'logo business'} onClick={() => {
          goTo('')
        }}/>
        <p>{window.locales.resetLink}</p>
        <div className="register_success_actions">
          <a href="#auth/signin">{window.locales.signIn}</a>
            <a href={"/"}>{window.locales.mainPage}</a>
        </div>
      </div>
    );
  }
}

export default Reseted;