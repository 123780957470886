import React, { Component } from 'react';

import { IconCheckMark } from '../icons';

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      status: props.status ? props.status : 'off',
      disabled: props.disabled ? props.disabled : false,
    };

    this.callbacks = {};
    this.callbacks.changed = props.changed ? props.changed : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      status: props.status ? 'on' : 'off',
      disabled: props.disabled ? props.disabled : false,
    });
  }

  click() {
    if (this.state.disabled) return;
    this.setState(
      {
        status: this.state.status === 'on' ? 'off' : 'on',
      },
      () => {
        this.callbacks.changed(this.state.status);
      },
    );
  }

  render() {
    return (
      <div
        className={'form checkbox ' + this.state.theme + (this.state.disabled ? ' disabled' : '')}
      >
        <div
          className={'checkboxContainer ' + this.state.status}
          onClick={this.click.bind(this)}
          id={this.state.id}
        >
          {this.state.status === 'on' && <IconCheckMark />}
        </div>
        {this.state.label && <div className={'label'}>{this.state.label}</div>}
      </div>
    );
  }
}

export default Checkbox;
