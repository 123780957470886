import Network from '../../common/Network';
window.data.network = {};

const getNetwork = (publickey, clean) => {
  if (window.data.network[publickey] && clean) delete window.data.network[publickey];

  return new Promise((resolve) => {
    if (window.data.network[publickey]) return resolve(window.data.network[publickey]);

    loadNetwork(publickey)
      .then((response) => {
        try {
          window.data.network[publickey] = response.response.countries;
          return resolve(window.data.network[publickey]);
        } catch (e) {
          return resolve([]);
        }
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

const loadNetwork = (publickey) => {
  let request = window.config.host + 'portal/project/countries';
  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
  };

  return Network.post(request, params);
};

const setVisibility = (publickey, country, visibility) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'portal/project/country';
    let params = {
      access_token: window.user.firebaseToken,
      publickey: publickey,
      country: country,
      visibility: visibility,
    };

    return Network.put(request, params)
      .then((response) => {
        return resolve();
      })
      .catch(() => {
        return reject({ message: 'Internal server error' });
      });
  });
};

const loadPools = () => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/pools';
    let params = {
      access_token: window.data.activeUser,
    };

    Network.get(request, params)
      .then((response) => {
        try {
          return resolve(response.response.pools);
        } catch (e) {
          return resolve([]);
        }
      })
      .catch(() => {
        return reject({ message: 'Internal server error' });
      });
  });
};

const loadPoolDescription = (name) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/pool/' + name;
    let params = {
      access_token: window.data.activeUser,
    };

    Network.get(request, params)
      .then((response) => {
        try {
          return resolve(response.response);
        } catch (e) {
          return resolve(undefined);
        }
      })
      .catch(() => {
        return resolve(undefined);
      });
  });
};

const clonePool = (name) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/pool/' + name + '/clone';
    let params = {
      access_token: window.data.activeUser,
    };

    Network.post(request, params, { name: name + '_' + Date.now() })
      .then((response) => {
        try {
          return resolve(response.response);
        } catch (e) {
          return resolve(undefined);
        }
      })
      .catch(() => {
        return reject(undefined);
      });
  });
};

const deletePool = (name) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/pool/' + name;
    let params = {
      access_token: window.data.activeUser,
    };

    Network.delete(request, params)
      .then((response) => {
        try {
          return resolve(response.response);
        } catch (e) {
          return reject(undefined);
        }
      })
      .catch((e) => {
        return reject(undefined);
      });
  });
};

const updatePool = (name, body) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/pool/' + name;
    let params = {
      access_token: window.data.activeUser,
    };

    Network.patch(request, params, body)
      .then((response) => {
        try {
          return resolve(response.response);
        } catch (e) {
          return reject(undefined);
        }
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

const addPool = (name, body) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/pool/' + name;
    let params = {
      access_token: window.data.activeUser,
    };

    Network.put(request, params, body)
      .then((response) => {
        try {
          return resolve(response.response);
        } catch (e) {
          return reject(undefined);
        }
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export {
  deletePool,
  clonePool,
  loadPools,
  loadPoolDescription,
  getNetwork,
  loadNetwork,
  setVisibility,
  updatePool,
  addPool,
};
