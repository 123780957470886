export default (time) => {
  if (time < 946684800000) time = time * 1000;

  let date = new Date(Number(time));
  if (date === 'Invalid Date' || !time) return undefined;

  let monthNames = window.locales.month;

  return {
    date:
      date.getDate() +
      window.locales.daySymbol +
      ' ' +
      monthNames[date.getMonth()] +
      ' ' +
      date.getFullYear() +
      window.locales.yearSymbol,
    time:
      date.getHours() +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes().toString()),
  };
};
