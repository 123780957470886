import React, { Component } from 'react';

class Input extends Component {
  constructor(props) {
    super(props);

    this.formId = 'inputDefaultId' + ++window.formId;
    this.state = {
      id: props.id ? props.id : this.formId,
      type: props.type ? props.type : 'text',
      icon: props.icon ? props.icon : undefined,
      name: props.name ? props.name : this.formId,
      limit: props.limit ? props.limit : false,
      style: props.style ? props.style : undefined,
      theme: props.theme ? props.theme : 'default',
      value: typeof props.value !== 'undefined' ? props.value : '',
      label: props.label ? props.label : undefined,
      prefix: props.prefix ? props.prefix : undefined,
      suffix: props.suffix ? props.suffix : undefined,
      notValid: props.notValid ? props.notValid : false,
      disabled: props.disabled ? props.disabled : false,
      className: props.className ? props.className : false,
      iconPosition: props.iconPosition ? props.iconPosition : 'left',
    };

    this.callbacs = {};
    this.callbacs.onBlur = props.onBlur ? props.onBlur : () => {};
    this.callbacs.onFocus = props.onFocus ? props.onFocus : () => {};
    this.callbacs.onEnter = props.onEnter ? props.onEnter : () => {};
    this.callbacs.onChange = props.onChange ? props.onChange : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : this.formId,
      type: props.type ? props.type : 'text',
      icon: props.icon ? props.icon : undefined,
      name: props.name ? props.name : this.formId,
      limit: props.limit ? props.limit : false,
      style: props.style ? props.style : undefined,
      theme: props.theme ? props.theme : 'default',
      value: typeof props.value !== 'undefined' ? props.value : '',
      label: props.label ? props.label : undefined,
      prefix: props.prefix ? props.prefix : undefined,
      suffix: props.suffix ? props.suffix : undefined,
      notValid: props.notValid ? props.notValid : false,
      disabled: props.disabled ? props.disabled : false,
      className: props.className ? props.className : false,
      iconPosition: props.iconPosition ? props.iconPosition : 'left',
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  onBlur() {
    let state = this.state;
    state.focus = false;

    this.callbacs.onBlur();
    this.setState(state);
  }

  onFocus() {
    let state = this.state;
    state.focus = true;

    this.callbacs.onFocus();
    this.setState(state);
  }

  onChange(event) {
    let state = this.state;
    if (this.props.inputMask && !event.target.value.match(this.props.inputMask)) return;

    state.value = event.target.value;
    if (this.state.limit && event.target.value.length > this.state.limit) return;

    this.callbacs.onChange(state.value);
    this.setState(state);
  }

  onEnter(event) {
    if (event.key !== 'Enter' || this.state.disabled) {
      return;
    }

    this.callbacs.onEnter();
  }

  setFocus() {
    this.input.focus();
  }

  render() {
    let value = this.state.value;
    return (
      <div
        style={this.state.style}
        className={
          'form input ' +
          this.state.theme +
          (this.state.icon ? ' withIcon ' + this.state.iconPosition + 'IconPosition' : '') +
          (this.state.notValid ? ' notValid' : '') +
          (this.state.disabled ? ' disabled' : '') +
          (this.state.prefix ? ' hasPrefix' : '') +
          (this.state.suffix ? ' hasSuffix' : '')
        }
      >
        {this.state.prefix && (
          <div className={'prefix'}>
            {this.state.prefix}
            {this.state.label && (value === '' || typeof value === 'undefined') && (
              <div className={'label'}>{this.state.label}</div>
            )}
          </div>
        )}
        {this.state.label &&
          (value === '' || typeof value === 'undefined') &&
          !this.state.prefix && <div className={'label'}>{this.state.label}</div>}
        {this.state.icon && this.state.iconPosition === 'left' ? this.state.icon : ''}
        <input
          id={this.state.id}
          type={this.state.type}
          name={this.state.name}
          value={value}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus.bind(this)}
          onChange={this.onChange.bind(this)}
          onKeyPress={this.onEnter.bind(this)}
          autoComplete={this.props.autoComplete ? 'on' : 'new-password'}
          disabled={this.state.disabled ? 'disabled' : ''}
          ref={(input) => {
            this.input = input;
          }}
        />
        {this.state.suffix && <div className={'suffix'}>{this.state.suffix}</div>}
        {this.state.icon && this.state.iconPosition === 'right' ? this.state.icon : ''}
      </div>
    );
  }
}

export default Input;
