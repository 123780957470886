import styled from "styled-components";
import React from "react";
import ArrowDown from "./ArrowDown";
import ArrowUp from "./ArrowUp";
import {IPlans} from "../../interfaces";

export const ButtonExpand = () =>
    <ButtonNoBackgroundContainer>
        <TextButton>{window.locales.showFeatures}</TextButton>
        <ArrowDown/>
    </ButtonNoBackgroundContainer>;

export const ButtonHide = () =>
    <ButtonNoBackgroundContainer>
        <TextButton>{window.locales.hideFeatures}</TextButton>
        <ArrowUp/>
    </ButtonNoBackgroundContainer>;

export const PlansContainer = styled.div`
  margin-top: 32px;
  display: grid;
  width: 100%;
  row-gap: 32px;

  @media only screen and (min-width: 768px) {
    row-gap: 40px;
    margin-top: 40px;
  }

  @media only screen and (min-width: 1024px) {
    grid-auto-flow: column;
    column-gap: 40px;
  }


  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const HeaderContainer = styled.div`
  height: 100%;
  display: grid;
`;

export const StickContainer = styled.div<{ isProduction: boolean, backgroundTitle?: string }>`
  font-size: 24px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  color: #FFFFFF;
  background: ${props => props.backgroundTitle ?? (props.isProduction ? '#00A2FF' : '#ffa823')};
`;

export const Condition = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  display: grid;
  row-gap: 16px;
`;

export const UpgradeText = styled.div`
  font-size: 24px;
`;

export const ButtonNoBackgroundContainer = styled.div`
  padding: 14px 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BodyContainer = styled.div`
  padding-bottom: 10px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PlanTitle = styled.div`
  text-transform: uppercase;
`;

export const TextButton = styled.div`
  font-size: 16px;
  margin-right: 6px;
  text-transform: uppercase;

  @media only screen and (min-width: 1024px) {
    text-transform: unset;
  }
`;

export const AbilityItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0 18px 0;
`;

export const TextOption = styled.div`
`;

export const Access = styled.div`
  text-align: end;
  display: grid;
  grid-auto-flow: column;
  margin-left: 19px;
  column-gap: 10px;
`;

export const MainContent = styled.div`
  padding: 32px 16px 48px;
  display: grid;
  height: 100%;
  row-gap: 52px;

  @media only screen and (min-width: 768px) {
    padding: 32px 48px 48px;
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  display: grid;
  height: 100%;
`;

export const Topic = styled.div`
  padding: 14px 0 14px 40px;
  font-size: 18px;
  margin-top: 18px;

  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
`;

export const ItemsContainer = styled.div`
  padding: 0 40px 0 40px;
`;

export const PlanContainer = styled.div<{ nameTheme: string, styleOptions?: IPlans }>`
  background: ${props => props.styleOptions?.background ?? (props.nameTheme ? '#FFFFFF' : '#555F6F')};
  border-radius: ${props => props.styleOptions?.background ?? '6px'};
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: ${(props) => props.styleOptions?.border ?? (props.nameTheme === 'light' ? '1px solid #B2B9C4' : 'unset')};
  color: ${props => props.styleOptions?.textColor ?? (props.nameTheme ? '#000000' : '#FFFFFF')};

  ${Topic} {
    background-color: ${({styleOptions}) => styleOptions?.backgroundHeader};
  }

  display: flex;
  flex-direction: column;

  path {
    fill: ${({styleOptions}) => styleOptions?.textColor};
  }
`;