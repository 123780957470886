import React, {Component} from 'react';

import {
  IconSettings
} from '../../../common/icons';

import {
  Input,
  Checkbox
} from '../../../common/form';

import {
  editLimits
} from '../../../components/Traffic';

import Modal from '../../../common/modal';

class Limit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      traffic: props.traffic,
      disabled: false,
      message: '',
      form: {
        limit: false
      }
    };

    this.form = {
      limit: props.traffic.traffic_limit ? props.traffic.traffic_limit : '',
      reset: 'on',
      unlimited: 'off'
    };

    this.reload = props.reload;
    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.reload = props.reload;
    this.form = {
      limit: props.traffic.traffic_limit ? props.traffic.traffic_limit : '',
      reset: 'on',
      unlimited: 'off'
    };

    this.setState({
      user: props.user,
      traffic: props.traffic
    });
  }

  fieldChanged(key, value) {
    this.form[key] = value;
  }

  formSubmit() {
    if (this.state.disabled) return;
    let state = this.state;
    state.form = {
      limit: false
    };
    let valid = true;

    if (this.form.limit === '' && this.form.unlimited === 'off') {
      valid = false;
      state.message = window.locales.fillInFields;
      state.form.limit = true;
    } else if (this.form.unlimited === 'off' && !this.form.limit.toString().match(/^[0-9]+$/)) {
      valid = false;
      state.message = 'Set correct value.';
      state.form.limit = true;
    }

    if (!valid) {
      return this.setState(state)
    }

    state.disabled = true;
    this.setState(state, () => {
      editLimits(this.state.user.id, {
        limit: this.form.limit,
        unlimited: !!(this.form.unlimited === 'on'),
        reset: !!(this.form.reset === 'on')
      }).then(() => {
        this.setState({
          disabled: false
        }, () => {
          this.modal.hide();
          this.reload();
        });
      }).catch((e) => {
        this.setState({
          message: e.message,
          disabled: false
        })
      });
    });
  }

  hideModal() {
    this.modal.hide();
  }

  render() {
    return (
      <div style={{marginLeft: '15px'}}>
        <IconSettings theme={'blue'} onClick={() => {
          this.modal.show()
        }}/>
        <Modal
          title={window.locales.editLimit}
          onRef={ref => {
            this.modal = ref;
          }}
          message={this.state.message}
          visibility={visible => {
            if (visible) {
              this.input.setFocus()
            }
          }}
          cancel={{
            label: window.locales.cancel,
            click: this.hideModal.bind(this)
          }}
          submit={{
            label: window.locales.editLimit,
            click: this.formSubmit.bind(this)
          }}
        >
          <Input
            label={window.locales.limit}
            theme={'dark'}
            notValid={this.state.form.limit}
            value={this.form.limit}
            disabled={this.state.disabled}
            onRef={ref => {
              this.input = ref;
            }}
            onChange={this.fieldChanged.bind(this, 'limit')}
            onEnter={this.formSubmit.bind(this)}
          />
          <Checkbox theme={'blue'} disabled={this.state.disabled} status={this.form.reset} label={window.locales.trafficReset}
                    changed={this.fieldChanged.bind(this, 'reset')}/>
          <Checkbox theme={'blue'} disabled={this.state.disabled} status={this.form.unlimited}
                    label={window.locales.setTrafficUnlimited} changed={this.fieldChanged.bind(this, 'unlimited')}/>
        </Modal>
      </div>
    );
  }
}

export default Limit;
