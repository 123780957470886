import React, { Component } from 'react';

import Modal from '../../common/modal';

import { Input, Select2, Textarea } from '../../common/form';

import { updatePool } from '../../components/Network';

import { IconClose } from '../../common/icons';

import { deleteDuplicateInObject } from '../../common/methods';

class EditPool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      i: 0,
      form: {
        name: false,
        selector: false,
      },
      message: '',
    };
  }

  componentDidMount() {
    if (this.props.onMount)
      this.props.onMount((pool) => {
        try {
          this.form = JSON.parse(JSON.stringify(pool));
          this.originalName = this.form.name;
          this.originalDescription = this.form.description;
          this.originalPermissions = this.form.permissions
            ? JSON.parse(JSON.stringify(this.form.permissions))
            : [];
          this.setState(
            {
              i: this.state.i + 1,
            },
            () => {
              this.modal.show();
            },
          );
        } catch (e) {}
      });
  }

  removePermission(k) {
    this.form.permissions.splice(k, 1);
    this.setState({
      i: this.state.i + 1,
    });
  }

  render() {
    if (!this.form) return '';

    let permissionsOptions = [
      ['can_read', 'Read'],
      ['can_use', 'Use'],
      ['can_write', 'Wite'],
    ];

    let permissions = [];
    if (this.form.permissions) {
      for (let k in this.form.permissions) {
        let v = [];
        for (let j in this.form.permissions[k]) {
          if (j === 'subject' || !this.form.permissions[k][j]) continue;
          v.push(j);
        }
        permissions.push(
          <div className={'modalPoolPermissionContainer'} key={k}>
            <Input
              value={this.form.permissions[k].subject}
              onChange={this.permissionNameChanged.bind(this, k)}
              label={window.locales.publickey}
            />
            <Select2
              onChange={this.permissionChanges.bind(this, k)}
              options={permissionsOptions}
              value={v}
              isMulti={true}
              label={'Permissions'}
              theme={'dark'}
              style={{ width: '407px' }}
            />
            <IconClose theme={'blue'} onClick={this.removePermission.bind(this, k)} />
          </div>,
        );
      }
    }

    let selector = this.form.selector;
    if (typeof selector === 'object') {
      selector = JSON.stringify(selector);
    }

    return (
      <Modal
        title={'Edit Pool'}
        onRef={(ref) => {
          this.modal = ref;
        }}
        cancel={{
          label: window.locales.cancel,
          click: this.hideModal.bind(this),
        }}
        submit={{
          label: 'Save changes',
          click: this.saveChanges.bind(this),
        }}
        message={this.state.message}
      >
        <div style={{ marginBottom: '5px' }}>Pool name</div>
        <Input
          label={'Pool name'}
          value={this.form.name}
          onChange={this.fieldChanged.bind(this, 'name')}
          notValid={this.state.form.name}
          inputMask={new RegExp(/^[a-zA-Z0-9_]+$/)}
        />

        <div style={{ marginBottom: '5px' }}>Permissions</div>
        {permissions}
        <span onClick={this.addPermission.bind(this)} className={'addPermissionToModal'}>
          Add permission
        </span>
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>Description</div>
        <Textarea
          value={this.form.description}
          onChange={this.fieldChanged.bind(this, 'description')}
          style={{ marginTop: '10px' }}
        />
      </Modal>
    );
  }

  /*<div style={{marginBottom:"5px"}}>Pool selector</div>
      <Input disabled={true} label={"Pool selector"} value={selector} notValid={this.state.form.selector}/>*/

  hideModal() {
    this.modal.hide();
  }

  saveChanges() {
    let state = this.state;
    state.message = '';
    state.form = {
      name: false,
      selector: false,
    };

    if (this.form.name === '') {
      state.form.name = true;
      state.message = window.locales.fillInFields;
      return this.setState(state);
    }

    let body = {};
    if (this.form.name !== this.originalName) {
      body.name = this.form.name;
    }

    if (this.form.description !== this.originalDescription) {
      body.description = this.form.description;
    }

    this.form.permissions = deleteDuplicateInObject(this.form.permissions, 'subject');

    if (this.form.permissions.length !== 0 || this.originalPermissions.length !== 0) {
      let addP = [];
      for (let k1 in this.form.permissions) {
        let p1 = this.form.permissions[k1];
        if (p1.subject.trim() === '') continue;
        let found = false;

        for (let k2 in this.originalPermissions) {
          let p2 = this.originalPermissions[k2];
          if (p1.subject !== p2.subject) continue;
          found = true;
          let changed = false;

          if (p1.can_read !== p2.can_read) changed = true;
          else if (p1.can_write !== p2.can_write) changed = true;
          else if (p1.can_use !== p2.can_use) changed = true;

          if (changed) addP.push(p1);
        }

        if (!found) addP.push(p1);
      }

      let delP = [];
      for (let k1 in this.originalPermissions) {
        let p1 = this.originalPermissions[k1];
        let found = false;

        for (let k2 in this.form.permissions) {
          let p2 = this.form.permissions[k2];
          if (p1.subject === p2.subject) found = true;
        }

        if (!found) delP.push(p1.subject);
      }

      if (addP.length > 0) body.add_permissions = addP;
      if (delP.length > 0) body.del_permissions = delP;
    }

    if (Object.keys(body).length === 0) return;

    window.Loading.show();
    this.setState(state, () => {
      updatePool(this.originalName, body)
        .then(() => {
          window.Loading.hide();
          this.props.parrentComponentMount();
        })
        .catch((e) => {
          if (e.message.indexOf('Error 1062: Duplicate entry') !== -1) {
            state.message = 'Pool name already taken';
          } else {
            state.message = e.message;
          }
          this.setState(state, window.Loading.hide);
        });
    });
  }

  fieldChanged(k, v) {
    this.form[k] = v;
  }

  permissionNameChanged(k, v) {
    this.form.permissions[k].subject = v;
  }

  permissionChanges(k, v) {
    this.form.permissions[k] = {
      subject: this.form.permissions[k].subject,
      can_read: false,
      can_write: false,
      can_use: false,
    };
    if (v.length > 0) {
      for (let k1 in v) {
        this.form.permissions[k][v[k1]] = true;
      }
    }
  }

  addPermission() {
    if (!this.form.permissions) this.form.permissions = [];

    this.form.permissions.push({
      subject: '',
      can_read: false,
      can_write: false,
      can_use: false,
    });

    this.setState({
      i: this.state.i + 1,
    });
  }
}

export default EditPool;
