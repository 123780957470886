import React, { Component } from 'react';

class Network extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 24,
      height: props.height ? props.height : 22,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 24,
      height: props.height ? props.height : 22,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 network ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M19.9 13.68c-.166 0-.328.013-.488.032-1.144-.67-2.194-1.7-2.97-3.033-.781-1.345-1.156-2.78-1.167-4.115a4.08 4.08 0 0 0 .827-2.462 4.102 4.102 0 0 0-8.204 0c0 .9.295 1.731.787 2.408 0 1.35-.375 2.806-1.167 4.169-.774 1.33-1.82 2.358-2.96 3.028a4.102 4.102 0 1 0-.456 8.177c1.662 0 3.09-.992 3.734-2.414 1.164-.676 2.602-1.082 4.17-1.082 1.56 0 2.992.403 4.154 1.074a4.1 4.1 0 0 0 7.84-1.68 4.1 4.1 0 0 0-4.1-4.101zM7.826 16.074a4.091 4.091 0 0 0-.426-.72c.02-1.32.397-2.735 1.169-4.062.804-1.382 1.903-2.437 3.096-3.104.11.009.221.017.334.017.1 0 .199-.008.297-.015 1.192.667 2.29 1.72 3.093 3.102.783 1.345 1.158 2.78 1.169 4.117-.15.21-.28.436-.39.674-1.162.674-2.599 1.08-4.163 1.08-1.572 0-3.014-.41-4.18-1.09z"
          />
        </svg>
      </div>
    );
  }
}

export default Network;
