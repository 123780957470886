import React, {Component} from 'react';

import {
    loadUsers,
    addUser,
    deleteUser,
    getRoles,
    setRole
} from '../../../components/Access';

import {
    IconPen,
    IconPlus,
    IconDelete
} from '../../../common/icons';

import {
    Empty,
    Input,
    Select,
    Checkbox
} from '../../../common/form';

import Modal from '../../../common/modal';

import Table from '../../../common/table';

import {
    editProject,
} from '../../../components/Project';

class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined,
            permissions: props.permissions,
            activeProject: props.activeProject,
            form: {
                email: false,
                role: false
            },
            message: '',
            disabled: false,
            edit: !(props.permissions.indexOf('portal_project_access_update') === -1)
        };

        this.form = {
            email: '',
            role: 'SUPPORT'
        };

        this.i = 0;
        this.modal = {};
        this.dump = JSON.stringify(props.dump);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        let state = this.state;

        state.data = undefined;
        state.permissions = props.permissions;
        state.activeProject = props.activeProject;
        state.edit = !(props.permissions.indexOf('portal_project_access_update') === -1);

        this.componentDidMount(state);
    }

    componentDidMount(state, clearCache) {
        return new Promise((resolve) => {
            window.Loading.show();
            state = state ? state : this.state;

            loadUsers(clearCache).then(users => {
                getRoles().then(roles => {
                    state.data = users;
                    state.roles = roles;

                    this.setState(state, () => {
                        window.Loading.hide();
                        return resolve();
                    });
                });
            });
        });
    }

    showModal(modal, data) {
        if (!this.state.edit) return;
        let state = this.state;
        state.i++;
        state.message = '';
        state.disabled = false;

        this.data = data;

        switch (modal) {
            case 'delete':
                this.setState(state, () => {
                    this.modal.delete.show();
                });
                break;
            case 'edit':
                this.form = {
                    email: this.state.data[this.data.id].email,
                    role: this.state.data[this.data.id].role.name
                };

                this.setState(state, () => {
                    this.modal.edit.show();
                });
                break;
            case 'add':
                this.form = {
                    email: '',
                    role: 'SUPPORT'
                };

                this.setState(state, () => {
                    this.modal.add.show();
                });
                break;
            default:
                break;
        }
    }

    hideModal(modal) {
        this.modal[modal].hide();
    }

    deleteMethod(email, isFallback) {
        if (!this.state.edit) return;

        return new Promise((resolve, reject) => {
            window.Loading.show();
            email = email ? email : this.state.data[this.data.id].email;

            this.setState({
                disabled: true,
                message: ''
            }, () => {
                deleteUser(email).then(() => {
                    this.deleteAuthMethod(email).then((config) => {
                        let project = this.state.activeProject;
                        project.config = config ? JSON.stringify(config) : project.config;
                        this.setState({
                            message: '',
                            disabled: false
                        }, () => {
                            window.Loading.hide();
                            this.modal.delete.hide();
                            if (isFallback) {
                                return resolve();
                            }
                            this.componentDidMount(undefined, true);
                        });
                    });
                }).catch((e) => {
                    if (isFallback) {
                        return reject(e);
                    }

                    window.error(e);
                    this.setState({
                        disabled: false,
                        message: e.message
                    }, window.Loading.hide);
                });
            });
        });
    }

    deleteAuthMethod(email) {
        return new Promise(resolve => {
            let config = {};
            try {
                config = JSON.parse(this.state.activeProject.config);
                let app = config.application;

                for (let k in app) {
                    if (!app[k] || !app[k].support) continue;

                    if (app[k].support.indexOf(email) !== -1) app[k].support.splice(app[k].support.indexOf(email), 1);
                    if (app[k].support.length === 0) delete app[k].support;
                    if (Object.keys(app[k]).length === 0) delete app[k];
                }
            } catch (e) {
                return resolve();
            }

            editProject(this.state.activeProject.publickey, {config: JSON.stringify(config)}).then(() => {
                return resolve(config);
            }).catch(() => {
                return resolve();
            });
        });
    }

    fieldChanged(key, value) {
        this.form[key] = value;
    }

    addSubmit() {
        if (!this.state.edit) return;
        let state = this.state;
        state.form = {
            role: false,
            email: false
        };
        state.message = '';
        state.disabled = false;

        let email = this.form.email.trim();

        if (!email.match(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)) {
            state.message = 'Please enter valid email';
            state.form.email = true;
            return this.setState(state);
        }

        window.Loading.show();
        state.disabled = true;
        this.setState(state, () => {
            addUser(email.trim()).then(() => {
                this.setState({
                    disabled: false,
                    message: ''
                }, () => {
                    this.saveCheckbox(email).then((config) => {
                        let project = this.state.activeProject;
                        project.config = config ? JSON.stringify(config) : project.config;

                        this.setState({
                            disabled: false,
                            activeProject: project
                        }, () => {
                            if (this.form.role.trim() === '') {
                                this.userAdded(email);
                            } else {
                                setRole(email, this.form.role).then(() => {
                                    this.userAdded(email);
                                }).catch((e) => {
                                    window.error(e);
                                    this.userAdded(email);
                                });
                            }
                        });
                    });
                });
            }).catch((e) => {
                window.error(e);
                this.setState({
                    disabled: false,
                    message: e.message
                }, window.Loading.hide);
            });
        });
    }

    userAdded(email) {
        let password = '';

        for (let i = 0; i < 8; i++) {
            password += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        if (!window.fb.membersFb) {
            window.fb.membersFb = window.fb.default.initializeApp({
                apiKey: window.config.firebaseKey,
                databaseURL: window.config.firebasedatabaseURL
            }, 'membersFb');
        }

        window.fb.membersFb.auth().createUserWithEmailAndPassword(email, password).then((response) => {
            window.fb.membersFb.database().ref('/users/' + window.fb.membersFb.auth().currentUser.uid).set({
                email: email,
                first: true
            }).then(() => {
                window.fb.membersFb.auth().useDeviceLanguage();
                window.fb.membersFb.auth().sendPasswordResetEmail(email,
                    {url: 'https://business.anchorfree.com/#auth/signin'}).then((r) => {
                    window.Loading.hide();
                    this.modal.add.hide();
                    this.componentDidMount(undefined, true);
                }).catch((e) => {
                    window.Loading.hide();
                    this.modal.add.hide();
                    this.componentDidMount(undefined, true);
                });
            }).catch(() => {
                window.fb.membersFb.auth().currentUser.delete();

                this.deleteMethod(email, true).then(() => {
                    this.setState({
                        disabled: false,
                        message: 'Internal server window.error, please try again'
                    }, window.Loading.hide);
                }).catch((e) => {
                    window.error(e);
                    this.setState({
                        disabled: false,
                        message: e.message
                    }, window.Loading.hide);
                });
            });
        }).catch((e) => {
            if (e.code !== 'auth/email-already-in-use') {
                this.deleteMethod(email, true).then(() => {
                    this.setState({
                        disabled: false,
                        message: 'Firebase window.error'
                    }, window.Loading.hide);
                }).catch((e) => {
                    window.error(e);
                    this.setState({
                        disabled: false,
                        message: e.message
                    }, window.Loading.hide);
                });
            } else {
                window.Loading.hide();
                this.modal.add.hide();
                return this.componentDidMount(undefined, true);
            }
        });
    }

    editSubmit() {
        if (!this.state.edit) return;
        window.Loading.show();
        this.setState({
            message: '',
            disabled: true
        }, () => {
            setRole(this.form.email, this.form.role).then(() => {
                this.saveCheckbox(this.form.email).then((config) => {
                    this.componentDidMount(undefined, true).then(() => {
                        window.Loading.hide();
                        this.modal.edit.hide();

                        let project = this.state.activeProject;
                        project.config = config ? JSON.stringify(config) : project.config;

                        this.setState({
                            disabled: false,
                            activeProject: project
                        });
                    });
                });
            }).catch((e) => {
                window.error(e);
                this.setState({
                    disabled: false,
                    message: e.message
                }, window.Loading.hide);
            });
        });
    }

    saveCheckbox(email) {
        return new Promise((resolve) => {
            if (!this.form.supportIos && !this.form.supportAndroid && !this.form.supportMacos && !this.form.supportWindows) return resolve();

            let config = {};
            try {
                config = JSON.parse(this.state.activeProject.config)
            } catch (e) {
            }

            config = this.checkSupport(config, this.form.supportIos, 'ios', email);
            config = this.checkSupport(config, this.form.supportAndroid, 'android', email);
            config = this.checkSupport(config, this.form.supportMacos, 'macos', email);
            config = this.checkSupport(config, this.form.supportWindows, 'windows', email);

            editProject(this.state.activeProject.publickey, {config: JSON.stringify(config)}).then(() => {
                return resolve(config);
            }).catch(() => {
                return resolve();
            });
        });
    }

    checkSupport(config, form, field, email) {
        if (!config.application) {
            config.application = {};
        }

        if (!config.application[field]) {
            config.application[field] = {};
        }

        if (!config.application[field].support) {
            config.application[field].support = [];
        }

        if (form === 'on' && config.application[field].support.indexOf(email) === -1) {
            config.application[field].support.push(email);
        } else if (form === 'off' && config.application[field].support.indexOf(email) !== -1) {
            config.application[field].support.splice(config.application[field].support.indexOf(email), 1)
        }

        if (Object.keys(config.application[field].support).length === 0) delete config.application[field].support;
        if (Object.keys(config.application[field]).length === 0) delete config.application[field];
        if (Object.keys(config.application).length === 0) delete config.application;

        return config;
    }

    fillCheckboxData() {
        let config = {};

        this.supportIos = 'off';
        this.supportAndroid = 'off';
        this.supportMacos = 'off';
        this.supportWindows = 'off';

        try {
            config = JSON.parse(this.state.activeProject.config);

            try {
                this.supportIos = config.application.ios.support.indexOf(this.form.email) !== -1 ? 'on' : 'off';
            } catch (e) {
            }

            try {
                this.supportAndroid = config.application.android.support.indexOf(this.form.email) !== -1 ? 'on' : 'off';
            } catch (e) {
            }

            try {
                this.supportMacos = config.application.macos.support.indexOf(this.form.email) !== -1 ? 'on' : 'off';
            } catch (e) {
            }

            try {
                this.supportWindows = config.application.windows.support.indexOf(this.form.email) !== -1 ? 'on' : 'off';
            } catch (e) {
            }
        } catch (e) {
        }
    }

    render() {
        if (!this.state.data) return <div/>;
        this.fillCheckboxData();

        let currentRole = 'SUPPORT';
        try {
            currentRole = this.state.activeProject.current_user_role.name
        } catch (e) {
        }

        let options = [];

        for (let k in this.state.roles) {
            if (currentRole === 'ADMIN' && this.state.roles[k].name === 'OWNER') continue;

            options.push([this.state.roles[k].name, window.locales.roles[this.state.roles[k].name].description]);
        }

        let head = [
            [window.locales.email],
            [window.locales.role, {width: 75}],
            [window.locales.clientSupport, {width: 190}],
        ];

        if (this.state.edit) {
            head.push(['', {width: 25}]);
            head.push(['', {width: 25}]);
        }

        let config = {};
        try {
            let temp = JSON.parse(this.state.activeProject.config).application;
            for (let k in temp) {
                if (!temp[k].support) continue;
                for (let j in temp[k].support) {
                    let email = temp[k].support[j];
                    if (!config[email]) {
                        config[email] = [];
                    }

                    config[email].push(<img alt="" key={++this.i} src={'/static/assets/icons/' + k + '.png'}/>);
                }
            }
        } catch (e) {
        }

        let body = [];
        for (let k in this.state.data) {
            let r = this.state.data[k];

            body.push([
                [r.email],
                [window.locales.roles[r.role.name].name],
                [config[r.email]]
            ]);

            if (this.state.edit && currentRole === 'OWNER') {
                body[(body.length - 1)].push([<IconPen theme={'blue'}
                                                       onClick={this.showModal.bind(this, 'edit', {id: k})}/>, {center: true}]);
                body[(body.length - 1)].push([<IconDelete theme={'blue'}
                                                          onClick={this.showModal.bind(this, 'delete', {id: k})}/>, {center: true}]);
            } else if (this.state.edit && currentRole === 'ADMIN' && r.role.name !== 'OWNER') {
                body[(body.length - 1)].push([<IconPen theme={'blue'}
                                                       onClick={this.showModal.bind(this, 'edit', {id: k})}/>, {center: true}]);
                body[(body.length - 1)].push([<IconDelete theme={'blue'}
                                                          onClick={this.showModal.bind(this, 'delete', {id: k})}/>, {center: true}]);
            } else if (this.state.edit) {
                body[(body.length - 1)].push(['']);
                body[(body.length - 1)].push(['']);
            }
        }

        let content = body.length > 0 ? <Table head={head} body={body}/> :
            <Empty row1={window.locales.noAuthentifications}/>;
        return (
            <div className={'settingsContentContainer'}>
                {this.state.edit &&
                <div style={{position: 'absolute', right: '0', top: '16px'}}><IconPlus theme={'blue'}
                                                                                       label={window.locales.addMember}
                                                                                       onClick={this.showModal.bind(this, 'add')}/>
                </div>}
                {content}
                <Modal
                    title={window.locales.changeRole}
                    onRef={ref => {
                        this.modal.edit = ref;
                    }}
                    message={this.state.message}
                    cancel={{
                        label: window.locales.cancel,
                        click: this.hideModal.bind(this, 'edit')
                    }}
                    submit={{
                        label: window.locales.saveChanges,
                        click: this.editSubmit.bind(this)
                    }}
                >
                    <Select
                        label={window.locales.role}
                        notValid={this.state.form.role}
                        value={this.form.role}
                        disabled={this.state.disabled}
                        options={options}
                        onChange={this.fieldChanged.bind(this, 'role')}
                        onEnter={this.editSubmit.bind(this)}
                    />
                    {currentRole !== 'SUPPORT' &&
                    <div className={'checkboxSupportTitle'}>{window.locales.checkboxSupportTitle}</div>}
                    {currentRole !== 'SUPPORT' && <div className={'settingsCheckboxContainer'}>
                        <Checkbox label={window.locales.supportIos} theme={'blue'} status={this.supportIos}
                                  changed={this.fieldChanged.bind(this, 'supportIos')}/>
                        <Checkbox label={window.locales.supportAndroid} theme={'blue'} status={this.supportAndroid}
                                  changed={this.fieldChanged.bind(this, 'supportAndroid')}/>
                        <Checkbox label={window.locales.supportMacos} theme={'blue'} status={this.supportMacos}
                                  changed={this.fieldChanged.bind(this, 'supportMacos')}/>
                        <Checkbox label={window.locales.supportWindows} theme={'blue'} status={this.supportWindows}
                                  changed={this.fieldChanged.bind(this, 'supportWindows')}/>
                    </div>}
                </Modal>
                <Modal
                    title={window.locales.addMember}
                    onRef={ref => {
                        this.modal.add = ref;
                    }}
                    message={this.state.message}
                    visibility={visible => {
                        if (visible) {
                            this.addInput.setFocus()
                        }
                    }}
                    cancel={{
                        label: window.locales.cancel,
                        click: this.hideModal.bind(this, 'add')
                    }}
                    submit={{
                        label: window.locales.addMember,
                        click: this.addSubmit.bind(this)
                    }}
                >
                    <Input
                        onRef={ref => {
                            this.addInput = ref;
                        }}
                        label={window.locales.email}
                        notValid={this.state.form.email}
                        value={this.form.email}
                        disabled={this.state.disabled}
                        onChange={this.fieldChanged.bind(this, 'email')}
                        onEnter={this.addSubmit.bind(this)}
                    />
                    <Select
                        label={window.locales.role}
                        notValid={this.state.form.role}
                        value={this.form.role}
                        disabled={this.state.disabled}
                        options={options}
                        onChange={this.fieldChanged.bind(this, 'role')}
                        onEnter={this.editSubmit.bind(this)}
                    />
                    {currentRole !== 'SUPPORT' &&
                    <div className={'checkboxSupportTitle'}>{window.locales.checkboxSupportTitle}</div>}
                    {currentRole !== 'SUPPORT' && <div className={'settingsCheckboxContainer'}>
                        <Checkbox label={window.locales.supportIos} theme={'blue'} status={this.supportIos}
                                  changed={this.fieldChanged.bind(this, 'supportIos')}/>
                        <Checkbox label={window.locales.supportAndroid} theme={'blue'} status={this.supportAndroid}
                                  changed={this.fieldChanged.bind(this, 'supportAndroid')}/>
                        <Checkbox label={window.locales.supportMacos} theme={'blue'} status={this.supportMacos}
                                  changed={this.fieldChanged.bind(this, 'supportMacos')}/>
                        <Checkbox label={window.locales.supportWindows} theme={'blue'} status={this.supportWindows}
                                  changed={this.fieldChanged.bind(this, 'supportWindows')}/>
                    </div>}
                </Modal>
                <Modal
                    title={window.locales.deleteMember}
                    onRef={ref => {
                        this.modal.delete = ref;
                    }}
                    message={this.state.message}
                    cancel={{
                        label: window.locales.cancel,
                        click: this.hideModal.bind(this, 'delete')
                    }}
                    submit={{
                        label: window.locales.deleteMember,
                        click: this.deleteMethod.bind(this)
                    }}
                >
                    <div className={'bodyText'}>
                        {window.locales.deleteUserConfirmation}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default index;
