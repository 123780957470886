import styled from "styled-components";

export const Table = styled.table<{nameTheme: string}>`
    margin-top: 25px;
    width: 100%;
    font-size: 13px;
    border-collapse: collapse;
    color: ${props => props.nameTheme ? '#000000' : '#FFFFFF'};
    td{
      padding: 10px;
    }
    td:nth-child(1) {  
      width: 15%;
    }
`

export const HeadTable = styled.thead`
    background: rgba(160, 170, 195, 0.17);
    td{
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.6);
    }
`;

export const InfoText = styled.span`
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -50%;
`;

export const InvoiceStatusCell = styled.div<{isPaid: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover{
   ${InfoText}{
      visibility: visible;
    }
  };
  color: ${props => props.isPaid ? '#FFFFFF' : '#FF0001'};
`;

export const InvoiceRange = styled.tr`
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    td{
        font-size: 14px;
        max-width: 100px;
        padding: 20px;
        text-transform: capitalize;
    }
`;

export const SortCell = styled.td`
  cursor: pointer;
  &::after{
    content: " ";
    position: relative;
    top: 10px;
    left: 10%;
    border-width: 6px;
    border-style: solid;
    border-color: rgba(255,255,255,0.6) transparent transparent transparent
  }
    :active{
      &::after{
        border-color: #2ea6f8 transparent transparent transparent
      }
    }
`;

export const Symbol = styled.span`
    margin-right: 3px;
    text-transform: uppercase;
`;