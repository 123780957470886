import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import {changeCss} from '../../common/methods';

const SAVED_TOKENS = 'Storage.SavedTokens';

let getEnvironments = (uid) => {
    return new Promise((resolve) => {
        window.fb.default
            .database()
            .ref('/access/' + uid + '/environments')
            .once('value')
            .then(function (result) {
                result = result && result.val ? result.val() : undefined;

                let projects = [['production', 'backend.northghost.com']];

                if (result) {
                    for (let k in result) {
                        let r = result[k];
                        for (let j in r) {
                            let r1 = r[j];

                            if (j === 'production') continue;
                            projects.push([j, r1]);
                        }
                    }
                }

                return resolve(projects);
            });
    });
};

let getInfoFromFirebase = (uid) => {
    return new Promise((resolve) => {
        window.fb.default
            .database()
            .ref('/users/' + uid)
            .once('value')
            .then(function (result) {
                result = result && result.val ? result.val() : {};
                return resolve(result);
            });
    });
};

export default () => {
    window.user = {
        authorized: false,
        email: '',
        verified: false,
        firebaseToken: '',
        infoFromFirebase: {},
        projects: [['production', 'backend.northghost.com']],
    };

    window.fb = {
        default: firebase,
        custom: {},
    };

    return new Promise((resolve) => {
        let tokenInterval;
        changeCss();

        window.fb.default.initializeApp({
            apiKey: window.config.firebaseKey,
            databaseURL: window.config.firebasedatabaseURL,
        });

        window.fb.membersFb = firebase.initializeApp(
            {
                apiKey: window.config.firebaseKey,
                databaseURL: window.config.firebasedatabaseURL,
            },
            'membersFb',
        );

        window.fb.default.auth().useDeviceLanguage();

        window.fb.default.auth().onAuthStateChanged((r) => {
            if (window.fb.default.auth().currentUser) {
                let updateToken = () => {
                    window.fb.default
                        .auth()
                        .currentUser.getIdToken(true)
                        .then((token) => {
                            let currentUser = firebase.auth().currentUser;
                            if (!currentUser.emailVerified) {
                                window.storage.set(SAVED_TOKENS, {});
                                window.signInResponseFromFB = true;
                                return resolve();
                            }

                            getEnvironments(currentUser.uid).then((projects) => {
                                getInfoFromFirebase(currentUser.uid).then((infoFromFirebase) => {
                                    window.user.uid = currentUser.uid;
                                    window.user.authorized = true;
                                    window.user.name = currentUser.displayName
                                        ? currentUser.displayName
                                        : currentUser.email;
                                    window.user.verified = currentUser.emailVerified;
                                    window.user.firebaseToken = token;
                                    window.user.projects = projects;
                                    window.user.infoFromFirebase = infoFromFirebase ? infoFromFirebase : {};
                                    if (!window.user.infoFromFirebase.company_code)
                                        window.user.infoFromFirebase.company_code = '';

                                    let project = window.storage.get('project') || 'production';
                                    let host = projects[0][1];
                                    for (let k in projects) {
                                        if (projects[k][0] === project) host = projects[k][1];
                                    }

                                    // FIXME: ugly reverse conversion, need a better solution to switch environments
                                    switch (host) {
                                        case 'api-prod-partner-us-west-2.northghost.com':
                                        case 'backend.northghost.com':
                                            window.config.host = '/api/prod/';
                                            break;
                                        case 'api-stage-partner-us-west-2.northghost.com':
                                            window.config.host = '/api/stage/';
                                            break;
                                        case 'api-dev-partner-us-west-2.northghost.com':
                                        case 'api-dev.northghost.com':
                                            window.config.host = '/api/dev/';
                                            break;
                                        default:
                                            window.config.host = '/api/' + host + '/';
                                            break;
                                    }

                                    //window.config.host = 'https://' + host + '/';
                                    window.project = project;

                                    window.signInResponseFromFB = true;
                                    resolve();
                                });
                            });
                        });
                };

                if (tokenInterval) {
                    clearInterval(tokenInterval);
                }

                tokenInterval = setInterval(() => {
                    updateToken();
                }, 50 * 60 * 1000);

                updateToken();
            } else {
                if (tokenInterval) clearInterval(tokenInterval);
                window.storage.set(SAVED_TOKENS, {});
                window.signInResponseFromFB = true;
                resolve();
            }
        });
    });
};
