import React, { Component } from 'react';

import Modal from '../../common/modal';

import { Input, Textarea } from '../../common/form';

import { updatePool } from '../../components/Network';

import { jsonBeautifier } from '../../common/methods';

class EditGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      i: 0,
      form: {
        name: false,
        request_selector: false,
        server_selector: false,
        priority: false,
      },
      message: '',
    };
  }

  componentDidMount() {
    if (this.props.onMount)
      this.props.onMount((group) => {
        try {
          this.form = JSON.parse(JSON.stringify(group));
          this.originalName = this.form.name;

          this.setState(
            {
              i: this.state.i + 1,
            },
            () => {
              this.modal.show();
            },
          );
        } catch (e) {}
      });
  }

  render() {
    if (!this.form) return '';

    return (
      <Modal
        title={'Edit Rule'}
        onRef={(ref) => {
          this.modal = ref;
        }}
        cancel={{
          label: window.locales.cancel,
          click: this.hideModal.bind(this),
        }}
        submit={{
          label: 'Save changes',
          click: this.saveChanges.bind(this),
        }}
        message={this.state.message}
      >
        <div style={{ marginBottom: '5px' }}>Rule name</div>
        <Input
          label={'Rule name'}
          value={this.form.name}
          onChange={this.fieldChanged.bind(this, 'name')}
          notValid={this.state.form.name}
        />
        <div style={{ marginBottom: '5px' }}>Rule selector</div>
        <Textarea
          label={'Rule selector'}
          value={jsonBeautifier(this.form.request_selector, true, true)}
          onChange={this.fieldChanged.bind(this, 'request_selector')}
          notValid={this.state.form.request_selector}
        />
        <div style={{ marginBottom: '5px' }}>Server selector</div>
        <Textarea
          label={'Server selector'}
          value={jsonBeautifier(this.form.server_selector, true, true)}
          onChange={this.fieldChanged.bind(this, 'server_selector')}
          notValid={this.state.form.server_selector}
        />
        <div style={{ marginBottom: '5px' }}>Description</div>
        <Textarea
          label={'Description'}
          value={this.form.description}
          onChange={this.fieldChanged.bind(this, 'description')}
        />
        <div style={{ marginBottom: '5px' }}>Priority</div>
        <Input
          label={'Priority'}
          value={this.form.priority}
          onChange={this.fieldChanged.bind(this, 'priority')}
          notValid={this.state.form.priority}
        />
      </Modal>
    );
  }

  hideModal() {
    this.modal.hide();
  }

  saveChanges() {
    let state = this.state;
    state.form = {
      name: false,
      request_selector: false,
      server_selector: false,
      priority: false,
    };

    if (this.form.name.trim() === '') {
      state.form.name = true;
      state.message = window.locales.fillInFields;
      return this.setState(state);
    }

    if (typeof this.form.request_selector === 'string' && this.form.request_selector !== '') {
      try {
        this.form.request_selector = JSON.parse(this.form.request_selector);
      } catch (e) {
        state.form.request_selector = true;
        state.message = 'Enter correct value';
        return this.setState(state);
      }
    }

    if (typeof this.form.server_selector === 'string' && this.form.server_selector !== '') {
      try {
        this.form.server_selector = JSON.parse(this.form.server_selector);
      } catch (e) {
        state.form.server_selector = true;
        state.message = 'Enter correct value';
        return this.setState(state);
      }
    }

    let n = Number(this.form.priority);
    if (isNaN(n)) {
      state.form.priority = true;
      state.message = 'Enter correct value';
      return this.setState(state);
    } else {
      this.form.priority = n;
    }

    let body = {};

    body.add_groups = [this.form];
    body.del_groups = [this.originalName];

    window.Loading.show();
    this.setState(state, () => {
      updatePool(this.props.pool.name, body)
        .then(() => {
          window.Loading.hide();
          this.modal.hide();
          this.props.parrentComponentMount();
        })
        .catch((e) => {
          this.setState(
            {
              message: e.message,
            },
            window.Loading.hide,
          );
        });
    });
  }

  fieldChanged(k, v) {
    this.form[k] = v;
  }
}

export default EditGroup;
