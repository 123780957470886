import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  margin-right: 5px;
  &:hover {
    path {
      fill: #ffffff;
    }
  }
`;

export default (props) => (
  <Icon width={props.width} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4375 0H0.5625C0.413316 0 0.270242 0.0592632 0.164752 0.164752C0.0592632 0.270242 0 0.413316 0 0.5625L0 17.4375C0 17.5867 0.0592632 17.7298 0.164752 17.8352C0.270242 17.9407 0.413316 18 0.5625 18H17.4375C17.5867 18 17.7298 17.9407 17.8352 17.8352C17.9407 17.7298 18 17.5867 18 17.4375V0.5625C18 0.413316 17.9407 0.270242 17.8352 0.164752C17.7298 0.0592632 17.5867 0 17.4375 0ZM16.875 16.875H1.125V1.125H16.875V16.875Z"
      fill="#B2B9C4"
    />
    <path
      d="M11.0868 7.71187L14.0625 4.73062V7.3125H15.1875V3.375C15.1875 3.22582 15.1282 3.08274 15.0227 2.97725C14.9172 2.87176 14.7741 2.8125 14.625 2.8125H10.6875V3.9375H13.2693L10.2937 6.91313L11.0868 7.71187Z"
      fill="#B2B9C4"
    />
    <path
      d="M3.375 15.1877H7.3125V14.0627H4.73062L7.70625 11.0871L6.91313 10.2939L3.9375 13.2696V10.6877H2.8125V14.6252C2.8125 14.7744 2.87176 14.9175 2.97725 15.0229C3.08274 15.1284 3.22582 15.1877 3.375 15.1877Z"
      fill="#B2B9C4"
    />
  </Icon>
);
