import React, { Component } from 'react';

class NotFound extends Component {
  componentDidMount() {
    window.Loading.hide();
  }

  render() {
    return (
      <div id="screenAuth" className={'fullHeight business'}>
        <div className="verifyEmail">
          <div className="logo" />
          <p style={{ fontSize: '24px' }}>Page not found</p>
          <div className="register_success_actions">
            <a href="#projects">Dashboard</a>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
            }
            <a href="/#">Main page</a>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
