import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  &:hover {
    path {
      stroke: #00a2ff;
    }
  }
`;

export default (props) => (
  <Icon width={props.width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 23L23 1" stroke="#152535" strokeWidth="2" strokeLinecap="round" />
    <path d="M23 23L1 1" stroke="#152535" strokeWidth="2" strokeLinecap="round" />
  </Icon>
);
