import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  margin-right: 5px;
`;

export default (props) => (
  <Icon width={props.width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5139 13.6296H21.7707H23.4003L20.1435 8.74316L16.8867 13.6296H18.5139Z"
      fill={props.color}
    />
    <path
      d="M5.48601 10.3704H2.22921H0.599609L3.85641 15.2568L7.11321 10.3704H5.48601Z"
      fill={props.color}
    />
    <path
      d="M11.9999 19.3296C10.3703 19.3296 7.91993 18.5136 7.11353 17.292L5.48633 18.9216C7.11353 20.5512 9.56633 21.7704 11.9999 21.7704C17.6999 21.7704 21.7703 17.292 21.7703 12H19.3295C19.3333 12.9636 19.1464 13.9184 18.7794 14.8094C18.4124 15.7004 17.8726 16.5099 17.1912 17.1913C16.5099 17.8727 15.7003 18.4124 14.8093 18.7794C13.9184 19.1464 12.9635 19.3334 11.9999 19.3296Z"
      fill={props.color}
    />
    <path
      d="M12.0009 4.67029C13.6305 4.67029 16.0809 5.48629 16.8873 6.70789L18.5145 5.07829C16.8873 3.45109 14.4441 2.22949 12.0009 2.22949C6.30087 2.22949 2.23047 6.71989 2.23047 11.9999H4.67127C4.66746 11.0363 4.85445 10.0815 5.22144 9.19048C5.58844 8.29949 6.12819 7.48997 6.80957 6.80859C7.49094 6.12721 8.30046 5.58747 9.19145 5.22047C10.0824 4.85347 11.0373 4.66648 12.0009 4.67029Z"
      fill={props.color}
    />
  </Icon>
);
