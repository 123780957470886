import React, { Component } from 'react';

import { IconPen } from '../../common/icons';

import Modal from '../../common/modal';

import { Select, Input } from '../../common/form';

import { bulkUsersUpdate } from '../../components/Users';

class UpdateByCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      i: 0,
      message: '',
      checkboxes: props.checkboxes,
    };

    this.fields = {
      type: 0,
    };

    this.loadUsers = props.loadUsers;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      checkboxes: props.checkboxes,
    });
  }

  formSubmit() {
    if (
      typeof this.fields.description === 'undefined' &&
      typeof this.fields.condition === 'undefined'
    ) {
      return this.setState({ message: window.locales.setNewValue });
    }

    let state = this.state;
    let request = {};
    for (let k in state.checkboxes) {
      if (k === 'all' || state.checkboxes[k] === 'off') continue;

      if (typeof this.fields.description !== 'undefined') {
        request[k] = {
          description: this.fields.description.trim(),
        };
      } else {
        request[k] = {
          condition: Number(this.fields.condition),
        };
      }
    }

    window.Loading.show();
    bulkUsersUpdate(request)
      .then((response) => {
        this.updateLog = response;
        state.message = '';
        this.setState(state, () => {
          this.loadUsers({ checkboxes: { all: 'off' }, page: '0' }).then(() => {
            window.Loading.hide();
          });
        });
      })
      .catch((e) => {
        this.setState({ message: e.message }, () => {
          window.Loading.hide();
        });
      });
  }

  downloadLog() {
    let date = new Date();
    let element = document.createElement('a');
    let log = [
      [
        window.locales.id +
          ',' +
          window.locales.unm +
          ',' +
          window.locales.description +
          ',' +
          window.locales.status,
      ],
    ];

    for (let k in this.updateLog) {
      try {
        let l = this.updateLog[k];
        let email = '';
        if (l && l.social && l.social.email) {
          email = l.social.email;
        } else if (l && l.given_name) {
          email = l.given_name;
        }

        log.push(
          l.id +
            ',' +
            l.user_name +
            ',' +
            email +
            ',' +
            (window.locales[l.status] ? window.locales[l.status] : l.status),
        );
      } catch (e) {}
    }
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(log.join('\n')),
    );
    element.setAttribute(
      'download',
      'USERS-UPDATE_' +
        date.getFullYear() +
        (date.getMonth() + 1) +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        '.csv',
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    this.modal.hide();
  }

  fieldChanged(key, value) {
    if (key === 'type') {
      this.fields = {
        type: value,
      };

      if (value === 'description') this.fields.description = '';
      else if (value === 'condition') this.fields.condition = 0;
      this.setState({ i: this.state.i + 1 });
    } else {
      this.fields[key] = value;
    }
  }

  showUpdateModal() {
    let selected = false;
    for (let k in this.state.checkboxes) {
      if (k !== 'all' && this.state.checkboxes[k] === 'on') {
        selected = true;
        break;
      }
    }

    if (!selected) return;

    this.fields = {
      type: 0,
    };
    this.updateLog = undefined;

    this.setState({ i: this.state.i + 1 }, () => {
      this.modal.show();
    });
  }

  hideModal() {
    this.modal.hide();
  }

  render() {
    let options = [
      ['condition', window.locales.condition],
      ['description', window.locales.description],
    ];

    let conditionField = (
      <Select
        theme={'dark capitalize updateUsersField'}
        options={[
          [0, window.locales.active],
          [1, window.locales.disabled],
        ]}
        value={this.fields.condition}
        disabled={this.state.disabled}
        onChange={this.fieldChanged.bind(this, 'condition')}
      />
    );

    let selected = false;
    for (let k in this.state.checkboxes) {
      if (k !== 'all' && this.state.checkboxes[k] === 'on') {
        selected = true;
        break;
      }
    }

    let cancelText = window.locales.cancel;
    let submitText = window.locales.updateSelectedUsersBtn;
    let submitFunction = this.formSubmit.bind(this);
    let style;

    let updateLog;
    if (this.updateLog) {
      updateLog = [];
      for (let k in this.updateLog) {
        try {
          let l = this.updateLog[k];
          let email = '';
          if (l && l.social && l.social.email) {
            email = l.social.email;
          } else if (l && l.given_name) {
            email = l.given_name;
          }

          let tc = {};
          l.changes = l.changes ? l.changes : {};
          if (typeof l.changes.condition !== 'undefined') {
            tc[window.locales.condition] =
              l.changes.condition === 0 ? window.locales.active : window.locales.disabled;
          }
          if (typeof l.changes.description !== 'undefined') {
            tc[window.locales.description] = l.changes.description;
          }

          let changesRow = [];
          for (let k in tc) {
            changesRow.push(<div key={k}>{k.toLocaleLowerCase() + ': ' + tc[k]}</div>);
          }

          if (changesRow.length === 0) changesRow = window.locales.noChanges;

          updateLog.push(
            <tr key={Number(k)}>
              <td>{l.id}</td>
              <td>{l.user_name}</td>
              <td>{email}</td>
              <td>{changesRow}</td>
              <td>{window.locales[l.status] ? window.locales[l.status] : l.status}</td>
            </tr>,
          );
        } catch (e) {}
      }

      cancelText = window.locales.close;
      submitText = window.locales.downloadUserUpdateLog;
      submitFunction = this.downloadLog.bind(this);
      style = { width: '1000px', marginLeft: '-500px', height: '680px' };
    }

    return (
      <div>
        <IconPen
          theme={'blue' + (!selected ? ' disabled' : '')}
          label={window.locales.updateSelectedUsers}
          style={{ marginLeft: '20px' }}
          onClick={this.showUpdateModal.bind(this)}
        />
        <Modal
          title={window.locales.updateSelectedUsersCbTitle}
          onRef={(ref) => {
            this.modal = ref;
          }}
          message={this.state.message}
          cancel={{
            label: cancelText,
            click: this.hideModal.bind(this, 'update'),
          }}
          submit={{
            label: submitText,
            click: submitFunction,
          }}
          style={style}
        >
          {!updateLog && (
            <div className={'updateUsersContainer'}>
              <Select
                label={window.locales.selectField}
                theme={'dark capitalize updateUsersContainerSelector'}
                options={options}
                value={this.fields.type}
                disabled={this.state.disabled}
                onChange={this.fieldChanged.bind(this, 'type')}
              />
              {this.fields.type === 'description' && (
                <Input
                  theme={'dark updateUsersField'}
                  value={this.fields.description}
                  onChange={this.fieldChanged.bind(this, 'description')}
                />
              )}
              {this.fields.type === 'condition' && conditionField}
            </div>
          )}
          {updateLog && (
            <div className={'logPlaceholder'}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <td>{window.locales.id}</td>
                    <td>{window.locales.unm}</td>
                    <td>{window.locales.description}</td>
                    <td>{window.locales.eventChanges}</td>
                    <td>{window.locales.status}</td>
                  </tr>
                </thead>
                <tbody>{updateLog}</tbody>
              </table>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default UpdateByCheckbox;
