import React, { Component, Fragment } from 'react';

import Table from '../../common/table';

import Search from './Search';

import { IconCard, IconList } from '../../common/icons';

import { goTo } from '../../common/methods';

import { IconPlus } from '../../common/icons';

import Add from './Add';
import { IconLogoSmall } from '../../common/icons';
import { ThemeContext } from '../../ThemeContext';
import styled from 'styled-components';

const PROJECTS_VIEW = 'Projects.viewType';

const TitleCardContainer = styled.div`
  height: 50%;
  padding: 10px 0 10px 0;
  margin: 0 30px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  position: relative;
  display: flex;
  align-items: center;
`;

const NameProject = styled.div`
  font-size: 20px;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => (props.nameTheme === '' ? '#FFFFFF' : '#000000')};
`;

const CodeProject = styled.div`
  color: #b3b9c3;
  font-size: 14px;
`;

class index extends Component {
  constructor(props) {
    super(props);
    const prefixProjectCode = window.user.infoFromFirebase.company_code
      ? window.user.infoFromFirebase.company_code
      : window.user.infoFromFirebase.company;

    this.state = {
      i: 0,
      projects: props.projects,
      search: '',
      prefixProjectCode: prefixProjectCode,
    };

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      projects: props.projects,
      search: '',
    });
  }

  changeView(view) {
    window.storage.set(PROJECTS_VIEW, view);
    this.setState({
      i: this.state.i + 1,
    });
  }

  searchCallback(value) {
    if (value.trim() === this.state.search) return;

    this.setState({
      search: value,
    });
  }

  showAdd() {
    goTo('createProject');
  }

  render() {
    let view = window.storage.get(PROJECTS_VIEW);
    if (!view) window.storage.set(PROJECTS_VIEW, 'card');

    let content;
    switch (view) {
      case 'list':
        content = this.renderTable();
        break;
      default:
        content = this.renderCards();
        break;
    }

    return (
      <div id="screenProjects" className="portalContentContainer">
        <div className="screenProjectsHeader">
          <Search callback={this.searchCallback.bind(this)} />
          {view === 'list' && (
            <IconCard
              id={'ICON_PROJECT_CARD'}
              theme={'blue'}
              label={window.locales.card}
              onClick={this.changeView.bind(this, 'card')}
            />
          )}
          {view !== 'list' && (
            <IconList
              id={'ICON_PROJECT_LIST'}
              theme={'blue'}
              label={window.locales.list}
              onClick={this.changeView.bind(this, 'list')}
            />
          )}
          {window.user.infoFromFirebase && this.state.prefixProjectCode && (
            <IconPlus
              id={'PROJECTS_PAGE_ADD_PROJECT'}
              label={window.locales.addProject}
              theme={'blue'}
              onClick={this.showAdd.bind(this)}
            />
          )}
        </div>
        {content}
        {
          <Add
            type={'modal'}
            onMount={(modal, input) => {
              this.modal = modal;
              this.input = input;
            }}
            show={this.state.show}
          />
        }
      </div>
    );
  }

  renderCards() {
    let content = [];
    if (window.user.infoFromFirebase && this.state.prefixProjectCode) {
      content.push(
        <div key={'add'} className={'card add'} onClick={this.showAdd.bind(this)}>
          <IconPlus theme={'blue'} label={window.locales.addProject} />
        </div>,
      );
    }

    let search = this.state.search !== '' ? new RegExp(this.state.search, 'gi') : false;

    for (let k in this.state.projects) {
      let project = this.state.projects[k];

      if (search) {
        let publickey = project.publickey ? project.publickey : '';
        let description = project.description ? project.description : '';
        let carrier_name = project.carrier_name ? project.carrier_name : '';

        if (!publickey.match(search) && !description.match(search) && !carrier_name.match(search))
          continue;
      }

      let permissions = [];
      try {
        permissions = project.current_user_role.permissions;
      } catch (e) {
        window.error(e);
      }
      if (permissions.indexOf('project_read') === -1) continue;

      let users = project.active_users ? project.active_users : { daily: 0, weekly: 0, monthly: 0 };

      let row1 = project.carrier_name;
      let row2 = project.publickey;

      if (row1.length > 15) row1 = row1.trim().substring(0, 15).trim() + '...';
      if (row2.length > 15) row2 = row2.trim().substring(0, 15).trim() + '...';

      content.push(
        <div
          key={k}
          className={'card'}
          onClick={() => {
            goTo('dashboard/' + project.publickey);
          }}
        >
          <TitleCardContainer>
            <ThemeContext.Consumer>
              {(context) => (
                <Fragment>
                  {project.icon ? (
                    <div className={'top'}>
                      <div className={'logo'} style={{ backgroundImage: `url(${project.icon})` }} />
                    </div>
                  ) : (
                    <IconLogoSmall color={context.theme === '' ? 'white' : 'black'} width="58" />
                  )}
                  <NameProject nameTheme={context.theme}>
                    {row1}
                    <CodeProject>{row2}</CodeProject>
                  </NameProject>
                </Fragment>
              )}
            </ThemeContext.Consumer>
          </TitleCardContainer>
          <div className={'bottom'}>
            <div>
              <div>
                {window.locales.daily}
                <br />
                {window.locales.users}
              </div>
              {users.daily ? users.daily : 0}
            </div>
            <div>
              <div>
                {window.locales.weekly}
                <br />
                {window.locales.users}
              </div>
              {users.weekly ? users.weekly : 0}
            </div>
            <div>
              <div>
                {window.locales.monthly}
                <br />
                {window.locales.users}
              </div>
              {users.monthly ? users.monthly : 0}
            </div>
          </div>
        </div>,
      );
    }

    return (
      <div className={'cardContainer'} style={{ width: 'calc(100% + 30px)' }}>
        {content}
      </div>
    );
  }

  renderTable() {
    let head = [
      ['№', { width: '25px' }],
      [window.locales.companyName, { width: '150px' }],
      [window.locales.publickey, { width: '150px' }],
      [window.locales.admin, { width: '180px' }],
      [window.locales.registeredUsers, { width: '90px' }],
      [window.locales.registeredDevices, { width: '90px' }],
      [window.locales.description],
      [window.locales.role, { width: '100px' }],
    ];

    let i = 0;
    let body = [];

    let search = this.state.search !== '' ? new RegExp(this.state.search, 'gi') : false;

    for (let k in this.state.projects) {
      let project = this.state.projects[k];

      if (search) {
        let publickey = project.publickey ? project.publickey : '';
        let description = project.description ? project.description : '';
        let carrier_name = project.carrier_name ? project.carrier_name : '';

        if (!publickey.match(search) && !description.match(search) && !carrier_name.match(search))
          continue;
      }

      let admins = [];
      if (project.admin_list && project.admin_list.length > 0) {
        for (let j in project.admin_list) {
          let admin = project.admin_list[j];
          admins[j] = (
            <div key={j}>
              {admin + (Number(j) === 1 && project.admin_list.length > 2 ? '...' : '')}
            </div>
          );
          if (Number(j) === 1) break;
        }
      }

      let name = project.carrier_name;
      if (name.length > 20) {
        name = name.substr(0, 20) + '...';
      }

      let description = project.description ? project.description : '';
      if (description.length > 50) description = description.substr(0, 50) + '...';

      let publickey = project.publickey ? project.publickey : '';
      if (publickey.length > 25) publickey = publickey.substr(0, 25) + '...';

      let registeredUsers =
        project.stats && project.stats.active_users ? project.stats.active_users : undefined;
      let registeredDevices =
        project.stats && project.stats.active_devices ? project.stats.active_devices : undefined;

      body.push({
        row: [
          [++i, { wordBreak: 'normal' }],
          [
            <div className="publickey">
              {project.icon ? (
                <img alt="" src={project.icon} />
              ) : (
                <ThemeContext.Consumer>
                  {(context) => (
                    <IconLogoSmall color={context.theme === '' ? 'white' : 'black'} width="30" />
                  )}
                </ThemeContext.Consumer>
              )}
              {name}
            </div>,
          ],
          [publickey],
          [admins, { notFlex: true }],
          [registeredUsers],
          [registeredDevices],
          [description],
          [
            project.current_user_role.name &&
            window.locales.roles[project.current_user_role.name].name
              ? window.locales.roles[project.current_user_role.name].name
              : project.current_user_role.name,
          ],
        ],
        click: () => {
          goTo('dashboard/' + project.publickey);
        },
      });
    }

    return <Table head={head} body={body} />;
  }
}

export default index;
