import Network from '../../common/Network';
import { getActiveProject } from '../Projects';

const loadPayments = () => {
  return new Promise((resolve) => {
    let project = getActiveProject();

    let request = window.config.host + 'portal/project/payments_settings';
    let params = {
      access_token: window.user.firebaseToken,
      publickey: project.publickey,
    };

    Network.get(request, params)
      .then((response) => {
        try {
          return resolve(response.response.all_purchase_settings);
        } catch (e) {
          return resolve([]);
        }
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

const addPayment = (publickey, purchase_type, purchase_settings) => {
  publickey = publickey ? publickey : getActiveProject().publickey;
  let request = window.config.host + 'portal/project/payments_settings';
  let params = {
    access_token: window.user.firebaseToken,
    publickey: publickey,
  };
  let body = {
    [purchase_type]: JSON.parse(purchase_settings),
  };

  return Network.post(request, params, body);
};

const editPayment = (purchase_type, purchase_settings) => {
  let request = window.config.host + 'portal/project/payments_settings';
  let project = getActiveProject();
  let params = {
    access_token: window.user.firebaseToken,
    publickey: project.publickey,
  };
  let body = {
    [purchase_type]: JSON.parse(purchase_settings),
  };

  return Network.put(request, params, body);
};

const deletePayment = (purchase_type) => {
  let request = window.config.host + 'portal/project/payments_settings';
  let project = getActiveProject();
  let params = {
    access_token: window.user.firebaseToken,
    publickey: project.publickey,
    purchase_type: purchase_type,
  };

  return Network.delete(request, params);
};

export { addPayment, editPayment, loadPayments, deletePayment };
