import React, {useContext, useState} from "react";
import {ThemeContext} from "../../ThemeContext";
import {
    BodyContainer,
    ButtonExpand,
    ButtonHide,
    HeaderContainer,
    PlanContainer,
    PlansContainer,
    StickContainer,
    Condition,
    UpgradeText,
    MainContent,
    AbilityItem,
    TextOption,
    Access,
    TextContainer, Topic, ItemsContainer,
    PlanTitle,
} from "./components";
import {
    ButtonPlanContainer,
    Minus,
    Plus,
} from "../components";
import {IPlans} from "../../interfaces";

export default ({freePlanButtons, payPlanButtons, style}: {
    freePlanButtons: JSX.Element,
    payPlanButtons: JSX.Element, style?: IPlans
}) => {
    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';
    const [togglePlans, setTogglePlans] = useState(false)

    return <>
        <PlansContainer>
            <PlanContainer styleOptions={style} nameTheme={context.theme}>
                <HeaderContainer>
                    <StickContainer backgroundTitle={style?.backgroundTitle} isProduction={isProduction}>
                        {window.locales.essentials}
                    </StickContainer>
                    <BodyContainer>
                        <MainContent>
                            <TextContainer>
                                <Condition>
                                    <PlanTitle>
                                        {window.locales.free}
                                    </PlanTitle>
                                    {window.locales.freePlan}
                                </Condition>
                                <UpgradeText>
                                    {window.locales.freeUpgrade}
                                </UpgradeText>
                            </TextContainer>
                            {freePlanButtons}
                        </MainContent>
                        {togglePlans && <>
                            <Topic>
                                {window.locales.general}
                            </Topic>
                            <ItemsContainer>
                                <AbilityItem>
                                    <TextOption>
                                        SDK Win, macOS, iOS, Android
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        User management
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Online dashboards
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                            </ItemsContainer>
                            <Topic>
                                {window.locales.vpnService}
                            </Topic>
                            <ItemsContainer>
                                <AbilityItem>
                                    <TextOption>
                                        VPN Day Bandwidth Limit – unlimited
                                    </TextOption>
                                    <Access>
                                        <Minus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        VPN locations
                                    </TextOption>
                                    <Access>
                                        10
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Optimal location settings
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Torrent support
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Video streaming
                                    </TextOption>
                                    <Access>
                                        <Minus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Email offload
                                    </TextOption>
                                    <Access>
                                        <Minus/>
                                    </Access>
                                </AbilityItem>
                            </ItemsContainer>
                            <Topic>
                                {window.locales.fireshieldService}
                            </Topic>
                            <ItemsContainer>
                                <AbilityItem>
                                    <TextOption>
                                        Domain categorisation
                                    </TextOption>
                                    <Access>
                                        <Minus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Domain DB check
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Domain Block
                                    </TextOption>
                                    <Access>
                                        <Minus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Tech support
                                    </TextOption>
                                    <Access>
                                        Email only
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Tech support hours
                                    </TextOption>
                                    <Access>
                                        <Minus/>
                                    </Access>
                                </AbilityItem>
                            </ItemsContainer>
                        </>}
                        <ButtonPlanContainer onClick={() => setTogglePlans(!togglePlans)}>
                            {togglePlans ? <ButtonHide/> : <ButtonExpand/>}
                        </ButtonPlanContainer>
                    </BodyContainer>
                </HeaderContainer>
            </PlanContainer>
            <PlanContainer nameTheme={context.theme} styleOptions={style}>
                <HeaderContainer>
                    <StickContainer backgroundTitle={style?.backgroundTitle} isProduction={isProduction}>
                        {window.locales.premium}
                    </StickContainer>
                    <BodyContainer>
                        <MainContent>
                            <TextContainer>
                                <Condition>
                                    <PlanTitle>
                                        {window.locales.pay}
                                    </PlanTitle>
                                    {window.locales.payPlanFreeUsers}
                                    <br/>
                                    {window.locales.payPlanPaidUsers}
                                </Condition>
                                <UpgradeText>
                                    {window.locales.priceUnlimitedPlan}
                                </UpgradeText>
                            </TextContainer>
                            {payPlanButtons}
                        </MainContent>
                        {togglePlans && <>
                            <Topic>
                                {window.locales.general}
                            </Topic>
                            <ItemsContainer>
                                <AbilityItem>
                                    <TextOption>
                                        SDK Win, macOS, iOS, Android
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        User management
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Online dashboards
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                            </ItemsContainer>
                            <Topic>
                                {window.locales.vpnService}
                            </Topic>
                            <ItemsContainer>
                                <AbilityItem>
                                    <TextOption>
                                        VPN Day Bandwidth Limit – unlimited
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        VPN locations
                                    </TextOption>
                                    <Access>
                                        30
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Optimal location settings
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Torrent support
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Video streaming
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Email offload
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                            </ItemsContainer>
                            <Topic>
                                {window.locales.fireshieldService}
                            </Topic>
                            <ItemsContainer>
                                <AbilityItem>
                                    <TextOption>
                                        Domain categorisation
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Domain DB check
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Domain Block
                                    </TextOption>
                                    <Access>
                                        <Plus/>
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Tech support
                                    </TextOption>
                                    <Access>
                                        Full support
                                    </Access>
                                </AbilityItem>
                                <AbilityItem>
                                    <TextOption>
                                        Tech support hours
                                    </TextOption>
                                    <Access>
                                        24x7
                                    </Access>
                                </AbilityItem>
                            </ItemsContainer>
                        </>}
                        <ButtonPlanContainer onClick={() => setTogglePlans(!togglePlans)}>
                            {togglePlans ? <ButtonHide/> : <ButtonExpand/>}
                        </ButtonPlanContainer>
                    </BodyContainer>
                </HeaderContainer>
            </PlanContainer>
        </PlansContainer>
    </>
}