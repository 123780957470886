import React, {Component} from 'react';

import {
    IconSmallArrow,
    IconCheckMark,
    IconSearch,
    IconPlus,
    IconLogoSmall,
} from '../../common/icons';

import {Input} from '../../common/form';

import {goTo, changeProject} from '../../common/methods';

import Add from '../../screens/Projects/Add';
import {ThemeContext} from '../../ThemeContext';

class ProjectsSelector extends Component {
    constructor(props) {
        super(props);
        const prefixProjectCode = window.user.infoFromFirebase.company_code
            ? window.user.infoFromFirebase.company_code
            : window.user.infoFromFirebase.company;

        this.state = {
            page: props.page,
            opened: false,
            projects: props.projects,
            activeProject: props.activeProject,
            i: 0,
            prefixProjectCode: prefixProjectCode,
        };

        window.data.projectSearch = window.data.projectSearch ? window.data.projectSearch : '';

        this._escFunction = this.escFunction.bind(this);
        this._click = this.click.bind(this);

        document.addEventListener('keydown', this._escFunction);
        document.addEventListener('click', this._click);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._escFunction);
        document.removeEventListener('click', this._click);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            page: props.page,
            opened: false,
            projects: props.projects,
            activeProject: props.activeProject,
        });
    }

    changeOpenState() {
        this.setState({opened: !this.state.opened}, () => {
            if (this.state.opened) {
                this.inputRef.setFocus();
            }
        });
    }

    escFunction(event) {
        if (event.keyCode === 27 && this.state.opened) {
            this.changeOpenState();
        }
    }

    click(e) {
        if (!this.state.opened) return;
        let is = (el1, el2) => {
            if (!el1) return false;
            if (el1 === el2) return true;
            if (el1.id === 'loadingContainer') return true;
            return is(el1.parentNode, el2);
        };

        let el;

        el = document.getElementById('ProjectsSelector');
        if (!is(e.target, el)) {
            return this.setState({opened: false});
        }
    }

    searchChange(value) {
        window.data.projectSearch = value;
        this.setState({i: this.state.i + 1});
    }

    changeProject(newProject) {
        if (newProject.publickey === this.state.activeProject.publickey) return;
        this.props.updateActiveProject(newProject);

        changeProject(newProject.publickey);
    }

    showAdd() {
        goTo('createProject');
    }

    projectAdded() {
        window.reloadPortal(undefined, true).then(() => {
            goTo('projects');
        });
    }

    render() {
        if (!this.state.activeProject || this.state.page === 'projects') {
            return (
                <div className="ProjectsSelector">
                    <img
                        alt=""
                        className="logo"
                        src="/static/assets/icons/business_default.png"
                        onClick={() => {
                            goTo('projects');
                        }}
                    />
                    <div
                        className={'activeProjectName noselect business ' + window.storage.get('css_color')}
                        onClick={() => {
                            goTo('projects');
                        }}
                    >
                        WI-FI SECURITY <span>BUSINESS</span>
                    </div>
                </div>
            );
        }

        let row1 = this.state.activeProject.carrier_name;
        let row2 = this.state.activeProject.publickey;

        if (row1.length > 20) row1 = row1.trim().substring(0, 20).trim() + '...';
        if (row2.length > 20) row2 = row2.trim().substring(0, 20).trim() + '...';

        return (
            <div className="ProjectsSelector" id="ProjectsSelector">
                <div
                    onClick={() => {
                        goTo('projects');
                    }}
                >
                    {this.state.activeProject.icon ? (
                        <div
                            className="logo2"
                            style={{backgroundImage: `url(${this.state.activeProject.icon})`}}
                        />
                    ) : (
                        <ThemeContext.Consumer>
                            {(context) => (
                                <IconLogoSmall color={context.theme === 'white' ? 'black' : 'white'} width="53"/>
                            )}
                        </ThemeContext.Consumer>
                    )}
                </div>
                <div
                    className="activeProjectName noselect"
                    onClick={this.changeOpenState.bind(this)}
                    id={'PROJECTS_SELECTOR_OPEN'}
                >
                    {row1}
                    <div>{row2}</div>
                </div>
                <IconSmallArrow theme="default flex" onClick={this.changeOpenState.bind(this)}/>
                {this.state.opened && this.renderList()}
            </div>
        );
    }

    renderList() {
        let content = [];
        let search =
            window.data.projectSearch !== '' ? new RegExp(window.data.projectSearch, 'gi') : undefined;
        for (let k in this.state.projects) {
            let project = this.state.projects[k];
            if (search !== '' && !project.publickey.match(search)) continue;

            let row1 = project.carrier_name;
            let row2 = project.publickey;

            if (row1.length > 20) row1 = row1.trim().substring(0, 20).trim() + '...';
            if (row2.length > 20) row2 = row2.trim().substring(0, 20).trim() + '...';

            content.push(
                <div
                    className={
                        'row' + (this.state.activeProject.publickey === project.publickey ? ' active' : '')
                    }
                    key={k}
                    onClick={this.changeProject.bind(this, project)}
                >
                    {project.icon ? (
                        <div
                            className="smallIconContainer"
                            style={{backgroundImage: `url(${project.icon})`}}
                        />
                    ) : (
                        <ThemeContext.Consumer>
                            {(context) => (
                                <IconLogoSmall color={context.theme ? 'black' : 'white'} width="50"/>
                            )}
                        </ThemeContext.Consumer>
                    )}
                    <div className="publickey">
                        {row1}
                        <div>{row2}</div>
                    </div>
                    <div className="selector"/>
                    {this.state.activeProject.publickey === project.publickey && <IconCheckMark/>}
                </div>,
            );
        }

        return (
            <div className="list">
                <div className="search">
                    <Input
                        label={window.locales.searchProject}
                        value={window.data.projectSearch}
                        onChange={this.searchChange.bind(this)}
                        icon={<IconSearch/>}
                        iconPosition={'right'}
                        onRef={(ref) => (this.inputRef = ref)}
                    />
                </div>
                <div
                    className="allProjects"
                    onClick={() => {
                        goTo('projects');
                    }}
                >
                    {window.locales.allProjects}
                    <div>{'>'}</div>
                </div>
                {window.user.infoFromFirebase && this.state.prefixProjectCode && (
                    <div className="addProject" onClick={this.showAdd.bind(this)}>
                        <IconPlus
                            label={window.locales.addProject}
                            id={'PROJECTS_SELECTOR_ADD_PROJECT'}
                            theme={'blue'}
                        />
                    </div>
                )}
                <div className="overflow scroll">{content}</div>
                <Add
                    type={'modal'}
                    projectAdded={this.projectAdded.bind(this)}
                    onMount={(modal, input) => {
                        this.modal = modal;
                        this.input = input;
                    }}
                />
            </div>
        );
    }
}

export default ProjectsSelector;
