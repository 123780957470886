import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  margin-right: 5px;
`;

export default (props) => (
  <Icon
    width={props.width}
    className="svg-inline--fa fa-minus fa-w-14"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill={props.color}
      d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
    />
  </Icon>
);
