import React, { Component } from 'react';

import { IconQuestion, IconAddUsers } from '../../common/icons';

import Modal from '../../common/modal';

class AddBulk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProject: props.activeProject,
      form: {
        username: false,
        password: false,
      },
      isUid: props.isUid,
      message: '',
    };

    this.form = {
      username: '',
      pasword: '',
      given_name: '',
    };

    this.addUser = props.addUser;
    this.loadUsers = props.loadUsers;
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      form: {
        username: false,
        password: false,
      },
      isUid: props.isUid,
      activeProject: props.activeProject,
      message: '',
    });
  }

  showModal() {
    this.file = false;
    this.log = false;

    let state = this.state;
    state.message = '';
    state.error = false;

    this.form.file = '';

    this.setState(state, () => {
      this.modal.show();
    });
  }

  formSubmit() {
    if (!this.file || this.file.length === 0) {
      return this.setState({
        error: window.locales.bulkUserEmpty,
      });
    }

    window.Loading.show();
    this.addUsers(-1, []).then((result) => {
      this.loadUsers().then(() => {
        this.log = result;
        this.setState(
          {
            i: this.state.i + 1,
          },
          window.Loading.hide,
        );
      });
    });
  }

  addUsers(i, r) {
    ++i;

    return new Promise((resolve) => {
      if (i >= this.file.length) return resolve(r);

      this.addUser(this.file[i]).then((result) => {
        r.push(result);

        this.addUsers(i, r).then((r) => {
          return resolve(r);
        });
      });
    });
  }

  handleFileUpload(event) {
    let file;
    try {
      file = event.target.files[0];
    } catch (e) {}

    this.form.file = '';

    if (
      !file ||
      !file.name ||
      !file.name.match(/\.[^.]+$/) ||
      ['txt', 'csv'].indexOf(file.name.match(/\.([^.]+)$/)[1].toLowerCase()) === -1
    ) {
      return this.setState({
        error: window.locales.bulkUserError,
      });
    }

    this.form.file = file.name;

    let reader = new FileReader();
    reader.onload = (theFile) => {
      try {
        let result = theFile.target.result;
        let t = result;
        if (t.indexOf('\n') !== -1) t = t.split('\n');
        else if (t.indexOf('\r') !== -1) t = t.split('\r');

        result = [];

        for (let k in t) {
          let l = t[k].split(',');

          if (!l || !l[0] || !l[0].trim() || l[0].trim() === '') continue;

          result.push({
            username: l[0].trim(),
            password: l[1] ? l[1].trim() : '',
            given_name: l[2] ? l[2].trim() : l[0].trim(),
          });
        }

        this.file = result;

        return this.setState({
          i: this.state.i + 1,
          error: false,
        });
      } catch (e) {
        return this.setState({
          error: window.locales.bulkUserMalformed,
        });
      }
    };
    reader.readAsText(file);
  }

  hideModal() {
    this.modal.hide();
  }

  render() {
    if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
      return <div />;
    }

    let content = (
      <div className={'bulkButtonContainer'}>
        <div className="saveButton">{window.locales.bulkUserAddCsv}</div>
        <input
          onChange={this.handleFileUpload.bind(this)}
          type="file"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '181px',
            height: '30px',
            opacity: '0',
          }}
        />
        {this.form.file && this.form.file && <div>{this.form.file}</div>}
        {this.state.error && <div className={'fileError'}>{this.state.error}</div>}
      </div>
    );

    let submit = {
      label: window.locales.add,
      click: this.formSubmit.bind(this),
    };

    let close = {
      label: window.locales.cancel,
      click: this.hideModal.bind(this),
    };

    if (this.log) {
      let temp = [];
      let l = [];

      l.push(
        window.locales.email +
          ',' +
          window.locales.bulkUserLogStatus +
          ',' +
          window.locales.errorMessage,
      );
      for (let k in this.log) {
        l.push(
          this.file[k].username +
            ',' +
            (this.log[k].status ? window.locales.done : window.locales.error) +
            (this.log[k].message ? ',' + this.log[k].message : ''),
        );
        temp.push(
          <div key={k} className={'bulkLogRow'}>
            {this.file[k].username}
            {this.log[k].status ? (
              <span className={'good'}>{window.locales.done}</span>
            ) : (
              <span className={'bad'}>{window.locales.error}</span>
            )}
            <span className={'badMessage'}>{this.log[k].message}</span>
          </div>,
        );
      }

      content = <div className={'bulkLog'}>{temp}</div>;

      submit = {
        label: window.locales.downloadUserUpdateLog,
        click: this.logDownload.bind(this, l.join('\n')),
      };
      close = {
        label: window.locales.close,
        click: this.hideModal.bind(this),
      };
    }

    return (
      <div className={'addUserContainer'}>
        <IconAddUsers
          id={'ADD_USER_BULK'}
          label={window.locales.usersAddMultiple}
          theme="blue"
          onClick={this.showModal.bind(this)}
        />
        <Modal
          title={window.locales.bulkUserDataUpload}
          onRef={(ref) => {
            this.modal = ref;
          }}
          message={this.state.message}
          cancel={close}
          submit={submit}
          style={{ width: '700px', marginLeft: '-350px' }}
        >
          <div className={'modalFileUploadContainer'}>{content}</div>
          {!this.log && (
            <div className={'exampleList'}>
              <span onClick={this.downloadExample.bind(this)}>
                {window.locales.downloadExample}
              </span>
              <IconQuestion onClick={this.openExampleDescription.bind(this)} />
            </div>
          )}
        </Modal>
      </div>
    );
  }

  openExampleDescription() {
    window.open('https://wifisb.zendesk.com/hc/en-us/articles/360016154252-How-to-Add-Users');
  }

  logDownload(text) {
    let date = new Date();
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute(
      'download',
      'USERS-UPLOAD_' +
        date.getFullYear() +
        (date.getMonth() + 1) +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        '.csv',
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.className = '';
    document.body.removeChild(element);
  }

  downloadExample() {
    let element = document.createElement('a');
    if (this.state.isUid) {
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(`test,test
test2,test2
test3,test3`),
      );
    } else {
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(`test@aftest.af,,Test
test2@aftest.af,test@aftest.af
test3@aftest.af`),
      );
    }
    element.setAttribute('download', 'example.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}

export default AddBulk;
