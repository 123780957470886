import React from "react";
import {Title, Description, PopupContextContainer} from "../../../components";

export default () => <>
    <PopupContextContainer>
        <Title>
            {window.locales.successful}
        </Title>
        <Description>
            {window.locales.successfulConfirmed}
        </Description>
    </PopupContextContainer>
</>