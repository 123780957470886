import styled from "styled-components";

export const TooltipContainer = styled.div<{contextTheme: string}>`
  background-color: ${(props) => (!!props.contextTheme ? '#ffffff' : '#59616F')};
  border-radius: 6px;
  padding: 15px;
  font-size: 12px;
  min-width: 160px;
  p {
    margin-top: 0;
    text-align: center;
    color: ${(props) => (!props.contextTheme ? '#ffffff' : '#000574')};
  }
  svg {
    margin-right: 5px;
  }
`;

export const Value = styled.label`
  margin-left: 5px;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemTooltip = styled.div`
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
`;

export const ItemMetric = styled.div<{contextTheme: string, selected: boolean}>`
  font-size: 13px;
  margin-bottom: 4px;
  display: flex;
  color: #b2b9c4;
  justify-content: space-between;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  :hover {
    opacity: 1;
    color: ${(props) => (props.contextTheme ? '#000000' : '#FFFFFF')};
  }
`;

export const NameItemMetric = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  color: inherit;
`;

export const OverflowContainer = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 9px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background: #252b33;
    opacity: 0.6;
  }

  &:hover::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  &:hover::-webkit-scrollbar {
    width: 8px;
  }
`;

export const LegendContainer = styled(OverflowContainer)`
  overflow: auto;
  height: 260px;
  width: 150px;
  padding-right: 10px;
  margin-left: 28%;
`;

export const LegendEntity = styled.div`
  font-size: 13px;
  position: absolute;
  text-transform: capitalize;
  top: -27px;
  :after {
    content: ':';
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TitleLegend = styled.div`
  width: 185px;
  display: flex;
  margin-left: 33px;
  justify-content: start;
  color: #ffffff;
`;

export const GraphContainer = styled.div<{contextTheme: string, opened: boolean}>`
  background-color: ${(props) => (!!props.contextTheme ? '#ffffff' : '#505763')};
  color: #b2b9c4;
  border-radius: 6px;
  width: ${(props) => (props.opened ? '98%' : '48%')};
  margin-bottom: 2%;
  font-size: 12px;
  border: ${(props) => (props.contextTheme === 'light' ? '1px solid #B2B9C4' : 'unset')};
  .recharts-tooltip-wrapper {
    z-index: 1;
  }
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const ZoomContainer = styled.div`
  cursor: pointer;
  margin-left: 16px;
`;

export const Titles = styled.div<{ width: string }>`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  width: ${(props) => props.width};
  margin: 24px;
`;

export const Circle = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;


export const ContainerNoFound = styled.div`
  position: relative;
  top: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #b2b9c4;
    width: 98px;
    margin-left: 10px;
  }
`;

