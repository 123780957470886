import React, {useEffect, useState} from "react";
import {PageContainer} from "../components";
import Header from "./Header";
import styled from "styled-components";
import Plans from './Plans'
import Invoices from './Invoices'
import {IPage} from "../../interfaces";
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {GetPublicKeyRequest} from "../../components/PaymentService";

const BillingContainer = styled.div`
  position: relative;

  @media only screen and (min-width: 1366px){
    padding-right: 20%;
  }
`;

export default ({activeProject}: IPage) => {
    const [chosenTab, setChosenType] = useState('Plans');
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>()
    useEffect(() => {
        GetPublicKeyRequest().then(result =>{
            setStripePromise(loadStripe(result.stripe_public_key))
        })
    }, []);

    return <PageContainer>
        <Header chosenTab={chosenTab} setChosenType={setChosenType} activeProject={activeProject}/>
        <BillingContainer>
            {
                chosenTab === 'Plans' && <Plans stripePromise={stripePromise} activeProject={activeProject}/>
            }
            {
                chosenTab === 'Invoices' && <Invoices projectId={activeProject.publickey}/>
            }
        </BillingContainer>
    </PageContainer>
}