import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from "react";
import {ThemeContext} from "../../../../ThemeContext";
import {editProject} from "../../../../components/Project";
import {
    ButtonContainer, ButtonSettings,
    FieldContainer,
    FieldInputContainer,
    FieldSelect,
    FieldValue,
    GeneralContainer,
    NameField,
    NameFieldContainer
} from "../../components";
import {IPage} from "../../../../interfaces";

const b2Mb = (num: string) => (Math.round(Number(num)*9.537*1e-7)).toString();
const mb2B = (num: string) => (Number(num)*1048576).toString();

export default ({activeProject}: IPage) => {
    const defaultConfig = useMemo(() => JSON.parse(activeProject.config), [activeProject.config])
    const [config, setConfig] = useState(defaultConfig);
    const [defaultBandwidthLimit, setDefaultBandwidthLimit] = useState(defaultConfig.default_traffic_limit ?
        b2Mb(defaultConfig.default_traffic_limit) : '');

    useEffect(() => {
        setConfig(defaultConfig);
        setDefaultBandwidthLimit(defaultConfig.default_traffic_limit ?
            b2Mb(defaultConfig.default_traffic_limit) : '');
    }, [defaultConfig])

    const context = useContext(ThemeContext);
    const isProduction = window.config.project === 'production';
    const setOptimalLocation = (event: ChangeEvent<HTMLSelectElement>) => setConfig({...config, 'disable_optimal_location': event.currentTarget.value});
    const saveConfig = async () => {
        const changes = {...config, 'default_traffic_limit': mb2B(defaultBandwidthLimit)}
        window.Loading.show();
        await editProject(activeProject.publickey, {config:  JSON.stringify(changes)});
        window.reloadPortal(undefined, true);
        window.Loading.hide();
    };

    return <GeneralContainer nameTheme={context.theme}>
        <FieldContainer>
            <NameFieldContainer>
                <NameField>{window.locales.bandwidthType}</NameField>
            </NameFieldContainer>
            <FieldInputContainer>
                <FieldValue>{window.locales[activeProject.traffic_limit_type]}</FieldValue>
            </FieldInputContainer>
        </FieldContainer>
        {activeProject.current_user_role.name === "OWNER" && <FieldContainer>
            <NameFieldContainer>
                <NameField>
                    {window.locales.optimalLocation}
                </NameField>
            </NameFieldContainer>
            <FieldInputContainer>
                <FieldSelect
                    defaultValue={config.disable_optimal_location}
                    isProduction={isProduction}
                    nameTheme={context.theme}
                    onChange={setOptimalLocation}>
                    <option value={'false'}>
                        {window.locales.enabled}
                    </option>
                    <option value={'true'}>
                        {window.locales.disabled}
                    </option>
                </FieldSelect>
            </FieldInputContainer>
        </FieldContainer>}
        <ButtonContainer>
            <ButtonSettings isProduction={isProduction} onClick={saveConfig}>
                {window.locales.saveChanges}
            </ButtonSettings>
        </ButtonContainer>
    </GeneralContainer>
}