import React, { Component } from 'react';

import Modal from '../../common/modal';

import { goTo, jsonBeautifier } from '../../common/methods';

import Table from '../../common/table';

import { deletePool, clonePool } from '../../components/Network';

import { Empty, Checkbox } from '../../common/form';

import { editProject } from '../../components/Project';

import { IconPen, IconDelete, IconExport, IconPlus } from '../../common/icons';

import EditPool from './EditPool';
import AddPool from './AddPool';

import PoolPage from './PoolPage';

class Pools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      i: 0,
    };
  }

  render() {
    if (!this.props.pools) return '';

    if (this.props.pool && this.props.pools[this.props.pool])
      return (
        <PoolPage
          parrentComponentMount={this.props.parrentComponentMount}
          pool={this.props.pools[this.props.pool]}
          updateActiveProject={this.props.updateActiveProject}
          activeProject={this.props.activeProject}
        />
      );

    let head = [
      ['', { width: '50px' }],
      ['Type', { width: '60px' }],
      ['Name', { width: '100px' }],
      ['Permissions', { width: '600px' }],
      //["Selector", {width: "200px"}],
      ['Description'],
      ['', { width: '25px' }],
      ['', { width: '25px' }],
      ['', { width: '25px' }],
    ];

    let body = [];

    let activePools = [];
    let config = {};
    try {
      config = JSON.parse(this.props.activeProject.config);
    } catch (e) {}

    if (config.server_pool) {
      activePools.push(config.server_pool);
    }

    config.server_pool = config.server_pool ? config.server_pool : '';
    config.private_pools = config.private_pools ? config.private_pools : [];

    for (let k in this.props.pools) {
      let pool = this.props.pools[k];

      let permissions = '';
      try {
        permissions = JSON.stringify(pool.permissions);
      } catch (e) {}

      let canWrite = false;
      for (let k in pool.permissions) {
        if (
          pool.permissions[k].subject === this.props.activeProject.publickey &&
          pool.permissions[k].can_write
        ) {
          canWrite = true;
          break;
        }
      }

      let type = 'public';
      try {
        type = pool.permissions[0].can_list ? 'private' : 'public';
      } catch (e) {}

      let permissionsText = window.locales.config;
      try {
        let tmp = [];
        for (let j in pool.permissions) {
          let pt = [];
          let prm = pool.permissions[j];
          for (let j1 in prm) {
            if (j1 === 'subject' || !prm[j1]) continue;
            pt.push(
              <div className="tag" key={j1}>
                {j1.replace('can_', '')}
              </div>,
            );
          }
          let sub = prm.subject === '*' ? 'everyone' : prm.subject;
          tmp.push(
            <div key={j} className="tagField">
              {sub}: {pt}
            </div>,
          );
        }

        permissionsText = tmp;
      } catch (e) {}

      permissions = JSON.parse(permissions);
      let pm = [];
      for (let k in permissions) {
        pm.push(<div key={k}>{jsonBeautifier(permissions[k])}</div>);
      }

      let c = (
        <div className={'absoluteDiv'}>
          <div className={'row'}>{pm}</div>
        </div>
      );

      body.push({
        row: [
          [
            <Checkbox
              theme={'blue small'}
              status={
                config.server_pool === pool.name || config.private_pools.indexOf(pool.name) !== -1
                  ? 'on'
                  : 'off'
              }
              changed={this.changePoolStatus.bind(
                this,
                pool.name,
                config.server_pool === pool.name || config.private_pools.indexOf(pool.name) !== -1
                  ? 'off'
                  : 'on',
                type,
              )}
            />,
            { noClick: true },
          ],
          [type],
          [pool.name],
          [
            <div>
              {permissionsText}
              {c}
            </div>,
          ],
          //[selector],
          [pool.description],
          [
            <IconExport theme={'blue'} onClick={this.clonePool.bind(this, pool.name)} />,
            { noClick: true },
          ],
          [
            canWrite ? (
              <IconPen theme={'blue'} onClick={this.updatePool.bind(this, pool.name)} />
            ) : (
              ''
            ),
            { noClick: true },
          ],
          [
            canWrite ? (
              <IconDelete theme={'blue'} onClick={this.showDeleteModal.bind(this, pool.name)} />
            ) : (
              ''
            ),
            { noClick: true },
          ],
        ],
        click: this.openPoolPage.bind(this, pool.name),
      });
    }

    return (
      <div id={'screenNetwork'} className="portalContentContainer">
        <h1>{window.locales.network}</h1>
        <div className={'navigation'}>
          <div onClick={this.changeTab.bind(this, 'network')}>
            {window.locales.locations}
            <div className={'line'} />
          </div>
          <div className={'active'}>
            Pools
            <div className={'line'} />
          </div>
        </div>
        <div>
          <IconPlus onClick={this.addPool.bind(this)} label={'Add pool'} theme={'blue'} />
        </div>
        {body.length > 0 && <Table head={head} body={body} />}
        {body.length === 0 && <Empty row1="No Pools" />}
        <EditPool
          parrentComponentMount={this.props.parrentComponentMount}
          activeProject={this.props.activeProject}
          onMount={(modal) => {
            this.editModal = modal;
          }}
        />
        <AddPool
          parrentComponentMount={this.props.parrentComponentMount}
          activeProject={this.props.activeProject}
          onMount={(modal) => {
            this.addModal = modal;
          }}
        />
        <Modal
          title={'Delete pool'}
          onRef={(ref) => {
            this.deleteModal = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: () => {
              this.deleteModal.hide();
            },
          }}
          submit={{
            label: 'Delete pool',
            click: () => {
              this.deletePoolSubmit();
            },
          }}
        >
          <div className={'bodyText'} style={{ marginBottom: '10px' }}>
            {'Do you want to delete pool?'}
          </div>
        </Modal>
      </div>
    );
  }

  showDeleteModal(name) {
    this.deletePoolName = name;
    this.deleteModal.show();
  }

  deletePoolSubmit() {
    this.deleteModal.hide();
    this.deletePool(this.deletePoolName);
  }

  openPoolPage(name) {
    goTo('pools/' + this.props.activeProject.publickey + '/' + name);
  }

  updatePool(name) {
    this.editModal(this.props.pools[name]);
  }

  addPool() {
    this.addModal({
      name: '',
      permissions: [],
      description: '',
    });
  }

  deletePool(name) {
    window.Loading.show();
    deletePool(name)
      .then(() => {
        window.Loading.hide();
        let config = {};
        try {
          config = JSON.parse(this.props.activeProject.config);
        } catch (e) {}

        if (config.server_pool && config.server_pool === name) {
          delete config.server_pool;
        }

        if (config.private_pools && config.private_pools.indexOf(name) !== -1) {
          config.private_pools.splice(config.private_pools.indexOf(name), 1);
        }

        editProject(this.props.activeProject.publickey, { config: JSON.stringify(config) })
          .then(() => {
            window.Loading.hide();
            this.props.parrentComponentMount();
          })
          .catch((e) => {
            window.Loading.hide();
            this.props.parrentComponentMount();
          });
      })
      .catch((e) => {
        window.Loading.hide();
      });
  }

  clonePool(name) {
    window.Loading.show();
    clonePool(name)
      .then(() => {
        window.Loading.hide();
        this.props.parrentComponentMount();
      })
      .catch((e) => {
        window.Loading.hide();
      });
  }

  changeTab() {
    goTo('network/' + this.props.activeProject.publickey);
  }

  changePoolStatus(name, status, type) {
    let config = {};
    try {
      config = JSON.parse(this.props.activeProject.config);
    } catch (e) {}

    if (status === 'off') {
      if (type === 'public' && (!config.private_pools || config.private_pools.length === 0))
        return this.setState({ i: this.state.i + 1 });
      else if (!config.server_pool && (!config.private_pools || config.private_pools.length < 2))
        return this.setState({ i: this.state.i + 1 });
    }

    if (type === 'public') {
      if (status === 'on') {
        if (config.private_pools && config.private_pools.indexOf(name) !== -1) {
          config.private_pools.splice(config.private_pools.indexOf(name), 1);
        }
        config.server_pool = name;
      } else delete config.server_pool;
    } else {
      if (!config.private_pools) config.private_pools = [];
      if (status === 'on') {
        if (config.server_pool === name) delete config.server_pool;

        config.private_pools.push(name);
      } else {
        if (config.private_pools.indexOf(name) !== -1) {
          config.private_pools.splice(config.private_pools.indexOf(name), 1);
        }
      }
    }

    window.Loading.show();
    editProject(this.props.activeProject.publickey, { config: JSON.stringify(config) })
      .then(() => {
        window.Loading.hide();
        let p = this.props.activeProject;
        p.config = JSON.stringify(config);
        this.props.updateActiveProject(p);
      })
      .catch((e) => {
        window.Loading.hide();
        this.props.updateActiveProject(this.props.activeProject);
      });
  }
}

export default Pools;
