import React from "react";
import {ButtonContainer, ButtonSubmit, InfoPopup, PopupContainer} from "../../components";
import {useTheme} from "../../../ThemeContext";

export default ({children, hidePopup}: {children: JSX.Element | null, hidePopup: () => void}) => {
    const [themeContext, env] = useTheme();
    return <PopupContainer>
        <InfoPopup nameTheme={themeContext.theme}>
            {children}
            <ButtonContainer>
                <ButtonSubmit isProduction={env} onClick={hidePopup}>
                    {window.locales.ok}
                </ButtonSubmit>
            </ButtonContainer>
        </InfoPopup>
    </PopupContainer>
}