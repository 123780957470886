import React from 'react';

export default (json, isPlain, originalOnError) => {
  let dump = json;
  json = transform(json);
  if (!json || typeof json !== 'object') {
    if (originalOnError) return dump;
    return undefined;
  }

  let isArray = Array.isArray(json);

  if (isPlain) {
    if (isArray) {
      return `[\n` + parse2(json) + `\n]`;
    } else {
      return `{\n` + parse2(json) + `\n}`;
    }
  } else {
    if (isArray) {
      return (
        <div className={'parsedJsonContainer'}>
          &#91;
          {parse(json)}
          &#93;
        </div>
      );
    } else {
      return (
        <div className={'parsedJsonContainer'}>
          &#123;
          {parse(json)}
          &#125;
        </div>
      );
    }
  }
};

let key = 0;

let parse = (json) => {
  if (!json) return undefined;

  let i = 0;
  let result = [];
  let length = Object.keys(json).length;
  let isArray = Array.isArray(json);

  for (let k in json) {
    let isArr;
    let value = json[k];
    let type = value ? typeof json[k] : undefined;
    let c = ++i < length ? <span className={'key'}>,</span> : undefined;

    if (!value) {
      type = value = <span className={'null'}>null</span>;
    } else if (type === 'object') {
      isArr = Array.isArray(value);
      value = parse(value);
    } else if (type === 'boolean')
      value = <span className={'boolean'}>{value ? 'true' : 'false'}</span>;
    else if (type === 'string') value = <span className={'string'}>"{value}"</span>;
    else value = <span className={'number'}>{value}</span>;

    if (isArray) {
      if (type === 'object') {
        if (isArr) {
          result.push(
            <div className={'jsonRow'} key={++key}>
              &#91;{value}&#93;{c}
            </div>,
          );
        } else {
          result.push(
            <div className={'jsonRow'} key={++key}>
              &#123;{value}&#125;{c}
            </div>,
          );
        }
      } else {
        result.push(
          <div className={'jsonRow'} key={++key}>
            {value}
            {c}
          </div>,
        );
      }
    } else {
      if (type === 'object') {
        if (isArr) {
          result.push(
            <div className={'jsonRow'} key={++key}>
              <span className={'key'}>"{k}"</span>: &#91;{value}&#93;{c}
            </div>,
          );
        } else {
          result.push(
            <div className={'jsonRow'} key={++key}>
              <span className={'key'}>"{k}"</span>: &#123;{value}&#125;{c}
            </div>,
          );
        }
      } else {
        result.push(
          <div className={'jsonRow'} key={++key}>
            <span className={'key'}>"{k}"</span>: {value}
            {c}
          </div>,
        );
      }
    }
  }

  return result;
};

let parse2 = (json, tab) => {
  tab = tab ? '  ' + tab : '  ';

  let i = 0;
  let result = [];
  let length = Object.keys(json).length;
  let isArray = Array.isArray(json);

  for (let k in json) {
    let isArr;
    let value = json[k];
    let type = typeof json[k];
    let c = ++i < length ? ',' : '';

    if (type === 'object') {
      isArr = Array.isArray(value);
      value = parse2(value, tab);
    } else if (type === 'boolean') value = value ? 'true' : 'false';
    else if (type === 'string') value = '"' + value + '"';

    if (isArray) {
      if (type === 'object') {
        if (isArr) {
          result.push(tab + `[\n` + value + `\n` + tab + `]` + c);
        } else {
          result.push(tab + `{\n` + value + `\n` + tab + `}` + c);
        }
      } else {
        result.push(tab + `` + value + `` + c);
      }
    } else {
      if (type === 'object') {
        if (isArr) {
          result.push(tab + `"` + k + `": [\n` + value + `\n` + tab + `]` + c);
        } else {
          result.push(tab + `"` + k + `": {\n` + value + `\n` + tab + `}` + c);
        }
      } else {
        result.push(tab + `"` + k + `": ` + value + `` + c);
      }
    }
  }

  return result.join('\n');
};

let transform = (json) => {
  if (typeof json === 'string') {
    try {
      json = JSON.parse(json);
    } catch (e) {
      return undefined;
    }
  } else if (typeof json !== 'object') return undefined;
  else {
    try {
      json = JSON.parse(JSON.stringify(json));
    } catch (e) {
      return undefined;
    }
  }

  return json;
};
