import React, { Component } from 'react';

class Android extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 16,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 16,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      label: props.label ? props.label : undefined,
      style: props.style ? props.style : {},
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 Android ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fill="#C4C3C3"
            fillRule="nonzero"
            d="M3.215 14.98c0 .5.32.833.799.833h.798v2.916c0 .667.559 1.25 1.198 1.25.638 0 1.197-.583 1.197-1.25v-2.916h1.597v2.916c0 .667.559 1.25 1.198 1.25.639 0 1.197-.583 1.197-1.25v-2.916h.799c.479 0 .798-.334.798-.834V6.646h-9.58v8.333zM1.22 6.645c-.639 0-1.198.583-1.198 1.25v5.833c0 .667.56 1.25 1.198 1.25.639 0 1.198-.583 1.198-1.25V7.896c0-.667-.56-1.25-1.198-1.25zm13.573 0c-.638 0-1.197.583-1.197 1.25v5.833c0 .667.559 1.25 1.197 1.25.64 0 1.198-.583 1.198-1.25V7.896c0-.667-.559-1.25-1.198-1.25zM10.8 1.813L11.838.729a.415.415 0 0 0 0-.583.375.375 0 0 0-.559 0l-1.197 1.25C9.522.979 8.804.812 8.006.812c-.799 0-1.517.167-2.156.5L4.732.062c-.16-.083-.479-.083-.639 0-.08.167-.08.5 0 .667l1.038 1.083c-1.118.917-1.916 2.334-1.916 4h9.581c0-1.666-.798-3.166-1.996-4zM6.41 4.146H5.61v-.833h.799v.833zm3.992 0h-.798v-.833h.798v.833z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Android;
