import styled from "styled-components";


export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const NameItem = styled.div`
  color: #b2b9c4;
  font-size: 14px;
`;
