import styled from "styled-components";
import {IColor} from "../../interfaces";

export const GeneralContainer = styled.div<IColor>`
    margin-top: 30px;
    width: 800px;
    color: ${props => props.nameTheme ?  '#4e5764' : '#ffffff'};
`;

export const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 14px;
`;

export const NameFieldContainer = styled.div`
`;

export const NameField = styled.div`
    font-weight: 700;
    width: 152px;
`;

export const FieldValue = styled.div`
`;

export const FieldInputContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const FieldInput = styled.input<IColor>`
  color: ${props => props.nameTheme ?  '#4e5764' : '#ffffff'};
  font-size: 16px;
  box-sizing: border-box;
  padding: 15px 25px;
  width: 100%;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.nameTheme ?  '#b3b9c3' : 'hsla(0,0%,89.8%,.3)'};
  background: transparent;
  outline: unset;
    :-webkit-autofill {
      -webkit-text-fill-color: ${(props) => (props.nameTheme ? '#000000' : '#ffffff')};
    }
    
  :disabled {
    opacity: 0.5;
  }
`;

export const FieldSelect = styled.select<IColor>`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.nameTheme ? '#b3b9c3' : 'hsla(0,0%,89.8%,.3)'};
  width: 100%;
  background: transparent;
  border-radius: 4px;
  font-size: 16px;
  outline: unset;
  color: ${(props) => props.nameTheme ? '#4e5764' : '#ffffff'};
  padding: 15px 35px 15px 16px;
`;

export const ButtonContainer = styled.div`
  padding-left: 152px;
`;

export const ButtonSettings = styled.button<IColor>`
  outline: unset;
  width: 180px;
  height: 50px;
  border-radius: 4px;
  background-color: ${(props) => props.isProduction ? '#2ea6f8' : '#ffa823'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border: unset;
`;