import Network from '../../common/Network';

import { getActiveProject } from '../Projects';

window.data.activeUser = undefined;
window.data.tokensUser = {};

const SAVED_TOKENS = 'Storage.SavedTokens';

const signIn = (project) => {
  return new Promise((resolve, reject) => {
    if (window.data.tokensUser[project.publickey]) {
      window.data.activeUser = window.data.tokensUser[project.publickey];
      return resolve();
    }

    let tokens = window.storage.get(SAVED_TOKENS) ? window.storage.get(SAVED_TOKENS) : {};
    let currentProject = window.config.project + '_' + project.publickey;
    if (typeof tokens !== 'undefined') {
      if (
        tokens[currentProject] &&
        tokens[currentProject].expire_time > Date.now() - 60 * 60 * 1000
      ) {
        window.data.activeUser = tokens[currentProject].access_token;
        window.data.tokensUser[project.publickey] = window.data.activeUser;
        return resolve();
      }
    }

    let request = window.config.host + 'partner/login';
    let params = {
      access_token: window.user.firebaseToken,
      login: project.publickey,
      password: project.privatekey,
    };

    Network.post(request, params)
      .then((response) => {
        try {
          if (response.response.result !== 'OK') return reject();
          window.data.activeUser = response.response.access_token;
          window.data.tokensUser[project.publickey] = window.data.activeUser;
          tokens[currentProject] = {
            access_token: response.response.access_token,
            expire_time: response.response.expire_time,
          };
          window.storage.set(SAVED_TOKENS, tokens);
          return resolve();
        } catch (e) {
          return reject();
        }
      })
      .catch((response) => {
        return reject();
      });
  });
};

const getUser = (uid) => {
  return new Promise((resolve, reject) => {
    let search = 'uid_' + uid;
    if (!uid.match(/^[0-9]+$/)) {
      search = 'unm_' + uid;
    }
    loadRegularUsers({ search: search })
      .then((user) => {
        if (user && user[0]) return resolve(user[0]);

        return reject();
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

const getUsers = (params, page) => {
  window.data.usersCursor = undefined;
  return new Promise((resolve, reject) => {
    if (!window.data.activeUser) return reject();
    let activeProject = getActiveProject();

    let method;

    if (activeProject.project_type === 'private_vpn') {
      method = loadPrivateUsers;
    } else {
      method = loadRegularUsers;
    }

    method(params, page)
      .then((users) => {
        return resolve(users);
      })
      .catch((e) => {
        return reject();
      });
  });
};

const loadPrivateUsers = (p, cursor) => {
  p = p ? p : {};
  return new Promise((resolve) => {
    let request = window.config.host + 'partner/users';
    //request = "http://0.0.0.0:8080/partner/users";
    let params = {
      access_token: window.data.activeUser,
    };

    if (p.search) {
      params.search = p.search;
    }
    if (p.order_field && p.order_value) {
      params.order_field = p.order_field;
      params.order_value = p.order_value;
    }
    if (cursor) {
      params.cursor = cursor;
    }
    if (p.status && p.status !== 'all') {
      params.status = p.status;
    }
    if (p.devices) {
      params.devices = p.devices;
    }
    if (p.devices_sign) {
      params.devices_sign = p.devices_sign;
    }
    if (p.registration_time_from) {
      params.registration_time_from = p.registration_time_from;
    }
    if (p.registration_time_till) {
      params.registration_time_till = p.registration_time_till;
    }

    Network.get(request, params)
      .then((response) => {
        if (response.response.cursor && response.response.cursor !== '0') {
          window.data.usersCursor = response.response.cursor;
        }

        return resolve(response.response.subscribers);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

const loadRegularUsers = (p, page) => {
  p = p ? p : {};
  return new Promise((resolve) => {
    let request = window.config.host + 'partner/subscribers';
    let params = {
      access_token: window.data.activeUser,
    };

    if (p.search) {
      let search = {
        uid: '/',
        unm: '/username/',
        utk: '/token/',
        uex: '/extref/',
        did: '/devices/',
      };

      let parsed = p.search.match(/^([a-z]+)_(.*)/);
      if (!parsed || !parsed[1] || !parsed[2] || typeof search[parsed[1]] === 'undefined')
        return resolve([]);

      request += search[parsed[1]] + parsed[2];
    }
    if (page) {
      params.page = page;
      params.size = 50;
    }

    Network.get(request, params)
      .then((response) => {
        resolve(
          response.response.subscriber
            ? [response.response.subscriber]
            : response.response.subscribers,
        );
      })
      .catch((response) => {
        resolve([]);
      });
  });
};

const loadUsers = (page) => {
  return new Promise((resolve, reject) => {
    if (!window.data.activeUser) return reject();
    let request = window.config.host + 'partner/subscribers';
    let params = {
      access_token: window.data.activeUser,
      size: 50,
      page: page ? page : 0,
    };

    Network.get(request, params)
      .then((response) => {
        return resolve(response.response.subscribers);
      })
      .catch((response) => {
        return reject();
      });
  });
};

const getActiveToken = () => {
  return window.data.activeUser;
};

const createUser = (username, licence, given_name) => {
  let request = window.config.host + 'partner/subscribers';
  let params = {
    access_token: window.data.activeUser,
    username: username,
    license_id: licence,
    given_name: given_name,
  };

  return Network.post(request, params)
    .then((response) => {
      if (response.response.result && response.response.result === 'USER_ALREADY_EXISTS') {
        return { success: false, response: { error: window.locales.error5 } };
      }
      return { success: true };
    })
    .catch((response) => {
      return { success: false, response: { error: 'Internal server error' } };
    });
};

const editUser = (uid, data) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/subscribers/' + uid;
    let params = {
      access_token: getActiveToken(),
      user_id: uid,
      name: data.name,
      license_id: data.license_id,
      extref: data.extref,
      given_name: data.given_name,
      country: data.country,
    };

    for (let k in params) {
      if (typeof params[k] === 'undefined') delete params[k];
    }

    Network.put(request, params)
      .then((response) => {
        return resolve();
      })
      .catch(() => {
        return reject({ message: 'Internal server error' });
      });
  });
};

const editUserData = (data) => {
  let request = window.config.host + 'partner/subscribers/' + data.id + '/userdata';
  let params = {
    access_token: getActiveToken(),
    delete: data.deleteData,
    export: data.exportData,
  };

  return Network.post(request, params);
};

const changeCondition = (subscriberId, condition) => {
  let request = window.config.host + 'partner/subscribers/' + subscriberId;
  let params = {
    access_token: getActiveToken(),
    condition: condition,
  };

  return Network.put(request, params);
};

const bulkUsersUpdate = (temp) => {
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/users/update';
    let params = {
      access_token: getActiveToken(),
    };

    let data = [];
    for (let k in temp) {
      temp[k].user_name = k;
      data.push(temp[k]);
    }

    return Network.put(request, params, data)
      .then((response) => {
        return resolve(response.response.response);
      })
      .catch((e) => {
        return reject({ message: e.message });
      });
  });
};

export {
  signIn,
  getUser,
  getUsers,
  editUser,
  loadUsers,
  createUser,
  editUserData,
  getActiveToken,
  changeCondition,
  bulkUsersUpdate,
  loadRegularUsers,
};
