import styled from 'styled-components';

export const VersionApplicationContainer = styled.div`
  color: #878c95;
  font-size: 10px;
  margin-top: 8px;
`;

export const VersionApplication = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
