import React, {Component} from 'react';

import DayPicker from 'react-day-picker';
import ReactInputMask from 'react-input-mask';

const MS_IN_DAY = 86400000;

const formatChars = {
  '1': '[0-2]',
  '2': '[0-9]',
  '3': '[0-5]',
  '4': '[0-9]'
};

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.last = new Date();
    this.last.setDate(1);
    this.last.setMonth(this.last.getMonth() - 1);

    this.state = {
      days: props.days,
      time: props.time,
      currentMonth: this.last,
      customTime: props.customTime,
      devicesDropdown: false,
      notValid: false,
    };

    this.callbacks = {
      closeCalendar: props.closeCalendar,
      updateSearch: props.updateSearch
    };

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      days: props.days,
      time: props.time,
      currentMonth: this.last,
      customTime: props.customTime,
      notValid: false
    });
  }

  handleDayClick(day) {
    let date = (day.getMonth() + 1) + '/' + day.getDate() + '/' + day.getFullYear();
    let days = this.state.days;

    let dayTs = new Date(date).getTime();
    let fromTs = new Date(days.from).getTime();
    let tillTs = new Date(days.till).getTime();

    if (fromTs === dayTs) {
      days.till = date;
    } else if (tillTs === dayTs) {
      days.from = date;
    } else if (fromTs > dayTs) {
      days.from = date;
    } else {
      days.till = date;
    }

    this.setState({
      days: days
    });
  }

  renderDay(day) {
    day.setHours(0, 0, 0);
    let className = this.checkIfSelected(day);

    return (
      <div className={'custom-day' + className}>
        {day.getDate()}
      </div>
    );
  }

  showToday() {
    let day = new Date();
    let date = (day.getMonth() + 1) + '/' + day.getDate() + '/' + day.getFullYear();

    this.setState({
      days: {
        from: date,
        till: date
      },
      currentMonth: this.last
    });
  }

  showThisWeek() {
    let till = new Date();
    let tillTs = till.getTime();
    let fromTs = tillTs - (till.getDay() * MS_IN_DAY);
    let from = new Date(fromTs);

    this.setState({
      days: {
        from: ((from.getMonth() + 1) + '/' + from.getDate() + '/' + from.getFullYear()),
        till: ((till.getMonth() + 1) + '/' + till.getDate() + '/' + till.getFullYear())
      },
      currentMonth: this.last
    });
  }

  showThisMonth() {
    let till = new Date();
    let tillDate = (till.getMonth() + 1) + '/' + till.getDate() + '/' + till.getFullYear();
    let from = new Date(tillDate);
    let fromDate = (from.getMonth() + 1) + '/1/' + from.getFullYear();

    this.setState({
      days: {
        from: fromDate,
        till: tillDate
      },
      currentMonth: this.last
    });
  }

  showYesterday() {
    let day = new Date(Date.now() - MS_IN_DAY);
    let date = (day.getMonth() + 1) + '/' + day.getDate() + '/' + day.getFullYear();

    this.setState({
      days: {
        from: date,
        till: date
      },
      currentMonth: this.last
    });
  }

  showLastWeek() {
    let today = new Date();
    let till = new Date(today.getTime() - (today.getDay() + 1) * MS_IN_DAY);
    let tillTs = till.getTime();
    let fromTs = tillTs - (6 * MS_IN_DAY);
    let from = new Date(fromTs);

    this.setState({
      days: {
        from: ((from.getMonth() + 1) + '/' + from.getDate() + '/' + from.getFullYear()),
        till: ((till.getMonth() + 1) + '/' + till.getDate() + '/' + till.getFullYear())
      },
      currentMonth: this.last
    });
  }

  showLastMonth() {
    let today = new Date();
    let till = new Date(today.getTime() - today.getDate() * MS_IN_DAY);

    this.setState({
      days: {
        from: ((till.getMonth() + 1) + '/1/' + till.getFullYear()),
        till: ((till.getMonth() + 1) + '/' + till.getDate() + '/' + till.getFullYear())
      },
      currentMonth: new Date((till.getMonth() + 1) + '/1/' + till.getFullYear())
    });
  }

  updateSearch() {
    let from = new Date(this.state.days.from + ' ' + this.state.time.from.replace(/_/g, '0')).getTime();
    let till = new Date(this.state.days.till + ' ' + this.state.time.till.replace(/_/g, '0')).getTime();

    if ((till - from) > 31 * MS_IN_DAY && this.props.activeProject.project_type !== "private_vpn") {
      return this.setState({
        notValid: true
      })
    }

    this.callbacks.updateSearch({
      days: this.state.days,
      time: {
        from: this.state.time.from.replace(/_/g, '0'),
        till: this.state.time.till.replace(/_/g, '0'),
      },
      customTime: this.state.customTime,
      notValid: false
    });
  }

  checkIfSelected(day) {
    let ts = day.getTime();
    let date = (day.getMonth() + 1) + '/' + day.getDate() + '/' + day.getFullYear();
    let from = new Date(this.state.days.from).getTime();
    let till = new Date(this.state.days.till).getTime() + MS_IN_DAY - 1;

    if (ts >= Date.now()) {
      return ' disabled';
    }

    if (!from && !till) return '';

    if (from > ts || till < ts) return '';

    let className = ' selected';

    if (this.state.days.from === date) {
      className += ' outer first';
    } else if (this.state.days.till === date) {
      className += ' outer last';
    } else {
      className += ' center';

      if (day.getDay() === 0) {
        className += ' first';
      } else if (day.getDay() === 6) {
        className += ' last';
      }
    }

    if (((till - from) / MS_IN_DAY) > 1) className += ' multiple';

    return className;
  }

  inputHandler(value, source) {
    let newState = this.state.time;
    newState[source] = value;

    let hrs = value.match(/^([0-9]+):/);
    if (hrs && hrs[1] && Number(hrs[1]) && Number(hrs[1]) > 23) return;

    this.setState({
      time: newState
    });
  }

  render() {
    return (
      <div className={"calendar_dropdown" + (this.state.notValid ? " notValid" : "")}>
        <div className={"errorMessageContainer"}>The maximum time interval is 31 days</div>
        <DayPicker
          onDayClick={this.handleDayClick.bind(this)}
          numberOfMonths={2}
          renderDay={this.renderDay.bind(this)}
          month={this.state.currentMonth}
          months={window.locales.fullMonth}
          weekdaysShort={window.locales.fullWeek}
          fixedWeeks/>
        <div className="DayPicker-delimiter"/>
        <div className="calendar_dropdown_options">
          <div className="calendar_dropdown_row">
            <div className="calendar_dropdown_item">
              <span onClick={() => this.showToday()}>{window.locales.today}</span>
            </div>
            <div className="calendar_dropdown_item">
              <span onClick={() => this.showThisWeek()}>{window.locales.thisWeek}</span>
            </div>
            <div className="calendar_dropdown_item">
              <span onClick={() => this.showThisMonth()}>{window.locales.thisMonth}</span>
            </div>
            <div className="calendar_dropdown_item">
              <span onClick={() => this.showYesterday()}>{window.locales.yesterday}</span>
            </div>
            <div className="calendar_dropdown_item">
              <span onClick={() => this.showLastWeek()}>{window.locales.lastWeek}</span>
            </div>
            <div className="calendar_dropdown_item">
              <span onClick={() => this.showLastMonth()}>{window.locales.lastMonth}</span>
            </div>
          </div>
        </div>
        <div className='customTimeRow'>
          <span>{window.locales.from}: </span>
          <div className={'form input default'}>
            <ReactInputMask
              mask="12:34"
              maskChar="_"
              value={this.state.time.from}
              onChange={(e) => this.inputHandler(e.target.value, 'from')}
              formatChars={formatChars}
            />
          </div>
          <span>{window.locales.till}: </span>
          <div className={'form input default last'}>
            <ReactInputMask
              mask="12:34"
              maskChar="_"
              value={this.state.time.till}
              onChange={(e) => this.inputHandler(e.target.value, 'till')}
              formatChars={formatChars}
            />
          </div>
        </div>
        <div className="footer">
          <div className="calendarButton" onClick={this.callbacks.closeCalendar}>{window.locales.cancel}</div>
          <div className="calendarButton blue" onClick={this.updateSearch.bind(this)}>{window.locales.search}</div>
        </div>
      </div>
    );
  }
}

export default Calendar;
