let project = window.storage.get('project') ? window.storage.get('project') : 'production';
let host = project === 'production' ? '/api/prod/' : '/api/stage/';

if (window.location.href.indexOf('partner-dev-88932.firebaseapp.com') !== -1) {
  host = '/api/stage/';
  project = 'STAGE';
}

window.config = {
  host: host,
  project: project,
  firebaseKey: 'AIzaSyBjJGXAQW9wuT7O1_bHMoLPaT6YSM1ELGU',
  firebaseAuthDomain: 'web-portal-for-partners.firebaseapp.com',
  firebasedatabaseURL: 'https://web-portal-for-partners.firebaseio.com',
  firebaseToken: window.storage.get('firebase')
    ? window.storage.get('firebase').firebaseToken
    : undefined,
};