import Network from '../../common/Network';
import { getActiveToken } from '../../components/Users';

const loadSessions = (uid, from, till) => {
  return new Promise((resolve) => {
    let request = window.config.host + 'partner/subscribers/' + uid + '/sessions';
    let params = {
      access_token: getActiveToken(),
      start_time: from,
      end_time: till,
    };

    return Network.get(request, params)
      .then((response) => {
        return resolve(
          response.response.sessions.sort((a, b) => {
            return a.end_time - b.end_time;
          }),
        );
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

const loadActiveSessions = (uid) => {
  return new Promise((resolve) => {
    let request = window.config.host + 'partner/subscribers/' + uid + '/active_sessions';
    let params = {
      access_token: getActiveToken(),
    };

    return Network.get(request, params)
      .then((response) => {
        return resolve(response.response.sessions);
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

const loadProjectActiveSessions = () => {
  return new Promise((resolve) => {
    let request = window.config.host + 'partner/active_sessions';
    let params = {
      access_token: getActiveToken(),
    };

    return Network.get(request, params)
      .then((response) => {
        return resolve(response.response.sessions);
      })
      .catch(() => {
        return resolve([]);
      });
  });
};

export { loadSessions, loadActiveSessions, loadProjectActiveSessions };
