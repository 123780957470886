import React, {Component} from 'react';

import Table from '../../../common/table';

import {
  getDateString,
  byteConvert
} from '../../../common/methods';

import {
  IconDelete,
} from '../../../common/icons';

import {
  getDevices,
  deleteDevice
} from '../../../components/Devices';

import Modal from '../../../common/modal';
import {
  Empty
} from "../../../common/form";

class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: [],
      user: props.user,
      deviceId: undefined,
      disabled: false,
      message: ''
    };

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      user: props.user,
    }, this.componentDidMount.bind(this));
  }

  componentDidMount() {
    getDevices(this.state.user.id, true).then((devices) => {
      this.setState({
        devices: devices
      })
    });
  }

  toggleDeleteModal(id) {
    this.setState({
      deviceId: id,
      disabled: false
    }, () => {
      this.modal.show();
    });
  }

  deleteDevice() {
    this.setState({
      message: '',
      disabled: true
    }, () => {
      deleteDevice(this.state.user.id, this.state.deviceId).then(() => {
        getDevices(this.state.user.id, true).then((devices) => {
          this.setState({
            message: '',
            disabled: false,
            devices: devices
          }, () => {
            this.modal.hide();
          });
        });
      }).catch((e) => {
        this.setState({
          message: e.message,
          disabled: false
        })
      });
    });
  }

  render() {
    if (this.state.devices.length === 0) {
      return <Empty row1={window.locales.noDeviceRow}/>;
    }

    let head = [
      [window.locales.did],
      [window.locales.accessToken],
      [window.locales.name],
      [window.locales.deviceType],
      [window.locales.registrationTime],
      []
    ];

    let deviceTraffic;
    if (this.props.activeProject.traffic_limit_type && this.props.activeProject.traffic_limit_type === "by_device" && !this.props.traffic.traffic_unlimited) {
      head = [
        [window.locales.did],
        [window.locales.accessToken],
        [window.locales.name],
        [window.locales.deviceType],
        [window.locales.registrationTime],
        ["Traffic limit"],
        ["Traffic used"],
        ["Traffic remaining"],
        []
      ];

      let temp = this.props.traffic && this.props.traffic.traffic_limit_devices ? this.props.traffic.traffic_limit_devices : {};
      deviceTraffic = {};

      for(let k in this.state.devices) {
        let device = this.state.devices[k];
        for(let j in temp) {
          let t = temp[j];
          if(device.device_id === t.device_id) {
            deviceTraffic[device.device_id] = {
              limit: t.traffic_limit,
              remaining: t.traffic_remaining,
              used: t.traffic_used,
            };
            break;
          }
        }

        if(!deviceTraffic[device.device_id]) {
          deviceTraffic[device.device_id] = {
            limit: this.props.traffic.traffic_limit,
            remaining: this.props.traffic.traffic_limit,
            used: 0,
          };
        }
      }
    }

    let body = [];
    for(let k in this.state.devices) {
      let device = this.state.devices[k];
      let date = getDateString(device.registration_time);

      if (deviceTraffic) {
        let limit = byteConvert(deviceTraffic[device.device_id].limit);
        let used = byteConvert(deviceTraffic[device.device_id].used);
        let remaining = byteConvert(deviceTraffic[device.device_id].remaining);

        body.push([
          [device.device_id],
          [device.access_token],
          [device.name],
          [device.type],
          [(date ? (date.date + ' ' + date.time) : '')],
          [limit.size + " " + limit.type],
          [used.size + " " + used.type],
          [remaining.size + " " + remaining.type],
          [<IconDelete theme={'blue'} onClick={this.toggleDeleteModal.bind(this, device.device_id)}/>]
        ]);
      } else {
        body.push([
          [device.device_id],
          [device.access_token],
          [device.name],
          [device.type],
          [(date ? (date.date + ' ' + date.time) : '')],
          [<IconDelete theme={'blue'} onClick={this.toggleDeleteModal.bind(this, device.device_id)}/>]
        ]);
      }
    }

    return (
      <div className={'screenUsersContentContainer'}>
        <Table head={head} body={body}/>
        <Modal
          title={window.locales.deleteDevice}
          onRef={ref => {
            this.modal = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: () => {
              this.modal.hide();
            }
          }}
          submit={{
            label: window.locales.deleteDevice,
            click: this.deleteDevice.bind(this)
          }}
        >
          <div className={'bodyText'}>
            {window.locales.deleteDeviceConfirmation}
          </div>
        </Modal>
      </div>
    );
  }
}

export default Devices;
