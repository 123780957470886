import React, { Component } from 'react';

class Sessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 28,
      height: props.height ? props.height : 26,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 28,
      height: props.height ? props.height : 26,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 sessions ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path d="M16.602 11.877c-.006 2.578-2.064 4.623-4.669 4.602a4.597 4.597 0 0 1-4.535-4.644 4.598 4.598 0 0 1 4.563-4.571 4.59 4.59 0 0 1 4.641 4.613zm-1.534-.003a3.048 3.048 0 0 0-3.022-3.073c-1.73-.012-3.11 1.355-3.117 3.042a3.064 3.064 0 0 0 3.125 3.099 3.046 3.046 0 0 0 3.015-3.068zM17.537 21.322l-1.533-2.343c-.51-.783-1.024-1.565-1.544-2.36l.69-.442 3.075 4.702c.678-.245 1.33-.21 1.949.164.415.248.731.632.894 1.088a2.149 2.149 0 0 1-3.589 2.204c-.631-.673-.911-1.967.058-3.013zM17.303 12.573l.044-.812 5.393.346c.038-.105.07-.2.108-.292.373-.918 1.387-1.476 2.354-1.297 1.003.187 1.75 1.041 1.776 2.033.03 1.163-.773 2.096-1.949 2.229-.971.109-2.036-.528-2.293-1.7-.028-.124-.074-.162-.196-.17-1.582-.096-3.163-.195-4.745-.297-.164-.01-.327-.026-.492-.04zM19.53 4.9l-3.563 3.447-.53-.624 3.546-3.433c-.32-.569-.393-1.163-.189-1.783.155-.487.483-.9.923-1.16a2.132 2.132 0 0 1 2.775.48c.672.819.645 2.005-.063 2.793-.66.736-1.842 1.017-2.898.28zM4.387 18.506l3.427-3.31.56.6-3.41 3.297c.357.577.457 1.192.257 1.846-.145.484-.462.9-.89 1.167a2.17 2.17 0 0 1-2.751-.37 2.168 2.168 0 0 1-.093-2.774c.607-.775 1.8-1.136 2.9-.456zM5.203 8.586c-.857.993-2.126 1.005-2.978.374a2.16 2.16 0 0 1-.59-2.761 2.155 2.155 0 0 1 2.652-.963c1.046.412 1.569 1.46 1.289 2.62l.98.539.98.538-.39.72-1.943-1.067z" />
        </svg>
      </div>
    );
  }
}

export default Sessions;
