import { goTo, parseUrl } from '../../common/methods';

export default (newProject) => {
  let current = parseUrl();
  if (!current.path[0]) return;

  if (
    [
      'users',
      'projects',
      'network',
      'settings',
      'dashboard',
      'active_sessions',
      'export',
      'event',
      'pools',
      'billing',
      'vpn',
    ].indexOf(current.path[0]) !== -1
  ) {
    current.path[1] = newProject;
    goTo(current.path.join('/'));
  }
  if (current.path[0] === 'user') {
    goTo('users/' + newProject);
  }
};
