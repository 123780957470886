import React, { Component } from 'react';

class SignOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 language ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M7.397 6.654A2.402 2.402 0 0 0 8.97 5.527c1.633.471 3.201 1.215 4.676 2.196a2.387 2.387 0 0 0-.086.36c-2.103.64-4.1 1.683-5.93 3.094-.18-.09-.371-.159-.573-.2.015-1.455.129-2.902.341-4.323zm.956-4.259c.243-.803.518-1.591.825-2.359.271-.022.544-.036.82-.036 4.609 0 8.498 3.133 9.652 7.38a16.93 16.93 0 0 0-1.125-.04c-.16 0-.32.005-.48.01a2.398 2.398 0 0 0-3.599-.769A18.264 18.264 0 0 0 9.3 4.177a2.391 2.391 0 0 0-.947-1.782zm9.955 6.337c.072-.001.145-.004.217-.004.467 0 .935.029 1.4.07.048.394.075.794.075 1.2a9.99 9.99 0 0 1-.16 1.776 23.994 23.994 0 0 0-1.81-2.159c.147-.267.243-.566.278-.883zM4.64 3.526c-.805.001-1.609.065-2.406.18A10.014 10.014 0 0 1 7.594.294a29.992 29.992 0 0 0-.544 1.62c-.048-.002-.095-.007-.143-.007a2.398 2.398 0 0 0-2.266 1.62zm12.392 7.067a23.13 23.13 0 0 1 2.333 2.908C17.943 17.292 14.28 20 9.998 20c-.596 0-1.18-.055-1.749-.156a30.116 30.116 0 0 1-.902-4.257 2.398 2.398 0 0 0 1.303-3.44c1.587-1.195 3.302-2.094 5.1-2.67a2.397 2.397 0 0 0 3.283 1.116zM4.367 14.29a25.076 25.076 0 0 0-1.804 2.386A9.964 9.964 0 0 1 0 9.998c0-1.69.423-3.282 1.166-4.678a15.54 15.54 0 0 1 3.424-.405 2.4 2.4 0 0 0 1.424 1.616 32.786 32.786 0 0 0-.347 4.567 2.398 2.398 0 0 0-1.3 3.193zm.92 1.062c.204.128.429.226.668.289.172 1.287.421 2.554.743 3.796a9.998 9.998 0 0 1-3.12-1.78c.537-.82 1.107-1.59 1.71-2.305z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default SignOut;
