import React, { Component } from 'react';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 profile ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M20.748 4.473c-.084-.273-.399-.315-.609-.126l-3.547 3.528a.711.711 0 0 1-.965 0l-2.498-2.499c-.252-.252-.252-.693 0-.966l3.59-3.549c.167-.168.083-.483-.127-.609A7.183 7.183 0 0 0 14.703 0c-3.715 0-6.696 3.192-6.255 6.993.084.609.21 1.134.441 1.659L.724 16.8c-.965.966-.965 2.541 0 3.465A2.503 2.503 0 0 0 2.466 21c.609 0 1.26-.273 1.742-.735l8.144-8.127c.525.21 1.092.357 1.658.441 3.8.42 6.99-2.562 6.99-6.279 0-.651-.084-1.26-.252-1.827z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Profile;
