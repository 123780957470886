import React, {Component} from 'react';

import Table from '../../../common/table';

import {
  getDateString
} from '../../../common/methods';

import {
  IconPlay,
  IconDelete,
} from '../../../common/icons';

import Modal from '../../../common/modal';

import {
  getPurchases,
  deletePurchases,
  activatePurchase
} from "../../../components/Purchases";

import {
  getActiveToken
} from "../../../components/Users";

import {
  Empty
} from '../../../common/form';

class Purchases extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchases: [],
      user: props.user,
      purchaseId: undefined,
      disabled: false,
      message: ''
    };

    this.dump = JSON.stringify(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.setState({
      user: props.user,
    }, this.componentDidMount.bind(this));
  }

  componentDidMount() {
    getPurchases(this.state.user.id).then((purchases) => {
      this.setState({
        purchases: purchases
      })
    });
  }

  disablePurchase(pid) {
    this.setState({
      message: '',
      disabled: true
    }, () => {
      deletePurchases(this.state.user.id, this.pid).then(() => {
        getPurchases(this.state.user.id, true).then((purchases) => {
          this.setState({
            message: '',
            disabled: false,
            purchases: purchases
          }, () => {
            this.modalDisable.hide();
          });
        });
      }).catch((e) => {
        this.setState({
          message: e.message,
          disabled: false
        })
      });
    });
  }

  enablePurchase(pid) {
    this.setState({
      message: '',
      disabled: true
    }, () => {
      activatePurchase(this.state.user.id, this.pid).then(() => {
        getPurchases(this.state.user.id, true).then((purchases) => {
          this.setState({
            message: '',
            disabled: false,
            purchases: purchases
          }, () => {
            this.modalEnable.hide();
          });
        });
      }).catch((e) => {
        this.setState({
          message: e.message,
          disabled: false
        })
      });
    });
  }

  toggleDisable(pid) {
    this.pid = pid;
    this.modalDisable.show();
  }

  toggleEnable(pid) {
    this.pid = pid;
    this.modalEnable.show();
  }

  render() {
    if (this.state.purchases.length === 0) {
      return <Empty row1={window.locales.noPurchasesRow}/>;
    }

    let head = [
      [window.locales.transactionId],
      [window.locales.productId],
      [window.locales.type, {center: true}],
      [window.locales.transactionTime],
      [window.locales.expirationTime],
      [window.locales.active, {center: true}],
      [window.locales.subscription, {center: true}],
      [window.locales.trial, {center: true}],
      [window.locales.rawData, {center: true}],
      []
    ];

    let body = [];
    for (let k in this.state.purchases) {
      let purchase = this.state.purchases[k];
      if (!purchase.id) continue;

      let data = {
        id: purchase.id,
        type: purchase.type,
        trial: 'No',
        subscription: 'No',
        active: purchase.active_purchase ? 'Yes' : 'No',
        time: getDateString(purchase.check_time),
        expiration: '',
        productId: ''
      };

      switch (purchase.type) {
        case 'apple_primary':
          data.type = 'iTunes';
          break;
        case 'google':
          data.type = 'Google';
          break;
        case 'elite':
          data.type = 'Elite';
          break;
	default:
	  break;
      }

      if (purchase.response_data && !purchase.purchase_data) {
        try {
          purchase.purchase_data = JSON.parse(purchase.response_data);
        } catch (e) {
        }
      }

      if (purchase.purchase_data && purchase.type === 'google') {
        let temp = purchase.purchase_data;

        data.id = temp.orderId ? temp.orderId : data.id;
        data.subscription = temp.autoRenewing ? 'Yes' : 'No';
        data.expiration = getDateString(temp.expiryTimeMillis);
      } else if (purchase.purchase_data && purchase.purchase_data.receipt && purchase.purchase_data.receipt.in_app && purchase.purchase_data.receipt.in_app[0]) {
        let temp = purchase.purchase_data.receipt.in_app[0];

        data.id = temp.transaction_id ? temp.transaction_id : data.id;
        data.productId = temp.product_id ? temp.product_id : '';
        data.expiration = getDateString(temp.expires_date_ms);
        data.trial = temp.is_trial_period === 'true' ? 'Yes' : 'No';
      }

      let icon = purchase.active_purchase
        ? <IconDelete theme={'blue'} onClick={this.toggleDisable.bind(this, purchase.id)}/>
        : <IconPlay theme={'blue r270'} onClick={this.toggleEnable.bind(this, purchase.id)}/>;

      body.push([
        [data.id.toString()],
        [data.productId],
        [data.type, {center: true}],
        [(data.time ? (data.time.date + ' ' + data.time.time) : '')],
        [(data.expiration ? (data.expiration.date + ' ' + data.expiration.time) : '')],
        [data.active, {center: true}],
        [data.subscription, {center: true}],
        [data.trial, {center: true}],
        [<a
          href={window.config.host + "partner/subscribers/" + this.state.user.id + '/purchase?access_token=' + getActiveToken()}
          target="_blank" rel="noopener noreferrer">JSON</a>, {center: true}],
        [icon, {center: true}]
      ]);
    }

    return (
      <div className={'screenUsersContentContainer'}>
        <Table head={head} body={body}/>
        <Modal
          title={window.locales.disablePurchase}
          onRef={ref => {
            this.modalDisable = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: () => {
              this.modalDisable.hide();
            }
          }}
          submit={{
            label: window.locales.disablePurchase,
            click: this.disablePurchase.bind(this)
          }}
        >
          <div className={'bodyText'}>
            {window.locales.disablePurchaseConfirmation}
          </div>
        </Modal>
        <Modal
          title={window.locales.activatePurchase}
          onRef={ref => {
            this.modalEnable = ref;
          }}
          message={this.state.message}
          cancel={{
            label: window.locales.cancel,
            click: () => {
              this.modalEnable.hide();
            }
          }}
          submit={{
            label: window.locales.activatePurchase,
            click: this.enablePurchase.bind(this)
          }}
        >
          <div className={'bodyText'}>
            {window.locales.enablePurchaseConfirmation}
          </div>
        </Modal>
      </div>
    );
  }
}

export default Purchases;
