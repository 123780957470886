import React, { Component } from 'react';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 22,
      height: props.height ? props.height : 22,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 22,
      height: props.height ? props.height : 22,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 settings ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M21.16 8.93l-1.307-.56a1.394 1.394 0 0 1-.716-.684v-.062a1.458 1.458 0 0 1 0-1.027l.53-1.307a1.08 1.08 0 0 0-.156-1.338l-1.463-1.463a1.083 1.083 0 0 0-1.307-.155l-1.338.529a1.365 1.365 0 0 1-1.774-.747L13.07.84A1.035 1.035 0 0 0 12.042 0H9.958C9.46 0 9.024.342 8.93.81l-.56 1.306a1.417 1.417 0 0 1-1.774.747l-1.307-.53c-.405-.28-.965-.217-1.338.156L2.489 3.952a1.083 1.083 0 0 0-.155 1.307l.529 1.338a1.365 1.365 0 0 1-.747 1.774L.84 8.93C.342 9.024 0 9.46 0 9.958v2.084c0 .498.342.934.81 1.027l1.306.56c.685.312.996 1.09.747 1.774l-.53 1.307c-.28.436-.217.965.156 1.338l1.463 1.463c.342.342.902.404 1.307.155l1.338-.529a1.365 1.365 0 0 1 1.774.747l.56 1.276c.093.498.529.84 1.027.84h2.084c.498 0 .934-.342 1.027-.81l.56-1.306a1.417 1.417 0 0 1 1.774-.747l1.307.53c.405.28.965.217 1.338-.156l1.463-1.463c.342-.342.404-.902.155-1.307l-.529-1.338a1.365 1.365 0 0 1 .747-1.774l1.276-.56c.498-.093.84-.529.84-1.027V9.958c0-.498-.342-.934-.84-1.027zm-10.176 6.566a4.515 4.515 0 0 1-4.512-4.512 4.515 4.515 0 0 1 4.512-4.512 4.515 4.515 0 0 1 4.512 4.512 4.515 4.515 0 0 1-4.512 4.512z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Settings;
