import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  margin-right: 5px;
`;

export default (props) => (
  <Icon width={props.width} viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7936 8.00781C12.2182 8.00781 8.49756 11.6036 8.49756 16.0254H12.5199C12.5199 13.7481 14.4372 11.8919 16.797 11.8919C19.1534 11.8919 21.0741 13.7449 21.0741 16.0254H25.0964C25.0897 11.6068 21.3657 8.00781 16.7936 8.00781Z"
      fill={props.color}
    />
    <path
      d="M16.7935 0.0679932C7.68956 0.0679932 0.281738 7.22715 0.281738 16.0255H4.30408C4.30408 9.36842 9.90856 3.95208 16.7968 3.95208C21.8851 3.95208 26.4236 6.89349 28.3577 11.4417L32.0784 9.96447C29.5175 3.95532 23.5208 0.0679932 16.7935 0.0679932Z"
      fill={props.color}
    />
  </Icon>
);
