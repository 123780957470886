import React, {ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    ButtonContainer, ButtonSettings,
    FieldContainer, FieldInput,
    FieldInputContainer,
    GeneralContainer,
    NameField,
    NameFieldContainer
} from "../components";
import {ThemeContext} from "../../../ThemeContext";
// @ts-ignore
import { Multiselect } from "multiselect-react-dropdown";
import {editProject} from "../../../components/Project";
import {
    InputContainer,
    InputName,
    SelectContainer,
    TwoFieldContainer,
    CustomCheckbox,
    ElementsContainer, WrapInputContainer
} from "./components";
import {IPage, IFieldsInputDomain} from "../../../interfaces";

const Services = [
    { key: "bbss", service: "bbss" },
    { key: "ip", service: "ip" },
    { key: "bitdefender", service: "bitdefender" },
    ];

const Categories = [
    { key: "safe", category: "safe" },
    { key: "unsafe", category: "unsafe" },
    { key: "unsafe:malware", category: "unsafe:malware" },
    { key: "unsafe:untrusted", category: "unsafe:untrusted" },
    { key: "unsafe:trackers", category: "unsafe:trackers" },
    { key: "unsafe:phishing", category: "unsafe:phishing" },
    { key: "custom", category: "custom" },
    ];

const Types = [
    { key: "bypass", type: "bypass" },
    { key: "vpn", type: "vpn" },
    { key: "proxy_peer", type: "proxy_peer" },
    { key: "block_dns", type: "block_dns" },
    { key: "block_alert_page", type: "block_alert_page" },
    ];


const FieldsInputDomain: FC<IFieldsInputDomain> = (props) => {

    const [domainsUrl, setDomainsUrl] = useState(props.domainsUrl);
    const changeDomainsUrl = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        domainsUrl[e.currentTarget.name][index] = e.currentTarget.value;
        setDomainsUrl({...domainsUrl})
    }, [setDomainsUrl, domainsUrl]);

    const changeFieldDomain = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if (!e.currentTarget.value) {
            domainsUrl[e.currentTarget.name].splice(index, 1);
            setDomainsUrl({...domainsUrl})
        } else {
            changeDomainsUrl(e, index);
            e.currentTarget.value = '';
        }

        props.changeDomainList({...domainsUrl});
    }, [changeDomainsUrl, domainsUrl, props]);

    return <>{
        domainsUrl[props.domain] && domainsUrl[props.domain].map((url: string, index: number) => {
            return <FieldInput key={index}
                        onChange={(e) => changeDomainsUrl(e, index)}
                        onBlur={(e) => changeFieldDomain(e, index)}
                        name={props.domain}
                        nameTheme={props.theme}
                        value={domainsUrl[props.domain][index]}
                        placeholder={'example.com'}/>
        })
    }
        <FieldInput
                    onBlur={(e) => changeFieldDomain(e, domainsUrl[props.domain].length)}
                    name={props.domain}
                    nameTheme={props.theme}
                    placeholder={'example.com'}/>
    </>
}

type SelectorValue = {key: string, value: string}

export default ({activeProject}: IPage) => {
    const defaultConfig = useMemo(() => JSON.parse(activeProject.config), [activeProject.config])
    const context = useContext(ThemeContext);
    const [config, setConfig] = useState(defaultConfig);
    const [fireshieldConfig, setFireshieldConfig] = useState(defaultConfig.fireshield ?? {});
    const isProduction = window.config.project === 'production';

    const styleSelect = useMemo(() => {
        return {
            option: {
                background: context.theme ? '#FFFFFF' : '#3e4651',
                color: 'unset'
            },
            searchBox: {
                padding: '12px 25px',
                boxSizing: 'border-box',
                height: '50px',
                borderColor: context.theme ? '#b3b9c3' : 'hsla(0,0%,89.8%,.3)',
                background: context.theme ? '#FFFFFF' : '#3e4651',
                fontSize: '16px',
            },
            chips: {
                fontSize: '16px',
            },
        }
    }, [context.theme])

    const parseTypes = useCallback(() => fireshieldConfig.categories ?
        fireshieldConfig.categories.filter((categoryObj: {category: string, type: string}) =>
            Types.findIndex((typePair) =>
                typePair.key === categoryObj.type) !== -1).map((categoryObj: {category: string, type: string}) =>
            Types[Types.findIndex((typePair) =>
                typePair.key === categoryObj.type)]
        ) : [], [fireshieldConfig.categories])

    const [types, setTypes] = useState<any>(() => parseTypes());

    const parseCategories = useCallback(() => fireshieldConfig.categories ?
        fireshieldConfig.categories.filter((categoryObj: {category: string, type: string}) =>
            Categories.findIndex((categoryPair) =>
                categoryPair.key === categoryObj.category) !== -1
        ).map((categoryObj: {category: string, type: string}) =>
            Categories[Categories.findIndex((categoryPair) =>
                categoryPair.key === categoryObj.category)]) : [],[fireshieldConfig.categories])

    const [categories, setCategories] = useState<any>(() => parseCategories());

    const parseServices = useCallback(() => fireshieldConfig.services ?
        fireshieldConfig.services.filter((service: string) =>
        Services.findIndex((serviceObj) => serviceObj.key === service) !== -1
    ).map((service: string) => Services[Services.findIndex((serviceObj) =>
        serviceObj.key === service)]): [], [fireshieldConfig.services]);

    const [services, setServices] = useState<any>(() => parseServices());

    const [enabled, setEnabled] = useState(fireshieldConfig.enabled ? fireshieldConfig.enabled : false);
    const [domain, setDomain] = useState(fireshieldConfig.alert_page ? fireshieldConfig.alert_page.domain : '');

    const [path, setPath] = useState(fireshieldConfig.alert_page ? fireshieldConfig.alert_page.path : '');

    const parseDomains = useCallback(() => fireshieldConfig.domains ?
        Object.keys(fireshieldConfig.domains).filter(key =>
            Categories.findIndex((categoryPair) =>
                categoryPair.key === key) !== -1
        ).map((key) => Categories[Categories.findIndex((categoryPair) =>
            categoryPair.key === key)])
        : [], [fireshieldConfig.domains])

    const [domains, setDomains] = useState<any>(() => parseDomains());

    const [domainsUrl, setDomainsUrl] = useState(fireshieldConfig.domains ?? {});

    useEffect(() => {
        setConfig(defaultConfig);
        setFireshieldConfig(defaultConfig.fireshield ?? {})
        setTypes(parseTypes());
        setCategories(parseCategories());
        setServices(parseServices());
        setEnabled(fireshieldConfig.enabled ? fireshieldConfig.enabled : false);
        setDomain(fireshieldConfig.alert_page ? fireshieldConfig.alert_page.domain : '');
        setPath(fireshieldConfig.alert_page ? fireshieldConfig.alert_page.path : '');
        setDomains(parseDomains());
        setDomainsUrl(fireshieldConfig.domains ?? {});
    }, [defaultConfig, fireshieldConfig.alert_page, fireshieldConfig.domains, fireshieldConfig.enabled,
        parseCategories, parseDomains, parseServices, parseTypes])

    const saveConfig = async () =>{
        window.Loading.show();
        const listCategories = categories.filter((categoryObj: any, index: number) => types[index]).map((categoryObj: any, index: number) =>{
            return {
                category: categoryObj.key,
                type: types[index].key
            }
        });

        Object.entries(domainsUrl).forEach((domainUrl: any)=>{
            const [key, urls] = domainUrl;
            urls.length === 0 && delete domainsUrl[key];
        });

        const fireshieldConfig = {
            enabled: enabled,
            alert_page: {domain: domain, path: path},
            services: services.map((service: {key: string, service: string}) => service.key),
            categories: listCategories,
            domains: domainsUrl,
        };

        const changes = {...config, 'fireshield': fireshieldConfig}
        await editProject(activeProject.publickey, {config:  JSON.stringify(changes)});

        window.reloadPortal(undefined, true);
        window.Loading.hide();
    }

    const selectedCategories = useCallback((selectedCategory:  SelectorValue[]) =>
        setCategories([...categories, selectedCategory[0]]),[categories]);

    const selectedServices = useCallback((selectedService: SelectorValue[]) =>
        setServices(selectedService),[]);

    const selectedTypes = useCallback((selectedType:  SelectorValue[]) =>
        setTypes([...types, selectedType[0]]),[types]);
    
    const selectedDomains = useCallback((selectedDomain: SelectorValue[]) => {
        domainsUrl[selectedDomain[0].key] = []
        setDomainsUrl({...domainsUrl})
        setDomains((prev: SelectorValue[]) => [...prev, selectedDomain[0]])
    },[domainsUrl]);

    const changeDomain = useCallback((e:  React.FormEvent<HTMLInputElement>) => setDomain(e.currentTarget.value),[]);

    const changePath = useCallback((e:  React.FormEvent<HTMLInputElement>) => setPath(e.currentTarget.value), []);

    const ElementsCategory = useCallback(() => {
        let categoriesCopy = [...Categories];
        return <>
            {categories.map((value: string, index: number) => {

                categoriesCopy.splice(categoriesCopy.findIndex((categoryPair) =>
                    categoryPair.key === value), 1);

                return <TwoFieldContainer key={index}>
                    <SelectContainer>
                        <Multiselect
                            selectedValues={[value]}
                            onSelect={selectedCategories}
                            closeIcon={'cancel'}
                            options={categoriesCopy}
                            style={styleSelect}
                            displayValue="key"
                            singleSelect={true}
                        />
                    </SelectContainer>
                    <SelectContainer>
                        <Multiselect
                            selectedValues={types.length > index ? [types[index]] : null}
                            onSelect={selectedTypes}
                            closeIcon={'cancel'}
                            options={Types}
                            style={styleSelect}
                            displayValue="key"
                            singleSelect={true}
                        />
                    </SelectContainer>
                </TwoFieldContainer>
            })}
            {categoriesCopy.length > 0 && <TwoFieldContainer>
                <SelectContainer>
                    <Multiselect
                        onSelect={selectedCategories}
                        closeIcon={'cancel'}
                        options={categoriesCopy}
                        style={styleSelect}
                        displayValue="key"
                        singleSelect={true}
                    />
                </SelectContainer>
                <SelectContainer>
                    <Multiselect
                        onSelect={selectedTypes}
                        closeIcon={'cancel'}
                        options={Types}
                        style={styleSelect}
                        displayValue="key"
                        singleSelect={true}
                    />
                </SelectContainer>
            </TwoFieldContainer>}
        </>
    }, [categories, selectedCategories, selectedTypes, types, styleSelect]);

    const ElementsDomain = useCallback(() => {
        let categoriesCopy = [...Categories];
        return <>
            {domains.map((value: { key: string, category: string }, index: number) => {
                categoriesCopy.splice(categoriesCopy.findIndex((categoryPair) =>
                    categoryPair.key === value.key), 1);

                return <TwoFieldContainer key={index}>
                    <SelectContainer>
                        <Multiselect
                            selectedValues={[value]}
                            onSelect={selectedDomains}
                            closeIcon={'cancel'}
                            options={categoriesCopy}
                            style={styleSelect}
                            displayValue="key"
                            singleSelect={true}
                        />
                    </SelectContainer>
                    <WrapInputContainer>
                        <FieldsInputDomain changeDomainList={setDomainsUrl} domainsUrl={domainsUrl} domain={value.key} theme={context.theme}/>
                    </WrapInputContainer>
                </TwoFieldContainer>
            })}
            {categoriesCopy.length > 0 && <TwoFieldContainer>
                <SelectContainer>
                    <Multiselect
                        onSelect={selectedDomains}
                        closeIcon={'cancel'}
                        options={categoriesCopy}
                        style={styleSelect}
                        displayValue="key"
                        singleSelect={true}
                    />
                </SelectContainer>
                <InputContainer>
                    <FieldInput nameTheme={context.theme} placeholder={'example.com'}/>
                </InputContainer>
            </TwoFieldContainer>}
        </>
    },[context.theme, domains, selectedDomains, domainsUrl, styleSelect])

    return <GeneralContainer nameTheme={context.theme}>
        <FieldContainer>
            <NameFieldContainer>
                <NameField>
                    Enabled
                </NameField>
            </NameFieldContainer>
            <FieldInputContainer>
                <CustomCheckbox
                    isProduction={isProduction}
                    nameTheme={context.theme}
                    onChange={() => setEnabled(!enabled)}
                    checked={enabled}/>
            </FieldInputContainer>
        </FieldContainer>
        <FieldContainer>
            <NameFieldContainer>
                <NameField>
                    Alert page
                </NameField>
            </NameFieldContainer>
            <TwoFieldContainer>
                <InputContainer>
                    <InputName>domain:</InputName>
                    <FieldInput placeholder={'example.com'} value={domain} onChange={changeDomain} nameTheme={context.theme}/>
                </InputContainer>
                <InputContainer>
                    <InputName>path:</InputName>
                    <FieldInput placeholder={'example.html'} value={path} nameTheme={context.theme} onChange={changePath}/>
                </InputContainer>
            </TwoFieldContainer>
        </FieldContainer>
        <FieldContainer>
            <NameFieldContainer>
                <NameField>
                    Services
                </NameField>
            </NameFieldContainer>
            <FieldInputContainer>
                <Multiselect
                    selectedValues={services}
                    onSelect={selectedServices}
                    closeIcon={'cancel'}
                    options={Services}
                    style={styleSelect}
                    displayValue="key"
                />
            </FieldInputContainer>
        </FieldContainer>
        <FieldContainer>
            <NameFieldContainer>
                <NameField>
                    Categories
                </NameField>
            </NameFieldContainer>
            <ElementsContainer>
                <ElementsCategory/>
            </ElementsContainer>
        </FieldContainer>
        <FieldContainer>
            <NameFieldContainer>
                <NameField>
                    Domains
                </NameField>
            </NameFieldContainer>
            <ElementsContainer>
                <ElementsDomain/>
            </ElementsContainer>
        </FieldContainer>
        <ButtonContainer>
            <ButtonSettings isProduction={isProduction} onClick={saveConfig}>
                {window.locales.saveChanges}
            </ButtonSettings>
        </ButtonContainer>
    </GeneralContainer>
}