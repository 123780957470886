import "./style.css"

window.log = console.log;
window.error = console.error;
window.debug = console.debug;
window.data = {};
window.Loading = {
    count: 1,
    loading: true,
    show: function () {
        window.Loading.count++;
        var e = document.getElementById("loadingContainer");
        window.Loading.loading = true;
        e.className = '';
    },
    hide: function (forced) {
        if (window.Loading.count === 0) return;
        window.Loading.count--;
        if (forced) window.Loading.count = 0;
        if (window.Loading.count > 0) return;

        var e = document.getElementById("loadingContainer");
        window.Loading.loading = false;
        e.className = 'hide';
    },
    status: function () {
        return window.Loading.loading;
    }
};