import React, {Component} from 'react';
import styled from "styled-components";

import Table from '../../../common/table';

import {
  IconPlus,
  IconDelete,
  IconUpload,
  IconDownload
} from '../../../common/icons';

import {
  Input
} from '../../../common/form';

import {
  saveDataToFile,
  loadDataFromFile
} from '../../../components/Bypass';

import {
  getProjects,
  getActiveProject
} from '../../../components/Projects';

import Modal from '../../../common/modal';

import Search from './Search';

const PREFIXES = {
  "production": "af-prod",
  "QA": "af-qa",
  "DEV": "af-dev",
  "DEV1": "af-dev1",
  "DEV2": "af-dev2",
  "DEV3": "af-dev3",
  "STAGE": "af-stage"
};

const BypassContainer = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding-top: 65px;
  
  td {
    .flexTd {
      height: 30px;
      align-items: center;

      .form {
        margin: 0;
        width: 300px;

        input {
          padding: 5px 15px;
          height: auto;
        }

        .label {
          left: 15px;
          right: 15px;
        }
      }
    }
  }
`;

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false,
      activeProject: props.activeProject,
      search: '',
      errors: {},
      i: 0
    };

    this.data = [];

    this.form = {
      domain: '',
      modified: false,
      error: ''
    };

    this.inputs = {};
    this.dump = JSON.stringify(props.dump);
    this.updateLog = [];
  }

  UNSAFE_componentWillReceiveProps(props) {
    let dump = JSON.stringify(props);
    if (dump === this.dump) return;
    this.dump = dump;

    this.data = [];
    if (props.activeProject.publickey !== this.state.activeProject.publickey) {
      this.updateLog = [];
    }

    this.setState({
      add: false,
      activeProject: props.activeProject,
      search: '',
      errors: {}
    }, this.componentDidMount.bind(this));
  }

  componentDidMount() {
    this.checkUrl();
    if (!this.fileUrl) return;

    window.Loading.show();
    loadDataFromFile(this.fileUrl).then((result) => {
      this.data = result;
      this.setState({i: this.state.i + 1, modified: false}, window.Loading.hide);
    }).catch(() => {
      window.Loading.hide();
    });
  }

  checkUrl() {
    this.fileUrl = undefined;
    try {
      this.fileUrl = JSON.parse(this.state.activeProject.config).files.bpl;
      if (!this.fileUrl.match(/^https?:\/\//)) {
        this.fileUrl = 'https://storage.googleapis.com/' + PREFIXES[window.config.project] + '/project/' + this.state.activeProject.publickey + '/files/bpl/' + this.fileUrl;
      }
    } catch (e) {
    }
  }

  checkDomain(string) {
    if (string.trim().match(/^((([A-Za-z0-9][A-Za-z0-9-]+[A-Za-z0-9])|(\*))\.)?([A-Za-z0-9]([A-Za-z0-9-]+)?[A-Za-z0-9])((\.[A-Za-z]{2,6})?\.([A-Za-z]{2,6}|\*))$/i)) return [string.trim(), true];
    return [string.trim(), false];
  }

  submitEdit() {
    if (!this.state.modified) return;
    this.modal.show();
  }

  submitAdd() {
    let [domain, ok] = this.checkDomain(this.form.domain);

    if (ok) {
      this.data.push(domain);
      this.updateLog.push([domain, 'Ok']);
      this.setState({i: this.state.i + 1, add: false, modified: true});
    } else {
      this.setState({i: this.state.i + 1, message: window.locales.domainNotValid});
    }
  }

  saveData() {
    if (!this.state.modified) return;
    let state = this.state;

    let temp = [];
    for (let k in this.data) {
      let [domain, ok] = this.checkDomain(this.data[k]);
      if (ok && temp.indexOf(domain) === -1) temp.push(domain);
    }
    this.data = temp;

    window.Loading.show();
    state.errors = {};
    saveDataToFile(this.state.activeProject.publickey, this.data.join('\n')).then(() => {
      getProjects(true).then(() => {
        ++state.i;
        state.modified = false;
        state.activeProject = getActiveProject();
        this.tempUpdateLog = this.updateLog
        this.downloadUpdateLog = this.updateLog
        this.setState(state, () => {
          this.checkUrl();
          this.updateLog = [];
          this.tempUpdateLog = undefined;
          window.Loading.hide();
        });
      });
    }).catch(() => {
      window.Loading.hide();
    });
  }

  toggleAdd() {
    this.form.domain = '';
    this.setState({add: !this.state.add, message: ''});
  }

  domainChanged(k, v) {
    this.data[k] = v;
    if (!this.state.modified) {
      this.setState({modified: true}, () => {
        this.inputs[k].setFocus();
      });
    }
  }

  fieldChanged(value) {
    this.form.domain = value;
  }

  removeElement(k) {
    let domain = this.data[k];
    for (let j in this.updateLog) {
      if (this.updateLog[j][0] === domain) {
        this.updateLog.splice(j, 1);
        break;
      }
    }
    this.data.splice(k, 1);
    this.setState({i: this.state.i + 1, modified: true});
  }

  downloadFile() {
    window.open(this.fileUrl);
  }

  searchChanged(search) {
    this.setState({
      search: search
    });
  }

  render() {
    let head = [
      [window.locales.domain],
      ['', {width: 50}]
    ];

    let body = [];
    if (this.state.add) {
      body.push([
        [
          [<Input
            key={1}
            label={window.locales.domain}
            theme={'dark'}
            value={this.form.domain}
            onChange={this.fieldChanged.bind(this)}
            onEnter={this.submitAdd.bind(this)}
          />,
            <div key={2} className={'saveButton'} onClick={this.submitAdd.bind(this)}>{window.locales.add}</div>,
            <div key={3}>{this.state.message}</div>]
        ],
        []
      ]);
    }

    for (let k in this.data) {
      if (this.state.search && this.state.search.trim() !== '' && this.data[k].indexOf(this.state.search) === -1) continue;
      body.push([
        [
          [<Input
            onRef={ref => {
              this.inputs[k] = ref;
            }}
            key={1}
            label={window.locales.domain}
            theme={'dark'}
            value={this.data[k]}
            onChange={this.domainChanged.bind(this, k)}
            onEnter={this.submitEdit.bind(this, k)}
            onBlur={this.checkIfValidDomain.bind(this, k)}
            onFocus={this.removeError.bind(this, k)}
          />,
            <div key={3} style={{marginLeft: '10px'}}>{this.state.errors[k]}</div>]
        ],
        [<IconDelete theme={'blue'} onClick={this.removeElement.bind(this, k)}/>]
      ]);
    }

    let cancelText = window.locales.domainsSaveQuestionCancel;
    let submitText = window.locales.domainsSaveQuestionSubmit;
    let submitFunction = this.saveData.bind(this);
    let style = {width: '700px', marginLeft: '-350px'}


    let updateLog;
    if (this.tempUpdateLog) {
      updateLog = [];
      for (let k in this.tempUpdateLog) {
        updateLog.push(<div key={k.trim()}><i>{this.tempUpdateLog[k][0].trim()}</i>: <b>{this.tempUpdateLog[k][1]}</b>
        </div>)
      }

      cancelText = window.locales.close;
      submitText = window.locales.downloadUserUpdateLog;
      submitFunction = this.downloadLog.bind(this);
      style = {width: '700px', marginLeft: '-350px'}
    }

    let submit = {
      label: submitText,
      click: submitFunction
    };

    if (updateLog && updateLog.length === 0) {
      updateLog = <div style={{textAlign: "center"}}>{window.locales.done}</div>;
      submit = undefined;
    }

    return (
        <BypassContainer>
          <div className={'bypassScreen'}>
            <Search
              publickey={this.state.activeProject.publickey}
              searchChanged={this.searchChanged.bind(this)}
            />
            {this.state.activeProject.role !== 'SUPPORT' &&
            <IconPlus theme={'blue'} label={window.locales.add} onClick={this.toggleAdd.bind(this)}/>}
            {this.state.activeProject.role !== 'SUPPORT' && <div style={{position: 'relative', display: 'flex'}}>
              <input onChange={this.handleFileUpload.bind(this)} type="file"
                     style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '30px', opacity: '0'}}/>
              <IconUpload style={{marginLeft: '10px'}} theme={'blue'} label={window.locales.upload} onClick={() => {
                this.modal.show()
              }}/>
            </div>}
            {this.fileUrl && <IconDownload style={{marginLeft: '10px'}} theme={'blue'} label={window.locales.download}
                                           onClick={this.downloadFile.bind(this)}/>}
            {this.state.activeProject.role !== 'SUPPORT' &&
            <div className={'saveButton' + (!this.state.modified ? ' disabled' : '')}
                 onClick={this.submitEdit.bind(this)}>{window.locales.save}</div>}
          </div>
          <Table head={head} body={body}/>
          <Modal
            title={window.locales.domainsSaveQuestionTitle}
            onRef={ref => {
              this.modal = ref;
            }}
            cancel={{
              label: cancelText,
              click: this.hideModal.bind(this)
            }}
            submit={submit}
            style={{style}}
          >
            {updateLog && <div className={"logPlaceholder"}>{updateLog}</div>}
            {!updateLog && window.locales.domainsSaveQuestionText}
          </Modal>
        </BypassContainer>
    );
  }

  downloadLog() {
    let date = new Date();
    let element = document.createElement('a');
    let log = [
      ["Domain,Status"]
    ];
    let total = 0,
      success = 0,
      notValid = 0,
      duplicated = 0;
    for (let k in this.downloadUpdateLog) {
      total++;
      if (this.downloadUpdateLog[k][1] === "Ok") success++;
      else if (this.downloadUpdateLog[k][1] === "Duplicate") duplicated++;
      else notValid++;
      log.push(this.downloadUpdateLog[k][0] + "," + this.downloadUpdateLog[k][1]);
    }

    log.push("\n\n");
    log.push("Total domains," + total);
    log.push("Successfully added," + success);
    log.push("Not valid domains," + notValid);
    log.push("Duplicate domains," + duplicated);

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(log.join("\n")));
    element.setAttribute('download', 'DOMAINS-UPLOAD_' + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + '.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    this.modal.hide();
  }

  hideModal() {
    this.modal.hide();
  }

  checkIfValidDomain(k) {
    let e = this.state.errors;
    let [, ok] = this.checkDomain(this.data[k]);
    if (!ok && !e[k]) {
      e[k] = window.locales.domainNotValid;
      this.setState({errors: e});
    }
  }

  removeError(k) {
    let e = this.state.errors;
    if (e[k]) {
      e[k] = '';
      this.setState({errors: e}, () => {
        this.inputs[k].setFocus();
      });
    }
  }

  handleFileUpload(event) {
    let file;
    try {
      file = event.target.files[0];
    } catch (e) {
    }

    this.form.file = '';

    if (!file || !file.name) {
      return this.setState({
        error: window.locales.bulkUserError
      })
    }

    this.form.file = file.name;
    let state = this.state;
    let reader = new FileReader();
    reader.onload = (theFile) => {
      try {
        let result = atob(theFile.target.result.replace(/[^,]+,([^,]+)$/, '$1')).split('\n');
        if (!result || result.length === 0) {
          return;
        }
        let changed = false;
        for (let k in result) {
          let [domain, ok] = this.checkDomain(result[k]);
          if (this.data.indexOf(domain) !== -1) {
            this.updateLog.push([domain, 'Duplicate']);
            continue;
          } else if (ok) {
            changed = true;
            this.updateLog.push([domain, 'Ok']);
          } else {
            this.updateLog.push([domain, window.locales.domainNotValid]);
            state.errors[this.data.length] = window.locales.domainNotValid;
          }

          this.data.push(domain);
        }
        ++state.i;
        if (changed) {
          state.modified = true;
        }
        this.setState(state)
      } catch (e) {

      }
    };
    reader.readAsDataURL(file);

  }
}

export default index;
