import React, {Component} from 'react';

import {
    Input,
    Select,
    Checkbox
} from '../../../common/form';

import {
    IconLock,
    IconPerson
} from '../../../common/icons';

import {
    signUp
} from '../../../common/firebase';

import {
    goTo
} from '../../../common/methods';

const PRODUCTS = [
    [window.locales.signUpProducts[0], window.locales.signUpProducts[0]],
    [window.locales.signUpProducts[1], window.locales.signUpProducts[1]],
    [window.locales.signUpProducts[2], window.locales.signUpProducts[2]],
    [window.locales.signUpProducts[3], window.locales.signUpProducts[3]],
];

const USERS = [
    [window.locales.signUpUsers[0], window.locales.signUpUsers[0]],
    [window.locales.signUpUsers[1], window.locales.signUpUsers[1]],
    [window.locales.signUpUsers[2], window.locales.signUpUsers[2]],
    [window.locales.signUpUsers[3], window.locales.signUpUsers[3]],
    [window.locales.signUpUsers[4], window.locales.signUpUsers[4]]
];

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {},
            form: {
                firstName: true,
                lastName: true,
                companyName: true,
                companyCode: true,
                email: true,
                password1: true,
                password2: true,
                plan: true,
                users: true,
                tos: true
            },
            disabled: false
        };

        this.form = {
            firstName: '',
            lastName: '',
            companyName: '',
            companyCode: '',
            email: '',
            password1: '',
            password2: '',
            plan: '',
            users: '',
            tos: 'off',
            button: 'Sign Up'
        };
    }

    fieldChanged(field, value) {
        this.form[field] = value;
    }

    componentDidMount() {
        window.Loading.hide();
    }

    submitForm() {
        if (this.state.disabled) return;
        let state = this.state;
        state.form = {
            firstName: true,
            lastName: true,
            companyName: true,
            companyCode: true,
            email: true,
            password1: true,
            password2: true,
            plan: true,
            users: true,
            tos: true
        };
        state.message = '';

        if (this.form.firstName === '') {
            state.form.firstName = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.lastName === '') {
            state.form.lastName = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.companyName === '') {
            state.form.companyName = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.companyCode === '') {
            state.form.companyCode = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (!this.form.companyCode.match(/^[a-z0-9_]{1,6}$/)) {
            state.form.companyCode = false;
            state.message = 'Company code should be 1-6 characters.';
            return this.setState(state);
        }

        if (this.form.email === '') {
            state.form.email = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (!this.form.email.match(/^(([^<>()\],;:\s@]+(\.[^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/)) {
            state.form.email = false;
            state.message = 'Enter valid email.';
            return this.setState(state);
        }

        if (this.form.password1 === '') {
            state.form.password1 = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.password2 === '') {
            state.form.password2 = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.password1 !== this.form.password2) {
            state.form.password1 = false;
            state.form.password2 = false;
            state.message = 'Passwords are not equals.';
            return this.setState(state);
        }

        if (this.form.password1.length < 6) {
            state.form.password1 = false;
            state.form.password2 = false;
            state.message = 'Password should be at least 6 characters';
            return this.setState(state);
        }

        if (this.form.plan === '') {
            state.form.plan = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.users === '') {
            state.form.users = false;
            state.message = window.locales.fillInFields;
            return this.setState(state);
        }

        if (this.form.tos !== 'on') {
            state.form.tos = false;
            state.message = 'You have to agree with terms of service.';
            return this.setState(state);
        }

        this.form.button = 'Please wait';
        state.disabled = true;
        window.Loading.show();
        this.setState(state, () => {
            signUp(this.form).then((response) => {
                let state = this.state;
                state.disabled = false;
                this.form.button = 'Sign Up';

                if (!response.success) {
                    state.message = response.message;
                }

                return this.setState(state, () => {
                    if (response.success) {
                        goTo('auth/verify');
                    }
                }, window.Loading.hide());
            });
        });
    }

    render() {
        return (
            <div className="signUpForm signUp">
                <div className={'logo business'} onClick={() => {
                    goTo('')
                }}/>
                <div className="errorMessage">
                    {this.state.message}
                </div>
                <div className="pageSwitcher">
                    <h3>{window.locales.signUp}</h3>
                    <a href="#/auth/signin">{window.locales.haveAccount}</a>
                </div>
                <div className="row twoElements">
                    <Input
                        type="text"
                        label={window.locales.firstName}
                        theme={'auth'}
                        value={this.form.firstName}
                        onChange={this.fieldChanged.bind(this, 'firstName')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.firstName}
                        disabled={this.state.disabled}
                        icon={<IconPerson theme={'auth'}/>}
                    />
                    <div className="delimiter"/>
                    <Input
                        type="text"
                        label={window.locales.lastName}
                        theme={'auth'}
                        value={this.form.lastName}
                        onChange={this.fieldChanged.bind(this, 'lastName')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.lastName}
                        disabled={this.state.disabled}
                        icon={<IconPerson theme={'auth'}/>}
                    />
                </div>
                <div className="row threeElements">
                    <Input
                        type="text"
                        label={window.locales.companyName}
                        theme={'auth'}
                        value={this.form.companyName}
                        onChange={this.fieldChanged.bind(this, 'companyName')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.companyName}
                        disabled={this.state.disabled}
                        icon={<IconPerson theme={'auth'}/>}
                    />
                    <div className="delimiter"/>
                    <Input
                        type="text"
                        label={window.locales.companyCode}
                        theme={'auth element2'}
                        value={this.form.companyCode}
                        onChange={this.fieldChanged.bind(this, 'companyCode')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.companyCode}
                        disabled={this.state.disabled}
                        icon={<IconPerson theme={'auth'}/>}
                    />
                </div>
                <div className="row delimiter">
                    <Input
                        type="text"
                        label={window.locales.email}
                        theme={'auth'}
                        value={this.form.email}
                        onChange={this.fieldChanged.bind(this, 'email')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.email}
                        disabled={this.state.disabled}
                        icon={<IconPerson theme={'auth'}/>}
                    />
                </div>
                <div className="row twoElements">
                    <Input
                        type="password"
                        label={window.locales.choosePassword}
                        theme={'auth'}
                        value={this.form.password1}
                        onChange={this.fieldChanged.bind(this, 'password1')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.password1}
                        disabled={this.state.disabled}
                        icon={<IconLock theme={'auth'}/>}
                    />
                    <div className="delimiter"/>
                    <Input
                        type="password"
                        label={window.locales.repeatPassword}
                        theme={'auth'}
                        value={this.form.password2}
                        onChange={this.fieldChanged.bind(this, 'password2')}
                        onEnter={this.submitForm.bind(this)}
                        notValid={!this.state.form.password2}
                        disabled={this.state.disabled}
                        icon={<IconLock theme={'auth'}/>}
                    />
                </div>
                <div className="row delimiter">
                    <Select
                        label={window.locales.whichProduct}
                        theme={'auth'}
                        value={this.form.plan}
                        onChange={this.fieldChanged.bind(this, 'plan')}
                        options={PRODUCTS}
                        notValid={!this.state.form.plan}
                        disabled={this.state.disabled}
                    />
                </div>
                <div className="row">
                    <Select
                        label={window.locales.numberOfUsers}
                        theme={'auth'}
                        value={this.form.plan}
                        onChange={this.fieldChanged.bind(this, 'users')}
                        options={USERS}
                        notValid={!this.state.form.users}
                        disabled={this.state.disabled}
                    />
                </div>
                <div className="row">
                    <Checkbox
                        label={<div>I agree with <a target="_blank"
                                                    href="/static/data/tos.docx">{window.locales.tos}</a></div>}
                        status={this.form.tos}
                        changed={this.fieldChanged.bind(this, 'tos')}
                        theme="whiteAuth"
                        disabled={this.state.disabled}
                    />
                </div>
                <div className="row delimiter">
                    <div className={'saveButton' + (this.state.disabled ? ' disabled' : '')}
                         onClick={this.submitForm.bind(this)}>{window.locales.signUp}</div>
                </div>
            </div>
        );
    }
}

export default SignUp;