import Network from '../../common/Network';

import { getActiveToken } from '../../components/Users';

const saveDataToFile = (publickey, file, name) => {
  name = name ? name : 'bpl';
  return new Promise((resolve, reject) => {
    let request = window.config.host + 'partner/files/upload';
    let params = {
      access_token: getActiveToken(),
      publickey: publickey,
      name: name,
    };

    Network.post(request, params, { isFile: true, file: file })
      .then((response) => {
        return resolve(response.response);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

const loadDataFromFile = (url) => {
  return new Promise((resolve, reject) => {
    Network.get(url)
      .then((response) => {
        let result = [];
        try {
          let t = response.response.split('\n');
          for (let k in t) {
            if (t[k].trim() !== '') result.push(t[k]);
          }
        } catch (e) {}

        return resolve(result);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

const loadDataFromFile2 = (url) => {
  return new Promise((resolve, reject) => {
    Network.get(url)
      .then((response) => {
        let result =
          response && response.response && response.response.length > 0 ? response.response : [];

        return resolve(result);
      })
      .catch((e) => {
        //return resolve([{"type":"wifi","ssid":["test","test1","test2"],"bssid":["asdkjh"],"action":"disable"},{"type":"wifi","ssid":["asdasdasd"],"bssid":[""],"action":"enable"},{"action":"enable"},{"type":"lan","action":"enable"}]);
        return reject(e);
      });
  });
};

export { saveDataToFile, loadDataFromFile, loadDataFromFile2 };
