import React, { Component } from 'react';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 20,
      height: props.height ? props.height : 20,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    let style = this.state.style;
    if (!style.height) style.height = this.state.height + 'px';

    return (
      <div
        id={this.state.id}
        className={
          'icons iconsStyle1 search ' + this.state.theme + (this.state.label ? ' flex' : '')
        }
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={'0 0 ' + this.state.width + ' ' + this.state.height}
        >
          <path
            fillRule="nonzero"
            d="M12.337 11.53l1.028.421 5.485 5.485-1.414 1.414-5.5-5.5-.387-1.03a7 7 0 1 1 .788-.791zM7 12A5 5 0 1 0 7 2a5 5 0 0 0 0 10z"
          />
        </svg>
        {this.state.label ? <div className="label">{this.state.label}</div> : ''}
      </div>
    );
  }
}

export default Search;
