import React, {Component} from 'react';
import "./Loader"
import User from './User';
import Event from './Event';
import Users from './Users';
import Export from './Export';
import Network from './Network';
import Settings from './Settings';
import Projects from './Projects';
import Dashboard from './Dashboard';
import ActiveSessions from './ActiveSessions';
import FirstProject from './Projects/Add';
import VPN from "./Settings/VPN";
import ViewProfile from "./ViewProfile";
import Billing from './Billing';
import AgreementOnNewTos from './AgreementOnNewTos';

import Header from './Header';
import SideMenu from './SideMenu';

import {getProjects, getActiveProject} from '../components/Projects';

import {signIn} from '../components/Users';

import {signOut} from '../common/firebase';

import {getAuthentifications} from '../components/Authentifications';

import {getLicenses} from '../components/Licenses';

import {goTo, changeProject} from '../common/methods';

import {grafanaSignIn} from '../components/Grafana';

import {Empty} from '../common/form';

class Portal extends Component {
    constructor(props) {
        super(props);

        const {projectPrefix, company_code, company} = window.user.infoFromFirebase;
        const prefixProjectCode = projectPrefix ? projectPrefix :
            company_code ? company_code : company;


        this.state = {
            path: props.path,
            params: props.params,
            prefixProjectCode: prefixProjectCode,
            showViewProfile: false,
        };

        this.no = false;
        this.dump = JSON.stringify(props);
        window.reloadPortal = this.componentDidMount.bind(this);
    }

    componentDidMount(props, clearCache) {
        if (window.Loading.status()) window.Loading.hide();
        window.Loading.show();
        if (window.config.reloadPortal) {
            clearCache = true;
            window.config.reloadPortal = false;
        }

        let state = this.state;
        if (props) {
            state.path = props.path;
            state.params = props.params;
        }

        if (['createProject', 'noprojects'].indexOf(this.state.path[0]) !== -1) {
            return this.setState(state, window.Loading.hide);
        }

        if (this.state.path[0] === 'projects') {
            return getProjects(clearCache)
                .then((projects) => {
                    state.projects = projects;

                    this.setState(state, window.Loading.hide);
                })
                .catch((e) => {
                    window.error(e);
                    if (e && e.message) {
                        signOut().then(() => {
                            goTo('');
                        });
                    } else if (window.user.infoFromFirebase && this.state.prefixProjectCode) {
                        goTo('createProject');
                    } else {
                        goTo('noprojects');
                    }
                });
        }

        this.no = true;

        return getProjects(clearCache)
            .then((projects) => {
                state.projects = projects;
                state.activeProject = getActiveProject();

                if (!state.activeProject) {
                    this.no = false;
                    return goTo('404');
                }

                if (['projects'].indexOf(this.state.path[0]) === -1 && !this.state.path[1]) {
                    this.no = false;
                    return changeProject(state.activeProject.publickey);
                }

                signIn(state.activeProject)
                    .then(() => {
                        getAuthentifications().then((authentifications) => {
                            getLicenses().then((licenses) => {
                                grafanaSignIn(window.user.name, state.activeProject.grafanakey).then((grafana) => {
                                    state.activeProject.grafana = grafana;
                                    state.activeProject.licenses = licenses;
                                    state.activeProject.authentifications = authentifications;
                                    this.no = false;
                                    return this.setState(state, window.Loading.hide);
                                });
                            });
                        });
                    })
                    .catch((e) => {
                        window.error(e);
                        this.no = false;
                        signOut().then(() => {
                            goTo('');
                        });
                    });
            })
            .catch((e) => {
                window.error(e);
                this.no = false;
                if (e && e.message) {
                    signOut().then(() => {
                        goTo('');
                    });
                } else if (window.user.infoFromFirebase && this.state.prefixProjectCode) {
                    goTo('createProject');
                } else {
                    goTo('noprojects');
                }
            });
    }

    shouldComponentUpdate() {
        return !this.no;
    }

    UNSAFE_componentWillReceiveProps(props) {
        let dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.componentDidMount(props);
    }

    updateActiveProject(project) {
        this.setState({
            activeProject: project,
        });
    }

    setShowViewProfile() {
        this.setState({
            showViewProfile: !this.state.showViewProfile
        });
    }

    render() {
        if (this.state.path[0] === 'noprojects') {
            return (
                <div id="portalContainer" style={{minHeight: 'calc(100vh - 75px)'}}>
                    <Header page={this.state.path[0]} setShowViewProfile={() => this.setShowViewProfile()}/>
                    {this.state.showViewProfile && <ViewProfile setShowViewProfile={() => this.setShowViewProfile()}/>}
                    <Empty theme={'projectsEmpty'} row1={window.locales.noProjects}/>
                    <AgreementOnNewTos prefixProjectCode={this.state.prefixProjectCode}
                                       showViewProfile={() => this.setShowViewProfile()}/>
                </div>
            );
        } else if (!this.state.projects
            && this.state.path[0] !== 'createProject') return <div/>;

        let permissions = [];
        if (this.state.activeProject) {
            try {
                permissions = this.state.activeProject.current_user_role.permissions;
            } catch (e) {
                window.error(e);
            }
        }

        let content;
        switch (this.state.path[0]) {
            case 'user':
                content = this.state.activeProject ? (
                    <User
                        path={this.state.path}
                        params={this.state.params}
                        activeProject={this.state.activeProject}
                    />
                ) : undefined;
                break;
            case 'users':
                content = this.state.activeProject ? (
                    <Users params={this.state.params} activeProject={this.state.activeProject}/>
                ) : undefined;
                break;
            case 'vpn':
                content = this.state.activeProject ? (
                    <VPN permissions={permissions} activeProject={this.state.activeProject}/>
                ) : undefined;
                break;
            case 'network':
            case 'pools':
                content = this.state.activeProject ? (
                    <Network
                        path={this.state.path}
                        activeProject={this.state.activeProject}
                        permissions={permissions}
                        updateActiveProject={this.updateActiveProject.bind(this)}
                    />
                ) : undefined;
                break;
            case 'settings':
                content = this.state.activeProject ? (
                    <Settings
                        tab={this.state.path[2] ? this.state.path[2] : 'parameters'}
                        activeProject={this.state.activeProject}
                        permissions={permissions}
                    />
                ) : undefined;
                break;
            case 'dashboard':
                content = (
                    <Dashboard
                        params={this.state.params}
                        tab={this.state.path[2] ? this.state.path[2] : 'general'}
                        path={this.state.path}
                        activeProject={this.state.activeProject}
                        permissions={permissions}
                    />
                );
                break;
            case 'projects':
                content = <Projects projects={this.state.projects}/>;
                break;
            case 'export':
                content = <Export activeProject={this.state.activeProject}/>;
                break;
            case 'active_sessions':
                content = <ActiveSessions activeProject={this.state.activeProject}/>;
                break;
            case 'event':
                content = <Event activeProject={this.state.activeProject} params={this.state.params}/>;
                break;
            case 'billing':
                content = <Billing activeProject={this.state.activeProject}/>;
                break;
            case 'createProject':
                return (
                    <div id="portalContainer" style={{minHeight: 'calc(100vh - 75px)'}}>
                        <Header setShowViewProfile={() => this.setShowViewProfile()}
                                page={this.state.path[0]}
                        />
                        {this.state.showViewProfile &&
                        <ViewProfile setShowViewProfile={() => this.setShowViewProfile()}/>}
                        <FirstProject
                            type={'firstproject'}/>
                    </div>
                );
            default:
                break;
        }

        return (
            <div id="portalContainer" style={{minHeight: 'calc(100vh - 75px)'}}>
                <Header setShowViewProfile={() => this.setShowViewProfile()}
                        page={this.state.path[0]}
                        projects={this.state.projects}
                        activeProject={this.state.activeProject}
                        updateActiveProject={(activeProject) => this.updateActiveProject(activeProject)}
                />
                {this.state.path[0] !== 'projects' && this.state.activeProject && (
                    <SideMenu
                        page={this.state.path[0]}
                        activeProject={this.state.activeProject}
                        permissions={permissions}
                    />
                )}
                {this.state.showViewProfile && <ViewProfile setShowViewProfile={() => this.setShowViewProfile()}/>}
                <AgreementOnNewTos prefixProjectCode={this.state.prefixProjectCode}
                                   showViewProfile={() => this.setShowViewProfile()}/>
                {content}
            </div>
        );
    }
}

export default Portal;
